import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Nav, Navbar, Form } from "react-bootstrap-v5";
import swal from "sweetalert";
import { nftService } from "../_services/nft.services";
import LoadingSpinerComponent from "./Loader";
import { useLocation } from "react-router-dom";
function Athletenfts() {
  const [username, setUsername] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [nameError, setNameError] = useState();
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [subject, setSubject] = useState();
  const [subjectError, setSubjectError] = useState();
  const [message, setMessage] = useState();
  const [messageError, setMessageError] = useState();

  const location =useLocation();
  let {fromHomePage } =location.state || {};

  // const NameRegex = /^[a-zA-Z][a-zA-Z\\s]+$/;
  const EmailRegex = /^[\w-.]+@([\w-])+.[\w-]{2,4}$/;

  const usernameHandler = (e) => {
    setUsername(e.target.value);

    if (!username) {
      setNameError("Name is required");
      // } else if (!username.match(NameRegex)) {
      //   setNameError("Only alphabets allowed")
      // } else if (username.length < 3) {
      //   setNameError("Name not less than 3 words")
    } else {
      setNameError("");
    }
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);

    if (!email) {
      setEmailError("Email is required");
    } else if (!email.match(EmailRegex)) {
      setEmailError("Email is Inavlid");
    } else {
      setEmailError("");
    }
  };

  const subjectHandler = (e) => {
    setSubject(e.target.value);

    if (!subject) {
      setSubjectError("Fill Subject");
    } else {
      setSubjectError("");
    }
  };

  const messageHandler = (e) => {
    setMessage(e.target.value);

    if (!message) {
      setMessageError("Message is required");
    } else {
      setMessageError("");
    }
  };

  const OnsubmitHandler = () => {
    if (!username) {
      setNameError("Name is required");
      // } else if (!username.match(NameRegex)) {
      //   setNameError("Only alphabets allowed")
    } else {
      setNameError("");
    }

    if (!email) {
      setEmailError("Email is required");
    } else if (!email.match(EmailRegex)) {
      setEmailError("Email is inavlid");
    } else {
      setEmailError("");
    }

    if (!subject) {
      setSubjectError("Subject is required");
    } else {
      setSubjectError("");
    }

    if (!message) {
      setMessageError("Message is required");
    } else {
      setMessageError("");
    }
    if (username && email && email.match(EmailRegex) && subject && message) {
      setShowLoader(true);
      const Data = {
        name: username,
        email: email,
        subject: subject,
        message: message,
        page: "Athletes",
      };
      nftService
        .postAPiAtheletenfts(Data)
        .then((res) => {
          setShowLoader(false);
          swal("Success", res.data.message, "success");
          setUsername("");
          setEmail("");
          setSubject("");
          setMessage("");
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };
//   const element
//   useEffect(() => {
//      = document.getElementById("athletenfts");
//     console.log("element:::", element);
//       element.scrollIntoView({ behavior: "smooth" });
// }, [fromHomePage, element]);

  

  return (
    <>
      <LoadingSpinerComponent promiseInProgress={showLoader} message={""} />
     
      <section className="athe-banner-area athelete-top area">
        <Container>
          <Row className="align-items-center flex-direction">
            <Col lg={6} md={8} xs={12}>
              <div className="ath-content">
                <h2>
                  Welcome to <span className="text-sport">Sportsfinda</span>
                </h2>
                <p>
                  Do you want to take control of your brand and maximize your
                  earning potential as an athlete? Sportsfinda can help you
                  unlock a new source of income by monetizing your Name, Image
                  and Likeness (NIL).
                </p>
                
              </div>
            </Col>
            <Col lg={6} md={4} xs={12}>
              <div className="ath-img banner-ath-image">
                <img
                  alt="logo"
                  src={require("../assets/images/athe.png")}
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="inner-bg">
        <div className="inner-content">
          <Container>
            <Row className="align-items-center mt-5">
              <Col md={6} xs={12}>
                <div className="unique-img">
                  <img
                    alt="logo"
                    src={require("../assets/images/unique.png")}
                  ></img>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="unique-content">
                  <h2>
                  Digital Trading Cards are{" "}
                    <span className="text-sport">
                      unique digital assets
                    </span>
                  </h2>
                  <p>
                    That represent ownership of a specific item, whether it's a
                    piece of art, a video clip, or even a tweet. They are stored
                    on a blockchain, which makes them secure and verifiable. By
                    creating and selling Digital Trading Cards that feature your
                    NIL, you can build your personal brand, connect with your
                    fans in new and exciting ways, and earn more from your NIL
                    than you ever could through traditional sponsorships or
                    endorsements
                  </p>
                 
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-content">
          <Container>
            <Row className="align-items-center mt-5 flex-direction">
              <Col md={6} xs={12}>
                <div className="unique-content">
                  <h2>
                    Sportsfinda provides a{" "}
                    <span className="text-sport">User-friendly platform</span>
                  </h2>
                  <p>
                    For creating and selling Digital Trading Cards, guidance on pricing and
                    marketing, and access to a network of collectors and fans.
                    We understand the challenges that athletes face in today's
                    fast-paced and competitive market, and we are dedicated to
                    helping you succeed both on and off the field
                  </p>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="unique-img">
                  <img
                    alt="logo"
                    src={require("../assets/images/plat.png")}
                  ></img>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-content">
          <Container>
            <Row className="align-items-center mt-5">
              <Col md={6} xs={12}>
                <div className="unique-img">
                  <img
                    alt="logo"
                    src={require("../assets/images/today.png")}
                  ></img>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="unique-content">
                  <h2>
                    Join Sportsfinda <span className="text-sport">today</span>
                  </h2>
                  <p>
                    Discover the power of Digital Trading Cards for yourself. Whether you're an
                    established athlete or an up-and-coming star, we can help
                    you turn your NIL into a lucrative source of income. Take
                    control of your brand, connect with your fans, and start
                    earning more today
                  </p>
                  {/* <Button variant="primary" className="btn-get-in">
                    Read More
                  </Button> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="get-in-section" id="athletes" >
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col lg={8}>
                <div className="get-in-area text-center" >
                  <h2>
                    Sign up today to start monetizing{" "}
                    <span className="text-sport">
                      <br></br>your NIL with Digital Trading Cards!
                    </span>
                  </h2>
                  <p>
                    Join the athletes who are already benefiting from this
                    innovative new technology <br />
                    and start building your brand and your future.
                  </p>
                  <div className="get-in-bg mt-5">
                    <Row className=" flex-direction">
                      <Col md={6}>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              type="text"
                              maxLength={50}
                              placeholder="Name"
                              value={username}
                              onKeyDown={(e) => {
                                if (!e.target.value && e.code === "Space") {
                                  e.preventDefault();
                                }
                              }}
                              onChange={usernameHandler}
                            />
                            {!username && (
                              <span className="text-danger">{nameError}</span>
                            )}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Control
                              type="email"
                              maxLength={50}
                              placeholder="Email"
                              onKeyDown={(e) => {
                                if (!e.target.value && e.code === "Space") {
                                  e.preventDefault();
                                }
                              }}
                              value={email}
                              onChange={emailHandler}
                            />
                            {(!email || !email.match(EmailRegex)) && (
                              <span className="text-danger">{emailError}</span>
                            )}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              type="text"
                              maxLength={100}
                              placeholder="Subject"
                              onKeyDown={(e) => {
                                if (!e.target.value && e.code === "Space") {
                                  e.preventDefault();
                                }
                              }}
                              value={subject}
                              onChange={subjectHandler}
                            />
                            {!subject && (
                              <span className="text-danger">
                                {subjectError}
                              </span>
                            )}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <textarea
                              name="postContent"
                              maxLength={250}
                              placeholder="Message"
                              className="form-control"
                              rows={4}
                              cols={40}
                              value={message}
                              onKeyDown={(e) => {
                                if (!e.target.value && e.code === "Space") {
                                  e.preventDefault();
                                }
                              }}
                              onChange={messageHandler}
                            />
                            {!message && (
                              <span className="text-danger">
                                {messageError}
                              </span>
                            )}
                          </Form.Group>
                          <Form.Group
                            className="mb-3 submit-btn"
                            controlId="formBasicEmail"
                          >
                            <Button
                              variant="primary"
                              className="btn-get-in"
                              onClick={OnsubmitHandler}
                            >
                              Submit
                            </Button>
                          </Form.Group>
                        </Form>
                      </Col>
                      <Col md={6}>
                        <div className="get-in-img">
                          <img
                            alt="logo"
                            src={
                              require("../assets/images/man-get.png")
                            }
                          ></img>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      
    </>
  );
}

export default Athletenfts;
