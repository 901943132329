import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap-v5";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let history = useNavigate();
  function Home() {
    history("/");
  }
  function Faq() {
    history("/faq");
  }
  function Terms() {
    history("/terms");
  }
  function Privacy() {
    history("/privacy");
  }
  function About() {
    history("/about");
  }
  function Home() {
    // global_data.set_active('home')
    sessionStorage.setItem("active", "home");
    history("/");
  }
  function FaqPage() {
    history("/faq");
  }

  return (
    <>
      <footer>
        <Container>
          {/* <Row>
            <Col md={4} className="text-start">
              
            </Col>
            <Col md={12} className="footerline mt-3 mb-4"></Col>
          </Row> */}
          <Row className="mt-3">
            <Col md={6} className="text-start term-privacy-box-new">
              <div className="leftfooter">
                <img
                  src={require("../assets/images/logo-footer.png")}
                  alt="logo"
                  onClick={Home}
                  style={{ cursor: "pointer" }}
                />
                <div className="rightsdefoote">
                  <a
                    href="https://www.facebook.com/Sportsfinda/"
                    className="icons_1 border-0 text-white"
                    variant=""
                    target="blank"
                  >
                    <img
                      src={require("../assets/images/fb.svg").default}
                      alt=""
                      className="me-2"
                      width=""
                    />
                  </a>
                  <a
                    href="https://twitter.com/sportsfinda"
                    className="icons_1 border-0 text-white"
                    variant=""
                    target="blank"
                  >
                    <img
                      src={require("../assets/images/tw.svg").default}
                      alt=""
                      className="me-2"
                      width=""
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/sportsfinda/"
                    className="icons_1 border-0 text-white"
                    variant=""
                    target="blank"
                  >
                    <img
                      src={require("../assets/images/insta.svg").default}
                      alt=""
                      className="me-2"
                      width=""
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/sports-finda/"
                    className="icons_1 border-0 text-white"
                    variant=""
                    target="blank"
                  >
                    <img
                      src={require("../assets/images/linkedin.svg").default}
                      alt=""
                      className="me-2"
                      width=""
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCw80F1j5juyIyiqI59JWwAA/"
                    className="icons_1 border-0 text-white"
                    variant=""
                    target="blank"
                  >
                    <img
                      src={require("../assets/images/youtube.svg").default}
                      alt=""
                      className="me-2"
                      width=""
                    />
                  </a>
                  <a
                    href="https://www.tiktok.com/@sportsfinda"
                    className="icons_1 border-0 text-white"
                    variant=""
                    target="blank"
                  >
                    <img
                      src={require("../assets/images/tik-tok.png")}
                      alt=""
                      className="me-2"
                      width=""
                    />
                  </a>
                </div>
                <div className="rightsdefooter d-flex">
                  <p className="mb-0" onClick={Terms}>
                    Terms & Conditions
                  </p>
                  <p className="mb-0 ms-4" onClick={Privacy}>
                    Privacy Policy
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} className="rightsdefooter">
              <Row>
                <Col s={6} className="text-start">
                  <h4>Services</h4>
                  <p onClick={handleShow}>Contact Support</p>
                  <p>
                    <a
                      href="https://open.spotify.com/show/5nu6jMAr07bZ3PzpCAo6of?go=1&sp_cid=8e8f0ba6cf64e74162defd1633dd9d8f&utm_source=embed_player_p&utm_medium=desktop&nd=1"
                      target="blank"
                    >
                      Podcast
                    </a>
                  </p>
                  <p onClick={About}>About us</p>
                </Col>

                <Col s={6} className="text-start">
                  <h4>Help</h4>
                  <p onClick={FaqPage}>FAQs</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}></Col>
          </Row>
        </Container>
      </footer>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        backdrop="static"
        centered
        className="text-center meta-mask-modal edit-profile-modal contact-us-modal"
      >
        <span className="modalclose_icon" onClick={handleClose}>
          <i className="fa fa-close"></i>
        </span>
        <Modal.Body className="modal-email footer-area-email">
          Send us an email at <span>support@sportsfinda.com</span> <br></br>Our
          team will contact you shortly.
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Footer;
