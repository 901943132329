import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MoralisProvider } from "react-moralis";
import GlobalState from './Context/GlobalState';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MoralisProvider appId="LzVSAtspfphdJ8uUMLGxI7v0XeV7KOWOlXYfUtXT" serverUrl="https://uy6flaadytxv.usemoralis.com:2053/server">
      <GlobalState>
      <App />
      </GlobalState>
    </MoralisProvider>
  </React.StrictMode>,
  // document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
