
export const formValidation = {
    loginvalidation,
    createprofile,
    Register_validation,
    item_validation,
    password_validation,
    item_validation_auction,
    collection_validation
    
  
};
function createprofile(params,errors){
  let fields = params;
  errors.formIsValid = true;
// console.log("===params==",params)
//   if (!fields["email"] || fields["email"].trim()==="") {
//     errors.formIsValid = false;
//     errors.email='E-mail field can’t be empty.'
//  }else{
//   if (fields["email"] !== "") {
//     var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
//     if (!pattern.test(fields["email"])) {
//       errors.formIsValid = false;
//       errors.email='Entered E-mail address is not valid.'
//     }else{
//       // emailvalid = true
//       errors.email=''
//      }
//   } 
//  }
 if (!fields["firstname"] || fields["firstname"].trim()==="") {
  errors.formIsValid = false;
  errors.firstname='Username field can’t be empty.'
}
// if (!fields["lastname"] || fields["lastname"].trim()==="") {
//   errors.formIsValid = false;
//   errors.lastname='Last Name field can’t be empty.'
// }


return errors;
}
function loginvalidation(params,errors) {
  
    let fields = params;
    // let errors :any = []; 

  
 
    // let emailvalid = false
  
    errors.formIsValid = true;
    if (!fields["email"] || fields["email"].trim()==="") {
      errors.formIsValid = false;
      errors.email='E-mail field can’t be empty.'
   }else{
    errors.email = "";
   }
    // if (fields["email"] !== "") {
    //   var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
    //   if (!pattern.test(fields["email"])) {
    //     errors.formIsValid = false;
    //     errors.email='Entered E-mail address is not valid.'
    //   }else{
    //     // emailvalid = true
    //     errors.email=''
    //    }
    // } 
    if (!fields["password"] || fields["password"].trim()==="") {
      errors.formIsValid = false;
      errors.password = 'Password field can’t be empty.'
      } else{
      errors.password = "";
      }
   
      // if (fields["password"] && fields["password"].length<6 && fields["password"]!=="") {
      //   errors.formIsValid = false;
      //   errors.password = 'Password must be at least 6 characters.'
      //  }else{
      //   if (fields["password"].trim()=="") {
      //     errors.formIsValid = false;
      //     errors.password = 'Password field can’t be empty.'
      //     } else{
      //     errors.password = "";
      //     }
       
      // }
  
  
  
    return errors;

}


function Register_validation(params,errors) {
  let fields = params;
  // let emailvalid = false

  errors.formIsValid = true;

  if (!fields["firstName"] || fields["firstName"].trim()==="") {
    errors.formIsValid = false;
    errors.firstName='First name field can’t be empty.'
  }else{
    errors.firstName = "";
  }
  if (!fields["lastName"] || fields["lastName"].trim()==="") {
    errors.formIsValid = false;
    errors.lastName='Last name field can’t be empty.'
  }else{
    errors.lastName = "";
  }

  if (!fields["email"] || fields["email"].trim()==="") {
    errors.formIsValid = false;
    errors.email='E-mail field can’t be empty.'
 }else{
  errors.email = "";
 }
  if (fields["email"] &&fields["email"] !== "") {
    var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
    if (!pattern.test(fields["email"])) {
      errors.formIsValid = false; 
      errors.email='Entered E-mail address is not valid.'
    }else{
      // emailvalid = true
      errors.email=''
     }
  } 
  if (!fields["password"] || fields["password"].trim()==="") {
    errors.formIsValid = false;
    errors.password = 'Password field can’t be empty.'
    } else if (fields["password"] && fields["password"].length<8 && fields["password"]!=="") {
      errors.formIsValid = false;
      errors.password = 'Password must be at least 8 characters.'
     }else if (fields["password"] && fields["password"].trim()=="") {
        errors.formIsValid = false;
        errors.password = 'Password field can’t be empty.'
        } 
     
    
    if (!fields["cpassword"]) {
    errors.formIsValid = false;
    errors.cpassword= 'Confirm Password field can’t be empty.'; 
    }else if (fields["cpassword"] && fields["cpassword"].trim()=="") {
      errors.formIsValid = false;
      errors.cpassword= 'Confirm Password field can’t be empty.'; 
      }else  if(fields["cpassword"] !== fields["password"]){
      errors.formIsValid = false;
      errors.cpassword= 'Password and Confirm password doesn’t match.'
    
    }else{
      errors.cpassword = "";
      }
     
        
  return errors;


}


function password_validation(params,errors){
  let fields = params;
  // let emailvalid = false
   errors.formIsValid = true;
  if (!fields["password"] || fields["password"].trim()==="") {
    errors.formIsValid = false;
    errors.password = 'Password field can’t be empty.'
    } else   if (fields["password"] && fields["password"].trim().length<8 && fields["password"]!=="") {
      errors.formIsValid = false;
      errors.password = 'Password must be at least 8 characters.'
     }else if (fields["password"] && fields["password"].trim()=="") {
        errors.formIsValid = false;
        errors.password = 'Password field can’t be empty.'
        } 
    
    if (!fields["cpassword"]) {
    errors.formIsValid = false;
    errors.cpassword= 'Confirm Password field can’t be empty.'; 
    }else{
    errors.cpassword = "";
    }

    if (fields["cpassword"] && fields["cpassword"].trim()=="") {
      errors.formIsValid = false;
      errors.cpassword= 'Confirm Password field can’t be empty.'; 
      }else{
      errors.cpassword = "";
      }
    if(fields["cpassword"] !== fields["password"]){
      errors.formIsValid = false;
      errors.cpassword= 'Password and Confirm password doesn’t match.'
    
    }else{
      errors.cpassword = "";
      }
      return errors;
}

function collection_validation(params,errors){
  let fields = params;
  // let emailvalid = false
   errors.formIsValid1 = true;
   if (!fields["image"]) {
    errors.formIsValid1 = false;
    errors.image = 'Please select Collection Image.'
    } 
  if (!fields["name"] || fields["name"].trim()==="") {
    errors.formIsValid1 = false;
    errors.name = 'Collection Name field can’t be empty.'
    } else if (fields["name"] && fields["name"].trim().length<3 && fields["name"]!=="") {
      errors.formIsValid1 = false;
      errors.name = 'Collection Name must be at least 3 characters.'
     }else if (fields["name"] && fields["name"].length>25 && fields["name"]!=="") {
      errors.formIsValid1 = false;
      errors.name = 'Collection name should be maximum 25 charchters.'
     }
     if (!fields["description"]){
      errors.formIsValid1 = false;
      errors.description = 'Collection description field can’t be empty.'
     }
     if (fields["description"] && fields["description"].length>500 && fields["description"]!=="") {
      errors.formIsValid1 = false;
      errors.description = 'Collection description should be maximum 500 charchters.'
     }
   
                    if (!fields["cover_image"]) {
                      errors.formIsValid1 = false;
                      errors.coverimage = 'Please select a cover image.'
                      }
                    
      
      return errors;
}


function item_validation(params,errors){
  let fields = params;
  // let emailvalid = false
   errors.formIsValid = true;
   if (!fields["image"]) {
    errors.formIsValid = false;
    errors.image = 'Please select item Image.'
    } 
  if (!fields["name"] || fields["name"].trim()==="") {
    errors.formIsValid = false;
    errors.name = 'Item Name field can’t be empty.'
    } else if (fields["name"] && fields["name"].trim().length<3 && fields["name"]!=="") {
      errors.formIsValid = false;
      errors.name = 'Item Name must be at least 3 characters.'
     }else if (fields["name"] && fields["name"].length>25 && fields["name"]!=="") {
      errors.formIsValid = false;
      errors.name = 'Item name should be maximum 25 charchters.'
     }
     if (!fields["description"]) {
      errors.formIsValid = false;
      errors.description = 'Item description field can’t be empty.'
      }else if(fields["description"] && fields["description"].trim()== "" ){
        errors.formIsValid = false;
        errors.description = 'Item description field can’t be empty.'
      }
      if (fields["royality_check"] && fields["royality_check"]==1) {

        if(fields["royality_percent"]== 0) {
          errors.formIsValid = false;
          errors.royality_percent= 'Royality percentage. can not be  0.';
          }
          if(fields["royality_percent"]> 15) {
            errors.formIsValid = false;
            errors.royality_percent= 'Royality percentage. can not be  greater than 15%.';
            }
          if(fields["royality_percent"] && fields["royality_percent"].trim()== "") {
            errors.formIsValid = false;
            errors.royality_percent= 'Royality percentage. can not be  empty.';
            }
  
            if(fields["royality_percent"] % 1 != 0 ){
              // alert(isNaN(fields["price"]) )
              if(isNaN(fields["royality_percent"])){
                
                errors.formIsValid = false;
                errors.royality_percent= 'Enter a valid Royality percentage.';  
              }
          
            }
          }
     if(fields['instant_check']==1){
    if (!fields["price"] || fields["price"].trim()=="") {

      errors.formIsValid = false;
      errors.price= 'Price field can’t be empty.'; 
      }else if (fields["price"]) {

          if(fields["price"] % 1 != 0 ){
            // alert(isNaN(fields["price"]) )
            if(isNaN(fields["price"])){
              
              errors.formIsValid = false;
              errors.price= 'Enter a valid price.';  
            }
        
          }else{
          
              if(fields["price"]== 0) {
                errors.formIsValid = false;
                errors.price= 'Price can not be  0.';
                }
          }
        
        }
      }else{
        if (!fields["MinBid"] || fields["MinBid"].trim()=="") {

          errors.formIsValid = false;
          errors.MinBid= 'MinBid Price field can’t be empty.'; 
          }else if (fields["MinBid"]) {
    
              if(fields["MinBid"] % 1 != 0 ){
                // alert(isNaN(fields["price"]) )
                if(isNaN(fields["MinBid"])){
                  
                  errors.formIsValid = false;
                  errors.MinBid= 'Enter a valid MinBid price.';  
                }
            
              }else{
              
                  if(fields["MinBid"]== 0) {
                    errors.formIsValid = false;
                    errors.MinBid= 'MinBid Price can not be  0.';
                    }
              }
            
            }

            if (!fields["ReservePrice"] || fields["ReservePrice"].trim()=="") {

              errors.formIsValid = false;
              errors.ReservePrice= 'Reserve Price  field can’t be empty.'; 
              }else if (fields["ReservePrice"]) {
        
                  if(fields["ReservePrice"] % 1 != 0 ){
                    // alert(isNaN(fields["price"]) )
                    if(isNaN(fields["ReservePrice"])){
                      
                      errors.formIsValid = false;
                      errors.ReservePrice= 'Enter a valid Reserve price.';  
                    }
                
                  }else{
                  
                      if(fields["ReservePrice"]== 0) {
                        errors.formIsValid = false;
                        errors.ReservePrice= 'Reserve Price can not be  0.';
                        }
                  }
                
                }
              
             
                
                if (!fields["StartDate"]) {
                  errors.formIsValid = false;
                  errors.StartDate = 'Start Date field can’t be empty.'
                  }
                  if (!fields["Enddate"]) {
                    errors.formIsValid = false;
                    errors.Enddate = 'End date field can’t be empty.'
                    }
                    if (!fields["image"]) {
                      errors.formIsValid = false;
                      errors.image = 'Please select an image.'
                      }
                    
      }
      return errors;
}

// item_validation_auction

function item_validation_auction(params,errors){
  let fields = params;
  // let emailvalid = false
   errors.formIsValid = true;
   if (!fields["image"]) {
    errors.formIsValid = false;
    errors.image = 'Please select item Image.'
    } 
  if (!fields["name"] || fields["name"].trim()==="") {
    errors.formIsValid = false;
    errors.name = 'Item Name field can’t be empty.'
    } else if (fields["name"] && fields["name"].trim().length<3 && fields["name"]!=="") {
      errors.formIsValid = false;
      errors.name = 'Item Name must be at least 3 characters.'
     }else if (fields["name"] && fields["name"].length>25 && fields["name"]!=="") {
      errors.formIsValid = false;
      errors.name = 'Item name should be maximum 25 charchters.'
     }
     if (!fields["description"]) {
      errors.formIsValid = false;
      errors.description = 'Item description field can’t be empty.'
      }else if(fields["description"] && fields["description"].trim()== "" ){
        errors.formIsValid = false;
        errors.description = 'Item description field can’t be empty.'
      }
      if (!fields["collectionId"]) {
        errors.formIsValid = false;
        errors.collection = 'Please select a collection.'
        }
        if (!fields["qty"]) {
          errors.formIsValid = false;
          errors.qty = 'Please enter quantity.'
          }else{
            if(fields["qty"]<= 0) {
              errors.formIsValid = false;
              errors.qty= 'Quantity should be greater than 0.';
              }
              if(fields["qty"]<1) {
                errors.formIsValid = false;
                errors.qty= 'Quantity should be 1 or greater than 1.';
                }
                if(fields["qty"]>2000) {
                  errors.formIsValid = false;
                  errors.qty= 'Quantity should be 2000 or less than 2000.';
                  }
          }
          if (!fields["price"]) {
            errors.formIsValid = false;
            errors.price = 'Please enter price.'
            }else
          if (fields["price"]) {

            if(fields["price"] < 0 ){
              errors.formIsValid = false;
                errors.price= 'Enter a valid price.Price should be greater than 0';  
              
          
            }else{
            
                if(fields["price"]== 0) {
                  errors.formIsValid = false;
                  errors.price= 'Price can not be  0.';
                  }
            }
          
          }
        
      return errors;
}