import { Link } from 'react-router-dom';
import React, { useState, useContext } from 'react';
import { Accordion, Container, Modal } from 'react-bootstrap';
import { useMoralis } from "react-moralis";
import GlobalContext from "../Context/GlobalContext";
import { useNavigate } from "react-router-dom";

const Faq=()=>{

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    var global_data=useContext(GlobalContext)
    const {  authenticate,isAuthenticated, user,userError,error,logout,isAuthenticating,Moralis , enableWeb3, isWeb3Enabled  } = useMoralis();
    // async function loginwithmetamask() {
    //     global_data.set_active('market')
    //     checkWeb3()
    //       await authenticate().then((response) => {
  
    //           if (userError){
    //               console.log("User Error flagged")
    //               // setLoader(false)
    //               console.log(response)
    //           } else {
    //               // window.location.reload();
                
    //               let currentuser =  Moralis.User.current();
  
    //               console.log(currentuser)
    //               if(currentuser == undefined){
    //                   // setshowmodale(true)
    //               }else{
    //                   if(currentuser && currentuser!=null && currentuser.get("firstname") == undefined){
                      
    //                       console.log(user,"open modal====");
    //                    }
    //               }
  
    //               // setLoader(false)
    //               // setshowmodale(false)
    //           }
    //       })
    //       .catch((err) => {
    //           console.log("catch errorrrrrrrrrr")
             
    //           console.log("Failed update")
    //            console.log(error)
    //           console.error("Error signing in.", err.toString());
    //       // setErrorMsg(response.toString());
    //       })
    //   }
      async function checkWeb3() {
        const ethereum = window.ethereum;
        if (!ethereum || !ethereum.on) {
            setShow(true)
        }
      }
      let history=useNavigate(); 
    return(
            <>
                <section className="works-area-box faq-page-box">
                    <Container>
                        <h2>F.A.Q.</h2>
                        <div className="faq-box">
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>How do I connect my Wallet?</Accordion.Header>
                                        <Accordion.Body>
                                            When you sign in to your Sportsfinda account for the first time, a pop-up window will<br></br>
                                            appear asking you to connect your wallet.<br></br>
                                            Step 1: Select ‘Connect’ on the pop-up.<br></br>
                                            Step 2: Select the wallet you would like to connect, we strongly recommend using the
                                            MetaMask extension.<br></br>
                                            Step 3: Once selected, a MetaMask window will open with a “Signature request”.<br></br>
                                            Select the correct account then select ‘sign’. Your MetaMask wallet will now be
                                            connected to the Sportsfinda site.<br></br>
                                            Connect your wallet   {isAuthenticated ? (
                                             <a onClick={(e)=>{global_data.set_active('market'); history('/marketplace')}}  style={{color:"#fe7702", cursor:"pointer"}} >here</a>):( <Link to="#"  style={{color:"#fe7702", cursor:"pointer"}}>here</Link>)}<br></br>
                                            Find out how to create a MetaMask wallet <a href="https://metamask.io/" target="_blank" style={{color:"#fe7702", cursor:"pointer"}}>here</a>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>How do I buy an Digital Trading Cards with Crypto?</Accordion.Header>
                                        <Accordion.Body>
                                            To purchase an Digital Trading Cards using crypto:<br></br>
                                            Step 1: Select the desired item.<br></br>
                                            Step 2: Select crypto as the payment method or press ‘Buy Now’.<br></br>
                                            Step 3: You will then be required to approve two transactions:<br></br>
                                            1. You will be asked to approve the spending of your ETH tokens.<br></br>
                                            2. Then you will be able to confirm the transaction.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>How do i claim my purchased Digital Trading Cardss?</Accordion.Header>
                                        <Accordion.Body>
                                            There are two ways to claim your Digital Trading Cards:<br></br>
                                            ● You will receive an email shortly after completing your purchase with a link
                                            to claim your Digital Trading Cards. Click on the link then, click on “Connect the wallet”. You
                                            will then be able to claim your Digital Trading Cards and proceed with the transaction.<br></br>
                                            ● Click the ‘My profile’ link on the top right corner of the page. You will see
                                            your purchased Digital Trading Cards in the “My claimable Digital Trading Cardss” section. Then, click on
                                            “Connect the wallet” (if not yet connected), then “Claim your Digital Trading Cards” and
                                            proceed with the transaction.<br></br>
                                            You cannot currently claim an Digital Trading Cards on Sportsfinda using a hard wallet.<br></br>
                                           
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Where can I find Digital Trading Cards Sales?</Accordion.Header>
                                        <Accordion.Body>
                                           <p> We understand that Blockchains and Crypto wallets can seem complicated to new
                                            users. That’s why we’ve written this guide to help you find your Digital Trading Cards Sales.<br></br>
                                            <b>Navigating Etherscan</b><br></br>
                                            Etherscan is a useful tool for exploring the Ethereum blockchain. You can search your
                                            wallet address to see your token balance or enter a transaction hash to verify
                                            transaction history. It's an easy way to verify a transaction's completion.</p>

                                            <img src={require('../assets/images/img1.png')}  alt="image"></img>
                                            <p className="mt-3">If you are using the MetaMask wallet, select the three dots at the top right of the
                                            extension, and select "View on Etherscan" to go view your token balance and
                                            transaction history on Etherscan.</p>

                                            <img src={require('../assets/images/img2.png')}  alt="image"></img>

                                            <p className="mt-3">Transactions such as Digital Trading Cards-sales, referrals, and royalty payments are viewable under
                                            the "Internal Txns" tab on an Etherscan account page.
                                            Please keep in mind the transaction records will have Sportsfinda's 5% fee and project
                                            royalties (if any) automatically deducted. Crypto wallets like MetaMask will not
                                            display internal transactions.</p>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>What is the floor price?</Accordion.Header>
                                        <Accordion.Body>
                                            The floor price is the lowest price for collection items, rather than the average item
                                            price, and is updated in real-time. Auctions are not included in floor price
                                            calculations.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>What are gas fees?</Accordion.Header>
                                        <Accordion.Body>
                                            Gas fees are like transaction fees on the Ethereum blockchain. When you make
                                            transactions, such as transferring crypto to another wallet or purchasing an Digital Trading Cards on
                                            Sportsfinda, you'll need enough ETH in your wallet for the initial transaction and the
                                            associated gas fees.<br></br>
                                            This guide explains the different fees users will pay when using the Ethereum
                                            blockchain on Sportsfinda.<br></br>
                                            There are three types of gas fees:<br></br>
                                            ● One-time fees<br></br>
                                            ● recurring fees<br></br>
                                            ● and actions that are gas-free<br></br>
                                            One-Time Fees<br></br>
                                            #1 Account Registration fees<br></br>
                                            If you’ve never sold on Sportsfinda before, you’ll need to pay an account initialization
                                            fee before you can list on Ethereum.<br></br>
                                            This allows trading between your wallet and Sportsfinda, and also allows Sportsfinda
                                            to access transfer items when a sale occurs.<br></br>
                                            #2 Token or Contract Approval (WETH, USDC, etc.)<br></br>
                                            If the item you are listing was not minted on Sportsfinda but through a custom
                                            Digital Trading Cards-contract, you will need to pay a one-time approval fee authorizing transactions. If
                                            this is your first time listing an auction, you will also need to approve WETH for
                                            trading.<br></br>
                                            Recurring Fees<br></br>
                                            You will pay gas fees on Ethereum when:<br></br>
                                            ● Accepting an offer<br></br>
                                            ● Transferring (or Gifting) an Digital Trading Cards to someone<br></br>
                                            ● Buying an Digital Trading Cards<br></br>
                                            ● Canceling a listed Digital Trading Cards<br></br>
                                            ● Canceling a Bid<br></br>
                                            ● Converting WETH back to ETH, and vice versa.<br></br>
                                            Gas-Free Actions<br></br>
                                            ● Minting a new Digital Trading Cards<br></br>
                                            ● Creating a collection<br></br>
                                            ● Listing an Digital Trading Cards as a fixed price<br></br>
                                            ● Listing an Digital Trading Cards as an auction<br></br>
                                            ● Reducing the price of an Digital Trading Cards you've listed* (WETH is only needed when you
                                            accept or create an offer)<br></br>
                                            Moving forward, because gas prices do fluctuate due to network congestion, it's
                                            important to check websites like ETH Gas Station before confirming a transaction. If
                                            gas prices are high, one possible solution is to try the transaction again later when
                                            prices are lower. <br></br>⚠️ Please note that not using enough gas in a transaction may result in the loss of
                                            the transaction fee. This is likely to happen during popular releases where many users
                                            are attempting to purchase a limited supply of Digital Trading Cardss.
                                            calculations.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>How can I contact Sportsfinda?</Accordion.Header>
                                        <Accordion.Body>
                                            You can fill out a request for support via email <b  onClick={handleShow} style={{cursor:"pointer", color:"#fe7702"}}>here</b>.<br></br>
                                            Please provide:<br></br>
                                            ● Your email address<br></br>
                                            ● Your wallet address (if applicable)<br></br>
                                            ● Choose relevant categories<br></br>
                                            ● Provide detailed information regarding your inquiry.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                        </div>
                        <h2 className="mt-5">Join the Community</h2>
                        <ul className="social-icon">
                            <li><a href="https://discord.com/invite/NvQStBhZ"><img src={require('../assets/images/discord.png')} alt="icon"></img></a></li>
                            <li><a href="https://t.me/sportsfinda"><img src={require('../assets/images/telegram.png')} alt="icon"></img></a></li>
                            <li><a href="https://www.facebook.com/Sportsfinda/"><img src={require('../assets/images/facebook.png')} alt="icon"></img></a></li>
                            <li><a href="https://www.instagram.com/accounts/login/"><img src={require('../assets/images/instagram.png')} alt="icon"></img></a></li>
                            <li><a href="https://twitter.com/sportsfinda"><img src={require('../assets/images/twitter.png')} alt="icon"></img></a></li>
                            <li><a href="https://www.youtube.com/channel/UCw80F1j5juyIyiqI59JWwAA"><img src={require('../assets/images/youtube.png')} alt="icon"></img></a></li>
                            <li><a href="https://www.tiktok.com/notfound"><img src={require('../assets/images/tik-tok.png')} alt="icon"></img></a></li>
                        </ul>
                    </Container>
                </section>

                <Modal show={show} onHide={handleClose} animation={false} centered className="text-center meta-mask-modal edit-profile-modal">
                    <span className="modalclose_icon" onClick={handleClose}>
                        <i className="fa fa-close"></i>
                    </span>
                    <Modal.Body className="modal-email">Send us an email at <span>support@sportsfinda.com</span> <br></br>Our team will contact you shortly</Modal.Body>
                </Modal>
            </>


    );
}
export default Faq;
