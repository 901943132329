import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import "./assets/css/style2.css";
import Header from "./Component/Header";
import Home from "./Pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Component/Footer";
import Seller_profile from "./Pages/Seller_profile";
// import ScrollToTop from "./Component/Scroll_top";
import Edit_profile from "./Pages/Edit_profile";
import Upload from "./Pages/Upload";
import Single_nft from "./Pages/Single_nft";
import Explore from "./Pages/Explore";
import Detail_page from "./Pages/Detail_page";
import Choose_blockchain from "./Pages/Choose_blockchain";
import ForgetPassword from "./Pages/ForgetPassword";
import Reset from "./Pages/Reset";
import Collection_detail from "./Pages/Collection_detail";
import SingleDetail from "./Pages/Single_detail";
import ProjectNft from "./Pages/ProjectNft";
import About from "./Pages/About";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import VerifyEmail from "./Pages/VerifyEmail";
import BeachSoccerLeague from "./Pages/BeachSoccerLeague";
import ContactUs from "./Pages/ContactUs";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import Athletenfts from "./Pages/Athletenfts";
import Faq from "./Pages/Faq";
import Branding from "./Pages/BrandingPartnership";
import BrandingPartnership from "./Pages/BrandingPartnership";
import UpdatedHome from "./Pages/UpdatedHome";
import NotFound from "./Component/common/NotFound";
import Mint from "./Component/common/Mint";
import StripeStatusPage from "./Component/common/StripeStatusPage";
import AddBank from "./Component/common/Addbank";
function App() {
  return (
    <div className="App">
      <Router>
          <Header />
          <Routes>
          <Route exact path="/updated-home" element={<Home />} />
            <Route exact path="/" element={<UpdatedHome />} />
            <Route path="/profile" element={<Seller_profile />} />
            <Route path="/user/:userSlug" element={<Seller_profile />} />
            <Route path="/projects/:projectSlug" element={<ProjectNft />} />
            <Route path="/upload/:blockchain" element={<Upload />} />
            <Route path="/trading_cards" element={<Single_nft />} />
            <Route path="/explore" element={<Explore />} />
            <Route
              path="/collection_details/:userSlug"
              element={<Detail_page />}
            />
            <Route
              path="/trading_cards/:userSlug"
              element={<SingleDetail />}
            />
            <Route path="/choose_blockchain" element={<Choose_blockchain />} />
            <Route exact path="/forgetpassword" element={<ForgetPassword />} />
            <Route
              path="/collection_trading_cards/:collectionSlug/:collection_id"
              element={<Collection_detail />}
            />
            <Route exact path="/reset/:token" element={<Reset />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route path="/payment-status/:status" element={<StripeStatusPage />} />

            <Route
              exact
              path="/user/email_verification/token/:token"
              element={<VerifyEmail />}
            />
            <Route exact path="/user/reset/token=:token" element={<Reset />} />
            <Route
              exact
              path="/beachsoccerleague"
              element={<BeachSoccerLeague />}
            />
            <Route exact path="/contact" element={<ContactUs />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/athletes" element={<Athletenfts />} />
            <Route exact path="/faq" element={<Faq />} />
            <Route exact path="/branding-partnership" element={<BrandingPartnership />} />
            <Route exact path="*" element={<NotFound />} />  
            <Route exact path="/add-bank" element={<AddBank />} />
          </Routes>
          <Footer />
      </Router>
    </div>
  );
}

export default App;
