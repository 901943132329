import { useEffect, useState } from "react";

import GlobalContext from "./GlobalContext"

const GlobalState = (props) => {
    const [active, set_active] = useState('home')
    const value={
        active ,set_active
    }
    return (
        <>
            <GlobalContext.Provider value={value}>
                {props.children}
            </GlobalContext.Provider>
        </>
    )
}

export default GlobalState;