import { useEffect, useState } from "react"
import { useParams,useLocation } from "react-router-dom"
import { useNavigate } from "react-router";
import swal from "sweetalert";
import { userServices } from "../services/user.Services"
import LoadingSpinerComponent from "./Loader"

function VerifyEmail() {
    const search = useLocation().search;
    const slug = new URLSearchParams(search).get("slug");
    const pathName = new URLSearchParams(search).get("path");
    const [status, setStatus] = useState(false);
    const [showloader, setShowloader] = useState(true)
    const history = useNavigate();
    useEffect(() => {
        EmailVerifyApi();
    }, [])

    let { token } = useParams();

    function EmailVerifyApi() {
        const params = {
            token: token,
        }
        userServices.EmailVerifyApi(params).then((res) => {
            if (res.status === 200) {
                setStatus(true);
                setShowloader(false);
                swal("Success", res.data.message, "success").then(() => {
                    history(pathName ? `/login?path=${pathName}`:'/login');
                })
            }
        }).catch((error) => {
            setShowloader(false);
            setStatus(false);
            swal("Opps..", error.response.data.message, "error").then(() => {
                history(pathName ? `/?path=${pathName}`:'/');
            });
        })
    }
    return (
        <>
            <LoadingSpinerComponent promiseInProgress={showloader} message={""} />

            <section className="user-login-page">
                <div className="page-title">
                    {status ? <h2>Email Verification</h2> : <h2></h2>}
                </div>
            </section>
            <div className="filled-area filled-area1 text-center" style={{ height: '37em' }}>
                <div className="col-md-4 m-auto">
                    <div className="popup1">
                        <span className="circle">
                            <i className="fa fa-check"></i>
                        </span>
                        {status ? <h4 className="mt-4 pt-3">Email Verification</h4> : <h4 className="mt-4 pt-3"></h4>}
                        {status ? <p>Your Email is being verified...</p> : <p></p>}
                        <form onSubmit={"handleNewPassword"} >
                        </form >
                    </div>
                </div>
            </div >
        </>
    )
}
export default VerifyEmail; 