import { currency_symbol, apiUrl } from '../config/config'
import axios from 'axios';
import { winter_username } from '../config/config'; 
import { formDataHeaders, jsonHeaders } from "../helper";
import { sl } from 'date-fns/locale';

var qs = require('qs');

var configjsonHeaders = {
    headers: jsonHeaders(),
}

export const nftService = {
    get_price,
    checkUser,
    updateUser,
    updateUserProfile,
    getuser,
    getUserDetailBySlug,
    gethotcollection,
    TopBuyer,
    TopSeller,
    Mynft,
    myCollections,
    marketplaceNft,
    marketplacceCollection,
    getNftDetail,
    mynftdetail,
    mycollectiondetail,
    home_new_item,
    topNfts,
    saveMultiTransactionLog,
    saveTransactionLog,
    PurchasedItem,
    approvestatus,
    buynft,
    getNftBYId, getNftLatestBid, multiple_detail, getCollectionOwnerDetai,
    getprojects,
    getActiveProjects,
    getProjectDetail,
    getAuctionDetail,
    createWinterApiProject,
    getWinterApiProject,
    updateWinterApiProject,
    getNftIdBySlug,
    getCollectionIdBySlug,
    getMyProfile,
    postAPiAtheletenfts,
    buyNft,
    put_on_Sale,
    removeFromSale,
    addBank,
    getBankInfo
}

function getNftLatestBid(nftId){
    return axios.get(`${apiUrl}/get-nft-latest-bid/`+nftId);
}

function getNftBYId(nftId){
    return axios.get(`${apiUrl}/nft-by-id/`+nftId);
}

function getCollectionOwnerDetai(nftId){
    return axios.get(`${apiUrl}/collection-owner-detail/`+nftId);
}

function get_price() {
    let url = `https://min-api.cryptocompare.com/data/price?fsym=` + currency_symbol + `&tsyms=USD`;
    return axios.get(url)
}

function checkUser(params) {
    let url = `${apiUrl}/checkUser`;
    return axios.post(url, params)
}

function updateUser(params) {
    let url = `${apiUrl}/updateUser`;
    return axios.post(url, params, configjsonHeaders)
}

function updateUserProfile(params) {
    let url = `${apiUrl}/updateUserProfile`;
    return axios.post(url, params)
}

function getuser(userId) {
    let url = `${apiUrl}/userInfo/` + userId;
    return axios.get(url,configjsonHeaders)
}
function getMyProfile() {
    let url = `${apiUrl}/my-profile`;
    return axios.get(url,configjsonHeaders)
}

// function myProfile() {
//     let url = `${apiUrl}/my-profile`;
//     return axios.get(url,configjsonHeaders)
// }

function getUserDetailBySlug(userslug) {
    let url = `${apiUrl}/slug/?slug=` + userslug;
    return axios.get(url)
}

function gethotcollection(params) {
    let url = `${apiUrl}/hot-collections/`;
    return axios.get(url)
}

function TopBuyer(utype, params) {
    let url = `${apiUrl}/` + utype + `?duration=` + params;
    return axios.get(url)
}

function TopSeller(params) {
    let url = `${apiUrl}/TopSellers?duration=MONTHLY`;
    return axios.get(url)
}

function topNfts() {
    let url = `${apiUrl}/topNfts`;
    return axios.get(url)
}

function Mynft(page, userId) {
    let url = `${apiUrl}/myNfts/` + userId + "?page=" + page;
    return axios.get(url)
}

function myCollections() {
    let url = `${apiUrl}/myCollections`
    return axios.get(url, configjsonHeaders)
}

function marketplaceNft(page, filter) {
    return axios.post(`${apiUrl}/marketplace-nfts?page=` + page, filter);
}

function marketplacceCollection(page, filter) {
    let url = `${apiUrl}/marketplace-collections?page=` + page;
    return axios.post(url, filter)
}

function multiple_detail(params, chainid) {
    let url = `${apiUrl}/nft-detail/` + params + "/" + chainid + "/MULTIPLE";
    return axios.get(url)
}

function mynftdetail(userSlug) {
    let url = `${apiUrl}/nft-detail/${userSlug}`;
    return axios.get(url,configjsonHeaders)
}

function getNftDetail(slugName) {
    let url = `${apiUrl}/nft-detail/${slugName}`;
    return axios.get(url,configjsonHeaders)
}

function mycollectiondetail(collection_id) {
    let url = `${apiUrl}/collection-detail/`+ collection_id;
    return axios.get(url)

}

function home_new_item() {
    let url = `${apiUrl}/new-items`;
    return axios.get(url)
}

function saveMultiTransactionLog() {
    let url = `${apiUrl}/saveMultiTransactionLog`;
    return axios.get(url)
}

function saveTransactionLog() {
    let url = `${apiUrl}/saveTransactionLog`;
    return axios.get(url)
}

function PurchasedItem(param, page) {
    let url = `${apiUrl}/purchased-items/` + param + '?page=' + page;
    return axios.get(url)
}

function approvestatus(param, chain) {
    let url = `${apiUrl}/approve-nft/` + chain + '/SINGLE';
    return axios.post(url, param)
}

function buynft(param) {
    let url = `${apiUrl}/buy-nft/`;
    return axios.post(url, param)
}

function getprojects(address) {
    let url = `${apiUrl}/user/projects/`+address;
    return axios.get(url)
}

function getActiveProjects() {
    let url = `${apiUrl}/active-projects`;;
    return axios.get(url)
}

function getProjectDetail(id) {
    let url = `${apiUrl}/project-detail/`+id;;
    return axios.get(url)
}

function getAuctionDetail(id) {
    let url = `${apiUrl}/get-nft-latest-bid/` + id;;
    return axios.get(url)
}

function createWinterApiProject(data) {
    let url = "https://winter-api.usewinter.com/createProject"
    return axios({
        method: "POST",
        url: url,
        auth: { username: winter_username },
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
    })
}

function getWinterApiProject(email) {
    let url = `https://winter-api.usewinter.com/getProjects?email=${email}&sandbox=true`
    return axios.get(url, { auth: { username: winter_username } })
}

function updateWinterApiProject(data) {
    let url = `https://winter-api.usewinter.com/updateProject`
    return axios({
        method: "POST",
        url: url,
        auth: { username: winter_username },
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
      })
}

function getNftIdBySlug(data){
    let url = `${apiUrl}/nft-by-slug/${data.nft_slug}`;
    return axios.get(url)
}

function getCollectionIdBySlug(data){
    let url = `${apiUrl}/getCollection/${data.slug}`;
    return axios.get(url)
}

function postAPiAtheletenfts(data) {
    let url = `${apiUrl}/monitizing-form`;
    // 9705/v1/
    return axios.post(url, data);
}

function buyNft(data){
    let url = `${apiUrl}/create-stripe-session`;
    return axios.post(url, data, configjsonHeaders);
}
function put_on_Sale(data){
    let url = `${apiUrl}/put-on-sale`;
    return axios.put(url, data, configjsonHeaders);
}

function removeFromSale(data){
    let url = `${apiUrl}/remove-on-sale`;
    return axios.put(url, data, configjsonHeaders);
}

function addBank(data){
    let url = `${apiUrl}/user/addBank`;
    return axios.post(url, data, configjsonHeaders);
}

function getBankInfo(){
    let url = `${apiUrl}/user/getBankInfo`;
    return axios.get(url, configjsonHeaders);
}