import React, { useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap-v5";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Button, Modal, ToastContainer } from "react-bootstrap";
import { useMoralis } from "react-moralis";
import Web3 from "web3";
import swal from "sweetalert";
import { userServices } from "../services/user.Services";
import {
  appName,
  httpproviderETH,
  sportsfindaMetamaskUrl,
} from "../config/config";
import GlobalContext from "../Context/GlobalContext";
import { getAccounts } from "../Component/common/CommonFunction";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState();
  const [currentUrl, setCurrentUrl] = useState("/");
  const [getInTouchName, setGetInTouchName] = useState("");
  const [getInTouchEmail, setGetInTouchEmail] = useState("");
  const [getInTouchPhone, setGetInTouchPhone] = useState("");
  const [getInTouchCName, setGetInTouchCName] = useState("");
  const [getInTouchJobTitle, setGetInTouchJobTitle] = useState("");
  const [submitButtonDisabled, setsubmitButtonDisabled] = useState(false);
  const emailRegex = new RegExp(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i
  );
  const [errors, setErrors] = useState({
    getInTouchName: "",
    getInTouchEmail: "",
    getInTouchPhone: "",
    getInTouchJobTitle: "",
    getInTouchCName: "",
  });

  function validation() {
    let status = true;
    let error = { ...errors };

    if (!getInTouchName) {
      error.getInTouchName = "Please enter name.";
      status = false;
    }
    if (!getInTouchEmail) {
      error.getInTouchEmail = "Please enter email.";
      status = false;
    } else if (!emailRegex.test(getInTouchEmail)) {
      setsubmitButtonDisabled(false);
      error.getInTouchEmail = "Please enter valid email.";
      status = false;
    }
    if (!getInTouchPhone) {
      error.getInTouchPhone = "Please enter phone no.";
      status = false;
    } else if (getInTouchPhone.length < 8) {
      setsubmitButtonDisabled(false);
      error.getInTouchPhone = "Please enter valid phone no.";
      status = false;
    }
    if (!getInTouchCName) {
      setsubmitButtonDisabled(false);
      error.getInTouchCName = "Please enter Company name.";
      status = false;
    }
    if (!getInTouchJobTitle) {
      setsubmitButtonDisabled(false);
      error.getInTouchJobTitle = "Please enter Message.";
      status = false;
    }
    setErrors(error);
    return status;
  }
  let history = useNavigate();

  const handleGetInTouchSubmit = async () => {
    setsubmitButtonDisabled(true);
    if (validation()) {
      const formData = {
        name: getInTouchName,
        email: getInTouchEmail,
        phone: getInTouchPhone,
        jobTitle: getInTouchJobTitle,
        company: getInTouchCName,
      };
      await userServices
        .GetInTouchForm(formData)
        .then((res) => {
          swal("Success", res.data.message, "success").then(() => {
            setGetInTouchCName("");
            setGetInTouchName("");
            setGetInTouchEmail("");
            setGetInTouchPhone("");
            setGetInTouchJobTitle("");
            setsubmitButtonDisabled(false);
            window.location.href = "/";
          });
        })
        .catch((err) => { });
    } else {
      // swal("Warning", "Please fill all details", "warning");
    }
  };

  useEffect(() => {


    isUserLogged();
    localStorage.removeItem("imageId")
  }, []);

  var global_data = useContext(GlobalContext);
  const { isAuthenticated, error, Moralis, user, userError } = useMoralis();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const isUserLogged = async () => {
    await userServices
      .getIDwithEdit()
      .then((res) => {
        setIsUserLoggedIn(true);
        setUserDetail(res.data.data);
      })
      .catch((error) => {
        setIsUserLoggedIn(false);
      });
  };

  function Gettouch() {
    global_data.set_active("gets");
    sessionStorage.setItem("active", "gets");
    window.location.href = "/#getsection";
  }

  const createStoreHandle = () => {
    localStorage.getItem("currentUserId") && isAuthenticated
      ? (window.location.href = "/trading-cards")
      : !isAuthenticated && localStorage.getItem("currentUserId")
        ? 
        <></>
        : (window.location.href = "/login");
  };

  function saveWalletAddressApi(walletaddress) {
    const params = {
      eth_address: walletaddress,
    };

    userServices
      .updateWalletAddress(params)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("WalletConnect", true);
          localStorage.setItem("walletType", "metamask");
          localStorage.setItem("walletAddress", walletaddress);
          Swal.fire({
            title: "Success",
            text: "Wallet connected successfully.",
            imageUrl: require("../../src/assets/images/thumbs-up.png"),
            imageWidth: 80,
            imageHeight: 100,
            imageAlt: "Custom image",
            animation: false,
          }).then(() => {
            nextPage();
          });
        }
      })
      .catch((Error) => {
        localStorage.removeItem("WalletConnect");
        localStorage.removeItem("walletType");
        localStorage.removeItem("walletAddress");
        Swal.fire({
          title: "Oops..",
          text: Error.response.data.message,
          imageUrl: require("../assets/images/thumbs-up.png").default,
          imageWidth: 80,
          imageHeight: 100,
          imageAlt: "Custom image",
          animation: false,
        }).then(() => {
          window.location.reload()
        });
      });
  }

  function nextPage() {
    window.location.href = currentUrl;
  }
  const [showMetaModal, setshowMetaModal] = useState(false);
  async function checkMeta() {
    const ethereum = window.ethereum;
    if (!ethereum || !ethereum.on) {
      setshowMetaModal(true);
      return false;
    } else {
      return true;
    }
  }



  return (
    <>
      <Modal
        show={showMetaModal}
        onHide={() => {
          setshowMetaModal(false);
        }}
        animation={false}
        centered
        className="contact-us-form"
      // backdrop="static"
      >
        <Modal.Body>
          <h3>{appName}</h3>
          Please Install Metamask.
        </Modal.Body>
        <Button
          type="button"
          className="ok-bt"
          onClick={() =>
            window.open(
              "https://metamask.io/",
              "_blank" // <- This is what makes it open in a new window.
            )
          }
        >
          Ok
        </Button>
      </Modal>

      <div className="common_bg pt-5">
        <img
          src={require("../assets/images/top_bg-1.png").default}
          alt=""
          className="img_position_1"
          style={{ left: "-10%" }}
        />
        <img
          src={require("../assets/images/blue.png").default}
          alt=""
          className="img_position_blue"
        />

        <section className="first_section_main mt-5">
          <div className="container section_positon">
            <div className="row align-items-center common_padding_1 home-banner-section">
              <div className="col-md-6 text-left px-3">
                <img
                  src={require("../assets/images/etherium.png").default}
                  alt=""
                />
              </div>
              <div className="col-md-6 text-left px-3">
                <h2 className="main_h2">
                  Your Sports
                  <br /> & Entertainment {" "}
                  <br />
                  <span>Digital Trading Cards Marketplace</span>
                </h2>
                <p className="p_text mt-3">Built on Polygon</p>
                <button
                  onClick={() => {
                    if (isUserLoggedIn) {
                      navigate("/trading-cards/?ERC-1155=true", {
                        state: { fromHomePage: true },
                      });
                      window.location.href = "/trading-cards/?ERC-1155=true";
                    } else {
                      localStorage.setItem("requrl", window.location.pathname);
                      window.location.href = "/login";
                    }
                  }}
                  type="button"
                  class="btn btn-unset px-4 mt-4"
                >
                  Launch Your Collections
                </button>

                <button
                  onClick={() => {
                    navigate("/nft-ai-generator");
                  }}
                  type="button"
                  class="btn btn-unset generate-nft-btn px-4 mt-4"
                >
                  Generate Your Digital Trading Cards
                </button>

              </div>
            </div>
          </div>

          <section className="kennedy-brooks">
            <div className="container section_positon">
              <div className="row align-items-center common_padding_1">
                <div className="col-md-6 text-left px-3">
                  <h2 className="main_h2">
                    Are you an athlete {" "}
                    <br />
                    looking to launch your
                    <br />
                    <span> own Digital Trading Cards?</span>
                  </h2>
                  <p className="p_text mt-3">
                    Find out how you can monetize your NIL through Digital Trading Cards.
                  </p>
                  <button
                    type="button"
                    class="btn btn-unset px-4 mt-4"
                    onClick={() => navigate("/athletenfts")}
                  >
                    Get in Touch
                  </button>
                </div>
                <div className="col-md-6 text-left px-3">
                  <img
                    src={require("../assets/images/kennedy.png").default}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="discord-section brand-mid-spacing">
          <div className="container">
            <div className="discord-section-bg branding-partnership-mid-area mt-0">
              <div className="discord-section-content">
                <h2>
                  Empower Your Brand with College

                  <br /><span> Athlete Collaborations!</span>
                </h2>
                <p>Maximize Impact and Reach through Sportsfinda’s partnerships.</p>
                <a href="branding-partnership">
                  Brand Partnerships
                </a>
              </div>
            </div>
          </div>
        </section>
          <div className="container section_positon">
            <section className="nil-providers">
              <img
                src={require("../assets/images/globe_1.png").default}
                className="globe-image"
              ></img>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 touch_section py-3">
                    <div className="text-center">
                      <h2 className="main_h2 underline_text">
                        Partnerships & NIL Provider
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row mt-5 justify-content-center">
                  <div className="col-md-4 mb-md-0 mb-lg-4">
                    <div className="providers-logo">
                      <img
                        src={require("../assets/images/logo-1.png").default}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-md-0 mb-lg-4">
                    <div className="providers-logo">
                      <img
                        src={require("../assets/images/logo-2.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-md-0 mb-lg-4">
                    <div className="providers-logo">
                      <img
                        src={require("../assets/images/logo-3.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-md-0 mb-lg-4">
                    <div className="providers-logo">
                      <img
                        src={require("../assets/images/logo-4.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-md-0 mb-lg-4">
                    <div className="providers-logo">
                      <img
                        src={require("../assets/images/logo-5.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-md-0 mb-lg-4">
                    <div className="providers-logo">
                      <img
                        src={require("../assets/images/logo-6.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-md-0 mb-lg-4">
                    <div className="providers-logo">
                      <img
                        src={require("../assets/images/logo-7.png")}
                        alt="img"
                      />
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-0 mb-lg-4">
                    <div className="providers-logo">
                      <img
                        src={require("../assets/images/logo-8.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-md-0 mb-lg-4">
                    <div className="providers-logo">
                      <img
                        src={require("../assets/images/logo-9.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                 
                </div>
              </div>
            </section>
            <hr></hr>

            <section className="discord-section">
              <div className="container">
                <div className="discord-section-bg">
                  <div className="discord-section-content">
                    <h2>
                      Join Our <span>Discord!</span>
                    </h2>
                    <p> Stay Up To Date With The Latest Athlete Digital Trading Cards Drops.</p>
                    <a href="https://discord.gg/KYjt7rzukX" target="blank">
                      Get in Touch
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        <section className="service_section1 launch_section">
          <img
            src={require("../assets/images/globe_1.png")}
            alt=""
            className="img_position_2"
          />
          <div className="container">
            <div className="row">
              <div className="col-md-12 touch_section py-3">
                <div className="text-center">
                  <h2 className="main_h2 underline_text">
                    {/* Launch Your Own Digital Trading Cards */}
                    Launch your own Digital Trading Cards
                  </h2>
                </div>
              </div>
            </div>
            <div className="row mt-5 py-4">
              <div className="col-lg-4 col-md-4 col-sm-6 mb-5 text-center">
                <div className="box1_shade common_box_content">
                  <img
                    src={require("../assets/images/shade_1.png")}
                    alt=""
                    className="img_position_1"
                  />
                  <div className="white_box">
                    <img
                      src={
                        require("../assets/images/connet_wallet.png")
                      }
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="relative-div">
                    <h5 className="mt-4 pt-1">Connect Your Wallet</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 mb-5">
                <div className="box1_shade common_box_content">
                  <img
                    src={require("../assets/images/shade_2.png")}
                    alt=""
                    className="img_position_1"
                  />
                  <div className="white_box">
                    <img
                      src={
                        require("../assets/images/connet_wallet.png")
                      }
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="relative-div">
                    <h5 className="mt-4 pt-1">Create Your Digital Trading Cards</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 mb-5">
                <div className="box1_shade common_box_content">
                  <img
                    src={require("../assets/images/shade_3.png")}
                    alt=""
                    className="img_position_1"
                  />
                  <div className="white_box">
                    <img
                      src={
                        require("../assets/images/connet_wallet.png")
                      }
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="relative-div">
                    <h5 className="mt-4 pt-1">Start Selling & Growing</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row create_store_row align-items-center py-4">
              <div className="col-md-6">
                <img
                  src={require("../assets/images/vector_img.png")}
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <div className="ps-4 create-store-info">
                  <h4 className="pt-3">
                    Bring your store to life in a few simple Steps
                  </h4>
                  <p>
                    Click create and set up your collection. Add social links, a
                    description, profile & banner images, and set a secondary
                    sales fee and add your Digital Trading Cards
                  </p>
                  <button
                    onClick={() => createStoreHandle()}
                    type="button"
                    class="btn btn-unset px-4 mt-4"
                  >
                    Create A Store Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="service_section1">
          <img
            src={require("../assets/images/globe_1.png")}
            alt=""
            className="img_position_2"
          />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <h2 className="main_h2 underline_text">Services</h2>
                </div>
              </div>
            </div>
            <div className="row mt-5 pt-3">
              <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
                <div className="box1_shade common_box_content">
                  <img
                    src={require("../assets/images/shade_1.png")}
                    alt=""
                    className="img_position_1"
                  />
                  <div className="relative-div">
                    <img
                      src={require("../assets/images/icn_1.png")}
                      alt=""
                    />
                    <h5 className="mt-4 pt-1">Scalability</h5>
                    <p>
                      Boost revenues with new crypto and fiat gateways and fees.
                      You can add gateways independently, or our team can do
                      all work on a one-time payment or revenue-sharing basis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
                <div className="box1_shade common_box_content">
                  <img
                    src={require("../assets/images/shade_2.png")}
                    alt=""
                    className="img_position_1"
                  />
                  <div className="relative-div">
                    <img
                      src={require("../assets/images/ui-design.png")}
                      alt=""
                    />
                    <h5 className="mt-4 pt-1">Customizable UI</h5>
                    <p>
                      Go with the default layout or choose your own design. We
                      tailor the look of the platform to your brand identity and
                      regularly update it according to the latest UI and UX
                      trends.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
                <div className="box1_shade common_box_content">
                  <img
                    src={require("../assets/images/shade_3.png")}
                    alt=""
                    className="img_position_1"
                  />
                  <div className="relative-div">
                    <img
                      src={require("../assets/images/portfolio.png")}
                      alt=""
                    />
                    <h5 className="mt-4 pt-1">Professional team</h5>
                    <p>
                      Keep your Digital Trading Cards marketplace constantly updated with the most
                      traded tokens from the moment it goes live. Our team of
                      blockchain experts works every day to improve your
                      solution.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
                <div className="box1_shade common_box_content">
                  <img
                    src={require("../assets/images/shade_4.png")}
                    alt=""
                    className="img_position_1"
                  />
                  <div className="relative-div">
                    <img
                      src={
                        require("../assets/images/customer-service.png")
                      }
                      alt=""
                    />
                    <h5 className="mt-4 pt-1">24/7 support</h5>
                    <p>
                      Provide full technical assistance to your Digital Trading Cards-based
                      business with 24/7 support services from the Sportsfinda
                      team.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
        <section className="service_section1">
          <div className="container">
            <img
              src={require("../assets/images/shade_5.png")}
              alt=""
              className="circle_img_postion1"
            />
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <h2 className="main_h2 underline_text">Features</h2>
                </div>
              </div>
            </div>
            <div className="row mt-5 pt-3 circle_row position-relative">
              <img
                src={require("../assets/images/circle_bg.png")}
                alt=""
                className="circle_img_postion"
              />
              <div className="col-md-6">
                <div className="count_box">
                  <h5 className="count_circle_1">
                    <span>1</span>
                    Storefront
                  </h5>
                  <p>
                    <span>A.</span>
                    Provide users with all the necessary information about an
                    item: preview, bids, owners, price history, displays chain
                    info for checking the smart contract so a buyer can be sure
                    of the authenticity of the purchased Digital Trading Cards.
                  </p>
                </div>

                <div className="count_box">
                  <h5 className="count_circle_1">
                    <span>2</span>
                    Searching for items
                  </h5>
                  <p>
                    <span>A.</span> Users will be able to search any Digital Trading Cards on the
                    platform with name, category, or description.
                  </p>
                  <p>
                    <span>B.</span> All assets will be tagged to several
                    categories such as American football, Football accessories,
                    Basketball, Soccer Etc. Which will make the search a lot
                    easier.
                  </p>
                  <p>
                    <span>C.</span> Details page will provide all the necessary
                    information about an item: preview, bids, owners, price
                    history, displays chain info for checking the smart contract
                    so a buyer can be sure of the authenticity of the purchased
                    Digital Trading Cards
                  </p>
                </div>

                <div className="count_box">
                  <h5 className="count_circle_1">
                    <span>3</span>
                    Creating listings
                  </h5>
                  <p>
                    <span>A.</span> The process of Digital Trading Cards submission and creation
                    of the collection will be simple.
                  </p>
                  <p>
                    <span>B.</span> Users will be able to upload a file and
                    enter the required token information such as name,
                    description, and tags.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="count_box">
                  <h5 className="count_circle_1">
                    <span>4</span>
                    Buy & Bid
                  </h5>
                  <p>
                    <span>A.</span> The platform will provide bidders with an
                    easy-to-use and efficient Digital Trading Cards buying system.
                  </p>
                  <p>
                    <span>B.</span> With the help of their integrated wallet,
                    users will be able to pay the Digital Trading Cards price in cryptocurrency
                    and will be able to purchase the Digital Trading Cards at the price set by the
                    creator.
                  </p>
                  <p>
                    <span>C.</span> Users will also be able to place a bid on
                    the listed Digital Trading Cardss that are available for the auction. There
                    will be a bid expiration date in the form to give users more
                    control over their bids.
                  </p>
                </div>

                <div className="count_box">
                  <h5 className="count_circle_1">
                    <span>5</span>
                    Wallet Integrations
                  </h5>
                  <p>
                    <span>A.</span> Users will be able to connect their Metamask
                    wallet which will allow users to store, send and receive
                    non-fungible tokens.
                  </p>
                </div>

                <div className="count_box">
                  <h5 className="count_circle_1">
                    <span>6</span>
                    Collections
                  </h5>
                  <p>
                    <span>A.</span> Launch your own collections very easily with
                    a collection profile.
                  </p>
                </div>

                <div className="count_box">
                  <h5 className="count_circle_1">
                    <span>7</span>
                    Profile
                  </h5>
                  <p>
                    <span>A.</span> User will be able to see their profile
                    section in which he would be able to see the Digital Trading Cardss he has
                    minted or purchased.
                  </p>
                  <p>
                    <span>B.</span> User can resell their Digital Trading Cardss from this page.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="touch_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <h2 className="main_h2 underline_text">
                    Blockchains & Integrations
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="blockchain_row py-4">
                  <div>
                    <span>
                      <img
                        src={
                          require("../assets/images/etherium_icon.png")
                        }
                        alt=""
                      />
                    </span>
                    <h4>Ethereum </h4>
                  </div>
                  <div>
                    <span>
                      <img
                        src={
                          require("../assets/images/metamask_icon.png")
                        }
                        alt=""
                      />
                    </span>
                    <h4>MetaMask </h4>
                  </div>
                  <div>
                    <span>
                      <img
                        src={
                          require("../assets/images/polygon_icon.png")
                        }
                        alt=""
                      />
                    </span>
                    <h4>Polygon </h4>
                  </div>
                  <div>
                    <span>
                      <img
                        src={
                          require("../assets/images/raible_icon.png")
                        }
                        alt=""
                      />
                    </span>
                    <h4>Rarible </h4>
                  </div>
                  <div>
                    <span>
                      <img
                        src={
                          require("../assets/images/opensea_icon.png")
                        }
                        alt=""
                      />
                    </span>
                    <h4>Opensea </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="touch_section" id="getsection">
          <img
            src={require("../assets/images/btm_img3.png")}
            alt=""
            className="circle_img_postion1"
          />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <h2 className="main_h2 underline_text">
                    Get In Touch With A Consultant Today
                  </h2>
                </div>
              </div>
            </div>
            <div className="row mt-5 pt-3">
              <div className="col-md-12">
                <div className="touch_form">
                  <Form>
                    <div className="row">
                      <div className="col-md-6 mb-2 thenae-box">
                        <Form.Control
                          type="Name"
                          name="firstName"
                          placeholder="Name"
                          onChange={(e) => {
                            setGetInTouchName(e.target.value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          value={getInTouchName}
                        />
                        {!getInTouchName && errors?.getInTouchName ? (
                          <span className="errors">
                            {errors.getInTouchName}
                            <br />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="col-md-6 mb-2 thenae-box">
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Work Email"
                          onChange={(e) => {
                            setGetInTouchEmail(e.target.value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          value={getInTouchEmail}
                        />
                        {(!getInTouchEmail ||
                          !emailRegex.test(getInTouchEmail)) &&
                          errors?.getInTouchEmail ? (
                          <span className="errors">
                            {errors.getInTouchEmail}
                            <br />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mb-2 thenae-box">
                        <Form.Control
                          type="number"
                          name="phone"
                          placeholder="Phone Number"
                          onKeyDown={(evt) => {
                            console.log("evt which...", evt.which);
                            if (
                              evt.which != 8 &&
                              evt.which != 9 &&
                              (evt.which < 48 || evt.which > 57) &&
                              (evt.which < 96 || evt.which > 105)
                            ) {
                              evt.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (e.target.value.length < 14) {
                              setGetInTouchPhone(e.target.value);
                            }
                          }}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          value={getInTouchPhone}
                        />
                        {(!getInTouchEmail || getInTouchPhone?.length < 10) &&
                          errors?.getInTouchPhone ? (
                          <span className="errors">
                            {errors.getInTouchPhone}
                            <br />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-6 mb-2 thenae-box">
                        <Form.Control
                          type="companyName"
                          name="companyName"
                          placeholder="Company Name"
                          onChange={(e) => {
                            setGetInTouchCName(e.target.value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          value={getInTouchCName}
                        />
                        {!getInTouchCName && errors?.getInTouchCName ? (
                          <span className="errors">
                            {errors.getInTouchCName}
                            <br />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-12 mb-2 thenae-box">
                        <Form.Control
                          type="jobTitle"
                          name="jobTitle"
                          placeholder="Message"
                          onChange={(e) => {
                            setGetInTouchJobTitle(e.target.value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          value={getInTouchJobTitle}
                        />
                        {!getInTouchJobTitle && errors?.getInTouchJobTitle ? (
                          <span className="errors">
                            {errors.getInTouchJobTitle}
                            <br />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-4 m-auto text-center">
                        <button
                          type="button"
                          disabled={submitButtonDisabled}
                          onClick={() => {
                            handleGetInTouchSubmit();
                          }}
                          className="btn btn-unset px-4 mt-4"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </div>
      {/* banner section end */}
    </>
  );
};

export default Home;
