import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useNavigate } from "react-router-dom";
import { userServices } from "../services/user.Services";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { appName, sportsfindaMetamaskUrl } from "../config/config";
import { getAccounts } from "../Component/common/CommonFunction";
import LoadingSpinerComponent from "./Loader";

const UpdatedHome = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState();
  useEffect(() => {


    isUserLogged();
    localStorage.removeItem("imageId")
  }, []);

  useEffect(() => {
    localStorage.removeItem("draftID")
    localStorage.removeItem("draftIDM")
  },[])

  //   var global_data = useContext(GlobalContext);
  const { isAuthenticated, error, Moralis, user, userError } = useMoralis();
  const isUserLogged = async () => {
    await userServices
      .getIDwithEdit()
      .then((res) => {
        setIsUserLoggedIn(true);
        setUserDetail(res.data.data);
      })
      .catch((error) => {
        setIsUserLoggedIn(false);
      });
  };

  const [getInTouchName, setGetInTouchName] = useState("");
  const [getInTouchEmail, setGetInTouchEmail] = useState("");
  const [getInTouchPhone, setGetInTouchPhone] = useState("");
  const [getInTouchLastName, setGetInTouchLastName] = useState("");
  const [getInTouchMessage, setGetInTouchMessage] = useState("");
  const [submitButtonDisabled, setsubmitButtonDisabled] = useState(false);
  const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i);
  const [errors, setErrors] = useState({
    getInTouchName: "", getInTouchEmail: "", getInTouchPhone: "", getInTouchLastName: "", getInTouchMessage: ""
  });

  function validation() {
    let status = true;
    let error = { ...errors }

    if (!getInTouchName) {
      error.getInTouchName = "Please enter first name.";
      status = false;
    }
    if (!getInTouchEmail) {
      error.getInTouchEmail = "Please enter email.";
      status = false;
    } else if (!emailRegex.test(getInTouchEmail)) {
      setsubmitButtonDisabled(false);
      error.getInTouchEmail = "Please enter valid email.";
      status = false;
    }
    if (!getInTouchPhone) {
      error.getInTouchPhone = "Please enter phone no.";
      status = false;
    } else if (getInTouchPhone.length < 8) {
      setsubmitButtonDisabled(false);
      error.getInTouchPhone = "Please enter valid phone no.";
      status = false;
    }
    if (!getInTouchMessage) {
      setsubmitButtonDisabled(false);
      error.getInTouchMessage = "Please enter Message.";
      status = false;
    } else if (getInTouchMessage && getInTouchMessage.length > 500) {
      setsubmitButtonDisabled(false);
      error.getInTouchMessage = "Please enter message should be maximum 500 characters";
      status = false;
    }
    setErrors(error);
    return status;
  }

  const submitContactUs = async () => {
    setsubmitButtonDisabled(true);
    if (validation()) {
      setIsLoading(true);
      const formData = {
        first_name: getInTouchName,
        last_name: getInTouchLastName,
        email: getInTouchEmail,
        phone: getInTouchPhone,
        message: getInTouchMessage.trim(),
         page: "Athlete Trading Card Collection"
      };
      await userServices
        .contactUs(formData)
        .then((res) => {
          setIsLoading(false);
          swal("Success", res.data.message, "success").then(() => {
            setGetInTouchLastName("");
            setGetInTouchName("");
            setGetInTouchEmail("");
            setGetInTouchPhone("");
            setGetInTouchMessage("");
            setsubmitButtonDisabled(false);
          });
        })
        .catch((err) => {
          setIsLoading(false);
          swal("Error", err.response.message, "error");
        });
    } else {
    }
  };

  function saveWalletAddressApi(walletaddress) {
    const params = {
      eth_address: walletaddress,
    };

    userServices
      .updateWalletAddress(params)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("WalletConnect", true);
          localStorage.setItem("walletType", "metamask");
          localStorage.setItem("walletAddress", walletaddress);
          Swal.fire({
            title: "Success",
            text: "Wallet connected successfully.",
            imageUrl: require("../../src/assets/images/thumbs-up.png"),
            imageWidth: 80,
            imageHeight: 100,
            imageAlt: "Custom image",
            animation: false,
          }).then(() => {
            window.location.reload();
          });
        }
      })
      .catch((Error) => {
        localStorage.setItem("WalletConnect", false);
        localStorage.removeItem("walletType");
        localStorage.removeItem("walletAddress");
        swal(
          "Warning",
          " This wallet address is not registered with the current account, please try with another wallet.",
          "warning"
        ).then(() => {
          window.location.reload()
        });
      });
  }


  async function checkWeb3() {
    if (typeof window.ethereum == "undefined") {
      Swal.fire({
        title: "",
        text: "Please Install Metamask.",
        imageUrl: require("../assets/images/thumbs-up.png"),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: "Custom image",
        // animation: false,
      }).then(() => {
        window.open(
          "https://metamask.io/",
          "_blank" // <- This is what makes it open in a new window.
        );
      });
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll page to top
  },[])



  return (
    <>

      <div className="common_bg pt-5">
      <LoadingSpinerComponent promiseInProgress={isLoading} />
        <img
          src={require("../assets/images/top_bg-1.png")}
          alt=""
          className="img_position_1"
          style={{ left: "-10%" }}
        />
        <img
          src={require("../assets/images/blue.png")}
          alt=""
          className="img_position_blue"
        />
        <section className="first_section_main mt-5">
          <div className="container section_positon">
            <div className="exclusive-athlete-area">
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-10 col-xl-8">
                  <h2>Exclusive Athlete Drops<br /> <span>Collect, Trade, And Connect—Cards That Evolve With The Journey.</span></h2>
                </div>
              </div>
              <div className="exclusive-athlete-bottom">
                <img src={require("../assets/images/exclusive-athlete1.png")} />
                <img src={require("../assets/images/exclusive-athlete2.png")} />
                <img src={require("../assets/images/exclusive-athlete3.png")} />
              </div>
              <button type="button" class="btn btn-unset"
                onClick={() => {
                  if (localStorage.getItem("auth")) {
                    navigate("/trading_cards/?ERC-1155=true", {
                      state: { fromHomePage: true },
                    });
                  }
                  else {
                    localStorage.setItem("requrl", window.location.pathname);
                    window.location.href = "/login";
                  }
                }}>
                Launch Your Collection
              </button>
            </div>
          </div>


        </section>
        <section className="service_section1 launch_section own-journey-area">
          <img
            src={require("../assets/images/globe_1.png")}
            alt=""
            className="img_position_2"
          />
          <div className="container">
            <div className="row create_store_row align-items-center py-4">
              <div className="col-md-12 col-lg-6 col-xl-6">
                <div className="create-store-info">
                  <h4 className="pt-3">
                    Own the <span>Journey</span> <br />
                    Direct From <span>The Athlete.</span>
                  </h4>
                  <p>
                    Experience the next evolution of trading cards where the athletes call the shots. Each card is more than a collectible—it’s a personal connection. With every drop crafted by your favorite athletes, you’re not just collecting, you’re unlocking exclusive moments, milestones, and interactions. Engage, trade, and evolve your cards as you follow their journey, building your collection from the source. This isn’t just a game; it’s your access to the athlete’s world.
                  </p>
                  <button

                    type="button"
                    class="btn btn-unset px-4 mt-4 home-get-in-touch"
                  // onClick={() => navigate("/athlete", { state: { fromHomePage: true } })}
                  >
                    <a href="athletes#athletes">
                      Get in Touch
                    </a>
                  </button>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-xl-6">
                <img
                  src={require("../assets/images/own-journey.png")}
                  alt=""
                />
              </div>

            </div>
          </div>
        </section>
        <section className="nil-providers">
          <img
            src={require("../assets/images/globe_1.png")}
            className="globe-image"
          ></img>
          <div className="container">
            <div className="row">
              <div className="col-md-12 touch_section py-3">
                <div className="text-center">
                  <h2 className="main_h2 underline_text">
                    Partnerships & NIL Provider
                  </h2>
                </div>
              </div>
            </div>
            <div className="row mt-5 justify-content-center">
              <div className="col-md-4 mb-md-0 mb-lg-4">
                <div className="providers-logo">
                  <img
                    src={require("../assets/images/logo-1.png")}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-4 mb-md-0 mb-lg-4">
                <div className="providers-logo">
                  <img
                    src={require("../assets/images/logo-2.png")}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-4 mb-md-0 mb-lg-4">
                <div className="providers-logo">
                  <img
                    src={require("../assets/images/logo-3.png")}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-4 mb-md-0 mb-lg-4">
                <div className="providers-logo">
                  <img
                    src={require("../assets/images/logo-4.png")}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-4 mb-md-0 mb-lg-4">
                <div className="providers-logo">
                  <img
                    src={require("../assets/images/logo-5.png")}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-4 mb-md-0 mb-lg-4">
                <div className="providers-logo">
                  <img
                    src={require("../assets/images/logo-6.png")}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-4 mb-md-0 mb-lg-4">
                <div className="providers-logo">
                  <img
                    src={require("../assets/images/logo-7.png")}
                    alt="img"
                  />
                </div>
              </div>

              <div className="col-md-4 mb-md-0 mb-lg-4">
                <div className="providers-logo">
                  <img
                    src={require("../assets/images/logo-8.png")}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-4 mb-md-0 mb-lg-4">
                <div className="providers-logo">
                  <img
                    src={require("../assets/images/logo-9.png")}
                    alt="img"
                  />
                </div>
              </div>
              {/* <div className="col-md-4 mb-md-0 mb-lg-4">
                    <div className="providers-logo">
                      <img
                        src={require("../assets/images/logo-10.png")}
                        alt="img"
                      />
                    </div>
                  </div> */}
            </div>
          </div>
        </section>
        <section className="touch_section" id="getsection">
          <img
            src={require("../assets/images/btm_img3.png")}
            alt=""
            className="circle_img_postion1"
          />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <h2 className="main_h2 underline_text">
                    Launch Your Own Trading Card Today
                  </h2>
                </div>
              </div>
            </div>
            {/* <div className="row mt-5 pt-3 justify-content-center">
                            <div className="col-md-12 col-lg-9 col-xl-9">
                                <div className="touch_form">
                                    <Form>
                                        <div className="row">
                                            <div className="col-md-12 mb-2 thenae-box">
                                                <Form.Control
                                                    type="Name"
                                                    name="firstName"
                                                    placeholder="Name"
                                                />

                                            </div>
                                            <div className="col-md-12 mb-2 thenae-box">
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                />

                                            </div>
                                            <div className="col-md-12 mb-2 thenae-box">
                                                <Form.Control
                                                    type="number"
                                                    name="phone"
                                                    placeholder="Phone Number"

                                                />

                                            </div>
                                            <div className="col-md-12 mb-2 thenae-box">
                                                    <Form.Control as="textarea" rows={3} placeholder="Message" />
                                            </div>
                                            <div className="col-md-4 m-auto text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-unset px-4 mt-4"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div> */}
            <div className="row mt-5 pt-3 justify-content-center">
              <div className="col-md-9">
                <div className="touch_form">
                  <Form>
                    <div className="row">
                      <div className="col-md-12 mb-2 thenae-box">
                        <Form.Control
                          type="Name"
                          name="firstName"
                          maxLength={30}
                          placeholder="First Name"
                          onChange={(e) => {
                            setGetInTouchName(e.target.value.trim());
                          }}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          value={getInTouchName}
                        />

                        {(!getInTouchName) && errors?.getInTouchName ? <span className="errors">{errors.getInTouchName}<br /></span> : <></>}

                      </div>
                      <div className="col-md-12 mb-2 thenae-box">
                        <Form.Control
                          type="text"
                          name="lastName"
                          maxLength={30}
                          placeholder="Last Name"
                          onChange={(e) => {
                            setGetInTouchLastName(e.target.value.trim());
                          }}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          value={getInTouchLastName}
                        />
                        {(!getInTouchLastName) && errors?.getInTouchLastName ? <span className="errors">{errors.getInTouchLastName}<br /></span> : <></>}

                      </div>
                      <div className="col-md-12 mb-2 thenae-box">
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Email"
                          maxLength={100}
                          onChange={(e) => {
                            setGetInTouchEmail(e.target.value.trim());
                          }}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          value={getInTouchEmail}
                        />
                        {((!getInTouchEmail) || (!emailRegex.test(getInTouchEmail))) && errors?.getInTouchEmail ? <span className="errors">{errors.getInTouchEmail}<br /></span> : <></>}

                      </div>
                      <div className="col-md-12 mb-2 thenae-box">
                        <Form.Control
                          type="number"
                          name="phone"
                          placeholder="Phone Number"
                          onKeyDown={evt => {
                            if (evt.which != 8 && evt.which != 9 && (evt.which < 48 || evt.which > 57) && (evt.which < 96 || evt.which > 105)) {
                              evt.preventDefault();
                            }
                          }}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          onChange={(e) => {
                            if (e.target.value.length < 14) {
                              setGetInTouchPhone(e.target.value.trim());
                            }
                          }}
                          value={getInTouchPhone}
                        />
                        {((!getInTouchPhone) || (getInTouchPhone.length < 8)) && errors?.getInTouchPhone ? <span className="errors">{errors.getInTouchPhone}<br /></span> : <></>}

                      </div>
                      <div className="col-md-12 mb-2 thenae-box">
                        <textarea rows="3" class="form-control"
                          type="text"
                          name="message"
                          placeholder="Message"
                          maxLength={500}
                          onChange={(e) => {
                            setGetInTouchMessage(e.target.value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === " " && e.target.value == 0)
                              e.preventDefault();
                          }}
                          value={getInTouchMessage}
                        ></textarea>

                        {((!getInTouchMessage) || (getInTouchMessage && getInTouchMessage.length > 500)) && errors?.getInTouchMessage ? <span className="errors">{errors.getInTouchMessage}<br /></span> : <></>}

                      </div>
                      <div className="col-md-4 m-auto text-center">
                        <button
                          type="button"
                          disabled={submitButtonDisabled}
                          onClick={() => {
                            submitContactUs();
                          }}
                          className="btn submit-bt px-4 mt-4"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default UpdatedHome;