import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { userServices } from "../services/user.Services";
import LoadingSpinerComponent from "./Loader";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setErrorEmail("Please enter email address.");
    } else if(!pattern.test(email)){
      setErrorEmail("Please enter valid email address.");
    }
    else {
      forgetpasswordAPI();
    }
  };

  function forgetpasswordAPI() {
    const params = {
      email: email,
    };

    setisLoading(true);
    userServices
      .forgetpasswordAPI(params)
      .then((res) => {
        if (res.status === 200) {
          setisLoading(false);
          Swal.fire({
            title: "Success",
            text: "Link to reset the password has been sent successfully.",
            imageUrl: require("../../src/assets/images/thumbs-up.png").default,
            imageWidth: 80,
            imageHeight: 100,
            imageAlt: "Custom image",
            animation: false,
          }).then((res)=>{
            window.location.href = "/login"
          });
        }
      })
      .catch((error) => {
        setisLoading(false);
        Swal.fire({
          title: "Error",
          text: "The email address is not registered with us. Please try again",
          imageUrl: require("../../src/assets/images/thumbs-down.png").default,
          imageWidth: 80,
          imageHeight: 100,
          imageAlt: "Custom image",
          animation: false,
        });
      });
  }

  return (
    <>
      <LoadingSpinerComponent promiseInProgress={isLoading} />
      <section className="about-page">
        <Container>
          <Row className="justify-content-center signin-form row">
            <Col md={5}>
              <h3 className="text-center">Forgot Password</h3>

              <form className="login-form-box" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="mb-2">Email address</label>
                  <br />
                  <input
                    name="email"
                    className="form-control-lg w-100"
                    type="email"
                    placeholder="Registered email"
                    required=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {((!email || !pattern.test(email))&& errorEmail) ? <span className="errors">{errorEmail}</span> : <></>}
                </div>
                <button type="submit" variant="unset" className="submit-bt">
                  Submit
                </button>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default ForgetPassword;
