import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { formValidation } from "../_services/validation.services";
import { userServices } from "../services/user.Services";
import { useNavigate } from "react-router-dom";
import LoadingSpinerComponent from "./Loader";
import Swal from "sweetalert2";

const Signup = () => {
  const [showloader, setshowloader] = useState(false);
  const search = useLocation().search;
  const pathName = new URLSearchParams(search).get("path");
  const [signupFields, setSignupFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    cpassword: "",
  });
  const history = useNavigate();
  const [errors, setErrors] = useState({});
  const [verifyEmail, setVerifyEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    let checkValidation = formValidation.Register_validation(signupFields, {});
    if (checkValidation.formIsValid) {
      setshowloader(true);
      await userServices
        .UserRegister({
          firstname: signupFields.firstName,
          lastname: signupFields.lastName,
          email: signupFields.email,
          password: signupFields.password,
        })
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              title: "Success",
              text: "A verfication email has been sent to your email address. Please verify your email.",
              imageUrl: require("../assets/images/thumbs-up.png").default,
              imageWidth: 80,
              imageHeight: 100,
              imageAlt: "Custom image"
            }).then(() => navigate(pathName ? `/login?path=${pathName}` :"/login"));
            navigate(pathName ? `/login?path=${pathName}` :"/login")
          }
          setshowloader(false);
        })
        .catch((error) => {
          console.log("error--->",error?.response?.data?.message)
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            imageUrl: require("../assets/images/thumbs-down.png").default,
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: "Custom image",
            animation: false,
          });
          setshowloader(false);
        });
    } else {
      setErrors(checkValidation);
    }
  };

  const resendVerification = () => {
    userServices
      .ResendEmailVerificationApi({
        email: signupFields.email,
      })
      .then((res) => {
        if (res.data.success) {
          Swal.fire({
            title: "Success",
            text: "Email sent Successfully",
            imageUrl: require("../assets/images/thumbs-up.png").default,
            imageWidth: 80,
            imageHeight: 100,
            imageAlt: "Custom image",
            // animation: false,
          }).then(
            () => {
              history(pathName ? `/login?path=${pathName}` :"/login");
            }
          );
          history(pathName ? `/login?path=${pathName}` :"/login");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Invalid request",
          imageUrl: require("../assets/images/thumbs-down.png").default,
          imageWidth: 80,
          imageHeight: 80,
          imageAlt: "Custom image",
          // animation: false,
        });
      });
  };
  return (
    <>
      <LoadingSpinerComponent
        promiseInProgress={showloader}
        // message={message2}
      />
      <section className="about-page">
        <Container>
          <Row className="justify-content-center signin-form">
            <Col md={5}>
              <h3 className="text-center">Sign up</h3>
              <Form
                className="login-form-box"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                noValidate
              >
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First name"
                    value={signupFields.firstName}
                    maxLength={20}
                    onKeyDown={(e) =>
                      [" "].includes(e.key) && e.preventDefault()
                    }
                    onChange={(event) => {
                      setSignupFields((prevObj) => {
                        return {
                          ...prevObj,
                          firstName: event.target.value.replace(/\s+/g, ""),
                        };
                      });
                      setErrors((obj) => {
                        return {
                          ...obj,
                          firstName: "",
                        };
                      });
                    }}
                  />
                  <span
                    className="validation-error"
                    style={styles.validationError}
                  >
                    {errors.firstName ? errors.firstName : ""}
                  </span>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    value={signupFields.lastName}
                    maxLength={20}
                    onKeyDown={(e) =>
                      [" "].includes(e.key) && e.preventDefault()
                    }
                    onChange={(event) => {
                      setSignupFields((prevObj) => {
                        return {
                          ...prevObj,
                          lastName: event.target.value.replace(/\s+/g, ""),
                        };
                      });
                      setErrors((obj) => {
                        return {
                          ...obj,
                          lastName: "",
                        };
                      });
                    }}
                  />
                  <span
                    className="validation-error"
                    style={styles.validationError}
                  >
                    {errors.lastName ? errors.lastName : ""}
                  </span>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={signupFields.email}
                    maxLength={100}
                    onKeyDown={(e) =>
                      [" "].includes(e.key) && e.preventDefault()
                    }
                    onChange={(event) => {
                      setSignupFields((prevObj) => {
                        return {
                          ...prevObj,
                          email: event.target.value.replace(/\s+/g, ""),
                        };
                      });
                      setErrors((obj) => {
                        return {
                          ...obj,
                          email: "",
                        };
                      });
                    }}
                  />
                  <span
                    className="validation-error"
                    style={styles.validationError}
                  >
                    {errors.email ? errors.email : ""}
                  </span>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Create Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={signupFields.password}
                    maxLength={16}
                    onKeyDown={(e) =>
                      [" "].includes(e.key) && e.preventDefault()
                    }
                    onChange={(event) => {
                      setSignupFields((prevObj) => {
                        return {
                          ...prevObj,
                          password: event.target.value.replace(/\s+/g, ""),
                        };
                      });
                      setErrors((obj) => {
                        return {
                          ...obj,
                          password: "",
                        };
                      });
                    }}
                  />
                  <span
                    className="validation-error"
                    style={styles.validationError}
                  >
                    {errors.password ? errors.password : ""}
                  </span>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm password"
                    value={signupFields.cpassword}
                    maxLength={16}
                    onChange={(event) => {
                      setSignupFields((prevObj) => {
                        return {
                          ...prevObj,
                          cpassword: event.target.value.replace(/\s+/g, ""),
                        };
                      });
                      setErrors((obj) => {
                        return {
                          ...obj,
                          cpassword: "",
                        };
                      });
                    }}
                  />
                  <span
                    className="validation-error"
                    style={styles.validationError}
                  >
                    {errors.cpassword ? errors.cpassword : ""}
                  </span>
                </Form.Group>
                <Button variant="unset" type="submit">
                  Sign up
                </Button>
                <p className="signup-bt">
                  Already have an account? <Link to={pathName ? `/login?path=${pathName}` :"/login"}> Sign in </Link>
                </p>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Signup;

const styles = {
  validationError: {
    fontSize: 12,
    color: "red",
  },
};
