import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap-v5";
import { useNavigate } from "react-router-dom";

const Choose_blockchain = () => {
  const [etherium, setEtherium] = useState('')
  const [polygon, setPolygon] = useState('')
  const [bnb, setBnb] = useState('')

  let history = useNavigate();

  function Upload(blockchain) {
    const address = localStorage.getItem('walletAddress')
    if (address) {
      // window.blockchain = blockchain
      history("/upload/" + blockchain);
    }
    else {
      window.location.href = "/wallet";
    }
  }
  return (
    <>
      <section className="user-login-page">
        <div className="top-image">
          <img src={require("../assets/images/banner.png").default}></img>
        </div>
        <div className="page-title">
          <h2>Choose Blockchain</h2>
          <p className="page-paragraph">
            Choose the most suitable blockchain for your needs.
          </p>
        </div>
      </section>
      <section className="upload-items text-center">
        <Container>
          <Row className="justify-content-center create-single-multiple">
            <Col md={3}>
              <div className="upload-single-nft" value={etherium} onClick={() => Upload("ETH")} style={{ cursor: "pointer" }}>
                <img src={require("../assets/images/eth.png").default}></img>
                <h4 className="mt-3">Ethereum</h4>
              </div>
            </Col>
            <Col md={3}>
              <div className="upload-single-nft" value={polygon} onClick={() => Upload("MATIC")} style={{ cursor: "pointer" }}>
                <img src={require("../assets/images/polygon.png").default}></img>
                <h4 className="mt-3">Polygon</h4>
              </div>
            </Col>
            <Col md={3}>
              <div className="upload-single-nft" value={bnb} onClick={() => Upload("BNB")} style={{ cursor: "pointer" }}>
                <img src={require("../assets/images/bnb.png").default}></img>
                <h4 className="mt-3">BNB</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Choose_blockchain;
