import React, { useEffect, useState } from "react";
import { TabList, TabPanel, Tab, Tabs } from "react-tabs";
import { Button, Col, Container, Form, Row } from "react-bootstrap-v5";
import { nftService, formValidation } from '../_services';
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
// import ReactTooltip from 'react-tooltip';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import LoadingSpinerComponent from "./Loader";
import { useMoralis } from "react-moralis";
import { PINATA_API_KEY, PINATA_SECRET_API_KEY } from "../config/config";
import axios from "axios";
const Edit_profile = () => {

  const [Username, setUsername] = useState('');
  const [username, setusername] = useState('');

  const [Description, setDescription] = useState('')

  const [isLoading, setIsLoading] = useState(false);
  const [myprofileFileshow, setmyprofileFileshow] = useState();
  const [ERR, setERR] = useState('');
  const [profileFile, setprofileFile] = useState();
  const [profileFileshow, setprofileFileshow] = useState();

  const [userAddress, setuserAddress] = useState();
  const [myfname, setmyfname] = useState('');
  const [mysocial_links, setmysocial_links] = useState([]);
  const { web3, enableWeb3, user, Moralis, isAuthenticated } = useMoralis()
  const [coverFileshow, setcoverFileshow] = useState();
  const [coverFile, setcoverFile] = useState();
  const [social_links, setsocial_links] = useState([{ twitter: '', insta: '', facebook: '', your_site: "", discord: "", telegram: "" }])
  const [siteErr, setsiteErr] = useState('');
  const [FbErr, setFbErr] = useState('');
  const [TwErr, setTwErr] = useState('');
  const [InstaErr, setInstaErr] = useState('');
  const [DisErr, setDisErr] = useState('');
  const [TeleErr, setTeleErr] = useState('');

  const errorsp = {
    email: "",
    firsname: "",
    lastname: "",
    formIsValid: false,
  };

  const InstagramRegex = new RegExp(
    /(ftp|http|https):\/\/?((www)\.)?instagram.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  );
  const regex =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  const faceBookRegex = /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      let user = Moralis.User.current();
      if (user) {
        let currentuser = user.get('ethAddress')
        setuserAddress(user.get('ethAddress'))
        GetUserDetail(currentuser)

      }
      if (user && user.get('social_links')) {
        setmysocial_links(JSON.parse(user.get('social_links')))
        setsocial_links(JSON.parse(user.get('social_links')))
      }
    } else {
      enableWeb3({
        provider: localStorage.getItem("cuurent_provider"),
      })
    }

  }, [user])

  const { id } = useParams();

  function checkfile(file_img) {
    var get_ext = file_img.name.split('.');

    var exts = ['png', 'jpg', 'jpeg', 'gif'];
    get_ext = get_ext.reverse();

    var a = exts.indexOf(get_ext[0].toLowerCase());

    if (a > -1) {
      return true;
    } else {
      alert("Please select a valid image file")
      return false;
    }
  }
  function readURL(input) {
    //

    if (input.files && input.files[0]) {
      var filetype = checkfile(input.files[0]);
      if (filetype === true) {
        setprofileFile(input.files[0])
        var reader = new FileReader();
        reader.onload = function (e) {
          setprofileFileshow(e.target.result)
        }
        reader.readAsDataURL(input.files[0]);
      }
    }
  }
  function readURLcover(input) {
    if (input.files && input.files[0]) {
      var filetype = checkfile(input.files[0]);
      if (filetype === true) {
        setcoverFile(input.files[0])
        var reader = new FileReader();
        reader.onload = function (e) {
          setcoverFileshow(e.target.result)
        }
        reader.readAsDataURL(input.files[0]);
      }
    }
  }
  const updateUser = async (params) => {
    await nftService.updateUser(params).then((response) => {

    }).catch((err) => {
      // setErrorMsg(response.toString());
    })
  }

  const GetUserDetail = async (params) => {
    await nftService.getuser(params).then((response) => {
      setUsername(response.data.data.username)
      setDescription(response.data.data.bio)
      setprofileFileshow(response.data.data.profile)
      setcoverFileshow(response.data.data.coevr)

    }).catch((err) => {
      // setErrorMsg(response.toString());
    })
  }

  const pinToPinata = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    const pinataApiKey = PINATA_API_KEY; // Replace with your Pinata API Key
    const pinataSecretApiKey = PINATA_SECRET_API_KEY; // Replace with your Pinata Secret API Key
    
    try {
      const response = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          pinata_api_key: pinataApiKey,
          pinata_secret_api_key: pinataSecretApiKey,
        },
      });
      return `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;
    } catch (error) {
      console.error('Error uploading file to Pinata:', error);
      throw new Error('Failed to upload file to Pinata');
    }
  };

  const createprofile = async () => {
    let currentuser = user.get("ethAddress");
    let params = {
      "firstname": Username,
    };
  
    let validate = formValidation.createprofile(params, errorsp);
    setERR(validate);
    
    if (validate.formIsValid) {
      setIsLoading(true);
      let profile = myprofileFileshow;
      let cover = coverFileshow;
  
      // Upload profile file to Pinata
      if (profileFile) {
        try {
          const nftFileMetadataFilePath = await pinToPinata(profileFile);
          user.set("profile", nftFileMetadataFilePath);
          profile = nftFileMetadataFilePath; // Use the IPFS link for profile
        } catch (error) {
          console.error('Profile file upload failed:', error);
          setIsLoading(false);
          return; // Stop execution if upload fails
        }
      } else {
        profile = profileFileshow;
      }
  
      // Upload cover file to Pinata
      if (coverFile) {
        try {
          const nftFileMetadataFilePath1 = await pinToPinata(coverFile);
          cover = nftFileMetadataFilePath1; // Use the IPFS link for cover
        } catch (error) {
          console.error('Cover file upload failed:', error);
          setIsLoading(false);
          return; // Stop execution if upload fails
        }
      }
  
      // Update user details
      user.set("firstname", Username.trimStart());
      user.set("social_links", JSON.stringify(social_links));
      user.set("bio", Description);
      
      // Prepare params for updating
      let uname = Username.trimEnd();
      let desc = Description.trimEnd();
      let params = {
        ethAddress: currentuser,
        username: uname.trimStart(),
        social_links: social_links,
        profile: profile,
        cover: cover,
        bio: desc.trimStart(),
      };
  
      updateUser(params);
      
      await user.save().then((response) => {
        setmyfname(username);
        setmysocial_links(social_links);
        swal('Success', 'Profile updated successfully.', 'success').then(() => {
          window.location.href = "/profile";
        });
        setmyprofileFileshow(profileFileshow);
        setIsLoading(false);
      }).catch((err) => {
        console.error('Error saving user data:', err);
        setIsLoading(false);
      });
    }
  };

  // const createprofile = async () => {
  //   let currentuser = user.get("ethAddress");
  //   let params = {
  //     "firstname": Username,
  //   }

  //   let validate = formValidation.createprofile(params, errorsp);
  //   setERR(validate)
  //   if (validate.formIsValid) {
  //     setIsLoading(true)
  //     let profile = myprofileFileshow
  //     let cover = coverFileshow
  //     if (profileFile) {
  //       const data = profileFile
  //       const files = new Moralis.File(data.name, data);
  //       await files.saveIPFS();
  //       const nftFileMetadataFilePath = files.ipfs();
  //       user.set("profile", nftFileMetadataFilePath)
  //       profile = nftFileMetadataFilePath
  //     } else {
  //       profile = profileFileshow
  //     }
  //     if (coverFile) {
  //       const data1 = coverFile
  //       const files = new Moralis.File(data1.name, data1);
  //       await files.saveIPFS();
  //       const nftFileMetadataFilePath1 = files.ipfs();
  //       cover = nftFileMetadataFilePath1
  //     }
  //     user.set("firstname", username.trimStart());
  //     user.set("social_links", JSON.stringify(social_links));
  //     user.set("bio", Description);
  //     let uname = Username.trimEnd()
  //     let desc = Description.trimEnd()
  //     let params = {
  //       ethAddress: currentuser,
  //       username: uname.trimStart(),
  //       social_links: social_links,
  //       profile: profile,
  //       coevr: cover,
  //       bio: desc.trimStart()
  //     }
  //     updateUser(params)
  //     await user.save().then((response) => {
  //       setmyfname(username)

  //       setmysocial_links(social_links)
  //       swal('Success', 'Profile updated successfully.', 'success').then(() => {
  //         window.location.href = "/profile"
  //       });
  //       setmyprofileFileshow(profileFileshow)
  //       setIsLoading(false)

  //     })
  //       .catch((err) => {
  //         setIsLoading(false)
  //       })
  //   }
  // };

  const handlechangesocialLinks = (type, val) => {

    const values = [...social_links];

    if (val.trim()) {
      let url = val.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g);
      if (!url) {
        //   errors.domain = 'Entered domain name is not valid.'
      }
    }
    if (type == "your_site") {

      values[0].your_site = val;

    }
    if (type == "facebook") {

      values[0].facebook = val;

    }
    if (type == "twitter") {
      values[0].twitter = val;
    }
    if (type == "insta") {
      values[0].insta = val;
    }

    if (type == "discord") {
      values[0].discord = val;
    }
    if (type == "telegram") {
      values[0].telegram = val;
    }

    setsocial_links(values)
  }

  const validsocillink = () => {
    let validdata = true
    if (social_links[0].your_site) {
      let url = social_links[0].your_site.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g);
      if (!url) {
        setsiteErr('Please enter valid url.');
        validdata = false;
      } else {
        setsiteErr('')
      }
    } if (social_links[0].insta) {
      let url = social_links[0].insta.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g);
      if (!url) {
        setInstaErr('Please enter valid  url.');
        validdata = false;
      } else {
        setInstaErr('')
      }
    }
    if (social_links[0].twitter) {
      let url = social_links[0].twitter.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g);
      if (!url) {
        setTwErr('Please enter valid url.');
        validdata = false;
      } else {
        setTwErr('')
      }
    } if (social_links[0].discord) {
      let url = social_links[0].discord.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g);
      if (!url) {
        setDisErr('Please enter valid  url.');
        validdata = false;
      } else {
        setDisErr('')
      }
    } if (social_links[0].facebook) {
      let url = social_links[0].facebook.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g);
      if (!url) {
        setFbErr('Please enter valid url.');
        validdata = false;
      } else {
        setFbErr('')
      }
    }
    if (social_links[0].telegram) {
      let url = social_links[0].telegram.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g);
      if (!url) {
        setTeleErr('Please enter valid  url.');
        validdata = false;
      } else {
        setTeleErr('')
      }
    } else {
    }
    return validdata;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!Username) {
      swal('Warning', 'Please enter username.', 'warning');
    } else if (Username.trim() == "") {
      swal('Warning', 'Please enter valid username.', 'warning');
    } else if (!Description) {
      swal("Warning", 'Please enter Description.', 'warning');
    }
    else if (Description.length > 500) {
      swal('Warning', 'Description field should be maximum of 500 characters.', 'warning');
    } else if (Description.trim() == "") {
      swal('Warning', 'Please enter valid Description.', 'warning');
    } else {
      let valid = validsocillink()
      if (valid) {
        createprofile()
      }

    }
  }
  const removeEmptySpaces = stringVal => {
    return /\s/g.test(stringVal);
  };
  const changestringVal = event => {
    const isValid = removeEmptySpaces(event);
    if (!isValid) {
      setUsername(event)
    }

  };

  return (
    <>
      <LoadingSpinerComponent promiseInProgress={isLoading} />
      <section className="user-login-page">
        <div className="top-image">
          <img src={require("../assets/images/banner.png").default}></img>
        </div>
        <div className="page-title">
          <h2>Edit Profile</h2>
        </div>
      </section>
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <div className="category-seller-nft mt-5 mb-5">
              <Tabs>
                <TabList className="profile-nft">
                  <Tab>
                    <i className="fa fa-user me-1" aria-hidden="true"></i> Profile
                  </Tab>

                </TabList>

                <TabPanel>
                  <div className="profile-tab">
                    <Row>
                      <Col md={7}>
                        <div className="profile-edit-left">
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicUser"
                            >
                              <Form.Label>
                                <i className="fa fa-user" aria-hidden="true"></i>{" "}
                                Username
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Username"
                                value={Username}
                                maxLength={50}
                                onChange={(e) => changestringVal(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                <i className="fa fa-link" aria-hidden="true"></i>{" "}
                                Your Site
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="https://websiteURL.com"
                                value={social_links[0].your_site}
                                onChange={(e) => handlechangesocialLinks("your_site", e.target.value)}
                              />
                              <span className="error">{siteErr}</span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                <i
                                  className="fa fa-instagram"
                                  aria-hidden="true"
                                ></i>{" "}
                                Instagram Url
                              </Form.Label>
                              <Form.Control maxLength={100} type="text" placeholder="Enter instagram url" value={social_links[0].insta} onChange={(e) => handlechangesocialLinks("insta", e.target.value)} />
                              <span className="error">{InstaErr}</span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                <i className="fa fa-twitter" aria-hidden="true"></i>{" "}
                                Twitter Url
                              </Form.Label>
                              <Form.Control maxLength={100} type="text" value={social_links[0].twitter} placeholder="Enter twitter url" onChange={(e) => handlechangesocialLinks("twitter", e.target.value)} />
                              <span className="error">{TwErr}</span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                <img
                                  src={
                                    require("../assets/images/discord.svg")
                                      .default
                                  }
                                ></img>{" "}
                                Discord Url
                              </Form.Label>
                              <Form.Control maxLength={100} type="text" placeholder="Enter discord url" value={social_links[0].discord} onChange={(e) => handlechangesocialLinks("discord", e.target.value)} />
                              <span className="error">{DisErr}</span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                <i
                                  className="fa fa-facebook"
                                  aria-hidden="true"
                                ></i>{" "}
                                Facebook Url
                              </Form.Label>
                              <Form.Control maxLength={100} type="text" value={social_links[0].facebook} placeholder="Enter facebook url" onChange={(e) => handlechangesocialLinks("facebook", e.target.value)} />
                              <span className="error">{FbErr}</span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                <img
                                  src={
                                    require("../assets/images/telegram.svg")
                                      .default
                                  }
                                ></img>{" "}
                                Telegram Url
                              </Form.Label>
                              <Form.Control maxLength={100} type="text" placeholder="Enter telegram url" value={social_links[0].telegram} onChange={(e) => handlechangesocialLinks("telegram", e.target.value)} />
                              <span className="error">{TeleErr}</span>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                <i
                                  className="fa fa-description"
                                  aria-hidden="true"
                                ></i>{" "}
                                Description
                              </Form.Label>
                              <Form.Control
                                type="text"
                                as="textarea"
                                rows={2}
                                maxLength={500}
                                placeholder="Enter Description"
                                value={Description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </Form.Group>

                          </Form>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="profile-edit-right">
                          <p>
                            Profile image{" "}

                            <i
                              className="fa fa-info-circle me-1"
                              aria-hidden="true" data-tip="Upload your profile image (Best fit 250px X 250px)"
                            ><ReactTooltip /></i>
                          </p>

                          <div className="upload-img-preview">
                            <input type="file" className="upload-profile-new" name="upload-img" id="input" accept="image/*" onChange={(e) => readURL(e.target)} />
                            <div className="label">
                              {profileFileshow ?
                                <img className="uploadpic" src={profileFileshow} />
                                :

                                <img className="uploadpic" src={require('../assets/images/user.png').default} />
                              }                        </div>
                          </div>
                          <br></br>
                          <p>
                            Profile banner{" "}
                            <i
                              className="fa fa-info-circle me-1"
                              aria-hidden="true" data-tip="Upload your cover image (Best fit 1920px X 350px)"
                            ><ReactTooltip />
                            </i>
                          </p>
                          <div className="upload-img-preview">
                            <div className="image-holder">
                            </div>
                            <input type="file" className="upload-banner" name="upload-img" id="input" accept="image/*" onChange={(e) => readURLcover(e.target)} />
                            <div className="label">
                              {coverFileshow ?
                                <img className="edit-banner" src={coverFileshow} />
                                :

                                <img className="edit-banner" src={require('../assets/images/banner.png').default} />
                              }
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Button className="submit-bt" onClick={handleSubmit}>Submit</Button>

                </TabPanel>
                <TabPanel>
                  <div className="profile-tab">
                    <Row>
                      <Col md={6}>
                        <div className="item-sold-box">
                          <div>
                            <h5>Auction Expiration</h5>
                            <p>When an auction you created ends.</p>
                          </div>
                          <Form className="description-toggle">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                          </Form>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="item-sold-box">
                          <div>
                            <h5>Bid Activity</h5>
                            <p>When someone Purchased your items</p>
                          </div>
                          <Form className="description-toggle">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                          </Form>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="item-sold-box">
                          <div>
                            <h5>Outbid</h5>
                            <p>
                              When an offer you placed is exceeded by another
                              user.
                            </p>
                          </div>
                          <Form className="description-toggle">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                          </Form>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="item-sold-box">
                          <div>
                            <h5>Price Change</h5>
                            <p>
                              When an item you made an offer on changes in price.
                            </p>
                          </div>
                          <Form className="description-toggle">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                          </Form>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="item-sold-box">
                          <div>
                            <h5>Successful Purchase</h5>
                            <p>When you successfully buy an item.</p>
                          </div>
                          <Form className="description-toggle">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Edit_profile;
