import React from "react";
import { Col, Container, Row } from "react-bootstrap-v5";
import { useNavigate, useParams } from "react-router-dom";

const Upload = () => {
  let history = useNavigate();
  const { blockchain } = useParams();
  function Single_nft() {
    history("/single/" + blockchain);
  }
  function Multiple() {
    history("/multiple/" + blockchain);
  }
  return (
    <>
      <section className="user-login-page">
        <div className="top-image">
          <img src={require("../assets/images/banner.png").default}></img>
        </div>
        <div className="page-title">
          <h2>Mint Digital Trading Cards</h2>
          <p className="page-paragraph">
            Choose <span>“Single”</span> if you want your collectible to be one
            of a kind or <span>“Multiple”</span> if you want to sell one
            collectible multiple times
          </p>
        </div>
      </section>
      <section className="upload-items text-center">
        <Container>
          <Row className="justify-content-center create-single-multiple">
            <Col md={3}>
              <div className="upload-single-nft">
                <img src={require("../assets/images/single.png").default}></img>
              </div>
              <a className="back-bt" onClick={Single_nft}>
                Single Digital Trading Cards
              </a>
            </Col>
            <Col md={3}>
              <div className="upload-single-nft">
                <img
                  src={require("../assets/images/multiple.png").default}
                ></img>
              </div>
              <a onClick={Multiple} className="back-bt">
                Multiple Digital Trading Cards
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Upload;
