import React, { useEffect } from 'react';
import { useERC20Balances } from 'react-moralis';
import { useParams, useLocation } from 'react-router-dom'; // Slug aur query params lene ke liye

const StripeStatusPage = () => {
    // useParams hook se slug (jaise "success" ya "fail") ko capture karna
    const { status } = useParams();
    const location = useLocation();

    // URL se query params capture karna (optional if tumhe pass karna ho)
    const searchParams = new URLSearchParams(location.search);
    const transactionId = searchParams.get('transactionId'); // Example: Query param jaise transactionId

    // CSS class dynamically assign karna success/fail ke according
    const statusStyle = status === 'success' ? 'success-style' : 'fail-style';

    useEffect(() =>{
        if(status === 'success'){
            window.location.href ="/profile";
        }
        if(status === "fail"){
          
        }
    },[status])

    return (
        <div className={`status-container main-section-container ${statusStyle}`}>
            <div className='confirmation-div'>
           {status === 'success' ?
            <img
                alt="logo"
                src={require('../../assets/images/check-ico.gif')}
                className="me-3"
            ></img>
            :
             <img
                alt="logo"
                src={require('../../assets/images/failed-icon.gif')}
                className="me-3"
            ></img>}
            <h1>
                {status === 'success' ? 'Trading cards purchased successfully.' : 'Trading cards failed to purchased!'}
            </h1>
            {transactionId && <p>Transaction ID: {transactionId}</p>}
            </div>
        </div>
    );
};

// CSS styles inline ya external stylesheet mein:
const styles = `
  .status-container {
    text-align: center;
    margin-top: 50px;
  }

  .success-style {
    color: green;
  }

  .fail-style {
    color: red;
  }
`;

export default StripeStatusPage;
