
let register_token = localStorage.getItem("auth") || "" ;
export function formDataHeaders() {
    return Object.assign({}, { "Content-Type": "application/x-www-form-urlencoded", 'Authorization': register_token }, commonHeaders);
}

export function jsonHeaders() {
    return Object.assign({}, { "Content-Type": "application/json", 'Authorization': register_token, 'X-API-KEY': '' }, commonHeaders);
}

export function withPaperHeaders() {
    return Object.assign({}, { "Content-Type": "application/json", 'Authorization':register_token }, commonHeaders);
}

export function jsonHeadersForImage(){
    return Object.assign({}, { "Content-Type": "application/x-www-form-urlencoded", 'Authorization': "e0e03790-d55b-4452-9130-65765da996d2"}, commonHeaders);
    
}

function commonHeaders() {
    return {
        'deviceType': 'w',
        'appVersion': '1.0',
        'Access-Control-Allow-Origin':"*",
        'timezone': 'Asia/Kolkata',
    }
}