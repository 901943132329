import React from "react";
import { Bars } from "react-loader-spinner"; // Import the specific loader

export const LoadingSpinerComponent = (props) => {
  return (
    <>
      {props.promiseInProgress === true ? (
        <div className="main-loader">
          <div className="loader">
            <div className="loader-div ml-5 text-center">
              <Bars color="#fe7702" height="100" width="100" /> {/* Use Bars here */}
            </div>
          </div>
          <p className="loading">{props.message}</p>
        </div>
      ) : null}
    </>
  );
};

export default LoadingSpinerComponent;
