import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap-v5";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import html2canvas from 'html2canvas';

import {
  platform_fee,
  categories,
  colectionaddress,
  nftaddressETH,
  colectionaddressETH,
  nftaddressPOLY,
  colectionaddressPOLY,
  chainidBNB,
  chainidETH,
  chainidPOLY,
  httpprovider,
  sportsfindaMetamaskUrl,
  httpproviderETH,
  appName,
  gasFee,
  apiUrl,
  PINATA_API_KEY,
  PINATA_SECRET_API_KEY,
} from "../config/config";
import {
  winter_single_email,
  winter_single_tokenSymbol,
  winter_single_projectName,
  winter_single_brandImageURL,
  winter_single_contractAddress,
  winter_single_mintFunctionParams,
  winter_single_mintFunction,
  winter_single_sandbox,
  winter_single_maxNumberOfMints,
  winter_single_ercType,
  winter_single_isActive,
  winter_single_requireAddress,
  winter_single_contractPriceFunctionParams,
  winter_single_contractABI,
} from "../config/config";
import { nftService } from "../_services";
import { useMoralis } from "react-moralis";
import { nftAbi } from "./Abi";
import Loader from "react-js-loader";
import { ethers } from "ethers";
import LoadingSpinerComponent from "./Loader";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Multiple_nft from "./Multiple_nft";
import { useNavigate } from "react-router-dom";
import {
  getAccounts,
  getEstimateGasFee,
  GetVideoThumbnails,
  handleScrollToTop,
} from "../Component/common/CommonFunction";
import Swal from "sweetalert2";
import { userServices } from "../services/user.Services";
import Web3 from "web3";
import { Base64 } from "js-base64";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Tooltip as ReactTooltip } from 'react-tooltip';


const Single_nft = (props) => {
  const navigate = useNavigate();
  const [symbolsArr1] = useState(["e", "E", "+", "-"]);
  const { isAuthenticated, user, userError, error, Moralis } = useMoralis();
  const [images, set_images] = useState("");
  const [description, setdescription] = useState("");
  const [price, setprice] = useState();
  const [royality_percent, setroyality_percent] = useState(0);
  const [instant_check, set_instant_check] = useState(false);
  const [royality_check, set_royality_check] = useState(0);
  const [auction_check, set_auction_check] = useState(true);
  const [tokenContractAddress, settokenContractAddress] = useState();
  const [showstep, setShowstep] = useState(false);
  const [imageFile, setimageFile] = useState();
  const [minBid, setMinbid] = useState("");
  const [reservePrice, setReservePrice] = useState("");
  const [message, setmessage] = useState("Loading...");
  const [showloader, setshowloader] = useState(false);
  const [category, setcategory] = useState("");
  const [StartTime, setStartTime] = useState(
    new Date(new Date().setMinutes(new Date().getMinutes() + 10))
  );
  const [EndTime, setEndTime] = useState(
    new Date(new Date(new Date().setHours(new Date().getHours() + 2)).setMinutes(
      new Date().getMinutes() + 10
    ))
  );
  const [name, setName] = useState("");
  const [projects, setProjects] = useState([]);
  const [projectId, setprojectId] = useState("");
  const [showvalue, SetShowvalue] = useState(false);
  const [onsale, Setonsale] = useState(false);
  const [upload, setUpload] = useState(false);
  const [approved, Setapproved] = useState(false);
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [addLevel, setAddLevel] = useState([
    { key: "", value1: "", value2: "" },
  ]);
  const [Propertiesvalues, setPropertiesvalues] = useState([
    { name: "", value1: "" },
  ]);
  const [Statevalues, setStatevalues] = useState([
    { name: "", value1: "", value2: "" },
  ]);
  const [showvaluePro, SetShowvaluePro] = useState(false);

  const [addLevelSave, setAddLevelSave] = useState([
    { key: "", value1: "", value2: "" },
  ]);
  const [PropertiesvaluesSave, setPropertiesvaluesSave] = useState([
    { name: "", value1: "" },
  ]);
  const [imageFrameUrl, setImageFrameUrl] = useState("")
  const [frameNumber, setframeNumber] = useState("")
  const [selectFrame, setselectFrame] = useState("")
  let frameImageUrl = useRef();
  const [frameName, setFrameName] = useState("")
  const { blockchain } = useParams();
  const [userDetail, setUserDetail] = useState();
  const [currentUrl, setCurrentUrl] = useState("/");
  const nftAddressCurrent = useRef();
  const [imageUrl, setImageUrl] = useState("");
  const location = useLocation();
  const AIURL = location.search.split("=")[1] == "yes";
  var AiImageID = location?.state?.imageId;

  const id = useLocation()?.search
  const draftID = new URLSearchParams(id).get('id');
  useEffect(() => {
    getUserDetail();
    if (localStorage.getItem("auth") != null) {
      // init();
      if (draftID) {
        getDraftDetails(draftID)
      }
    } else {
      history("/login");
    }

  }, []);


  const [mimeType, setMineType] = useState()
  function getDraftDetails(draftID) {
    userServices.getdraftData(draftID).then((res) => {
      let data = res?.data?.data;
      if (data?.nft_type === "S") {
        setName(data?.nft_name)
        setMineType(data?.file_mime_type)
        setdescription(data?.nft_description)
        setcategory(data?.nft_category);
        setroyality_percent(data?.nft_royality)
        if (data?.nft_on_auction === "YES") {
          setMinbid(data?.nft_price || "")
          setStartTime(new Date(parseInt(data?.nft_auction_start)))
          setEndTime(new Date(parseInt(data?.nft_auction_end)))
        }
        else {
          setprice(data?.nft_price)
        }
        setDraftId(data?.id)
        setimageFile(data?.nft_image)
        set_images(data?.nft_image)
        set_royality_check(data?.nft_royality > 0 ? true : false)
        set_auction_check(data?.nft_on_auction === "YES" ? true : false)
        set_instant_check(data?.nft_on_auction === "YES" ? false : true)
      }

    })
      .catch((error) => {
        Swal.fire({
          title: "",
          text: "You are not authorised to access this page.",
          imageUrl: require("../assets/images/thumbs-down.png"),
          imageWidth: 80,
          imageHeight: 80,
          imageAlt: "Custom image",
          animation: false,
        }).then(() => window.location.href = "/trading-cards");
      })
  }

  useEffect(() => {
    if (draftID) {
      getDraftDetails(draftID)
    }
  }, [])

  useEffect(() => {
    // metamaskConnected();
    let data = AiImageID ? AiImageID : localStorage.getItem("imageId");
    if (AIURL) {
      if (data) {
        getDetailsImage(data)
      }
    }
  }, []);



  function getDetailsImage(id) {
    userServices.getImageDetails(id).then((res) => {
      let data = res?.data?.data;
      frameImageUrl.current = data[0]?.image_url;
      setImageFrameUrl(data[0]?.image_url)
      // console.log("data[0]?.image_name",data)
      setframeNumber(data[0]?.image_number)
      setFrameName(data[0]?.image_name)
      setselectFrame(data[0]?.frame_url)
    })
      .catch((error) => {
        console.log("error", error)
      })
  }

  // screensport function

  const takeScreenshot = (isFromSubmit) => {
    const divElement = document.getElementById("center-frame1");
    console.log(divElement)
    // Replace "my-div" with the ID of your div
    if (divElement) {
      html2canvas(divElement, { backgroundColor: null, borderColor: null }).then((canvas) => {
        canvas.style.width = "100%";
        const imgData = canvas.toDataURL();
        console.log('imgData---------', imgData)
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = imgData;
        img.width = divElement.offsetWidth; // make the image the same width and height as the target container
        img.height = divElement.offsetHeight;
        const base64String = imgData; // Base64 encoded string
        const fileName = 'image.png'; // Desired file name
        const file = base64ToFile(base64String, fileName, isFromSubmit);
      }).catch((error) => {
        console.log("error", error);
      });
    }
  }


  const [showFrame, setShowFrame] = useState(false);
  function base64ToFile(base64String, fileName, isFromSubmit) {
    // Remove the data URI prefix and any whitespace from the base64 string
    const base64 = base64String.replace(/^data:image\/(png|jpg|jpeg);base64,/, '').replace(/\s/g, '');
    // Convert the base64 string to a Blob
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: 'image/png' }); // Change the MIME type as needed
    const file = new File([blob], fileName, { type: blob.type });
    setimageFile(file);
    if (isFromSubmit) {
      console.log("isFromSubmit");
      setTimeout(() => {
        if (validation()) {
          create_nft(file);
        }
      }, 1000)
    } else {
      console.log("uploadImageOnIPFS")
      uploadImageOnIPFS(file)
    }
  }

  const [draft_id, setDraftId] = useState(localStorage.getItem("draftID") ? localStorage.getItem("draftID") : "");
  const draftRef = useRef("");
  function SavetoDraft(imgurl, imagetype) {
    console.log("draft_id", draft_id, "draftRef.current", draftRef.current)
    const params = {
      nft_name: name || "",
      nft_type: "S",
      nft_description: description || "",
      price: price,
      nft_image: imgurl ? imgurl : imageFile,
      nft_category: category,
      draft_id: draft_id ? draft_id : draftRef.current,
      mime_type: imagetypeRef.current ? imagetypeRef.current : "",
    }
    userServices.saveTodraft(params).then((res) => {
      if (res.status === 200) {
        console.log("save To Draft", res);
        let data = res?.data?.data;
        localStorage.setItem("draftID", data);
        setDraftId(data)
        draftRef.current = data;
        toast.success("Saved to draft.", {
          toastId: 'success_draft_1',
        })
      }
    })
      .catch((error) => {
        console.log("error", error)
      })
  }



  const history = useNavigate();

  // const init = async () => {
  //   // await window.ethereum.enable();
  //   let web3 = new Web3(window.ethereum);
  //   let collectionAddressCurrent = "";
  //   if (blockchain == "ETH") {
  //     nftAddressCurrent.current = nftaddressETH;
  //     collectionAddressCurrent = colectionaddressETH;
  //   }
  //   if (blockchain == "MATIC") {
  //     nftAddressCurrent.current = nftaddressPOLY;
  //     collectionAddressCurrent = colectionaddressPOLY;
  //   }
  //   const tokenContract = new web3.eth.Contract(
  //     nftAbi,
  //     nftAddressCurrent.current
  //   );
  //   settokenContractAddress(tokenContract);
  // };

  async function getUserDetail() {
    userServices
      .getIDwithEdit()
      .then((res) => {
        console.log("userDetail", res?.data?.data);
        setUserDetail(res?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401)
          Swal.fire({
            title: "",
            text: "Your session has expired. Please login to pick up where you left off.",
            imageUrl: require("../assets/images/thumbs-down.png").default,
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: "Custom image",
            animation: false,
          }).then(() => logout());
      });
  }

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  function saveWalletAddressApi(walletaddress) {
    const params = {
      eth_address: walletaddress,
    };

    userServices
      .updateWalletAddress(params)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("WalletConnect", true);
          localStorage.setItem("walletType", "metamask");
          localStorage.setItem("walletAddress", walletaddress);
          setShowLoader(false);
          Swal.fire({
            title: "Success",
            text: "Wallet connected successfully.",
            imageUrl: require("../../src/assets/images/thumbs-up.png").default,
            imageWidth: 80,
            imageHeight: 100,
            imageAlt: "Custom image",
            animation: false,
          }).then(() => {
            nextPage();
          });
        }
      })
      .catch((Error) => {
        localStorage.setItem("WalletConnect", false);
        localStorage.removeItem("walletType");
        localStorage.removeItem("walletAddress");
        setShowLoader(false);
        Swal.fire({
          title: "Oops..",
          text: Error.response.data.message,
          imageUrl: require("../../src/assets/images/thumbs-down.png").default,
          imageWidth: 80,
          imageHeight: 100,
          imageAlt: "Custom image",
          animation: false,
        });
      });
  }

  function nextPage() {
    window.location.href = currentUrl;
  }

  /*-------------on focuse fiels functionality--------------*/
  function onfocuse() {
    if (!imageFile && localStorage.getItem("imageId") === null) {
      document.getElementById("file").focus()
    } else if (!name) {
      document.getElementById("name").focus()

    } else if (!description) {
      document.getElementById("description").focus()

    } else if (!category) {
      document.getElementById("category").focus()
    }
    else if (instant_check && !price) {
      document.getElementById("number").focus()
    } else if (instant_check && price == 0) {
      document.getElementById("number").focus()
    }
    else {

    }
  }


  const handleCreatenft = async (e) => {
    e.preventDefault();
    setmessage("Loading.");
    if (validation()) {
      create_nft();
    }
    onfocuse();
  };
  let balanceRef = useRef();
  let gasPriceRef = useRef();

  async function getGasFee(userAddress) {
    const web3 = new Web3(window.ethereum);
    let provider = web3.eth.setProvider(httpproviderETH);
    let gasPriceGet = await web3.eth.getGasPrice();
    gasPriceRef.current = gasPriceGet;

    let balance = await web3.eth.getBalance(userAddress).then((res) => {
      return res;
    });
    balanceRef.current = balance;
  }

  const [errors, setErrors] = useState({
    image: "",
    auction_check: "",
    name: "",
    royality_percent: "",
    description: "",
    category: "",
    minBid: "",
    price: "",
  });
  let startDateTime = new Date(StartTime);
  let currentDateTime = new Date();

  function validation() {
    let status = true;
    let error = { ...errors };
    if (!imageFile && localStorage.getItem("imageId") === null) {
      error.image = "Please add media.";
      status = false;
    }
    if (!name) {
      error.name = "Please enter name.";
      status = false;
    }
    if (!description) {
      error.description = "Please enter Description.";
      status = false;
    }
    if (!category) {
      error.category = "Please select category.";
      status = false;
    }
    if (!price) {
      error.price = "Please enter price.";
      status = false;
    } else if (price == 0) {
      error.price = "Price cannot be zero.";
      status = false;
    }
    setErrors({ ...errors, ...error });
    return status;
  }

  useEffect(() => {
    getprice()



  }, [])

  const [usdprice, setusdprice] = useState("")
  useEffect(() => {
    getprice();
  }, []);

  const getprice = async () => {
    await nftService
      .get_price()
      .then((response) => {
        setusdprice(response.data.USD);
      })
      .catch((err) => {
      });
  };

  const getprojects = async (userAddress) => {
    await nftService
      .getprojects(userAddress)
      .then((response) => {
        setProjects(response.data.data);
      })
      .catch((err) => {
        console.error("Error signing in.", err.toString());
      });
  };

  async function readURL(input) {
    let type = input.files[0].type.split("/");
    if (input.files && input.files[0]) {
      var filetype = checkfile(input.files[0]);
      if (filetype === true) {
        uploadImageOnIPFS(input.files[0])
        set_images("");
        var reader = new FileReader();
        reader.onload = function (e) {
          set_images(e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
  }
  let thumbnailImageUrlRef = useRef();
  const imagetypeRef = useRef();
  const [ImageMimeType, setImageMimeType] = useState()

  async function uploadImageOnIPFS(input) {


    const data = input;
    var imagetype = input?.type?.split("/");
    let thumbnailImageUrl;

    // Check if the input is a video and generate a thumbnail if needed
    if (imagetype[0] === "video") {
      let props = { file: data };
      thumbnailImageUrl = await GetVideoThumbnails(props); // Assuming GetVideoThumbnails is a custom function you have for generating thumbnails
      thumbnailImageUrlRef.current = thumbnailImageUrl;
    }

    // Prepare form data for Pinata upload
    const formData = new FormData();
    formData.append("file", data);

    const metadata = JSON.stringify({
      name: Math.floor(Date.now() / 1000) + "." + imagetype[1], // File name
    });

    formData.append("pinataMetadata", metadata);
    formData.append("pinataOptions", JSON.stringify({ cidVersion: 0 }));

    try {
      // Pinata upload request
      const res = await axios.post(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        formData,
        {
          maxBodyLength: "Infinity", // Prevents 413 Request Entity Too Large errors
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            pinata_api_key: PINATA_API_KEY,
            pinata_secret_api_key: PINATA_SECRET_API_KEY,
          },
        }
      );

      const imgurl = `https://gateway.pinata.cloud/ipfs/${res.data.IpfsHash}`;
      imagetypeRef.current = imagetype[0];
      setMineType(imagetypeRef.current);
      setimageFile(imgurl);
      SavetoDraft(imgurl, imagetype);
    } catch (error) {
      console.error("Error uploading file to Pinata: ", error);
    }
  }

  function checkfile(file_img) {
    var get_ext = file_img.name.split(".");
    var exts = ["png", "jpg", "jpeg", "gif", "mp4", "mov"];
    get_ext = get_ext.reverse();

    var a = exts.indexOf(get_ext[0].toLowerCase());

    if (a > -1) {
      return validateSize(file_img);
    } else {
      alert("Please select a valid image/video file");
      return false;
    }
  }
  function validateSize(input) {
    const fileSize = input.size / 1024 / 1024; // in MiB
    if (fileSize > 50) {
      alert("File size exceeds 50 MB");
      return false;
    } else {
      return true;
    }
  }

  // Function to upload file to Pinata
  const uploadToPinata = async (file, fileName) => {
    try {
      const formData = new FormData();
      formData.append("file", file, fileName);

      const pinataMetadata = JSON.stringify({
        name: fileName,
      });
      formData.append("pinataMetadata", pinataMetadata);

      const response = await axios.post(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            pinata_api_key: PINATA_API_KEY,
            pinata_secret_api_key: PINATA_SECRET_API_KEY,
          },
        }
      );
      return `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;
    } catch (error) {
      console.error("Error uploading to Pinata:", error);
      throw error;
    }
  };

  // Function to upload metadata to Pinata
  const uploadMetadataToPinata = async (metadata) => {
    try {
      const encodedMetaData = Base64.encode(JSON.stringify(metadata), true);
      const metadataBlob = new Blob([encodedMetaData], { type: "application/json" });

      const formData = new FormData();
      formData.append("file", metadataBlob, "metadata.json");

      const pinataMetadata = JSON.stringify({
        name: "metadata.json",
      });
      formData.append("pinataMetadata", pinataMetadata);

      const response = await axios.post(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            pinata_api_key: PINATA_API_KEY,
            pinata_secret_api_key: PINATA_SECRET_API_KEY,
          },
        }
      );
      return `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;
    } catch (error) {
      console.error("Error uploading metadata to Pinata:", error);
      throw error;
    }
  };

  console.log("userDetail", userDetail?.isNftCreated)

  // Main NFT creation function
  const create_nft = async (file) => {
    const params = {
      name: name.replace(/[“”‘’]/g, ""),
      description: description.replace(/[“”‘’]/g, ""),
      image: imageFile,
      thumbnailImage: thumbnailImageUrlRef.current,
      draft_id: draft_id,
      category: category,
      instant_check: instant_check,
      price: price?.toString(),
      nft_media_type:imagetypeRef.current ? imagetypeRef.current : "",
    }
    console.log("single nft params:::::::", params)
    setshowloader(true);
    userServices.createSingleNft(params).then((res) => {
      console.log("success::::::::", res?.status)
      if (res?.status == 200) {
        Swal.fire({
          title: "Success",
          text: "Trading Card created successfully.",
          imageUrl: require("../../src/assets/images/thumbs-up.png"),
          imageWidth: 80,
          imageHeight: 100,
          imageAlt: "Custom image",
          animation: false,
        }).then(() => {
          if (userDetail?.isNftCreated ==0){
           history("/Add-bank?nft=yes");
            return; 
          }
          else{
            history("/explore", { replace: true })
          }
        })
        setshowloader(false);

      }
    }).catch((error) => {
      if (error?.response?.status === 401) {
        Swal.fire({
          title: "Error",
          text: "Your session has expired. Please login to pick up where you left off.",
          imageUrl: require("../../src/assets/images/thumbs-down.png").default,
          imageWidth: 80,
          imageHeight: 100,
          imageAlt: "Custom image",
          animation: false,
        }).then(() => logout());
      }
    });

  };

  const deleteValue = (i) => {
    var temp = [...addLevel];
    temp.splice(i, 1);
    setAddLevel(temp);
  };

  const addMoreFields = () => {
    var temp = [...addLevel];
    temp.push({ key: "", value1: "", value2: "" });
    setAddLevel(temp);
  };



  function checkfile(file_img) {
    var get_ext = file_img.name.split(".");
    var exts = ["png", "jpg", "jpeg", "gif", "mp4", "mov"];
    get_ext = get_ext.reverse();

    var a = exts.indexOf(get_ext[0].toLowerCase());

    if (a > -1) {
      return validateSize(file_img);
    } else {
      alert("Please select a valid image/video file");
      return false;
    }
  }

  function validateSize(input) {
    const fileSize = input.size / 1024 / 1024; // in MiB
    if (fileSize > 50) {
      alert("File size exceeds 50 MB");
      return false;
    } else {
      return true;
    }
  }


  function handleChange(event) {
    set_instant_check(event.target.checked);
    if (event.target.checked == true) {
      set_auction_check(false);
      setMinbid("")
    } else {
      set_auction_check(true);
      setStartTime(new Date());
      setEndTime(new Date().setHours(new Date().getHours() + 2));
      setprice("")
    }
  }

  function handlechangeroyality_check(e) {
    set_royality_check(e.target.checked);
    if (!e.target.checked) {
      setroyality_percent(0)
    }
  }

  function handleAuctionChange(e) {
    set_auction_check(e.target.checked);
    set_instant_check(false);
    setprice("")
    if (e.target.checked == true) {
      set_instant_check(false);
      setStartTime(new Date());
      setEndTime(new Date().setHours(new Date().getHours() + 2));
      setprice("")
    } else {
      set_instant_check(true);
    }
  }

  const handleClosevaluePro = () => {
    setPropertiesvalues(PropertiesvaluesSave);
    SetShowvaluePro(false);
  };

  const handleClosevalue = () => {
    setAddLevel(addLevelSave);
    SetShowvalue(false);
  };

  const addMoreProperties = () => {
    var temp = [...Propertiesvalues];
    temp.push({ name: "", value1: "" });
    setPropertiesvalues(temp);
  };
  const deletePro = (i) => {
    var temp = [...Propertiesvalues];
    temp.splice(i, 1);
    setPropertiesvalues(temp);
  };
  const deleteSta = (i) => {
    var temp = [...Statevalues];
    temp.splice(i, 1);
    setStatevalues(temp);
  };

  function handleChangePro(i, event, _key1) {
    const values = [...Propertiesvalues];
    if (_key1 == "name") {
      values[i].name = event;
    }
    if (_key1 == "value1") {
      values[i].value1 = event;
    }

    setPropertiesvalues(values);
  }

  const savePro = () => {
    setPropertiesvaluesSave(Propertiesvalues);
    SetShowvaluePro(false);
  };

  function handleChangelevel(i, event, _key1) {
    let isvalid = true;
    const values = [...addLevel];
    if (_key1 == "key") {
      values[i].key = event;
    }
    if (_key1 == "value1") {
      values[i].value1 = event;
    }
    if (_key1 == "value2") {
      values[i].value2 = event;
    }

    setAddLevel(values);
  }

  function setShowLoader(showloader) {
    setshowloader(showloader);
  }

  return (
    <>
      <ToastContainer />
      <LoadingSpinerComponent
        promiseInProgress={showloader}
        message={message}
      />

      <section className="collection_section">
        <Container>
          <div className="single-multiple-box">
            <h2 class="mt-4 mb-3">Create collectible</h2>
            <h4 className="choose-heading">
              Choose Type{" "}
              <span
                data-tip="Single:<br/> If you want to highlight the uniqueness and 
                            individuality of your item<br/><br/> Multiple:<br/> If you want to share your item with a large number of 
                            community member"
              >
                <i class="fa fa-info-circle" aria-hidden="true"></i>
              </span>
              <ReactTooltip place="bottom" multiline={true} />
            </h4>
            <Tabs defaultIndex={location?.state?.fromHomePage ? 1 : 0}>
              <TabList>
                <Tab>Single</Tab> <Tab>Multiple</Tab>
              </TabList>
              <TabPanel>
                <Row className="crete-collect-new">
                  <Col lg={8}>
                    <div className="collectible-box signle-area-collectibla-box">
                      <Form.Control
                        type="file"
                        id="file"
                        className="uplage-image-collect"
                        onChange={(e) => readURL(e.target)}


                      />
                      <div className="collectible-upload-image text-center">
                        <div className="collectible-bg">
                          <i className="fa fa-upload" aria-hidden="true"></i>
                          <span>PNG, GIF, JPG, mp4 Or JPEG</span>

                          <h6 className="mt-2">Browse Files</h6>
                        </div>
                      </div>
                      <div className="preview-area preview-mobile-view">
                        <h6>Preview</h6>
                        <p className="preview-text single-nft-preview">
                          Preview of your new collectible
                        </p>
                        {images != "" ? (
                          <>
                            <div className="preview-image ">
                              {imageFile && imageFile.type == "video/mp4" ? (
                                <video
                                  className="video-container video-container-overlay"
                                  autoplay
                                  muted
                                  loop
                                  preload='none'
                                  playsInline
                                  class="uvodna__bg-video"
                                >
                                  <source src={images} type={imageFile.type} />
                                </video>
                              ) : (
                                <img src={images} alt="images"></img>
                              )}
                            </div>

                            <h5 className="mb-1 mt-2">{name}</h5>
                            <p className="mb-1 description-text">
                              {description}
                            </p>
                            <h4 className="priceh4">
                              {console.log("price::::::::", price)}
                              {price && price !== "null" ? "$" + Number(price).toFixed(2) : ""}


                            </h4>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {!imageFile && errors?.image ? (
                        <span className="errors">{errors.image}</span>
                      ) : (
                        <></>
                      )}

                      <div className="name-description-filed single-nft-detail-area">
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              maxLength="25"
                              type="text"
                              id="name"
                              placeholder="Enter Name"
                              value={name}
                              onKeyPress={(e) => {
                                if (e.key === " " && e.target.value == 0)
                                  e.preventDefault();
                              }}
                              onChange={(e) => setName(e.target.value)}
                              onBlur={(e) => {
                                if (e?.target?.value?.length > 0) {
                                  SavetoDraft()
                                }
                              }}
                            />
                            {!name && errors?.name ? (
                              <span className="errors">{errors.name}</span>
                            ) : (
                              <></>
                            )}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Description </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={2}
                              id="description"
                              maxLength={500}
                              placeholder="Enter Description"
                              value={description}
                              onKeyPress={(e) => {
                                if (e.key === " " && e.target.value == 0)
                                  e.preventDefault();
                              }}
                              onChange={(e) => setdescription(e.target.value)}
                              onBlur={(e) => {
                                if (e?.target?.value?.length > 0) {
                                  SavetoDraft()
                                }
                              }}
                            />
                            {!description && errors?.description ? (
                              <span className="errors">
                                {errors.description}
                                <br />
                              </span>
                            ) : (
                              <></>
                            )}
                            <Form.Text className="text-muted">
                              With preserved line break
                            </Form.Text>
                          </Form.Group>
                          {projects.length > 0 ? (
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                              style={{ position: "relative" }}
                            >
                              <Form.Label>Project</Form.Label>

                              <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => setprojectId(e.target.value)}
                              >
                                <option value={""}>Please select</option>;
                                {projects.length > 0 ? (
                                  projects.map((item, i) => {
                                    return (
                                      <option value={item.id} key={i}>
                                        {item.project_name}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </Form.Select>
                            </Form.Group>
                          ) : null}
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                            style={{ position: "relative" }}
                          >
                            <Form.Label>Category</Form.Label>

                            <Form.Select
                              aria-label="Default select example"
                              value={category}
                              id="category"
                              onChange={(e) => setcategory(e.target.value)}
                              onBlur={(e) => {
                                if (e?.target?.value?.length > 0) {
                                  SavetoDraft()
                                }
                              }}
                            >
                              <option value={""}>Please select Category</option>
                              {categories.length > 0 ? (
                                categories.map((item, i) => {
                                  return (
                                    <option value={item} key={i}>
                                      {item}
                                    </option>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </Form.Select>
                            {!category && errors?.category ? (
                              <span className="errors">{errors.category}</span>
                            ) : (
                              <></>
                            )}
                          </Form.Group>
                          <div className="auction-toggle area">
                  <div className="instant-sale-prize">
                    <Form.Label>Instant sale price</Form.Label>
                    <br />
                    <Form.Text className="text-muted">
                      Enter the price for which the item will be instantly sold
                    </Form.Text>
                    <br />
                    <div className="input-group">
                      <span className="input-group-text">$</span>
                      <Form.Control
                        type="number"
                        placeholder="Enter Price"
                        value={price}
                        id="price"
                        min={1} // Minimum value set to 1
                        maxLength={15}
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Ensure value is a valid number and not less than 1
                          if (/^(\d)*(\.)?(\d)*$/.test(value) && value >= 1) {
                            setprice(value);
                          } else if (value === "") {
                            setprice("");
                          }
                        }}
                        onBlur={(e) => {
                          if (e?.target?.value?.length > 0) {
                            SavetoDraft();
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key === " " && e.target.value == 0) e.preventDefault();
                        }}
                      />
                    </div>
                  </div>
                </div>

                {(!price || price == 0) && errors?.price ? (
                  <span className="errors">{errors.price}</span>
                ) : null}


                        </Form>
                      </div>
                      <div className="create-nft-bottom-btns">
                        <div className="create-btn-left">
                          <button
                            type="submit"
                            className="create-bt"
                            onClick={handleCreatenft}
                          >
                            Create
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} >
                    <div className="preview-area preview-desktop-view">

                      <h6
                        onClick={(e) => {
                          takeScreenshot(false)
                        }}
                      >Preview</h6>
                      <p className="preview-text single-nft-preview" >
                        Preview of your new collectible
                      </p>


                      {images != "" ? (
                        <>
                          {imageFile ? <div className="preview-image">
                            {imageFile && mimeType == "video" ? (
                              <video
                                className="video-container video-container-overlay"
                                autoPlay
                                muted
                                loop
                                controls={false}
                                data-reactid=".0.1.0.0"
                              >
                                <source src={images} />
                              </video>
                            ) : (
                              <img
                                src={images}
                                alt="images"></img>
                            )}
                          </div>
                            :
                            <div className="preview-image">
                              <Skeleton height={200} />
                            </div>
                          }

                          <h5 className="mb-1 mt-2">{name}</h5>
                          <p className="mb-1 description-text">{description}</p>
                          <h4 className="priceh4">
                            {price && price !== "null" ? "$" + Number(price).toFixed(2) : ""}

                          </h4>
                        </>
                      ) :
                        localStorage.getItem("imageId") ?
                          (
                            // <div className="output">
                            <div id="" >
                              <div className="center-frame-area" id="center-frame1">
                                <div className={frameNumber?.length == 1 ? `number-input one-digit-preview` : frameNumber?.length == 2 ? "number-input two-digit-preview" : "number-input three-digit-preview"}>
                                  {/* <Form.Control type="number" value={frameNumber} readOnly={true} /> */}
                                  <span className="frame-number" >
                                    {frameNumber}
                                  </span>
                                </div>
                                {selectFrame && <img
                                  src={selectFrame} alt="img" />}
                                {/* <img src="http://localhost:3000/frame4.png" alt="" /> */}
                                <div className="frame-center-image-area">
                                  <img style={{ height: "590px" }}
                                    id="my-img"
                                    onLoad={(e) => {
                                      console.log("image uploaded---", e)
                                      takeScreenshot(false)
                                    }}
                                    src={imageFrameUrl}
                                    // src="http://localhost:3000/openai_1685013943438.png"
                                    alt="img" />
                                </div>

                                {/* </div> */}
                                <div className="name-input">
                                  <span className="frame-value">
                                    {frameName}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                          : null
                      }



                    </div>
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel>
                <Multiple_nft setShowLoader={setShowLoader} />
              </TabPanel>
            </Tabs>
          </div>
        </Container>
      </section>
    </>
  );
}
export default Single_nft;
