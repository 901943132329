import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Container, Nav, Navbar, Modal, Dropdown } from "react-bootstrap-v5";
import $ from "jquery";
import { useNavigate, useLocation } from "react-router";
import { useMoralis } from "react-moralis";
import swal from "sweetalert";
import { userServices } from "../services/user.Services";
import { nftService } from "../_services";
import GlobalContext from "../Context/GlobalContext";
import {
  appName,
  httpproviderETH,
  sportsfindaMetamaskUrl,
} from "../config/config";
import Web3 from "web3";
import LoadingSpinerComponent from "../Pages/Loader";
import { getAccounts, getChain } from "./common/CommonFunction";
import { Button } from "react-bootstrap";
import BrandingPartnership from "../Pages/BrandingPartnership";
import Swal from "sweetalert2";

const Header = () => {
  var global_data = useContext(GlobalContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showbuy1, setShowbuy1] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [defaultaccount, setDefaultAccount] = useState("");
  const [address, setAddress] = useState("");
  const [btnhandler] = useState("Connect wallet");
  const [currentUrl, setCurrentUrl] = useState("/");
  const [addFundsUrl, setAddFundsUrl] = useState("");
  const [userDetail, setUserDetail] = useState();
  const authenticated = localStorage.getItem("auth");
  const [showLoader, setShowLoader] = useState(false);
  const [showMetaModal, setshowMetaModal] = useState(false);
  const search = useLocation().search;
  const pathName = new URLSearchParams(search).get("path");

  const { isAuthenticated, user, userError, error, Moralis } = useMoralis();
  const handleClosebuy1 = () => setShowbuy1(false);
  let history = useNavigate();
  const location = useLocation();

  function isAuthenticatedMoralis(){
    return isAuthenticated;
  }

  useEffect(() => {
    if (authenticated != null) {
      getUserDetail();
    }
  }, [location.pathname]);

  useEffect(() => {
    const type = localStorage.getItem("walletType");
    const wallet = localStorage.getItem("WalletConnect");
    const address = localStorage.getItem("walletAddress");
    setAddress("");
    if (wallet == "true" && type == type) {
      setAddress(address);
      let params = { ethAddress: address };
    }
    if (isAuthenticated && localStorage.getItem("WalletConnect")) {
      let params = { ethAddress: user.get("ethAddress") };
      setAddress(user.get("ethAddress"));
    }
  }, [isAuthenticated]);

  // if (isAuthenticated && localStorage.getItem("WalletConnect")){
  //   window.ethereum?.on("accountsChanged", function (accounts) {
  //     setAddress(accounts[0]);
  //     console.log("accounts[0]",accounts[0])
  //     window.location.reload();
  //   });
  // }
  
  const logout = () => {
    localStorage.clear();
    window.open("/", "_self");
  };

  // async function buy_crypto() {
  //   if (user) {
  //     let add = user.get("ethAddress");
  //     let response = await Moralis.Plugins.fiat.buy(
  //       { coin: "matic", receiver: add },
  //       { disableTriggers: true }
  //     );
  //     setShowbuy1(true);
  //     setAddFundsUrl(response.data);
  //   } else {
  //     alert("Please login");
  //   }
  // }

  function Homepage() {
    history("/");
    setExpanded(false);
  }

  function AthletesPage() {
    history("/athletes");
    setExpanded(false);
  }

  function About() {
    history("/about");
    setExpanded(false);
  }

  function Seller_profile() {
    history("/profile");
    setExpanded(false);
  }

  function Explore() {
    history("/explore");
    setExpanded(false);
  }
  function Nft_single() {
    history("/trading_cards");
    setExpanded(false);
  }
  function Profile() {
    history("/profile");
    setExpanded(false);
  }
  function AddBank() {
    history("/Add-bank");
    setExpanded(false);
  }
  function Gettouch() {
    history("/contact");
  }
  function BrandingPartnership() {
    history("/branding-partnership");
    setExpanded(false);
  }


  function saveWalletAddressApi(walletaddress) {
    const params = {
      eth_address: walletaddress,
    };

    userServices
      .updateWalletAddress(params)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("WalletConnect", true);
          localStorage.setItem("walletType", "metamask");
          localStorage.setItem("walletAddress", walletaddress);
          setShowLoader(false);
          Swal.fire({
            title: "Success",
            text: "Wallet connected successfully.",
            icon: "success",
            button: "OK", 
          })
          .then(() => {
            nextPage();
          });
        }
      })
      .catch((Error) => {
        localStorage.setItem("WalletConnect", false);
        localStorage.removeItem("walletType");
        localStorage.removeItem("walletAddress");
        setShowLoader(false);
        swal({
          title: "Oops..",
          text: Error.response.data.message,
          imageUrl: require("../assets/images/thumbs-up.png").default,
          imageWidth: 80,
          imageHeight: 100,
          imageAlt: "Custom image",
        })
        .then(() => {
          window.location.reload()
        });
      });
  }

  $(function () {
    var header = $(".navbar");

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (
        (scroll >= 0 && window.innerWidth > 767) ||
        (scroll >= 50 && window.innerWidth < 767)
      ) {
        header.addClass("scrolled");
      } else {
        header.removeClass("scrolled");
      }
    });
  });

  function Logout() {
    const logout = window.confirm("Are you sure you want to logout?");
    if (logout) {
      localStorage.clear();
      setTimeout(nextPage, 1000);
    }
  }
  function nextPage() {
    window.location.href = location.pathname =="/nft-ai-generator" ? `/trading-cards?aitools=yes`:  "/";
  }

  const handledisconnect = (e) => {
    Swal.fire({
      icon: "info",
      html: `<div class="logout-text">
        Are you sure you, want to logout?
      </div>
      `,
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        localStorage.clear();
        localStorage.setItem("WalletConnect", false);
        localStorage.removeItem("walletAddress");
        localStorage.removeItem("walletType");
        window.location.href = "/";
      }
    });
  };

  const connectWalletHandler = (e) => {
    window.location.href = "/wallet";
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0);
    });
  }, []);


  async function getUserDetail() {
    userServices
      .getIDwithEdit()
      .then((res) => {
        setUserDetail(res?.data?.data);
        localStorage.setItem("user",JSON.stringify(res?.data?.data))
      })
      .catch((error) => {
        if (error?.response?.status === 401)
          Swal.fire({
            title: "",
            text: "Your session has expired. Please login to pick up where you left off.",
            imageUrl: require("../assets/images/thumbs-down.png").default,
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: "Custom image",
            animation: true,
          })
          .then(() => logout());
      });
  }

  
  

  return (
    <>
     
      <LoadingSpinerComponent
        promiseInProgress={showLoader}
        message={"Please wait.."}
      />
      <header
        className={
          (window.location.pathname.includes("/profile") ? "black-head" : "") ||
          (scroll ? "bg-black" : "")
        }
      >
        <Navbar
          expanded={expanded}
          className={
            location.pathname == "/" ||
            location.pathname == "/profile" ||
            location.pathname.includes("/collection_details") ||
            location.pathname.includes("/collection_nft") ||
            location.pathname.includes("/nft")
              ? "home_header"
              : ""
          }
          expand="xl"
        >
          <Container>
            <Navbar.Brand onClick={Homepage}>
              <img
                alt="logo"
                src={require("../assets/images/logo.png")}
                className="me-3"
              ></img>
            </Navbar.Brand>

            <div className="toggle-drop-right">
              {authenticated ? (
                <Nav.Link className="">
                  <Dropdown className="wallet-bt-connect">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {userDetail?.profile ? (
                        <i>
                          <img
                            class="user-profile-pic"
                            src={`${userDetail.profile}`}
                          ></img>
                        </i>
                      ) : (
                        <i
                          class="fa fa-user-circle-o user-icon"
                          aria-hidden="true"
                        ></i>
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={Profile}>Profile</Dropdown.Item>
                      <Dropdown.Item onClick={AddBank}>Billing</Dropdown.Item>
                      
                      {isAuthenticated ? (
                        <>
                          <Dropdown.Item onClick={Nft_single}>
                            Start Selling
                          </Dropdown.Item>
                         
                        </>
                      ) : (
                        <></>
                      )}
                      <Dropdown.Item
                        className="wallet-bt-connect"
                        onClick={handledisconnect}
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Link>
              ) : (
                <>
                  {location.pathname == "/login" ||
                  location.pathname == "/signup" ? null : (
                    <>
                      {/* {address == "" || !authenticated ? ( */}
                      {!isAuthenticated && !authenticated ? (
                        <Nav.Link className="header-login-hide" href="/login">
                          Login
                        </Nav.Link>
                      ) : authenticated ? (
                        <Nav.Link className="" onClick={logout}>
                          Logout
                        </Nav.Link>
                      ) : null}
                    </>
                  )}
                </>
              )}

              <Navbar.Toggle
                aria-controls="navbarScroll"
                onClick={() => setExpanded(expanded ? false : true)}
              />
            </div>
            <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto my-2 my-lg-0" navbarscroll="true">
                <Nav.Link
                  className={location.pathname === "/" ? "active" : "nav-link"}
                  onClick={Homepage}
                >
                  Home
                </Nav.Link>

                <Nav.Link
                  className={location.pathname === "/athletes" ? "active" : "nav-link"}
                  onClick={AthletesPage}
                >
                  Athletes
                </Nav.Link>

                <Nav.Link
                  onClick={Explore}
                  className={
                    location.pathname === "/explore" ? "active" : "nav-link"
                  }
                >
                  Marketplace
                </Nav.Link>
                <Nav.Link
                  onClick={BrandingPartnership}
                  className={
                    location.pathname === "/branding-partnership" ? "active" : "nav-link"
                  }
                >
             Brand Partnerships
                </Nav.Link>
                {/* <Nav.Link className="connect-wallet" onClick={Blockchain}>
                Mint NFT
              </Nav.Link> */}
                <Nav.Link
                  onClick={About}
                  className={
                    location.pathname === "/about" ? "active" : "nav-link"
                  }
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  onClick={Gettouch}
                  className={
                    location.pathname === "/contact" ? "active" : "nav-link"
                  }
                >
                  Contact us
                </Nav.Link>
                {/* <Nav.Link  href= "https://sportsfinda.substack.com/" target="_blank" >
                  Blog
                </Nav.Link> */}
                {/* {localStorage.getItem("WalletConnect") != "true" &&
                authenticated != null ? (
                  <Nav.Link
                    className=""
                    onClick={() => {
                      if (checkWeb3) {
                        loginwithmetamask();
                      }
                    }}
                  >
                    <span className="connect-wallet-bt">Connect Wallet</span>
                  </Nav.Link>
                ) : null} */}
                {localStorage.getItem("auth") ? (
                  <Nav.Link className="">
                    <Dropdown className="wallet-bt-connect">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {userDetail?.profile ? (
                          <i>
                            <img
                              class="user-profile-pic"
                              src={`${userDetail.profile}`}
                            ></img>
                          </i>
                        ) : (
                          <i
                            class="fa fa-user-circle-o user-icon"
                            aria-hidden="true"
                          ></i>
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={Profile}>Profile</Dropdown.Item>
                        <Dropdown.Item onClick={AddBank}>Billing</Dropdown.Item>
                        {localStorage.getItem("auth") ? (
                          <>
                            <Dropdown.Item onClick={Nft_single}>
                              Start Selling
                            </Dropdown.Item>
                           
                          </>
                        ) : (
                          <></>
                        )}
                        <Dropdown.Item
                          className="wallet-bt-connect"
                          onClick={handledisconnect}
                        >
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Link>
                ) : (
                  <>
                    {location.pathname == "/login" ||
                    location.pathname == "/signup" ? null : (
                      <>
                        {/* {address == "" || !authenticated ? ( */}
                        {!localStorage.getItem("auth") ? (
                          <Nav.Link className="" href="/login">
                            Login
                          </Nav.Link>
                        ) : authenticated ? (
                          <Nav.Link className="" onClick={logout}>
                            Logout
                          </Nav.Link>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      {/* connect wallet modal */}
      {/* <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="meta-wallet">
            <div className="metaimgae">
              <img
                src={require("../assets/images/meta.svg").default}
                alt="meta"
              ></img>
            </div>
            <h4>MetaMask</h4>
            <p>Connect your Metamask Wallet</p>
          </div>
          <div className="meta-wallet">
            <div className="metaimgae">
              <img
                src={require("../assets/images/trust.webp")}
                alt="trust"
              ></img>
            </div>
            <h4>Trust Wallet</h4>
            <p>Connect your TrustWallet</p>
          </div>
          <div className="meta-wallet">
            <div className="metaimgae">
              <img
                src={require("../assets/images/wallet.svg").default}
                alt="wallet"
              ></img>
            </div>
            <h4>Wallet Connect</h4>
            <p>Connect your Wallet Connect</p>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* <Modal
        show={showbuy1}
        onHide={handleClosebuy1}
        animation={false}
        backdrop="static"
        centered
        className="text-center meta-mask-modal bhhhhhhhhh"
      >
        <Modal.Body className="modal-email">
          <span className="modalclose_icon" onClick={handleClosebuy1}>
            <i className="fa fa-close"></i>
          </span>
          <h3>Sportsfinda </h3>
          <div id="iframe-main" style={{ display: "block" }}>
            <iframe
              id="myIframe"
              src={addFundsUrl}
              width="100%"
              height="650"
              style={{ display: "block" }}
            ></iframe>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};
export default Header;
