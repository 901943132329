import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { userServices } from "../services/user.Services";
import LoadingSpinerComponent from "./Loader";

function Reset() {
  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [isLoading, setisLoading] = useState(false);

  let { token } = useParams();

  const [errors, setErrors] = useState({
    newpassword: "", confirmpassword: "",
  });

  const handleNewPassword = (e) => {
    e.preventDefault();
    let status = true;
    let error = { ...errors }

    if (!newpassword) {
      error.newpassword = "Please enter new password.";
      status = false;
    } else if (newpassword && newpassword.length < 8) {
      error.newpassword = "Password must be at least 8 characters.";
      status = false;
    }


    if (!confirmpassword) {
      error.confirmpassword = "Please enter new password.";
      status = false;
    } else if (confirmpassword && confirmpassword.length < 8) {
      error.confirmpassword = "Password must be at least 8 characters.";
      status = false;
    } else if (newpassword != confirmpassword) {
      error.confirmpassword = "new password and confirm password must match";
      status = false;
    }
    setErrors(error);
    if (status) {
      ResetpasswordApi();
    }
  };

  function ResetpasswordApi() {
    const params = {
      newPassword: newpassword,
      token: token,
    };
    setisLoading(true);
    userServices
      .ResetpasswordApi(params)
      .then((res) => {
        if (res.status === 200) {
          setisLoading(false);
          swal("Success", "Password reset successfully", "success");
          window.location.href = "/user_login";
        }
      })
      .catch((error) => {
        setisLoading(false);
        swal("Error", "Reset Password token has expired", "error");
      });
  }
  return (
    <>
      <LoadingSpinerComponent promiseInProgress={isLoading} message={""} />
      <section className="about-page">
        <Container>
          <Row className="justify-content-center signin-form">
            <Col md={5}>
              <h3 className="text-center">Reset Password</h3>
              <form className="login-form-box" onSubmit={handleNewPassword}>
                <div className="col-12 mb-3">
                  <label className="form-label">Enter New password</label>
                  <input
                    type="password"
                    placeholder="Enter new password"
                    required=""
                    name="changepassword"
                    autoComplete="off"
                    className="form-control-lg w-100"
                    onKeyDown={(e) =>{
                      if(!e.target.value && e.code === "Space"){
                      e.preventDefault();
                    }
                  }}
                    onChange={(e) => setNewpassword(e.target.value)}
                  />
                  {(((!newpassword) || (newpassword && newpassword.length < 8)) && errors?.newpassword) ? <span className="errors">{errors.newpassword}</span> : <></>}
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label">Enter confirm password</label>
                  <input
                    type="Password"
                    placeholder="Enter confirm password"
                    required=""
                    name="confirmpassword"
                    autoComplete="off"
                    className="form-control-lg w-100"
                    onKeyDown={(e) =>{
                      if(!e.target.value && e.code === "Space"){
                      e.preventDefault();
                    }
                  }}
                    onChange={(e) => setConfirmpassword(e.target.value)}
                  />
                  {(((!confirmpassword) || (confirmpassword && confirmpassword.length < 8) || (newpassword != confirmpassword)) && errors?.confirmpassword) ? <span className="errors">{errors.confirmpassword}</span> : <></>}
                </div>
                <button type="submit" className="submit-bt">
                  Submit
                </button>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default Reset;
