import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { userServices } from "../services/user.Services";
import LoadingSpinerComponent from "./Loader";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [getInTouchName, setGetInTouchName] = useState("");
  const [getInTouchEmail, setGetInTouchEmail] = useState("");
  const [getInTouchPhone, setGetInTouchPhone] = useState("");
  const [getInTouchLastName, setGetInTouchLastName] = useState("");
  const [getInTouchMessage, setGetInTouchMessage] = useState("");
  const [submitButtonDisabled, setsubmitButtonDisabled] = useState(false);
  const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i);
  const [errors, setErrors] = useState({
    getInTouchName: "", getInTouchEmail: "", getInTouchPhone: "", getInTouchLastName: "", getInTouchMessage: ""
  });

  function validation() {
    let status = true;
    let error = { ...errors }

    if (!getInTouchName) {
      error.getInTouchName = "Please enter first name.";
      status = false;
    }
    if (!getInTouchLastName) {
      error.getInTouchLastName = "Please enter last name.";
      status = false;
    }
    if (!getInTouchEmail) {
      error.getInTouchEmail = "Please enter email.";
      status = false;
    } else if (!emailRegex.test(getInTouchEmail)) {
      setsubmitButtonDisabled(false);
      error.getInTouchEmail = "Please enter valid email.";
      status = false;
    }
    if (!getInTouchPhone) {
      error.getInTouchPhone = "Please enter phone no.";
      status = false;
    } else if (getInTouchPhone.length < 8) {
      setsubmitButtonDisabled(false);
      error.getInTouchPhone = "Please enter valid phone no.";
      status = false;
    }
    if (!getInTouchMessage) {
      setsubmitButtonDisabled(false);
      error.getInTouchMessage = "Please enter Message.";
      status = false;
    } else if (getInTouchMessage && getInTouchMessage.length > 500) {
      setsubmitButtonDisabled(false);
      error.getInTouchMessage = "Please enter message should be maximum 500 characters";
      status = false;
    }
    setErrors(error);
    return status;
  }

  const submitContactUs = async () => {
    setsubmitButtonDisabled(true);
    if (validation()) {
      setIsLoading(true);
      const formData = {
        first_name: getInTouchName,
        last_name: getInTouchLastName,
        email: getInTouchEmail,
        phone: getInTouchPhone,
        message: getInTouchMessage.trim(),
        page: " Contact Us"
      };
      await userServices
        .contactUs(formData)
        .then((res) => {
          setIsLoading(false);
          swal("Success", res.data.message, "success").then(() => {
            setGetInTouchLastName("");
            setGetInTouchName("");
            setGetInTouchEmail("");
            setGetInTouchPhone("");
            setGetInTouchMessage("");
            setsubmitButtonDisabled(false);
            window.location.href ="/"
          });
        })
        .catch((err) => {
          setIsLoading(false);
          swal("Error", err.response.message, "error");
        });
    } else{
      // swal("Warning", "Please fill all details", "warning");
    }
    // if (!getInTouchName) {
    //   swal("Warning", "Please enter First Name.", "warning");
    //   setIsLoading(false);
    //   setsubmitButtonDisabled(false);
    //   return;
    // }
    // if (!getInTouchEmail) {
    //   swal("Warning", "Please enter email.", "warning");
    //   setIsLoading(false);
    //   setsubmitButtonDisabled(false);
    //   return;
    // } else if (!emailRegex.test(getInTouchEmail)) {
    //   swal("Warning", "Please enter valid email.", "warning");
    //   setIsLoading(false);
    //   setsubmitButtonDisabled(false);
    //   return;
    // }
    // if (!getInTouchPhone) {
    //   swal("Warning", "Please enter Phone no.", "warning");
    //   setIsLoading(false);
    //   setsubmitButtonDisabled(false);
    //   return;
    // } else if (getInTouchPhone.length < 10) {
    //   swal("Warning", "Please enter valid Phone no.", "warning");
    //   setIsLoading(false);
    //   setsubmitButtonDisabled(false);
    //   return;
    // }
    // if (!getInTouchLastName) {
    //   swal("Warning", "Please enter Last name.", "warning");
    //   setIsLoading(false);
    //   setsubmitButtonDisabled(false);
    //   return;
    // }
    // if (!getInTouchMessage.trim()) {
    //   swal("Warning", "Please enter Message.", "warning");
    //   setIsLoading(false);
    //   setsubmitButtonDisabled(false);
    //   return;
    // }

  };


  useEffect(() => {
    document.title = "Contact Us"
    return () => {
      document.title = "Athlete & Artist NFT Marketplace"
    }
  }, [])

  return (
    <>
      <section className="contact-us-page">
        <LoadingSpinerComponent promiseInProgress={isLoading} />
        <Container>
          <div className="touch_section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center">
                    <h2 className="main_h2 underline_text">Contact Us</h2>
                  </div>
                </div>
              </div>
              <div className="row mt-5 pt-3 justify-content-center">
                <div className="col-md-9">
                  <div className="touch_form">
                    <Form>
                      <div className="row">
                        <div className="col-md-6 mb-2 thenae-box">
                          <Form.Control
                            type="Name"
                            name="firstName"
                            maxLength={30}
                            placeholder="First Name"
                            onChange={(e) => {
                              setGetInTouchName(e.target.value.trim());
                            }}
                            onKeyPress={(e) => {
                              if (e.key === " " && e.target.value == 0)
                                e.preventDefault();
                            }}
                            value={getInTouchName}
                          />

                          {(!getInTouchName) && errors?.getInTouchName ? <span className="errors">{errors.getInTouchName}<br /></span> : <></>}

                        </div>
                        <div className="col-md-6 mb-2 thenae-box">
                          <Form.Control
                            type="text"
                            name="lastName"
                            maxLength={30}
                            placeholder="Last Name"
                            onChange={(e) => {
                              setGetInTouchLastName(e.target.value.trim());
                            }}
                            onKeyPress={(e) => {
                              if (e.key === " " && e.target.value == 0)
                                e.preventDefault();
                            }}
                            value={getInTouchLastName}
                          />
                          {(!getInTouchLastName) && errors?.getInTouchLastName ? <span className="errors">{errors.getInTouchLastName}<br /></span> : <></>}

                        </div>
                        <div className="col-md-6 mb-2 thenae-box">
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email"
                            maxLength={100}
                            onChange={(e) => {
                              setGetInTouchEmail(e.target.value.trim());
                            }}
                            onKeyPress={(e) => {
                              if (e.key === " " && e.target.value == 0)
                                e.preventDefault();
                            }}
                            value={getInTouchEmail}
                          />
                          {((!getInTouchEmail) || (!emailRegex.test(getInTouchEmail))) && errors?.getInTouchEmail ? <span className="errors">{errors.getInTouchEmail}<br /></span> : <></>}

                        </div>
                        <div className="col-md-6 mb-2 thenae-box">
                          <Form.Control
                            type="number"
                            name="phone"
                            placeholder="Phone Number"
                            onKeyDown={evt => {
                              if (evt.which != 8 && evt.which != 9 && (evt.which < 48 || evt.which > 57) && (evt.which < 96 || evt.which > 105)) {
                                evt.preventDefault();
                              }
                            }}
                            onKeyPress={(e) => {
                              if (e.key === " " && e.target.value == 0)
                                e.preventDefault();
                            }}
                            onChange={(e) => {
                              if (e.target.value.length < 14) {
                                setGetInTouchPhone(e.target.value.trim());
                              }
                            }}
                            value={getInTouchPhone}
                          />
                          {((!getInTouchPhone) || (getInTouchPhone.length < 8)) && errors?.getInTouchPhone ? <span className="errors">{errors.getInTouchPhone}<br /></span> : <></>}

                        </div>
                        <div className="col-md-12 mb-2 thenae-box">
                          <Form.Control
                            type="text"
                            name="message"
                            placeholder="Message"
                            maxLength={500}
                            onChange={(e) => {
                              setGetInTouchMessage(e.target.value);
                            }}
                            onKeyPress={(e) => {
                              if (e.key === " " && e.target.value == 0)
                                e.preventDefault();
                            }}
                            value={getInTouchMessage}
                          />

                          {((!getInTouchMessage) || (getInTouchMessage && getInTouchMessage.length > 500)) && errors?.getInTouchMessage ? <span className="errors">{errors.getInTouchMessage}<br /></span> : <></>}

                        </div>
                        <div className="col-md-4 m-auto text-center">
                          <button
                            type="button"
                            disabled={submitButtonDisabled}
                            onClick={() => {
                              submitContactUs();
                            }}
                            className="btn submit-bt px-4 mt-4"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};
export default ContactUs;
