import { CollectionAbi, nftAbi } from "../Pages/Abi";
// export const categories = ["American Football","Athletics", "Boxing", "Kick boxing", "MMA", "Charity", "Esports", "Soccer","Swimming"];
export const categories = [
    "American Football",
    "Athletics",
    "Basketball",
    "Boxing",
    "Charity",
    "Esports",
    "Kick boxing",
    "MMA",
    "Soccer"
  ];
export const chains = ["ETH", "BNB", "MATIC"];
export const currency_symbol = "MATIC";
export const colectionaddress = ""
export const nftaddressETH = ""
export const colectionaddressETH = ""
export const chainidBNB = 97
export const chainidETH = 5
export const platform_fee = 2
export const fbappid = ""
export const ipfsurl = "https://ipfs.io"
export const appName = "SportsFinda"
export const httpprovider = "https://polygon-bor-rpc.publicnode.com"
export const blockchain = "Polygon"
export const winterCheckoutUserAddress = "0x590201D533655400eD1Af9653ad2F4e3bf31F6ec"
export const chain = "MATIC"
export const tokenstandard = "ERC-721"
export const tokenstandard_multi = "ERC-1155"
export const appove_estimate_gasfee = "48739"
export const gasFee = 25000;



export const contracturl = "https://testnet.bscscan.com/address/"
export const contracturlETH = "https://etherscan.io/address/"
export const winter_username = "sk_live_zX9lU9fGlbqihleetBkv=" // winter username
const brandImageUrl = "https://sportsfinda.com/static/media/Sportsfinda_Logo.9475eb8c.png"
const useWinterEmail = "ahmad@sportsfinda.com"
const tokenSymbol = "MATIC"
const projectName = "sportsFinda"
export const PINATA_API_KEY = "3f349595ebfe901bac30";
export  const PINATA_SECRET_API_KEY = "003fef0379584253559559ae65948073e676b2d9f7e4dc91a096b6da8f2e0ddf";

/** ------------------------------------ With Paper -------------------------------- */ 
// export const withPaperSingleContractId = "b1f60a24-f4c6-42be-95e5-cc40c32be23f"
// export const withPaperMultipleContractId ="bc798336-a698-42b9-9829-a7eb6bc9497a";




/** ------------------------------------ TEST SERVER Testnet-------------------------------- */ 
// export const withPaperSingleContractId ="a289439c-24f0-4305-97e3-1e3a3a0ed3ed";
// export const withPaperMultipleContractId ="25ad5f84-76b3-42da-bc15-1d68d0319600"
// export const apiUrl = "https://test-api.sportsfinda.com/v1"; 
// // export const apiUrl = "http://192.168.3.21:9705/v1"; 
// export const appUrl = "https://test.sportsfinda.com";
// export const sportsfindaMetamaskUrl = "https://metamask.app.link/dapp/test.sportsfinda.com/"
// export const nftaddressPOLY = "0xd30016ffd42590A012fD7A29D74512482c57c5Fc"  
// export const colectionaddressPOLY = "0xBe66439AcE35a38D45223c951615B836423dd251" 
// export const contracturlPOLY = "https://mumbai.polygonscan.com/address/"
// export const chainidPOLY = 80001
// export const httpproviderETH = "https://polygon-mumbai.g.alchemy.com/v2/1_5hDrzhQd9Vxz9pIgi5HS9mn_StUusz"
// export const winterCheckoutProduction = true 
// const winterSandbox = true 

// /** ------------------------------------ STAGING SERVER Mainnet-------------------------------- */ 
// export const apiUrl = "https://staging-api.sportsfinda.com/v1"; 
// export const appUrl = "https://staging.sportsfinda.com";
// export const sportsfindaMetamaskUrl = "https://metamask.app.link/dapp/staging.sportsfinda.com/"
// // export const sportsfindaMetamaskUrl = "https://metamask.app.link/dapp/http://192.169.2.95:3001/"
// // :3000/"

// export const nftaddressPOLY = "0x393021E885f60Ae7f66eF4E94e21103a2E31B8c5"  
// export const colectionaddressPOLY = "0x319522914f9319b1FB2e36bcd1EB4433756e6E8c"
// // export const sportsfindaMetamaskUrl = "https://metamask.app.link/dapp/test.sportsfinda.com/"
// // export const nftaddressPOLY = "0xd30016ffd42590A012fD7A29D74512482c57c5Fc"  
// // export const colectionaddressPOLY = "0xBe66439AcE35a38D45223c951615B836423dd251"  
// export const contracturlPOLY = "https://polygonscan.com/address/"
// export const httpproviderETH = "https://polygon-mainnet.g.alchemy.com/v2/MFq9npe7CLnOuFsXCSVyudVCj2QKjUEV"
// export const winterCheckoutProduction = false 
// const winterSandbox = false 
// export const chainidPOLY = 137
// export const withPaperSingleContractId ="45ecc879-a77c-48e3-bce9-a9202166af4a";
// export const withPaperMultipleContractId ="3dd34ec8-6350-4fec-9f0c-15851ea9db23";
/** ------------------------------------ MAIN SERVER Mainnet-------------------------------- */ 
// export const apiUrl = "https://api.sportsfinda.com/v1";
// export const appUrl = "https://www.sportsfinda.com";
 export const apiUrl = "https://staging-api.sportsfinda.com/v1";
 export const appUrl = "https://staging.sportsfinda.com";
export const sportsfindaMetamaskUrl = "https://metamask.app.link/dapp/sportsfinda.com/"
export const nftaddressPOLY = "0x18Be9B68da6D0EBf85048FE70754aCAD65eE5e8c"
export const colectionaddressPOLY = "0x3717242CBF52e941D9Cf8a8a5b1C6341dfE747E9"
export const contracturlPOLY = "https://polygonscan.com/address/"
export const httpproviderETH = "https://polygon-mainnet.g.alchemy.com/v2/MFq9npe7CLnOuFsXCSVyudVCj2QKjUEV";
export const winterCheckoutProduction = false
const winterSandbox = false;
export const chainidPOLY = 137;
export const withPaperSingleContractId ="8137abe8-0376-4dbb-8d66-5167867ab235";
export const withPaperMultipleContractId ="6998502d-86bd-48dc-add6-bd7947cb6814";


//live 
// export const chain = "Ethereum Mainnet" //live
// export const chainid = 1 //live
// export const apiUrl = "https://api.sportsfinda.com/v1"; //live
// export const appUrl = "https://sportsfinda.com"; //live
// export const nftaddressETH = "0x6A7Ce1AF584E04758D71cEe0d595DE150BF8E27D" // Mainet
// export const colectionaddressETH = "0xcF20Dc23594374dac2D22BEbF0468DDd47011f84" //Mainet
// export const httpproviderETH = "https://eth-mainnet.g.alchemy.com/v2/cpeSA8vNf1jBh8vgjcf7R6KTjNUjFQUO" // Mainnet
// export const chainidETH = 1
// export const winterCheckoutProduction = false // live
// const winterSandbox = false // live
// const brandImageUrl = "https://sportsfinda.com/static/media/Sportsfinda_Logo.9475eb8c.png"
// const useWinterEmail = "ahmad@sportsfinda.com"
// const tokenSymbol = "ETH"
// const projectName = "sportsFinda"
// export const winter_username = "sk_live_zX9lU9fGlbqihleetBkv=" // winter username


// usewinter variables
const mintFunctionType = "buy"
const maxNumberOfMints = 1
const isActive = true
const requireAddress = false

// usewinter multiple mint params 

export const winter_multiple_email = useWinterEmail
export const winter_multiple_tokenSymbol = tokenSymbol
export const winter_multiple_projectName = projectName
export const winter_multiple_brandImageURL = brandImageUrl
export const winter_multiple_sandbox = winterSandbox
export const winter_multiple_mintFunction = mintFunctionType
export const winter_multiple_maxNumberOfMints = maxNumberOfMints
export const winter_multiple_isActive = isActive
export const winter_multiple_requireAddress = requireAddress

export const winter_multiple_contractAddress = colectionaddressETH
export const winter_multiple_contractABI = JSON.stringify(CollectionAbi)
export const winter_multiple_mintFunctionParams = "contractAddress,itemSplitId,userId,amount,address"
export const winter_multiple_ercType = "1155"

// usewinter single mint params

export const winter_single_email = useWinterEmail
export const winter_single_tokenSymbol = tokenSymbol
export const winter_single_projectName = projectName
export const winter_single_brandImageURL = brandImageUrl
export const winter_single_sandbox = winterSandbox
export const winter_single_mintFunction = mintFunctionType
export const winter_single_maxNumberOfMints = maxNumberOfMints
export const winter_single_isActive = isActive
export const winter_single_requireAddress = requireAddress

export const winter_single_contractAddress = nftaddressETH
export const winter_single_mintFunctionParams = "contractaddress,userId,nftId,address"
export const winter_single_contractABI = JSON.stringify(nftAbi)
export const winter_single_contractPriceFunctionParams = "contractaddress,nftId,userAddress"
export const winter_single_ercType = "721"