import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import Web3 from "web3";
import { colectionaddressETH, colectionaddressPOLY, gasFee, httpproviderETH, nftaddressETH, nftaddressPOLY, PINATA_API_KEY, PINATA_SECRET_API_KEY } from "../../config/config";
import { nftAbi,CollectionAbi } from "../../Pages/Abi";

export const GetVideoThumbnails = async (props) => {
  let imageUrl;
  let videoFile = props.file;
  const pinataApiKey = 'yourPinataApiKey';
  const pinataSecretApiKey = 'yourPinataSecretApiKey';

  if (videoFile) {
      await generateVideoThumbnails(videoFile, 1).then(async (thumbs) => {
          let thumb;
          if (Array.isArray(thumbs)) {
              thumb = DataURLtoFile(thumbs[0], "thumbnails.jpeg");
          } else {
              thumb = DataURLtoFile(thumbs, "thumbnails.jpeg");
          }

          // Prepare the file for upload to Pinata
          const thumbnailImageType = thumb.type.split("/");
          const thumbnailImageName = Math.floor(Date.now() / 1000) + "." + thumbnailImageType[1];

          const formData = new FormData();
          formData.append('file', thumb, thumbnailImageName);

          try {
              // Upload to Pinata
              const response = await fetch('https://api.pinata.cloud/pinning/pinFileToIPFS', {
                  method: 'POST',
                  headers: {
                      'pinata_api_key': PINATA_API_KEY,
                      'pinata_secret_api_key': PINATA_SECRET_API_KEY,
                  },
                  body: formData,
              });

              const result = await response.json();
              if (result.IpfsHash) {
                  imageUrl = `https://gateway.pinata.cloud/ipfs/${result.IpfsHash}`;
              } else {
                  console.error("Error: ", result);
              }
          } catch (err) {
              console.error("Error uploading file to Pinata:", err);
          }
      }).catch(err => {
          console.error("Error generating video thumbnails:", err);
      });
  }

  return imageUrl;
};

export const DataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}


// export const WEIToETH = (props) => {
//     if (props.value) {
//         // console.log("price wei---->",props.value);
//         // let web3 = new Web3(window.ethereum);
//         // console.log("buyFeturetest value before",props.value );
//         let value = web3.utils.fromWei((props.value).toString(), 'ether')
//         // console.log("buyFeturetest value",value );
//     //    console.log("ethPri---->",ethPri);
//         // let value = (Number(props.value) / Math.pow(10, 18));
//         // value = value.toFixed(18).toString();
//         // function get(str) {
//         //     let len = str.length - 1
//         //     let at = str.at(len)
//         //     while (at == "0") {
//         //         len = len - 1
//         //         at = str.at(len)
//         //     }
//         //     return str.slice(0, len + 1);
//         // }
//         // value = get(value);
//         // if (value.at(value.length - 1) == ".") {
//         //     value = value.slice(0, value.length - 1)
//         // }
//         // console.log("price ETH---->",value);
//         return value;
//     } else {
//         return "";
//     }
// }


// export const handleScrollToTop = () =>{
//     window.scrollTo({top : 0,behavior:'smooth'})
//   }

  // export const getAccounts = async () => {
  //   let web3 = new Web3(window.ethereum);
  //   let account
  //   try {
  //     account = await web3.eth.getAccounts().then((res) => {
  //       return res;
  //     });
  //   } catch (error) {
  //     try {
  //       let provider = web3.eth.setProvider(httpproviderETH);
  //       account = await web3.eth.getAccounts().then((res) => {
  //         return res;
  //       });
  //     } catch (error) {

  //     }
  //   }
  //   return account;
  // }
  // export const getChain = async () => {
  //   let web3 = new Web3(window.ethereum);
  //   let chain
  //   try {
  //     chain = await web3.eth.getChainId().then((res) => {
  //       return res;
  //     });
  //   } catch (error) {
  //     try {
  //       let provider = web3.eth.setProvider(httpproviderETH);
  //       chain = await web3.eth.getChainId().then((res) => {
  //         return res;
  //       });
  //     } catch (error) {

  //     }
  //   }
  //   return chain;
  // }

  export const getEstimateGasFee = async (props) => {
    console.log("getEstimateGasFee props_______",props)
    let web3 = new Web3(window.ethereum);
    const params=props.params;
    const methodName=props.methodName;
    const sendParams=props.sendParams;
    let chain=props.chain;
    const nftType=props.nftType;
    let provider = web3.eth.setProvider(httpproviderETH);
    let gasPriceGet = await web3.eth.getGasPrice();

    let balance = await web3.eth.getBalance(sendParams.from)
    .then((res) => {
      return res;
    });


   let contractMethods
    if (chain == "ETH") {
      if(nftType=="S"){
        contractMethods = await new web3.eth.Contract(nftAbi,nftaddressETH);
      }
      else if(nftType=="M"){
        contractMethods = await new web3.eth.Contract(CollectionAbi,colectionaddressETH);
      }
    }
    if (chain == "MATIC") {
      if(nftType=="S"){
        contractMethods = await new web3.eth.Contract(nftAbi,nftaddressPOLY);

      }
      else if(nftType=="M"){
        contractMethods = await new web3.eth.Contract(CollectionAbi,colectionaddressPOLY);
      }
    }
    let gasLimit
    try {
      gasLimit = balance > (sendParams.value ? (Number(sendParams.value) + Number(gasFee * (gasPriceGet))) : (gasFee * (gasPriceGet)))  ? await contractMethods.methods[methodName](...params).estimateGas(sendParams).then((res) =>{
        return res;
      }) : gasFee;
    } catch (error) {
      gasLimit =gasFee;
    }
    return [(gasPriceGet * 2),(gasLimit * 2)];
  }