import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Row, Modal, Form, OverlayTrigger, Tooltip } from "react-bootstrap-v5";
import { useNavigate, useParams, useLocation } from "react-router";
import { TabList, TabPanel, Tab, Tabs } from "react-tabs";
import { fbappid, PINATA_API_KEY, PINATA_SECRET_API_KEY } from "../config/config";
// import LoadingSpinerComponent from "./Loader";
import { nftService } from "../_services";
import { useMoralis } from "react-moralis";
import ReactPaginate from "react-paginate";
import { Image, Shimmer } from "react-shimmer";
import AliceCarousel from "react-alice-carousel";
import copy from "copy-to-clipboard"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import Swal from "sweetalert2";
import {
  EmailIcon,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { appUrl, appName } from "../config/config";
import Pagination from "react-js-pagination";
import Countdown from "react-countdown";
// import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { WEIToETH } from "../Component/common/CommonFunction";
import { userServices } from "../services/user.Services";
import swal from "sweetalert";
import axios from "axios";
import { sl } from "date-fns/locale";
// import { Skeleton, Tooltip } from "@mui/material";

const Seller_profile1 = () => {
  const location = useLocation();
  let history = useNavigate()
  const { web3, enableWeb3, user, Moralis, isAuthenticated } = useMoralis();

  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setemail] = useState("");
  const [emailshow, setemailshow] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  // let location = window.location.pathname;


  const [selectedTab, setselectedTab] = useState("N");
  const [share_url, setshare_url] = useState(null);
  const [ERR, setERR] = useState("");
  const [description, setDescription] = useState("");
  const [ERR1, setERR1] = useState("");
  const [profileFileshow, setprofileFileshow] = useState();
  const [showloader, setshowloader] = useState(false);
  const [mysocial_links, setmysocial_links] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoaderTab, setIsLoaderTab] = useState(false);
  const [coverFileshow, setcoverFileshow] = useState();
  const [userAddress, setuserAddress] = useState();
  const [usercurrentAddress, setusercurrentAddress] = useState();
  const [totalpage, settotalpage] = useState();
  const [totalpageC, settotalpageC] = useState(1);
  const [totalPageCountDraft, settotalPageCountDraft] = useState(1)
  const [totalpageP, settotalpageP] = useState();
  const [showshare, setShowshare] = useState(false);
  const [copy, setCopy] = useState(false);
  const handleCloseshare = () => setShowshare(false);
  const [profileFile, setprofileFile] = useState();
  let title = `Hey! Checkout my profile  on ` + appName;
  const { userSlug } = useParams();
  const [CollectionList, setCollectionList] = useState([]);
  const [usdprice, setusdprice] = useState();

  const [myitems, setitems] = useState([]);
  const [userDetail, setUserDetail] = useState();

  const [page, setpage] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    console.log("Email____", userDetail);
    setFName(userDetail?.firstname);
    setLName(userDetail?.lastname);
    setemail(userDetail?.email);
    setUpdatedisbled(true);
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    setCopy(false);
  };
  const [currentUserId, setCurrentUserId] = useState();
  const [updatedisbled, setUpdatedisbled] = useState(true);
  let TabIndex = useRef(location?.state?.fromCreateCollection ? 1 : 0);
  let navigate = useNavigate();

  function readURL(input) {
    setUpdatedisbled(false);
    if (input.files && input.files[0]) {
      var filetype = checkfile(input.files[0]);
      if (filetype === true) {
        setprofileFile(input.files[0]);
        //    saveprofileimage(input.files[0])
        var reader = new FileReader();
        reader.onload = function (e) {
          setprofileFileshow(e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
  }

  useEffect(() => {
    localStorage.removeItem("draftID")
    localStorage.removeItem("draftIDM")
  }, [])
  useEffect(() => {
    setIsLoader(true);
    setIsLoaderTab(true);
    const windowUrl = window.location.search;
    console.log("windowUrl", windowUrl)
    const params = new URLSearchParams(windowUrl);
    console.log("params______", params);
    if (params.has("selected_tab")) {
      TabIndex.current = 2;
    }
    if (user && user.get("social_links")) {
      setmysocial_links(JSON.parse(user.get("social_links")));
    }
    init();
    getprice();
  }, [user, isAuthenticated, location.pathname]);

  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Expired</span>;
    } else {
      // Render a countdown
      return (
        <span>
          {days + "d"} {hours + "h"}:{minutes + "m"}:{seconds + "s"}
        </span>
      );
    }
  };
  const handlePageClick = (event) => {
    setpage(event.selected);
    if (selectedTab == "N") {
      Mynft(event.selected, currentUserId);
    }
    if (selectedTab == "C") {
      MyCollection(event.selected, currentUserId);
    }
  };
  const init = async () => {
    if (userSlug) {
      getUserDetailBySlug(userSlug);
    } else {
      GetUserDetailById(localStorage.getItem("currentUserId"));
      MyProfile();
      if (user) {
        let currentuser = user.get("ethAddress");
        setuserAddress(user.get("ethAddress"));
        setusercurrentAddress(user.get("ethAddress"));
      }
    }
  };
  let slugDetails = useRef()

  const getUserDetailBySlug = async (slug) => {
    await nftService
      .getUserDetailBySlug(slug)
      .then((response) => {
        console.log("res_______________", response?.data?.data[0]?.id)
        let data = response?.data?.data[0];
        slugDetails.current = data;
        if (response.data?.data[0]?.role == "ADMIN") {
          navigate("/");
        } else {
          let userId = response.data?.data[0]?.id;

          if (localStorage.getItem("currentUserId") == userId) {
            window.location.href = "/profile";
          } else if (userId) {
            GetUserDetailById(userId);
          }
        }
      })
      .catch((err) => { });
  };

  const Mynft = async (page, userId) => {
    setselectedTab("N");
    await nftService
      .Mynft(page - 1, userId)
      .then((response) => {
        settotalpage(response.data.total_records);
        // total_records
        setitems(response.data.data);
        setshowloader(false);
        setIsLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
        setshowloader(false);
        if (err.response.data.message == "Invalid User") {
          navigate("/login");
        }
      });
  };

  const [showloader3, setshowloader3] = useState(false);


  const MyCollection = async (page, userId) => {
    setselectedTab("C");
    setshowloader3(true);
    await nftService
      .myCollections(page - 1, userId)
      .then((response) => {
        setCollectionList(response.data);
        setIsLoaderTab(false);
        setshowloader3(false);
      })
      .catch((err) => {
        setIsLoaderTab(false);
        setshowloader3(false);
      });
  };

  const PurchasedItem = async (params, page) => {
    setselectedTab("P");
    await nftService
      .PurchasedItem(params, page)
      .then((response) => {
        settotalpageP(response.data.total_records);
        setIsLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
      });
  };
  const [guestUser, setguestUser] = useState("");

  const GetUserDetailById = async (userId) => {
    setshare_url(appUrl + "/profile/" + userId);
    setCurrentUserId(userId);
    Mynft(page, userId);
    MyCollection(totalpageC, userId);
    await nftService
      .getuser(userId)
      .then((response) => {
        setuserAddress(response.data.data.ethAddress);
        setFName(response.data.data.firstname);
        setLName(response.data.data.lastname);
        setemailshow(response.data.data);
        setDescription(response.data.data.bio);
        setprofileFile(response.data.data.profile);
        setprofileFileshow(response.data.data.profile);
        if (userSlug) {
          setguestUser(response.data.data);
          setUserDetail(response.data.data);
        }
        if (response.data.data.coevr) {
          setcoverFileshow(response.data.data.coevr);
        } else {
          setcoverFileshow("");
        }
      })
      .catch((err) => {
      });
  };

  const MyProfile = async () => {
    await nftService
      .getMyProfile()
      .then((response) => {
        setuserAddress(response.data.data.ethAddress);
        setFName(response.data.data.firstname);
        setLName(response.data.data.lastname);
        setUserDetail(response.data.data);
        if (response.data.data.coevr) {
          setcoverFileshow(response.data.data.coevr);
        } else {
          setcoverFileshow("");
        }
      })
      .catch((err) => {

      });
  };


  function readURLcover(input) {
    if (input.files && input.files[0]) {
      var filetype = checkfile(input.files[0]);
      if (filetype === true) {
        savecoverimage(input.files[0]);
        var reader = new FileReader();
        reader.onload = function (e) {
          setcoverFileshow(e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
  }
  function checkfile(file_img) {
    var get_ext = file_img.name.split(".");

    var exts = ["png", "jpg", "jpeg", "gif"];
    get_ext = get_ext.reverse();

    var a = exts.indexOf(get_ext[0].toLowerCase());

    if (a > -1) {
      return true;
    } else {
      alert("Please select a valid image file");
      return false;
    }
  }


  const savecoverimage = async (file) => {
    const data = file;

    // Prepare form data for Pinata
    const formData = new FormData();
    formData.append("file", data, data.name);

    // Optional: Add metadata or other options
    const metadata = JSON.stringify({
      name: data.name,
    });
    formData.append("pinataMetadata", metadata);

    try {
      // Pinata API call to upload file to IPFS
      const response = await axios.post(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            pinata_api_key: PINATA_API_KEY,
            pinata_secret_api_key: PINATA_SECRET_API_KEY,
          },
        }
      );

      // Get the IPFS URL from the response
      const nftFileMetadataFilePath = `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;

      // Update user cover field with IPFS URL
      user.set("cover", nftFileMetadataFilePath);

      let params = {
        cover: nftFileMetadataFilePath,  // Fix typo from "coevr" to "cover"
        ethAddress: userAddress,
      };

      // Call the function to update the user profile
      updateUserProfile(params);

      // Save user object (assuming you still need to save user data locally)
      await user.save()
        .then((response) => {
          setcoverFileshow(nftFileMetadataFilePath);  // Update cover file shown
        })
        .catch((err) => {
          console.error("Error saving user:", err);
        });

    } catch (error) {
      console.error("Error uploading to Pinata:", error);
    }
  };
  const updateUserProfile = async (params) => {
    await nftService
      .updateUserProfile(params)
      .then((response) => { })
      .catch((err) => {
      });
  };
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  function validation() {
    let status = true;
    let error = { ...errors };

    if (!fName) {
      error.firstName = "Please enter first name.";
      status = false;
    }
    if (!lName) {
      error.lastName = "Please enter last name.";
      status = false;
    }
    // }
    setErrors(error);
    return status;
  }

  const updateUser = async () => {
    if (validation()) {
      setshowloader(true);
      const formData = new FormData();
      formData.append("firstname", fName);
      formData.append("lastname", lName);
      formData.append("email", userDetail.email);
      formData.append("profile", profileFile);
      await nftService
        .updateUser(formData)
        .then((response) => {
          window.location.reload();
          setshowloader(false);
          init();
          setShow(false);
        })
        .catch((err) => {
          setshowloader(false);
          console.error("Error signing in.", err.toString());
        });
    }
  };

  function Nft_single() {
    history("/trading_cards");
  }

  // delete Draft
  function DeleteDraft(id) {
    userServices.deleteDraft(id).then((res) => {
      Swal.fire({
        title: "Success",
        text: res?.data?.message,
        imageUrl: require("../assets/images/thumbs-up.png"),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: "Custom image",
        animation: false,
        showCancelButton: false,
        confirmButtonText: "Ok",
        // cancelButtonText: "Resend link",
      }).then((e) => {
        if (e.isConfirmed) {
          window.location.reload()
        }
      });
    })
      .catch((error) => {
        console.log("error", error)
      })
  }

  const [draftuserList, setDraftuserList] = useState([])
  const [totalpageDraft, settotalpageDraft] = useState();
  const [showloader1, setshowloader1] = useState(false);

  function draftList(page) {
    setshowloader1(true);
    userServices.draftList(page - 1).then((res) => {
      let data = res?.data?.data;
      settotalpageDraft(res?.data?.total_records);
      setDraftuserList(data)
      setIsLoader(false);
      setshowloader(false)
      setshowloader1(false);
    })
      .catch((error) => {
        console.log("error", error)
        setIsLoader(false);
        setshowloader1(false);
        setshowloader(false)
      })
  }

  function handlePageChange(pageNumber, tabType) {
    setshowloader(true);
    if (tabType == "NFT") {
      setpage(pageNumber);
      Mynft(pageNumber, localStorage.getItem("currentUserId"));
    }
    if (tabType == "CNFT") {
      settotalpageC(pageNumber);
      MyCollection(pageNumber, localStorage.getItem("currentUserId"));
    }

    if (tabType == "Draft") {
      settotalPageCountDraft(pageNumber)
      draftList(pageNumber)
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }

  function showCollectionDetails(slug, id) {
    history("/collection_trading_cards/" + slug + "/" + id)
  }

  function showSingleDetails(nft_slug) {
    history("/trading_cards/" + nft_slug)
  }
  function showMultipleDetails(nft_slug) {
    history("/collection_details/" + nft_slug)
  }

  const getprice = async () => {
    await nftService
      .get_price()
      .then((response) => {
        setusdprice(response.data.USD);
      })
      .catch((err) => {
      });
  };

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const staticitems = [
    <Container>
      <Row>
        <Col md={3}>
          <div className="card">
            <div className="shimmerBG media"></div>
            <div className="p-32">
              <div className="shimmerBG title-line"></div>
              <div className="shimmerBG title-line end"></div>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div className="card">
            <div className="shimmerBG media"></div>
            <div className="p-32line">
              <div className="shimmerBG title-line"></div>
              <div className="shimmerBG title-line end"></div>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="card">
            <div className="shimmerBG media"></div>
            <div className="p-32">
              <div className="shimmerBG title-line"></div>
              <div className="shimmerBG title-line end"></div>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="card">
            <div className="shimmerBG media"></div>
            <div className="p-32">
              <div className="shimmerBG title-line"></div>
              <div className="shimmerBG title-line end"></div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>,
  ];

  function Nft_single() {
    history("/trading_cards");
  }

  const handleFollowToggle = async () => {
    try {
      // if (userDetail?.isFollowing) {
      const params = {
        userId: localStorage.getItem("currentUserId"),
        follower_id: userDetail?.id
      };
      // console.log("params", params);
      await userServices.followUnfollow(params).then((response) => {
        if (response.status === 200) {
          console.log("response", response);
          Swal.fire("Success", response?.data?.message, "success").then(() => {
            window.location.reload();
          })
          getUserDetailBySlug(userSlug);
        }
      });
      // } 
    } catch (error) {
      console.error("Error toggling follow status:", error);
    }
  };

  const handleFollowToggleWithConfirmation = () => {
    if (userDetail?.isFollow) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't get email notifications for user’s trading card posts.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, unfollow!',
        cancelButtonText: 'No, keep following',
      }).then((result) => {
        if (result.isConfirmed) {
          handleFollowToggle(); // Call the original unfollow function
        }
      });
    } else {
      handleFollowToggle(); // If it's a follow action, proceed without confirmation
    }
  };



  return (
    <>
      <section className="seller-info">

        <section className="profile-box under-profile-box">
          <div className="profile-left-side">
            {localStorage.getItem("currentUserId") == currentUserId && (
              <i
                class="fa fa-pencil edint-pencil"
                aria-hidden="true"
                onClick={handleShow}
              ></i>
            )}
            <div className="profile-image">
              {profileFileshow ? (
                <img src={profileFileshow}></img>
              ) : (
                <img
                  src={require("../assets/images/profile-user.png")}
                ></img>
              )}
            </div>
            <div className="profile-content mt-5">
              <div className="d-flex align-items-center justify-content-between mobile-view">
                <h5>
                  <i class="fa fa-user me-1" aria-hidden="true"></i>{" "}
                  {userDetail &&
                    userDetail?.firstname + " " + userDetail?.lastname}
                </h5>
                {Number(localStorage.getItem("currentUserId")) !== userDetail?.id ?
                <>
                {userDetail?.isFollow ==false &&
                  <Button className="follow-btn" 
                  onClick={() => {
                    if (localStorage.getItem("auth")) {
                      handleFollowToggleWithConfirmation();
                    } else {
                      window.location.href = `/login`;
                      localStorage.setItem("requrl", window.location.pathname);
                    }
                  }}
                  ><img
                    src={require("../assets/images/follow.svg").default}
                  ></img> Follow</Button>}
                {userDetail?.isFollow && 
                <Button className="follow-btn unfollow-btn" 
                onClick={() => {
                  if (localStorage.getItem("auth")) {
                    handleFollowToggleWithConfirmation();
                  } else {
                    window.location.href = `/login`;
                    localStorage.setItem("requrl", window.location.pathname);
                  }
                }}
                ><img
                  src={require("../assets/images/unfollow.svg").default}
                ></img> Unfollow</Button>
                }
                </>:""}
              </div>
              {userDetail && userDetail?.email ? (
                <h5>
                  {" "}
                  <i class="fa fa-envelope me-1" aria-hidden="true"></i>{" "}
                  {userDetail?.email ==
                    JSON.parse(localStorage.getItem("user")).email
                    ? userDetail?.email
                    : ""}
                </h5>
              ) : (
                ""
              )}

              <div
                className="logout-flag "
                onClick={() => setShowshare(true)}
                style={{ cursor: "pointer" }}
              ></div>

              {errorMessage}
            </div>
          </div>
          <div className="action-right-btn">
            {localStorage.getItem("currentUserId") == userDetail?.id &&
              <div class="button-borders">
                <button onClick={Nft_single} class="primary-button"> Start Selling
                </button>
              </div>}
            {/* {Number(localStorage.getItem("currentUserId")) !== userDetail?.id && (
    <div className="button-borders">
      <button onClick={handleFollowToggleWithConfirmation} className="primary-button">
        {userDetail?.isFollow ? "Unfollow" : "Follow"}
      </button>
    </div>
  )} */}



          </div>
        </section>
        <Container>
          <div className="profile-right-side">
            <div className="my_listing-area listingsection">
              <Tabs
                defaultIndex={TabIndex.current}
                // defaultActiveKey={NftType}
                className="collection-nft-tab"
              >
                <div className="nft-collection-box">
                  <TabList className="profile-nft">
                    <Tab onClick={() => { localStorage.setItem("nftType", "NFT"); Mynft(1, currentUserId) }}>
                      {localStorage.getItem("currentUserId") == currentUserId
                        ? "My Digital Trading Cards"
                        : "Digital Trading Cards"}
                    </Tab>
                    <Tab onClick={() => { localStorage.setItem("nftType", "Collection"); MyCollection(1, currentUserId) }}>
                      {localStorage.getItem("currentUserId") == currentUserId
                        ? "My Collections"
                        : "Collections"}
                    </Tab>
                    {localStorage.getItem("currentUserId") == currentUserId &&
                      <Tab onClick={() => { localStorage.setItem("nftType", "draft"); draftList(1) }}>
                        {localStorage.getItem("currentUserId") == currentUserId
                          ? "My Drafts"
                          : "Drafts"}
                      </Tab>
                    }
                  </TabList>
                </div>
                <TabPanel>
                  {isLoader ? (
                    <div className="loader-home">
                      <Row>
                        <Col md={3}>
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}
                  {!isLoader ? (
                    <section
                      className="new-item-box"
                      style={{ position: "relative" }}
                    >

                      <Row>
                        {
                          myitems.length ? (
                            myitems.map((item, index) => {
                              return (
                                <Col lg={3} md={6} sm={6} key={index}>
                                  <div
                                    className={`slide-2 mb-4 list-market-new ${item.nft_enabled == "NO"
                                      ? ""
                                      : "cursor-pointer"
                                      }`}
                                  >
                                    <div className="new-tems-box" onClick={() => {
                                      if (item.nft_enabled == "NO") {
                                      } else {
                                        if (item.nft_type == "SINGLE") {
                                          showSingleDetails(
                                            item.nft_slug
                                          );
                                        } else {
                                          showMultipleDetails(
                                            item.nft_slug
                                          );
                                        }
                                      }
                                    }}>
                                      <div
                                        className={`user-timer-box`}
                                        onClick={() => {
                                          if (item.nft_enabled == "NO") {
                                          } else {
                                            if (item.nft_type == "SINGLE") {
                                              showSingleDetails(
                                                item.nft_slug
                                              );
                                            } else {
                                              showMultipleDetails(
                                                item.nft_slug
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        <div className="overlay-upper" style={{ backgroundImage: `url(${item.nft_image} )` }} ></div>
                                        <div className="user-img">
                                          {
                                            item?.nft_media_type == "video" ? (
                                              <video
                                                width="100%"
                                                height="100%"
                                                autoPlay
                                                muted
                                                loop
                                                controls={false}
                                              >
                                                <source
                                                  src={item.nft_image}
                                                  type="video/mp4"
                                                />
                                                <source
                                                  src={item.nft_image}
                                                  type="video/ogg"
                                                />
                                              </video>
                                            ) : (
                                              <img className="containe_img" src={item.nft_image}></img>
                                            )
                                          }
                                        </div>
                                        {item.nft_enabled == "NO" ? (
                                          <div className="timer-box">
                                            <span>Disabled by Admin</span>
                                          </div>
                                        ) :
                                          item.nft_is_on_sale == "0" ? (
                                            item?.nft_buy_count > 0 ?
                                              <div className="timer-box">
                                                <span>Sold out</span>
                                              </div>
                                              :
                                              <div className="timer-box">
                                                <span>Not available for sale</span>
                                              </div>
                                          ) :
                                            item?.nft_type == "MULTIPLE" && item?.nft_availabile == "NO" && item?.nft_buy_count == 0 ?
                                              <div className="timer-box">
                                                <span>Not available for sale</span>
                                              </div> :
                                              item?.nft_type == "MULTIPLE" && item?.nft_availabile == "NO" && item?.nft_buy_count > 0 &&
                                              <div className="timer-box">
                                                <span>Sold out</span>
                                              </div>
                                        }

                                      </div>

                                      <div className="btm_details">
                                        <div className="btm_details draft-bottom-area">
                                          <h5 className="echo-two">
                                            {item.nft_name}
                                          </h5>
                                          {item?.nft_price && item?.nft_price > 0 ?
                                            <h6 className="attri-price">
                                              {" $"} {Number(item?.nft_price)?.toFixed(2)}{" "}
                                            </h6> : ""}

                                          <h6 className="items-price">
                                            {item.nft_category}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              );
                            })
                          ) : (
                            <div className="no-data text-center">
                              <img
                                src={
                                  require("../assets/images/empty.png")
                                }
                                alt="img"
                                width="250"
                              />
                              <p>No Data Found</p>
                            </div>

                          )}
                      </Row>
                      {totalpage > 12 && (
                        <div className="pagination1">
                          <Pagination
                            activePage={page}
                            itemsCountPerPage={12}
                            totalItemsCount={totalpage}
                            pageRangeDisplayed={5}
                            onChange={(e) => handlePageChange(e, "NFT")}
                            hideDisabled={true}
                            prevPageText={"Previous"}
                            nextPageText={"Next"}
                          />
                        </div>
                      )}
                    </section>
                  ) : null}
                  {/* new items section end */}
                </TabPanel>
                <TabPanel>
                  {isLoaderTab ? (
                    <div className="loader-home">
                      <div className="load-absolute">
                        {/* <Loader
                          type="Bars"
                          color="#0c5af2"
                          height="100"
                          width="100"
                        /> */}
                        <p className="loading color-black">Loading..</p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {!isLoaderTab ? (
                    <section className="new-item-box">
                      <Row>
                        {CollectionList?.data?.length > 0 ? (
                          CollectionList?.data?.map((item, index) => (
                            <Col xxl={3} xl={6} md={6}>
                              <div className="slide-2 mb-4 list-market-new cursor-pointer">
                                <div className="new-tems-box" onClick={() =>
                                  showCollectionDetails(
                                    item?.collection_slug,
                                    item?.id
                                  )
                                }>
                                  <div
                                    className="items-image-box-two"
                                    onClick={() =>
                                      showCollectionDetails(
                                        item?.collection_slug,
                                        item?.id
                                      )
                                    }
                                  >
                                    <Image
                                      src={item.collectionImage}
                                      alt="nft images"
                                      fallback={
                                        <Shimmer width="100" height="100%" />
                                      }
                                    />
                                  </div>
                                  <div className="btm_details draft-bottom-area">
                                    <div className="items-name">
                                      <h5>{item.collection_name}</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ))
                        ) : !showloader3 ? (
                          <div className="no-data text-center">
                            <img
                              src={
                                require("../assets/images/empty.png")
                              }
                              alt="img"
                              width="250"
                            />
                            <p>No Data Found</p>
                          </div>
                        )
                          :
                          <>
                            <Row>
                              <Col md={3} className="mb-3">
                                <div className="card">
                                  <div className="shimmerBG media"></div>
                                  <div className="p-32">
                                    <div className="shimmerBG title-line"></div>
                                    <div className="shimmerBG title-line end"></div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={3} className="mb-3">
                                <div className="card">
                                  <div className="shimmerBG media"></div>
                                  <div className="p-32">
                                    <div className="shimmerBG title-line"></div>
                                    <div className="shimmerBG title-line end"></div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={3} className="mb-3">
                                <div className="card">
                                  <div className="shimmerBG media"></div>
                                  <div className="p-32">
                                    <div className="shimmerBG title-line"></div>
                                    <div className="shimmerBG title-line end"></div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={3} className="mb-3">
                                <div className="card">
                                  <div className="shimmerBG media"></div>
                                  <div className="p-32">
                                    <div className="shimmerBG title-line"></div>
                                    <div className="shimmerBG title-line end"></div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </>}
                      </Row>
                      {CollectionList?.total_records > 12 && (
                        <div className="pagination1">
                          <Pagination
                            activePage={totalpageC}
                            itemsCountPerPage={12}
                            totalItemsCount={CollectionList?.total_records}
                            pageRangeDisplayed={5}
                            onChange={(e) => handlePageChange(e, "CNFT")}
                            hideDisabled={true}
                            prevPageText={"<"}
                            nextPageText={">"}
                          />
                        </div>
                      )}
                    </section>
                  ) : null}
                </TabPanel>

                <TabPanel>
                  {isLoader ? (
                    <div className="loader-home">

                    </div>
                  ) : (
                    ""
                  )}
                  {!isLoader ? (
                    <section
                      className="new-item-box"
                      style={{ position: "relative" }}
                    >
                      <Row>
                        {draftuserList &&
                          draftuserList?.length > 0 ? (
                          draftuserList?.map((item, index) => {
                            return (
                              <Col lg={3} md={6} sm={6} key={index} className="trash-area-box">
                                <div className="edit-trach-top-area">
                                  <div className="draft-area-edit"
                                    onClick={() => {
                                      if (item.nft_type == "S") {
                                        navigate(`/trading_cards/?id=${item?.id}`, { state: { fromHomePage: false } })
                                      } else {
                                        navigate(`/trading_cards/?id=${item?.id}`, { state: { fromHomePage: true } })
                                      }

                                    }}
                                  >
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                  </div>
                                  <div className="trash-icon-area" onClick={(e) => {
                                    Swal.fire({
                                      icon: "info",
                                      html: `<div class="logout-text">
                                              Are you sure, you want to delete draft?
                                              </div>
                                              `,
                                      showCloseButton: true,
                                      showConfirmButton: true,
                                      showCancelButton: true,
                                    }).then((res) => {
                                      if (res.isConfirmed) {
                                        DeleteDraft(item?.id)
                                      }
                                    });
                                  }}>
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                  </div>
                                </div>
                                <div
                                  className={`slide-2 mb-4 list-market-new draft-area ${item.nft_enabled == "NO"
                                    ? ""
                                    : "cursor-pointer"
                                    }`}
                                  onClick={() => {
                                    if (item.nft_type == "S") {
                                      navigate(`/trading_cards/?id=${item?.id}`, { state: { fromHomePage: false } })
                                    } else {
                                      navigate(`/trading_cards/?id=${item?.id}`, { state: { fromHomePage: true } })
                                    }

                                  }}
                                >
                                  <div className="new-tems-box">

                                    <div
                                      className={` `}

                                    >
                                      <div className="overlay-upper" style={{ backgroundImage: `url(${item.nft_image} )` }} ></div>
                                      <div className="user-img no-image-found-border" onClick={() => {
                                        if (item.nft_type == "S") {
                                          navigate(`/trading_cards/?id=${item?.id}`, { state: { fromHomePage: false } })
                                        } else {
                                          navigate(`/trading_cards/?id=${item?.id}`, { state: { fromHomePage: true } })
                                        }

                                      }}>
                                        {item?.file_mime_type == "video" ? (
                                          <video
                                            width="100%"
                                            height="100%"
                                            // autoPlay
                                            muted
                                            loop
                                            controls={false}
                                          >
                                            <source
                                              src={item.nft_image}
                                              type="video/mp4"
                                            />
                                            <source
                                              src={item.nft_image}
                                              type="video/ogg"
                                            />
                                          </video>
                                        ) :
                                          item?.nft_image ?
                                            (
                                              <img className="containe_img"
                                                src={item?.nft_image}
                                              ></img>
                                            ) :
                                            (
                                              <img
                                                className="containe_img"
                                                src={
                                                  require("../assets/images/no-data-draft-bg.png")
                                                }
                                                alt="no-data"
                                              ></img>
                                            )}

                                      </div>

                                      {item.nft_enabled == "NO" ? (
                                        <div className="timer-box">
                                          <span>Disabled by Admin</span>
                                        </div>
                                      ) : item.nft_is_on_auction == "0" &&
                                        item.nft_is_on_sale == "0" &&
                                        item.nft_status == "CREATED" ? (
                                        <div className="timer-box">
                                          <span>Not available for sale</span>
                                        </div>
                                      ) : (item.nft_is_on_auction == "1" ||
                                        item.nft_is_on_sale == "1") &&
                                        item.nft_status == "CREATED" ? (
                                        <div className="timer-box">
                                          <span>Not available for sale</span>
                                        </div>
                                      ) : item.nft_is_on_auction == "1" ? (
                                        <div className="timer-box">
                                          {new Date() >=
                                            new Date(
                                              item.nft_auction_start * 1000
                                            ) &&
                                            new Date(
                                              item.nft_auction_end * 1000
                                            ) >= new Date() ? (
                                            <ul>
                                              <Countdown
                                                date={
                                                  new Date(
                                                    item.nft_auction_end * 1000
                                                  )
                                                }
                                                renderer={renderer}
                                              />
                                            </ul>
                                          ) : new Date(
                                            item.nft_auction_end * 1000
                                          ) <= new Date() ? (
                                            <></>
                                          ) : (
                                            <p>Coming Soon</p>
                                          )}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="btm_details draft-bottom-area">
                                      <h5 className="echo-two">
                                        {item.nft_name}
                                      </h5>

                                      {item?.nft_price && item?.nft_price !== "null" && item?.nft_price > 0 ? (
                                        <h6 className="attri-price">
                                          {"$" + Number(item?.nft_price).toFixed(2)}
                                        </h6>
                                      ) : null}


                                      <h6 className="items-price">
                                        {item.nft_category}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            );
                          })
                        )
                          : !showloader1 ? (
                            <div className="no-data text-center">
                              <img
                                src={
                                  require("../assets/images/empty.png")
                                }
                                alt="img"
                                width="250"
                              />
                              <p>No Data Found</p>
                            </div>
                          )
                            :
                            <>
                              <Row>
                                <Col md={3} className="mb-3">
                                  <div className="card">
                                    <div className="shimmerBG media"></div>
                                    <div className="p-32">
                                      <div className="shimmerBG title-line"></div>
                                      <div className="shimmerBG title-line end"></div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={3} className="mb-3">
                                  <div className="card">
                                    <div className="shimmerBG media"></div>
                                    <div className="p-32">
                                      <div className="shimmerBG title-line"></div>
                                      <div className="shimmerBG title-line end"></div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={3} className="mb-3">
                                  <div className="card">
                                    <div className="shimmerBG media"></div>
                                    <div className="p-32">
                                      <div className="shimmerBG title-line"></div>
                                      <div className="shimmerBG title-line end"></div>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={3} className="mb-3">
                                  <div className="card">
                                    <div className="shimmerBG media"></div>
                                    <div className="p-32">
                                      <div className="shimmerBG title-line"></div>
                                      <div className="shimmerBG title-line end"></div>
                                    </div>
                                  </div>
                                </Col>
                              </Row></>}
                      </Row>
                      {totalpageDraft > 10 && (
                        <div className="pagination1">
                          <Pagination
                            activePage={totalPageCountDraft}
                            itemsCountPerPage={10}
                            totalItemsCount={totalpageDraft}
                            pageRangeDisplayed={5}
                            onChange={(e) => handlePageChange(e, "Draft")}
                            hideDisabled={true}
                            prevPageText={"Previous"}
                            nextPageText={"Next"}
                          />
                        </div>
                      )}
                    </section>
                  ) : null}
                </TabPanel>
              </Tabs>

            </div>
          </div>
        </Container>
        <Modal
          show={showshare}
          onHide={handleCloseshare}
          animation={false}
          centered
          className="text-center meta-mask-modal"
        >
          <Modal.Body className="boda-share">
            <h3>BodaV</h3>
            {copy ? (
              <>
                <span style={{ fontSize: "12px", color: "#0d6efd" }}>
                  Link Copied
                </span>
                <br></br>
              </>
            ) : (
              <>
                <span style={{ fontSize: "10px" }}></span>
                <br></br>
              </>
            )}
            <input
              type="text"
              value={share_url}
              disabled={true}
              style={{ fontSize: "12px" }}
            />
            <CopyToClipboard text={share_url} onCopy={() => {
              setCopy(true)
            }}>
              <i
                className="fa fa-clipboard Demo__some-network__share-button"
                title="Copy to clipboard"
                aria-hidden="true"
                style={{ fontSize: "19px", cursor: "pointer" }}
              ></i>
            </CopyToClipboard>
            <br></br>
            <br></br>
            <WhatsappShareButton
              url={share_url}
              title={title}
              separator=" "
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <EmailShareButton
              url={share_url}
              subject={title}
              body="Nft: "
              separator=" "
              className="Demo__some-network__share-button"
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
            <LinkedinShareButton
              url={share_url}
              className="Demo__some-network__share-button"
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <FacebookShareButton
              url={share_url}
              quote={title}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <FacebookMessengerShareButton
              url={share_url}
              appId={`${fbappid}`}
              className="Demo__some-network__share-button"
            >
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
            <TwitterShareButton
              url={share_url}
              title={title}
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </Modal.Body>
          <Button
            type="button"
            className="ok-bt"
            onClick={() => setShowshare(false)}
          >
            Ok
          </Button>
        </Modal>

        <Modal
          show={show}
          onHide={handleClose}
          animation={false}
          centered
          className="text-center meta-mask-modal edit-profile-modal"
        >
          <span className="modalclose_icon" onClick={handleClose}>
            <i className="fa fa-close"></i>
          </span>
          <Form className="modal-form-new">
            <div className="text-center">
              <Form.Group className="mb-3 uploadimg" controlId="formBasicEmail">
                {profileFileshow ? (
                  <img
                    src={profileFileshow}
                    className="modal-img-new"
                    alt="image"
                  ></img>
                ) : (
                  <img
                    src={require("../assets/images/user-profile.png")}
                    className="modal-img-new"
                    alt="image"
                  ></img>
                )}
                <Form.Control
                  type="file"
                  placeholder="Name"
                  onChange={(e) => readURL(e.target)}
                />
              </Form.Group>
            </div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                maxLength={15}
                type="text"
                placeholder="First Name"
                value={fName}
                onChange={(e) => {
                  setUpdatedisbled(false);
                  setFName(e.target.value.replace(/\s+/g, ""));
                }}
                onKeyDown={(e) => [" "].includes(e.key) && e.preventDefault()}
              />
              {!fName && errors?.firstName ? (
                <span className="errors">{errors.firstName}</span>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                maxLength={15}
                type="text"
                placeholder="Last Name"
                value={lName}
                onChange={(e) => {
                  setUpdatedisbled(false);
                  setLName(e.target.value.replace(/\s+/g, ""));
                }}
                onKeyDown={(e) => [" "].includes(e.key) && e.preventDefault()}
              />
              {!lName && errors?.lastName ? (
                <span className="errors">{errors.lastName}</span>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                maxLength={40}
                type="text"
                placeholder="Email"
                value={userDetail?.email}
                disabled={true}
              // onChange={(e) =>{
              //   setemail(e.target.value)
              // }}
              />
              {!email && errors?.email ? (
                <span className="errors">{errors.email}</span>
              ) : (
                <></>
              )}
            </Form.Group>
            <Button
              type="button"
              disabled={updatedisbled}
              className="ok-bt profile-area-submit-btn"
              onClick={updateUser}
            >
              Update
            </Button>
          </Form>
        </Modal>
      </section>
    </>
  );
};
export default Seller_profile1;
