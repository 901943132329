import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { userServices } from "../services/user.Services";
import swal from "sweetalert";

function BrandingPartnership() {
    const [userDetail, setUserDetail] = useState();
    const [currentUrl, setCurrentUrl] = useState("/");
    const [getInTouchName, setGetInTouchName] = useState("");
    const [getInTouchEmail, setGetInTouchEmail] = useState("");
    const [getInTouchPhone, setGetInTouchPhone] = useState("");
    const [getInTouchCName, setGetInTouchCName] = useState("");
    const [getInTouchJobTitle, setGetInTouchJobTitle] = useState("");
    const [submitButtonDisabled, setsubmitButtonDisabled] = useState(false);
    const [errors, setErrors] = useState({
        getInTouchName: "",
        getInTouchEmail: "",
        getInTouchPhone: "",
        getInTouchJobTitle: "",
        getInTouchCName: "",
    });
    const emailRegex = new RegExp(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i
    );

    function validation() {
        let status = true;
        let error = { ...errors };

        if (!getInTouchName) {
            error.getInTouchName = "Please enter name.";
            status = false;
        }
        if (!getInTouchEmail) {
            error.getInTouchEmail = "Please enter email.";
            status = false;
        } else if (!emailRegex.test(getInTouchEmail)) {
            setsubmitButtonDisabled(false);
            error.getInTouchEmail = "Please enter valid email.";
            status = false;
        }
        if (!getInTouchPhone) {
            error.getInTouchPhone = "Please enter phone no.";
            status = false;
        } else if (getInTouchPhone.length < 8) {
            setsubmitButtonDisabled(false);
            error.getInTouchPhone = "Please enter valid phone no.";
            status = false;
        }
        if (!getInTouchCName) {
            setsubmitButtonDisabled(false);
            error.getInTouchCName = "Please enter Company name.";
            status = false;
        }
        if (!getInTouchJobTitle) {
            setsubmitButtonDisabled(false);
            error.getInTouchJobTitle = "Please enter Message.";
            status = false;
        }
        setErrors(error);
        return status;
    }

    const handleGetInTouchSubmit = async () => {
        setsubmitButtonDisabled(true);
        if (validation()) {
            const formData = {
                name: getInTouchName,
                email: getInTouchEmail,
                phone: getInTouchPhone,
                jobTitle: getInTouchJobTitle,
                company: getInTouchCName,
                page: " Brand Partnership"
            };
            await userServices.GetInTouchForm(formData)
                .then((res) => {
                    swal("Success", res.data.message, "success").then(() => {
                        setGetInTouchCName("");
                        setGetInTouchName("");
                        setGetInTouchEmail("");
                        setGetInTouchPhone("");
                        setGetInTouchJobTitle("");
                        setsubmitButtonDisabled(false);
                        // window.location.href = "/";
                    });
                })
                .catch((err) => { });
        } else {
            // swal("Warning", "Please fill all details", "warning");
        }
    };
    const quizListElement = document.getElementById("getsection");
    const handlePartnerClick = () => {
        const quizListElement = document.getElementById("getsection");
                quizListElement.scrollIntoView({ behavior: 'smooth' });
      };
    return (
        <>
            <div className="common_bg pt-5">
                <section className="branding-whole-page">
                    <section className="branding-banner-area">
                        <Container>
                            <Row>
                                <Col md={12} lg={7} xl={7}>
                                    <div className="branding-banner-text-area">
                                        <h1>Unlock the Power of Student Athletes with<br /> <span>Sportfinda</span></h1>
                                        <p>Leverage the Influence and Reach of College Athletes for Your Brand's Success</p>
                                        <button className="partner-with-us" type="button" onClick={(e) => handlePartnerClick()}>
                                            Partner With Us
                                        </button>
                                    </div>
                                </Col>
                                <Col md={12} lg={5} xl={5}></Col>
                            </Row>
                        </Container>

                    </section>

                    <section className="branding-about-sportsfinda">
                        <Container>
                            <div className="branding-about-sportsfinda-content">
                                <h2 className="main_h2 underline_text">
                                    About  Sportsfinda
                                </h2>
                            </div>
                            <Row className="align-items-center mt-5">
                                <Col md={12} lg={6} xl={6}>
                                    <div className="branding-content-left">
                                        <img src={require("../assets/images/branding-picture-left.png")} />
                                    </div>
                                </Col>
                                <Col md={12} lg={6} xl={6}>
                                    <div className="branding-content-right">
                                        <p>We are sportsfinda, a leading nil <span>(Name, Image, And Likeness)</span> company dedicated to helping
                                            college student athletes monetize their potential and connect with brands like yours.
                                        </p>
                                        <p>With the recent changes in ncaa regulations, student athletes now have the opportunity to
                                            showcase their talents and partner with brands for mutually beneficial campaigns.</p>
                                        <p>By partnering with sportsfinda, you can tap into the vast potential of college athletes, reaching
                                            a highly engaged audience and boosting your brand's visibility.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className="branding-why-choose-us-area">
                        <Container>
                            <div className="branding-about-sportsfinda-content">
                                <h2 className="main_h2 underline_text">
                                    Why Choose Us
                                </h2>
                            </div>
                            <Row className="justify-content-center">
                                <Col md={6} lg={4} className="mb-5">
                                    <div className="branding-choose-area-content">
                                        <div className="brading-choose-icon-area">
                                            <img src={require("../assets/images/choose-icon1.png")} />
                                        </div>
                                        <h4>Extensive Network </h4>
                                        <p> Our vast network of college athletes spans across various sports, giving
                                            you access to a diverse range of talent to represent your brand authentically.
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6} lg={4} className="mb-5">
                                    <div className="branding-choose-area-content">
                                        <div className="brading-choose-icon-area">
                                            <img src={require("../assets/images/choose-icon2.png")} />
                                        </div>
                                        <h4>Compliance Expertise </h4>
                                        <p>We understand the intricate rules and regulations surrounding NIL,
                                            ensuring that all campaigns adhere to the guidelines, protecting both your brand and the
                                            athletes.
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6} lg={4} className="mb-5">
                                    <div className="branding-choose-area-content">
                                        <div className="brading-choose-icon-area">
                                            <img src={require("../assets/images/choose-icon3.png")} />
                                        </div>
                                        <h4>Tailored Campaigns</h4>
                                        <p>Our team works closely with you to create personalized marketing
                                            campaigns that align with your brand's goals and the unique image of the athlete, delivering
                                            compelling and impactful results
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className="get-in-touch-area">
                        <Container>
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <div className="get-in-touch-left-content">
                                        <p>Ready to unlock the potential of student athletes for your brand? Partner with Sportsfinda
                                            today and create impactful campaigns that resonate with your audience.</p>
                                        <Button className="partner-with-us" type="button" onClick={(e) => window.location.href = "/athletes"}>
                                        Get in Touch
                                        </Button>
                                      
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="get-in-touch-right-picture">
                                        <img src={require("../assets/images/get-in-touch-img-right.png")} alt="img" />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="touch_section" id="getsection">
                        <img
                            src={require("../assets/images/btm_img3.png")}
                            alt=""
                            className="circle_img_postion1"
                        />
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="text-center">
                                        <h2 className="main_h2 underline_text">
                                            Get In Touch With A Consultant Today
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5 pt-3 justify-content-center">
                                <div className="col-md-12 ">
                                    <div className="touch_form">
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-6 mb-2 thenae-box">
                                                    <Form.Control
                                                        type="Name"
                                                        name="firstName"
                                                        placeholder="Name"
                                                        onChange={(e) => {
                                                            setGetInTouchName(e.target.value);
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (e.key === " " && e.target.value == 0)
                                                                e.preventDefault();
                                                        }}
                                                        value={getInTouchName}
                                                    />
                                                    {!getInTouchName && errors?.getInTouchName ? (
                                                        <span className="errors">
                                                            {errors.getInTouchName}
                                                            <br />
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>

                                                <div className="col-md-6 mb-2 thenae-box">
                                                    <Form.Control
                                                        type="email"
                                                        name="email"
                                                        placeholder="Work Email"
                                                        onChange={(e) => {
                                                            setGetInTouchEmail(e.target.value);
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (e.key === " " && e.target.value == 0)
                                                                e.preventDefault();
                                                        }}
                                                        value={getInTouchEmail}
                                                    />
                                                    {(!getInTouchEmail ||
                                                        !emailRegex.test(getInTouchEmail)) &&
                                                        errors?.getInTouchEmail ? (
                                                        <span className="errors">
                                                            {errors.getInTouchEmail}
                                                            <br />
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                <div className="col-md-6 mb-2 thenae-box">
                                                    <Form.Control
                                                        type="number"
                                                        name="phone"
                                                        placeholder="Phone Number"
                                                        onKeyDown={(evt) => {
                                                            console.log("evt which...", evt.which);
                                                            if (
                                                                evt.which != 8 &&
                                                                evt.which != 9 &&
                                                                (evt.which < 48 || evt.which > 57) &&
                                                                (evt.which < 96 || evt.which > 105)
                                                            ) {
                                                                evt.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            if (e.target.value.length < 14) {
                                                                setGetInTouchPhone(e.target.value);
                                                            }
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (e.key === " " && e.target.value == 0)
                                                                e.preventDefault();
                                                        }}
                                                        value={getInTouchPhone}
                                                    />
                                                    {(!getInTouchEmail || getInTouchPhone?.length < 10) &&
                                                        errors?.getInTouchPhone ? (
                                                        <span className="errors">
                                                            {errors.getInTouchPhone}
                                                            <br />
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                <div className="col-md-6 mb-2 thenae-box">
                                                    <Form.Control
                                                        type="companyName"
                                                        name="companyName"
                                                        placeholder="Company Name"
                                                        onChange={(e) => {
                                                            setGetInTouchCName(e.target.value);
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (e.key === " " && e.target.value == 0)
                                                                e.preventDefault();
                                                        }}
                                                        value={getInTouchCName}
                                                    />
                                                    {!getInTouchCName && errors?.getInTouchCName ? (
                                                        <span className="errors">
                                                            {errors.getInTouchCName}
                                                            <br />
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                <div className="col-md-12 mb-2 thenae-box">
                                                    <Form.Control
                                                        type="jobTitle"
                                                        name="jobTitle"
                                                        placeholder="Message"
                                                        onChange={(e) => {
                                                            setGetInTouchJobTitle(e.target.value);
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (e.key === " " && e.target.value == 0)
                                                                e.preventDefault();
                                                        }}
                                                        value={getInTouchJobTitle}
                                                    />
                                                    {!getInTouchJobTitle && errors?.getInTouchJobTitle ? (
                                                        <span className="errors">
                                                            {errors.getInTouchJobTitle}
                                                            <br />
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                <div className="col-md-4 m-auto text-center">
                                                    <button
                                                        type="button"
                                                        disabled={submitButtonDisabled}
                                                        onClick={() => {
                                                            handleGetInTouchSubmit();
                                                        }}
                                                        className="btn btn-unset px-4 mt-4"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>


        </>
    )

}
export default BrandingPartnership;