import {
  Container,
  Row,
  Col,
  Form,
  Modal,
  Button,
  ProgressBar,
  Collapse,
  Nav,
  ModalHeader,
} from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useMoralis } from "react-moralis";
import { nftAbi, CollectionAbi } from "./Abi";
import swal from "sweetalert";
import LazyLoad from "react-lazy-load";
import { Image, Shimmer } from "react-shimmer";
import {
  winter_multiple_email,
  winter_multiple_tokenSymbol,
  winter_multiple_projectName,
  winter_multiple_brandImageURL,
  winter_multiple_contractAddress,
  winter_multiple_maxNumberOfMints,
  winter_multiple_mintFunctionParams,
  winter_multiple_mintFunction,
  winter_multiple_sandbox,
  winter_multiple_ercType,
  winter_multiple_isActive,
  winter_multiple_requireAddress,
  winter_multiple_contractABI,
  withPaperMultipleContractId,
} from "../config/config";
import {
  appUrl,
  platform_fee,
  colectionaddress,
  tokenstandard_multi,
  contracturl,
  contracturlETH,
  contracturlPOLY,
  httpprovider,
  chainidBNB,
  chainidETH,
  chainidPOLY,
  fbappid,
  currency_symbol,
  appName,
  nftaddressETH,
  nftaddressPOLY,
  colectionaddressETH,
  colectionaddressPOLY,
  httpproviderETH,
  sportsfindaMetamaskUrl,
  winterCheckoutProduction,
  winterCheckoutUserAddress,
} from "../config/config";
import { useParams } from "react-router-dom";

import { userServices } from "../services/user.Services";
import { nftService } from "../_services";
import LoadingSpinerComponent from "./Loader";
import { ethers } from "ethers";
import { set } from "date-fns";
import Loader from "react-js-loader";
import correct from "../assets/images/correct.png";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import defaultprofile from "../assets/images/main_avatar.png";
import Loaderdiv from "./SmallLoader";
import Swal from "sweetalert2";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Web3 from "web3";

const Details_new = () => {
  const navigate = useNavigate();
  const [approveForSaleModal, setApproveforSaleModal] = useState(false)
  const [activeTab, setActiveTab] = useState(0); // State to track the active tab
  const [opendetails, setOpendetails] = useState(true);
  // const { user, userError, Moralis } = useMoralis();
  const [usdprice, setusdprice] = useState();
  const [showloader, setshowloader] = useState(true);
  const [message2, setmessage2] = useState("Please wait..");
  const [onSale, setonSale] = useState(true);
  const [approved, setapproved] = useState(false);
  const { userSlug } = useParams();
  const [buyqty, setbuyqty] = React.useState(activeTab == 0 ? "" : 1);
  const [saleqty, setsaleqty] = React.useState();
  const [saleprice, setsaleprice] = React.useState();
  const [collectionId, setcollectionId] = React.useState();
  const [nftOwner, setNftOwner] = React.useState();
  const [buyPrice, setbuyPrice] = React.useState();
  const [buyqtyavailabe, setbuyqtyavailabe] = React.useState();
  const [showloader_inner, setshowloaderInner] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const ContractRefcollection = useRef();
  const [message, setmessage] = useState("");
  const [showdata, setShowdata] = useState(false);
  const [qtyErr, SetqtyErr] = React.useState();
  const [saleErr, SetsaleErr] = React.useState();
  const [priceErr, SetpriceErr] = React.useState();
  const [totalforsale, Settotalforsale] = React.useState();
  const [availableforsale, Setavailableforsale] = React.useState();
  const [itemOwner, SeitemOwner] = React.useState();
  const [showshare, setShowshare] = useState(false);
  const [share_url, setshare_url] = useState(null);
  const [copy, setCopy] = useState(false);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showMetaModal, setshowMetaModal] = useState(false);
  const [currentUserQty, setcurrentUserQty] = useState(false);
  let myuserAddress = useRef();
  const [openProperty, setOpenProprty] = useState(false);
  const [openLevel, setOpenLevel] = useState(false);
  const [openStats, setOpenStats] = useState(false);
  const [nftlevel, setnftLevel] = useState();
  const [nftstat, setnftstat] = useState();
  const [nftProperties, setnftProperties] = useState();
  const [showWinter, setShowWinter] = useState(false);
  const [useWinterProjectId, setUseWinterProjectId] = useState("");
  const [show, setShow] = useState(false);
  const [showCrossmintButton, setShowCrossmintButton] = useState(false);
  const [priceqty_buy, setPriceqty_buy] = useState(null);

  const [symbolsArr] = useState([
    " ",
    "`",
    "~",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "_",
    "-",
    "=",
    "+",
    "{",
    "}",
    "[",
    "]",
    "'",
    "|",
    ";",
    ":",
    '"',
    "?",
    "/",
    "<",
    ">",
    ",",
    "\\",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ]);
  const [showstep, setShowstep] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [showMintModal, setShowMintModal] = useState(false);
  const [isSale, setIsSale] = useState(false);
  const [currentCollectionAddress, setcurrentCollectionAddress] = useState();
  const [currentChain, setcurrentChain] = useState();
  const [currentContractUrl, setcurrentContractUrl] = useState();
  const [showPaperCheckout, setShowPaperCheckout] = useState(false);
  let location = window.location.pathname;


  let title = `Hey! Checkout this Trading Cards detail on ${appName}`;
  useEffect(() => {
    isUserLogged();
    setshare_url(`${appUrl}` + "/collection_details/" + userSlug);
  }, []);

  useEffect(() => {
    if (copy) {
      const interval = setInterval(() => {
        setCopy(false);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [copy]);
  const locationPath = useLocation();

  useEffect(() => {
   
    const handlePageLoad = () => {
      if (userSlug) {
      getNftDetail(userSlug)
    }
    };
    handlePageLoad();
    
  }, [userSlug,document.getElementById("back-reload"),locationPath])

  const [nftDetail, setNftDetail] = useState({});
  const [buyQuantity, setBuyQuantity] = useState();
  const [isSuccessCheckout, setIsSuccessCheckout] = useState(false);
  const [userDetail, setUserDetail] = useState();
  let clientSecret = useRef();


  const [isUserLoggedIn, setIsUserLoggedIn] = useState(localStorage.getItem("auth") || false);
  const [selectedNftOwner, setSelectedNftOwner] = useState(null); // State for selected nft_owner
  const selectedNftOwnerRef = useRef(null);

  const isUserLogged = async () => {
    await userServices
      .getIDwithEdit()
      .then((res) => {
        setIsUserLoggedIn(true);
      })
      .catch((error) => {
        setIsUserLoggedIn(false);
      });
  };
  let adminWalletAddress = useRef();


  async function getNftDetail(useSlug) {
    await nftService
      .getNftDetail(useSlug)
      .then((res) => {
        if (res) {
          if (res.data.data?.length == 0) {
            swal(
              "Warning",
              "This Trading Cards is no longer available.",
              "warning"
            ).then(() => {
              navigate("/profile");
            });
          } else if (res.data.data.nft_enabled == "NO") {
            swal(
              "Warning",
              "This Trading Cards is no longer available.",
              "warning"
            ).then(() => {
              navigate("/profile");
            });
          } else {
            setNftDetail(res.data.data);
            setUseWinterProjectId(res.data.data.project_id);
            let othervalues = JSON.parse(res.data.data.nft_properties);
            othervalues?.map(async function (item, index) {
              if (item.trait_type == "properties") {
                if (item.value[0].name != "") {
                  setnftProperties(item.value);
                }
              }
              if (item.trait_type == "level") {
                if (item.value[0].key != "") {
                  setnftLevel(item.value);
                }
              }
              if (item.trait_type == "Stats") {
                if (item.value[0].name != "") {
                  setnftstat(item.value);
                }
              }
            });
          }
        }
        setTimeout(() => {
          setShowLoader(false);
        }, 500);
      });
  }

  const handleCloseshare = () => setShowshare(false);
  window.desktopcheck = function () {
    var check = false;
    if (window.innerWidth > 768) {
      check = true;
    }
    return check;
  };


  const handleChangeQty = (e) => {
    if (e.keyCode == 69) {
      return false;
    }
    var target = e.srcElement || e.target;
    var tag = target.tagName.toUpperCase();
    var type = target.type.toUpperCase();
    if (e.target.value < 0) {
      e.preventDefault();
    }
    //   if (e.keyCode === 38 || e.keyCode === 40) {
    //     e.preventDefault();
    // }
    const re = /^[^\.]+$/;

    // if value is not blank, then test the regex
    if (
      e.target.value === "" ||
      (re.test(e.target.value) && e.target.value > 0)
    ) {
      setbuyqty(e.target.value);
      let wei_price_total = Number(selectedSplitIdPrice.current) * Number(buyqty);
      // let Priceqty_buy = WEIToETH({ value: wei_price_total });
      setPriceqty_buy(wei_price_total);
    }
  };
  const handleChangeCreditCardQty = (e) => {
    if (e.keyCode == 69) {
      return false;
    }
    var target = e.srcElement || e.target;
    var tag = target.tagName.toUpperCase();
    var type = target.type.toUpperCase();
    if (e.target.value < 0) {
      e.preventDefault();
    }
    const re = /^[^\.]+$/;
    if (
      e.target.value === "" ||
      (re.test(e.target.value) && e.target.value > 0)
    ) {
      setBuyQuantity(parseInt(e.target.value));
    }
  };
  const handleChangeQty1 = (e) => {
    const re = /^[^\.]+$/;
    if (e === "" || re.test(e)) {
      setsaleqty(e);
    }
  };

  const [nftId, setNftId] = useState();

  function getAdminWalletAddress() {
    userServices
      .getAdminWalletAddress()
      .then((res) => {
        let data = res.data.admin[0].ethAddress;
        adminWalletAddress.current = data;
      })
      .catch((error) => {
      });
  }

  const successCheckout = (event) => {
    setIsSuccessCheckout(true);
  };


  const handleClosedata = () => {
    setShowdata(false);
    handleClose();
  };
  const handleClose = () => {
    SetqtyErr("");
    setcollectionId();
    setbuyPrice();
    setbuyqtyavailabe();
    setShow(false);
  };
  const handleWinterClose = () => {
    SetqtyErr("");
    setcollectionId();
    setBuyQuantity();
  };

  const handleShow = (id, price, qty, owner) => {
    itemSplitId.current = id;
    setcollectionId(id);
    setbuyPrice(price);
    // setbuyqtyavailabe(qty);
    SeitemOwner(owner);
    setShow(true);
    itemSplitId.current = id;
    selectedSplitIdPrice.current = price;
  };
  const closeUseWinterModal = () => {
    setShowWinter(false);
    if (isSuccessCheckout) {
      window.location.reload();
    }
  };


  const [showbuy, setShowbuy] = useState(false);
  const handleClosebuy = () => {
    setcollectionId();
    SetsaleErr("");
    SetpriceErr("");
    Settotalforsale();
    Setavailableforsale();
    setsaleqty();
    setShowbuy(false);

  };
  // const handleShowbuy = (id, total, onsale, nftOwner, isApproved) => {
  //   let nftApproved = isApproved == "0" ? false : true;
  //   setIsApprove(nftApproved);
  //   setNftOwner(nftOwner);
  //   setcollectionId(id);
  //   Settotalforsale(total);
  //   Setavailableforsale(total - onsale);
  //   setShowbuy(true);
  // };
  const [tokenSplitId, setTokenSplitId] = useState("");
  const itemSplitId = useRef();
  const selectedSplitIdPrice = useRef();
  const handlePaymentMethodModal = (token_split_id) => {
    setTokenSplitId(token_split_id);
  };




  // const btnhandler = () => {
  //   if (window.ethereum) {
  //     window.ethereum
  //       .request({ method: "eth_requestAccounts" })
  //       .then((res) => { });
  //   } else {
  //     swal("Warning", "Please install metamask extension!!", "warning").then(
  //       () => {
  //         window.open(
  //           "https://metamask.io/",
  //           "_blank" // <- This is what makes it open in a new window.
  //         );
  //       }
  //     );
  //   }
  // };


  /* buy nft */

  const [buyPriceMultiple, setbuyPriceMultiple] = useState();
  const buyPriceRefMultiple = useRef();
  async function BuyNft(nftItem) {
    console.log("item::", nftItem)

    let propsParams = {
      price: buyPriceRefMultiple.current ? buyPriceRefMultiple.current : buyPriceMultiple,
      nft_id: nftDetail?.id,
      quantity: buyqty,
      seller: selectedNftOwnerRef.current ? selectedNftOwnerRef.current : selectedNftOwner
    };
    console.log("propsParams::", propsParams)
    setShowLoader(true);
    nftService.buyNft(propsParams)
      .then((response) => {
        if (response?.status == 200) {
          setShowLoader(false);
          console.log("response", response);
          window.location.replace(response?.data?.url);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  /* put on Sale */
  async function PutOnSale(nftItem) {
    console.log("item sell::", nftItem)

    let propsParams = {
      nftId: nftItem?.id,
      nft_type: nftDetail?.nft_type,
      quantity: saleqty,
      nft_price: saleprice
    };
    console.log("put on sale from params:::::", propsParams)
    setShowLoader(true);
    nftService.put_on_Sale(propsParams)
      .then((response) => {
        if (response?.status == 200) {
          setShowLoader(false);
          setApproveforSaleModal(false)
          // setShow(false)
          console.log("response", response);
          Swal.fire("Success", "Trading card put on sale successfully.", "success")
          getNftDetail(userSlug)
          setShowbuy(false)
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  /* remove from sale */
  async function RemoveFromSale(nftItem) {
    let propsParams = {
      nftId: nftItem?.id,
      nft_type: nftDetail?.nft_type,
      // quantity: buyqty
    };
    console.log("remove from sale from params:::::", propsParams)
    setshowloader(true);
    nftService.removeFromSale(propsParams)
      .then((response) => {
        if (response?.status == 200) {
          setshowloader(false);
          console.log("response", response);
          Swal.fire("Success", "Trading card removed from sale successfully.", "success")
          getNftDetail(userSlug)
        }
      })
      .catch((err) => {
        setshowloader(false);
      });
  }

  const handleFollowToggle = async () => {
    try {
      // if (userDetail?.isFollowing) {
      const params = {
        userId: localStorage.getItem("currentUserId"),
        follower_id: nftDetail?.nft_creator
      };
      // console.log("params", params);
      await userServices.followUnfollow(params).then((response) => {
        if (response.status === 200) {
          console.log("response", response);
          Swal.fire("Success", response?.data?.message, "success")
          getNftDetail(userSlug)
        }
      });
      // } 
    } catch (error) {
      console.error("Error toggling follow status:", error);
    }
  };


  return (
    <>

      <section id="bid-auction-id" className="bid_auction">
        <LoadingSpinerComponent
          promiseInProgress={showLoader}
          message={message2}
        />
        <Container>
          {nftDetail.id ? (
            <Row className="auction-reverse-box">
              <Col md={12} lg={5}>
                <div className="scroll-div">
                  {nftDetail ? (
                    <>
                      <div className="air-max" id="back-reload">
                        <h2>{nftDetail.nft_name}</h2>
                        <div className="share-bt" style={{ cursor: "pointer" }}>
                          <p onClick={() => setShowshare(true)}>
                            <i
                              className="fa fa-share-alt mr-2"
                              aria-hidden="true"
                            ></i>{" "}
                            Share
                          </p>
                        </div>
                      </div>
                      {nftDetail.nft_price ? (
                        <h6 className="air-price-new not-available-btn">
                          <b>From {" "}
                            {"$"}
                            {
                              Number(nftDetail.nft_price
                              ).toFixed(2)}{" "}
                          </b>
                          {
                            // isCurrentUserOwner ?
                            // <div className="timer-box"> <span> No available for sale</span> </div> :
                            nftDetail?.total_avail_for_sale === 0 ?
                              <div className="timer-box"> <span> Sold out</span> </div>
                              : <span>
                                {nftDetail?.total_avail_for_sale
                                  ? nftDetail?.total_avail_for_sale
                                  : "0"}{" "}
                                of {""}
                                {nftDetail?.total_avail_qty
                                  ? nftDetail?.total_avail_qty
                                  : "0"}{" "}
                                available
                              </span>}
                        </h6>
                      ) : (
                        <></>
                      )}
                      {nftDetail.project_name != null ? (
                        <a href={"/projects/" + nftDetail.project_slug}>
                          {" "}
                          <div className="projectData">
                            <i class="fa fa-tasks"></i> {nftDetail.project_name}
                          </div>
                        </a>
                      ) : null}
                      <p>{nftDetail.nft_description}.</p>
                    </>
                  ) : (
                    <></>
                  )}
                  <>
                    <div className="creater-rayality-box">
                      <div className="collection-team">
                        <h6 className="air-desc">Creator:</h6>
                        <div className="follow-boxes">
                          <a href={"/user/" + nftDetail?.slug}>
                            <img
                              src={
                                nftDetail.creator_profile
                                  ? nftDetail.creator_profile
                                  : defaultprofile
                              }
                            ></img>{" "}
                          </a>
                          <div className="follow-div">
                            <p>  {nftDetail.creator_firstname &&
                              nftDetail.creator_lastname &&
                              `${nftDetail.creator_firstname} + ${nftDetail.creator_lastname}`
                                .length < 20
                              && nftDetail.creator_firstname + " " + nftDetail.creator_lastname
                            }
                              {nftDetail?.isFollow &&
                                Number(localStorage.getItem("currentUserId")) !== Number(nftDetail?.nft_creator) && (
                                  <i className="fa fa-check checkmark" aria-hidden="true"></i>
                                )}
                            </p>
                            {!nftDetail?.isFollow &&
                            Number(localStorage.getItem("currentUserId")) !== Number(nftDetail?.nft_creator) && (
                               <span className="follow-btn-text"
                              onClick={() => {
                                if (isUserLoggedIn) {
                                  handleFollowToggle(nftDetail);
                                } else {
                                  window.location.href = "/login";
                                  localStorage.setItem(
                                    "requrl",
                                    window.location.pathname
                                  );
                                }
                              }}
                            >Follow</span>)}
                          </div>
                        </div>

                      </div>
                      <div className="collection-team detail-collection-area">
                        <h6 className="air-desc">Collection:</h6>
                        {nftDetail.collectionImage ? (
                          <>
                            {" "}
                            <a
                              href={
                                "/collection_trading_cards/" + nftDetail?.collection_slug + "/" + nftDetail?.collection_id
                              }
                            >
                              <img src={nftDetail.collectionImage} alt=""></img>{" "}
                              {nftDetail.collection_name}{" "}
                            </a>{" "}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </>

                  <div className="collection-team">
                    <h5 className="air-desc detail-owner-heading">Owner:</h5>
                    {nftDetail.nftowners ? (
                      nftDetail.nftowners.map((item, i) => {
                        // console.log("item all feautre of bou sell eveything", item);
                        let data = item?.token_split_id;
                        return (
                          <div className="owner-list" key={i}>
                            <a
                              href={
                                item?.firstname && item?.lastname
                                  ? "/user/" + item?.user_slug
                                  : "https://sandbox-dashboard.usewinter.com/"
                              }
                              target={
                                item?.firstname && item?.lastname
                                  ? ""
                                  : "_blank"
                              }
                              className="text-dark"
                            >
                              <img
                                src={
                                  item.profile
                                    ? item.profile
                                    : require("../assets/images/user.png")
                                      .default
                                }
                                className="me-2"
                                alt=""
                              ></img>
                            </a>
                            <div className="owner-details">
                              <p
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  item?.firstname && item?.lastname
                                    ? window.location.href(
                                      "/user/" + item.user_slug,
                                      "_self"
                                    )
                                    : window.open(
                                      "https://sandbox-dashboard.usewinter.com/"
                                    );
                                }}
                              >
                                {item?.firstname && item?.lastname
                                  ? item?.firstname + " " + item?.lastname
                                  : "Winter user"}
                              </p>

                              <p className="owner-sale-box">
                                {" "} {item.avail_for_sale ? item.avail_for_sale : 0}/{item.avail_qty} on sale
                                {item.avail_for_sale > 0 &&
                                  item.selling_price && (
                                    <>
                                      {" "}
                                      for{" $"}
                                      {item.selling_price}{" "}
                                      each
                                      {/* {Number(item.selling_price)}{" "}
                                      USD each */}
                                    </>
                                  )}
                              </p>
                            </div>



                            {item.nftowner_is_approved == "1" &&
                              item.avail_for_sale > 0 &&
                              localStorage.getItem("auth") && Number(localStorage.getItem("currentUserId") == Number(item.nft_owner))
                              ?
                              (
                                localStorage.getItem("auth") ? (
                                  <button
                                    type="button"
                                    className="buy-now-bt"
                                    onClick={(e) => RemoveFromSale(nftDetail)}
                                  >
                                    Unlist
                                  </button>
                                ) :
                                  (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        if (isUserLoggedIn) {
                                        } else {
                                          window.location.href = "/login";
                                          localStorage.setItem(
                                            "requrl",
                                            window.location.pathname
                                          );
                                        }
                                      }}
                                      className="buy-now-bt"
                                    >
                                      Unlist
                                    </button>
                                  )
                              )
                              :
                              item.nftowner_is_approved == "1" &&
                                item.avail_for_sale > 0 &&
                                Number(localStorage.getItem("currentUserId") != Number(item.nft_owner)) ?
                                (
                                  <>
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        console.log("isUserLoggedIn::", isUserLoggedIn)
                                        if (isUserLoggedIn) {
                                          setbuyqtyavailabe(item?.avail_for_sale);
                                          setbuyPriceMultiple(item?.selling_price);
                                          buyPriceRefMultiple.current = item?.selling_price;
                                          setSelectedNftOwner(item.nft_owner); // Save the nft_owner in state
                                          selectedNftOwnerRef.current = item.nft_owner;
                                          setShow(true)
                                        } else {
                                          window.location.href = "/login";
                                          localStorage.setItem(
                                            "requrl",
                                            window.location.pathname
                                          );
                                        }
                                      }}
                                      className="buy-now-bt"
                                    >
                                      Buy Now
                                    </button>
                                  </>
                                ) :

                                (item.avail_for_sale === 0 || item.avail_for_sale === null || item.avail_for_sale === "null") &&
                                  item.avail_qty > 0 &&
                                  localStorage.getItem("auth") &&
                                  Number(localStorage.getItem("currentUserId")) === Number(item.nft_owner)
                                  ?
                                  (

                                    <>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          {
                                            if (isUserLoggedIn) {
                                              Setavailableforsale(item?.avail_for_sale);
                                              Settotalforsale(item?.avail_qty);
                                              setShowbuy(true);
                                            } else {
                                              window.location.href = "/login";
                                              localStorage.setItem(
                                                "requrl",
                                                window.location.pathname
                                              );
                                            }
                                          }
                                        }}
                                        className="buy-now-bt"
                                      >
                                        Put on Sale
                                      </button>
                                    </>
                                  )
                                  :
                                  0 == item.avail_for_sale ? (
                                    <p className="not-available-error">
                                      Not available for sale{" "}
                                    </p>
                                  )
                                    : (
                                      <></>
                                    )}
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Col>
              <Col md={12} lg={7}>
                <div className="detail-left-side">
                  {nftDetail?.nft_media_type == "video" ? (
                    <LazyLoad height="100%">
                      <video
                        width="100%"
                        height="380"
                        autoPlay
                        muted
                        loop
                        preload='none'
                        playsInline
                        controls={false}
                        id="individual-video-player"
                      >
                        <source src={nftDetail.nft_image} type="video/mp4" />
                        <source src={nftDetail.nft_image} type="video/ogg" />
                      </video>
                    </LazyLoad>
                  ) : (
                    <Image
                      src={nftDetail?.nft_image}
                      className="img-fluid"
                      alt="nft images"
                      fallback={<Shimmer width="100" height="100%" />}
                    />
                  )}
                </div>

                {nftProperties ? (
                  <div>
                    <div className="mt-4">
                      <div
                        className="property_head"
                        onClick={() => setOpenProprty(!openProperty)}
                        aria-controls="Property-box"
                        aria-expanded={openProperty}
                      >
                        <img
                          src={
                            require("../assets/images/properties.svg").default
                          }
                          alt=""
                          width={20}
                        />
                        <div>
                          <h6 className="mb-2">Properties</h6>
                        </div>
                      </div>
                      <Collapse in={openProperty}>
                        <div className="row" id="Property-box">
                          {nftProperties &&
                            nftProperties.map((item, index) => (
                              <div className="col-md-4 mb-2" key={index}>
                                <div className="property-box">
                                  <p className="mb-2">{item.value1}</p>
                                  <h5>{item.name}</h5>
                                </div>
                              </div>
                            ))}
                        </div>
                      </Collapse>
                    </div>
                    <hr />
                  </div>
                ) : null}

                {nftlevel ? (
                  <div>
                    <div className="mt-4 mb-3">
                      <div
                        className="property_head"
                        onClick={() => setOpenLevel(!openLevel)}
                        aria-controls="level-box"
                        aria-expanded={openLevel}
                      >
                        <img
                          src={require("../assets/images/star1.svg").default}
                          alt=""
                          width={20}
                        />
                        <div>
                          <h6 className="mb-2">Levels</h6>
                        </div>
                      </div>
                      <Collapse in={openLevel}>
                        <div id="level-box">
                          {nftlevel &&
                            nftlevel.map((item, index) => (
                              <div className="number_box1" key={index}>
                                <div className="d-flex justify-content-between">
                                  <p className="mb-2">{item.key}</p>
                                  <p className="mb-2">
                                    {item.value1} of {item.value2}
                                  </p>
                                </div>
                                <div>
                                  <ProgressBar
                                    now={item.value1}
                                    max={item.value2}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </Collapse>
                    </div>
                    <hr />
                  </div>
                ) : null}

                {nftstat ? (
                  <div>
                    <div className="mt-4 mb-4">
                      <div
                        className="property_head"
                        onClick={() => setOpenStats(!openStats)}
                        aria-controls="stats-box"
                        aria-expanded={openStats}
                      >
                        <img
                          src={require("../assets/images/stats1.svg").default}
                          alt=""
                          width={20}
                        />
                        <div>
                          <h6 className="mb-2">Stats</h6>
                        </div>
                      </div>
                      <Collapse in={openStats}>
                        <div id="level-box">
                          {nftstat.map((item, index) => (
                            <div
                              className="number_box1"
                              id="stats-box"
                              key={index}
                            >
                              <div className="d-flex justify-content-between">
                                <p className="mb-0">{item.name}</p>
                                <p className="mb-0">
                                  {item.value1} of {item.value2}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Collapse>
                    </div>
                  </div>
                ) : null}
              </Col>
            </Row>
          ) : (

            <></>
          )}
        </Container>
      </section>

      <Modal
        show={show}
        animation={false}
        centered
        className="meta-mask-modal edit-profile-modal buy-now-modal detail-area-buy-nft"
      >
        <ModalHeader>
          <h3>Buy Digital Trading Cards</h3>
          <span className="modalclose_icon" onClick={handleClose}>
            <i className="fa fa-close"></i>
          </span>
        </ModalHeader>
        <Modal.Body>
          <Tabs className="buy-nft-tab" selectedIndex={activeTab}>
            <TabPanel>
              <div className="nft-modal-items">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="m-0 ">Enter Quantity</Form.Label>
                  <Form.Control
                    className="minimum-quantity"
                    type="number"
                    value={buyqty}
                    onWheel={() => document.activeElement.blur()}
                    onKeyDown={(e) =>
                      symbolsArr.includes(e.key) && e.preventDefault()
                    }
                    placeholder="Enter Quantity"
                    onChange={(e) => handleChangeQty(e)}
                  />

                  <span className="error">{qtyErr}</span>
                </Form.Group>
              </div>

              <Button
                type="button"
                className="buy-now-bt nft-buy-now detail-submit-btn"
                disabled={parseInt(buyqty) <= parseInt(buyqtyavailabe) ? false : true}
                onClick={() => {
                  if (!buyqty) {
                    if (parseInt(buyqty) <= parseInt(buyqtyavailabe)) {
                      SetqtyErr(
                        "Enter maximum " + buyqtyavailabe + " quantity."
                      );
                      return false;
                    }
                    if (buyqty < 1) {
                      SetqtyErr("Enter minimum 1 quantity.");
                      return false;
                    }
                    if (parseInt(buyqty) + parseInt(currentUserQty) > 100000) {
                      SetqtyErr(
                        "you cannot exceed maximum purchase limit of 100000 Digital Trading Cards of collection."
                      );
                      return false;
                    }
                  } else {
                    BuyNft(nftDetail)
                  }
                }}
                variant="unset"
              >
                Submit
              </Button>

            </TabPanel>

          </Tabs>
        </Modal.Body>
      </Modal>


      {/* <Modal
        show={showbuy}
        animation={false}
        centered
        className="meta-mask-modal edit-profile-modal buy-now-modal"
      >
        <ModalHeader>
          <h3>Put on Sale</h3>
          <span className="modalclose_icon" onClick={handleClosebuy}>
            <i className="fa fa-close"></i>
          </span>
        </ModalHeader>
        <Modal.Body>
          <div className="buy-now-modal">
            <div>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="m-0">Instant sale price</Form.Label>
                <br></br>
                <Form.Text className="text-muted">
                  Enter the price for one piece on which the item will be
                  instantly sold
                </Form.Text>
                <Form.Control
                  className="minimum-quantity"
                  type="number"
                  onWheel={() => document.activeElement.blur()}
                  placeholder="Enter Price"
                  maxLength={18}
                  onChange={(e) => setsaleprice(e.target.value)}
                  onKeyDown={(e) =>
                    symbolsArr.includes(e.key) && e.preventDefault()
                  }
                />
                <span className="errors">{priceErr}</span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Number of copies{" "}
                  <span>
                    ( {availableforsale}/{totalforsale} available )
                  </span>
                </Form.Label>
                <Form.Control
                  className="minimum-quantity"
                  type="number"
                  value={saleqty}
                  maxLength="4"
                  placeholder="Eg.100"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => handleChangeQty1(e.target.value)}
                  onKeyDown={(e) =>
                    symbolsArr.includes(e.key) && e.preventDefault()
                  }
                />
                <span className="errors">{saleErr}</span>
              </Form.Group>
              <Button
                type="button"
                className="buy-now-bt "
                disabled={parseInt(saleqty) <= parseInt(totalforsale) ? false : true}
                onClick={(e) => PutOnSale(nftDetail)}
                variant="unset"
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      <Modal
        show={showbuy}
        animation={false}
        centered
        className="meta-mask-modal edit-profile-modal buy-now-modal"
      >
        <ModalHeader>
          <h3>Put on Sale</h3>
          <span className="modalclose_icon" onClick={handleClosebuy}>
            <i className="fa fa-close"></i>
          </span>
        </ModalHeader>
        <Modal.Body>
          <div className="buy-now-modal">
            <div>
              {/* <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="m-0">Instant sale price</Form.Label>
          <br />
          <Form.Text className="text-muted">
            Enter the price for one piece on which the item will be instantly sold
          </Form.Text>
          <Form.Control
            className="minimum-quantity"
            type="number"
            min={1} // Minimum price is set to 1
            onWheel={() => document.activeElement.blur()}
            placeholder="Enter Price"
            maxLength={18}
            value={saleprice} // Ensure it is bound to saleprice
            onChange={(e) => {
              const value = e.target.value;
              // Ensure price is not less than 1
              if (value >= 1) {
                setsaleprice(value);
              } else {
                setsaleprice(""); // Handle invalid input
              }
            }}
            onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
          />
          <span className="errors">{priceErr}</span>
        </Form.Group> */}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="m-0">Instant sale price</Form.Label>
                <br />
                <Form.Text className="text-muted">
                  Enter the price for one piece on which the item will be instantly sold
                </Form.Text>

                <div className="input-group">
                  <span className="input-group-text">$</span>
                  <Form.Control
                    className="minimum-quantity"
                    type="number"
                    min={1} // Minimum price is set to 1
                    onWheel={() => document.activeElement.blur()}
                    placeholder="Enter Price"
                    maxLength={18}
                    value={saleprice} // Ensure it is bound to saleprice
                    onChange={(e) => {
                      const value = e.target.value;
                      // Ensure price is not less than 1
                      if (value >= 1) {
                        setsaleprice(value);
                      } else {
                        setsaleprice(""); // Handle invalid input
                      }
                    }}
                    onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
                  />
                </div>
                <span className="errors">{priceErr}</span>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Number of copies{" "}
                  <span>
                    ({availableforsale}/{totalforsale} available)
                  </span>
                </Form.Label>
                <Form.Control
                  className="minimum-quantity"
                  type="number"
                  min={1} // Minimum quantity is set to 1
                  value={saleqty}
                  maxLength="4"
                  placeholder="Eg.100"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Ensure quantity is not less than 1
                    if (value >= 1) {
                      handleChangeQty1(value);
                    } else {
                      handleChangeQty1(""); // Handle invalid input
                    }
                  }}
                  onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
                />
                <span className="errors">{saleErr}</span>
              </Form.Group>
              <Button
                type="button"
                className="buy-now-bt"
                disabled={parseInt(saleqty) <= parseInt(totalforsale) && saleprice >= 1 ? false : true} // Button disabled based on valid conditions
                onClick={(e) => PutOnSale(nftDetail)}
                variant="unset"
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={showdata}
        onHide={handleClosedata}
        animation={false}
        centered
        className="text-center meta-mask-modal"
      >
        <Modal.Body>
          <h3>{appName}</h3>
          {message}
        </Modal.Body>
        <Button type="button" className="ok-bt" onClick={handleClosedata}>
          Ok
        </Button>
      </Modal>
      <Modal
        show={showshare}
        onHide={handleCloseshare}
        animation={false}
        centered
        className="text-center meta-mask-modal share-modal-new link-copied-box"
      >
        <Modal.Body>
          <h3>{appName}</h3>
          {copy ? (
            <>
              <span style={{ fontSize: "12px", color: "#ff7750" }}>
                Link Copied
              </span>
              <br />
            </>
          ) : (
            <>
              <span style={{ fontSize: "10px" }}></span>
              <br></br>
            </>
          )}
          <input
            type="text"
            value={share_url}
            disabled={true}
            style={{ fontSize: "12px" }}
          />
          <CopyToClipboard text={share_url} onCopy={() => setCopy(true)}>
            <i
              className="fa fa-clipboard Demo__some-network__share-button"
              title="Copy to clipboard"
              aria-hidden="true"
              style={{ fontSize: "19px", cursor: "pointer" }}
            ></i>
          </CopyToClipboard>
          <br></br>
          <br></br>
          <div className="popup-social-icons">
            <WhatsappShareButton
              url={share_url}
              title={title}
              separator=" "
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <EmailShareButton
              url={share_url}
              subject={title}
              body="Lincode Card: "
              separator=" "
              className="Demo__some-network__share-button"
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
            <LinkedinShareButton
              url={share_url}
              className="Demo__some-network__share-button"
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <FacebookShareButton
              url={share_url}
              quote={title}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <FacebookMessengerShareButton
              url={share_url}
              appId={`${fbappid}`}
              className="Demo__some-network__share-button"
            >
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
            <TwitterShareButton
              url={share_url}
              title={title}
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="ok-bt"
            onClick={() => setShowshare(false)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Details_new;
