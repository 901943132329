import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

const About = () => {
  useEffect(() => {
    document.title = "Sportsfinda Digital Trading Cards Marketplace";
    return () => {
      document.title = "Athlete & Artist Digital Trading Cards Marketplace";
    };
  }, []);
  return (
    <>
      <section className="about-page">
        <Container>
          <h3 className="text-center">About Us</h3>
          <h2>For the fans,</h2>
          <h1>
            {" "}
            from the <span>stars!</span>
          </h1>
          <p>
            Having kicked off in early 2019, Sportsfinda set out to establish
            the global athlete peer-to-peer Digital Trading Cards marketplace that would allow
            world class sports stars and fans to connect digitally, creating the
            opportunity to sell, purchase and exchange highly unique digital
            collectable memorabilia from the world's top athletes.
          </p>
          <div className="down-scroll">
            <a href="#greate" className="scroll-down">
              <img
                src={require("../assets/images/scroll.png")}
                alt="image"
                className="vert-move"
              ></img>
            </a>
          </div>
        </Container>
      </section>
      {/* <div className="our-team">
        <Container>
          <h2 className="text-center mb-4">
            Our <span>Founders</span>
          </h2>
          <Row className="justify-content-center">
            <Col lg={3} sm={6}>
              <div className="member-one">
                <div className="user-img">
                  <img
                    src={require("../assets/images/ahmed.png")}
                  ></img>
                </div>
                <div className="member-info">
                  <h5>Ahmad Elhawli</h5>
                  <p>Founder/CEO at Sportsfinda</p>
                </div>
                <a
                  className="linkedin-bt"
                  target="blank"
                  href="https://www.linkedin.com/in/aelhawli"
                >
                  <img
                    src={require("../assets/images/linkedin.png")}
                  ></img>
                </a>
              </div>
            </Col>
          </Row>
        </Container>

      </div> */}
      <section className="filda-bounding" id="greate">
        <Container>
          <div className="founding-div">
            <h4>
              The Sportsfinda founding team line up is made up of some of the
              most passionate and knowledgeable sports fans, with backgrounds in
              technology, sport management and business.
            </h4>
            <p>
              We decided to build our playing field utilising block-chain
              technology enabling athletes to launch highly unique digital
              collectables, showcasing significant sporting moments from their
              career.
            </p>
            <p>
              We are set to launch several unique collections of Digital Trading Cardss featuring
              some of the biggest icons in world sport, enabling users to
              discover, collect and sell block-chain based digital assets
              through smart contracts on the Sportsfinda marketplace.
            </p>
          </div>
        </Container>
      </section>

      <section className="greate-our-goal">
        <Container>
          <Row className="align-items-center direction-change">
            <Col md={6}>
              <div className="greate-left">
                <h2>
                  <span>GREAT</span>
                  <br></br>WORDS
                </h2>
                <p>
                  The great footballer Johan Cruyff once said “Quality without
                  results is pointless. Results without quality is boring”. As a
                  company, we are determined to establish ourselves as one of
                  the best Digital Trading Cards marketplaces in the world.{" "}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="greate-right text-center">
                <img
                  src={require("../assets/images/smile.png")}
                  alt="smile"
                  className="img-fluid"
                ></img>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="greate-right text-center">
                <img
                  src={require("../assets/images/cir.png")}
                  alt="smile"
                  className="img-fluid"
                ></img>
              </div>
            </Col>
            <Col md={6}>
              <div className="greate-left">
                <h2>
                  <span>OUR</span>
                  <br></br>GOAL
                </h2>
                <p>
                  We are continuously innovating and investing in our core
                  infrastructure with the goal of becoming the most accessible
                  platform for athlete Digital Trading Cards creators, buyers and sellers.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default About;
