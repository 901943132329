import React from "react";
import { Button } from "react-bootstrap";

const NotFound = () => {
    return (
        <section className="not-found-page-section">
            <div className="not-found-box">
                <img src={require("../../assets/images/no-data.gif")} alt="img" />
                <h1>No Data Found</h1>
                <p>The page you are looking for does not exist.</p>
                <Button className="btn back-to-home btn btn-unset cursor-pointer" onClick={() => window.location.href = "/"}>Back to home</Button>
            </div>
        </section>
    );
};

export default NotFound;
