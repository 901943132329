import React from "react";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap-v5";
import { useMoralis } from "react-moralis";
import { chainidETH, chainidPOLY, chainidBNB } from "../config/config";
import { useParams } from "react-router-dom";
import { nftService } from "../_services";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LoadingSpinerComponent from "./Loader";
import { Image, Shimmer } from "react-shimmer";
import { WEIToETH } from "../Component/common/CommonFunction";

const Collection_detail = () => {
  const { user } = useMoralis();
  const { collection_slug, collection_id } = useParams();
  const [collectionData, setcollectionData] = useState();
  const [showloader, setshowloader] = useState(true);
  const [usdprice, setusdprice] = useState();
  useEffect(() => {
    if(collection_id){
    mycollectiondetail(collection_id);
    }
  }, [collection_id]);


  const mycollectiondetail = async (nftId) => {
    setshowloader(true);
    await nftService
      .mycollectiondetail(nftId)
      .then((response) => {
        setcollectionData(response.data.data);
        setshowloader(false);
      })
      .catch((err) => {
      });
  };

  function showSingleDetails(nft_slug) {
    window.location.href = "/collection_details/" + nft_slug;
  }

  return (
    <>
      (
        <div className="bid_auction">
          <Container>
            {showloader ?
            (<>
            <Row>
                      <Col md={12} className="mb-3">
          <div className="card">
            <div className="shimmerBG media"></div>
            <div className="p-32">
              <div className="shimmerBG title-line"></div>
              <div className="shimmerBG title-line end"></div>
            </div>
          </div>
        </Col>
        </Row>
            </>)
            :
            (
          <div>
            <h5 className="collec-heading mb-3">
              <a href="/explore?ERC-1155=true">All Collections</a> {">"}{" "}
              <span>{collectionData?.collection_name}</span>
            </h5>
           
            <div className="details-cover">
              <Image
                src={collectionData?.collection_cover}
                alt="nft images"
                fallback={<Shimmer width="100%" height="350px" />}
              />
            </div>

            <Row className="align-items-center collections-name-box">
              <div
                className="collections-details-box"
                style={{ maxWidth: "unset" }}
              >
                <img src={collectionData?.collectionImage} alt="profile"></img>
                <div className="name-description">
                  <h5>{collectionData?.collection_name}</h5>
                  <p>{collectionData?.collection_desc}</p>
                </div>
              </div>
            </Row>
            </div>
            )}

            <div className="buy-sell-box">
              <div className="buy-sell-created">
                <h4>Buy and Sell</h4>
                <div className="rightside_border">
                  <div className="mb-2 create-flex">
                    <a href={"/user/" + collectionData?.user_slug}>
                      <img
                        src={
                          collectionData?.profile
                            ? collectionData?.profile
                            : require("../assets/images/user.png")
                        }
                        className="created_use_img"
                        alt=""
                      />
                    </a>
                    <div>
                      <CopyToClipboard
                        text={collectionData?.firstname+ " " + collectionData?.lastname}
                        style={{ cursor: "pointer" }}
                        title="copy"
                      >
                        <a href={"/user/" + collectionData?.user_slug}>
                          <span className="auto_break_next_line">
                            {collectionData?.firstname} {collectionData?.lastname}
                          </span>
                        </a>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>
              {collectionData?.nfts?.length > 0 ? (
                <Row className="mt-4 row mb-5">
                  {collectionData?.nfts?.map((item, index) => (
                    <Col lg={3} md={4} sm={6}>
                      <div
                        className={`slide-2 mb-4  list-market-new ${
                          item.nft_enabled == "NO" ? "" : "cursor-pointer"
                        }`}
                      >
                        <div className="new-tems-box">
                          {
                            item?.nft_availabile =="NO" && item?.nft_buy_count == 0 ?
                            <div className="d-flex user-timer-box">
                            <div className="timer-box"> <span>Not available for sale</span> </div>
                            </div>
                            :
                            item?.nft_availabile =="NO" && item?.nft_buy_count > 0 &&
                            <div className="d-flex user-timer-box">
                            <div className="timer-box"> <span>Sold out</span> </div>
                            </div>
                          }
                              <div className="overlay-upper" style={{backgroundImage : `url(${item.nft_image} )`}} ></div>

                          <div
                            className={`items-image-box-two user-timer-box`}
                            onClick={() => {
                              if (item.nft_enabled != "NO") {
                                showSingleDetails(item.nft_slug);
                              }
                            }}
                          >
                            {item.nft_media_type == "video" ? (
                              <video
                                width="100%"
                                height="100%"
                                autoPlay
                                muted
                                loop
                                controls={false}
                              >
                                <source src={item.nft_image} type="video/mp4" />
                                <source src={item.nft_image} type="video/ogg" />
                              </video>
                            ) : (
                              <img className="containe_img" src={item.nft_image}></img>
                            )}
                            {item.nft_enabled == "NO" ? (
                              <div className="timer-box">
                                <span>Disabled</span>
                              </div>
                            ) : null}
                          </div>
                          <div className="btm_details market-btm-details mt-3"    onClick={() => {
                              if (item.nft_enabled != "NO") {
                                showSingleDetails(item.nft_slug);
                              }
                            }}>
                            <h5 className="echo-two">{item.nft_name}</h5>
                            <h6 className="prices-nft">{item.nft_category}</h6>
                            <h6 className="items-price">
                             {"$"} {item.nft_price?.toFixed(2)}{" "}
                              
                              <span></span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              ) : !showloader ? (
                <div className="no-data text-center">
                  <img
                    src={require("../assets/images/empty.png")}
                    alt="img"
                    width="250"
                  />
                  <p>No Data Found</p>
                </div>
              )
            :
            (
              <>
              <Row>
                      <Col md={3} className="mb-3">
          <div className="card">
            <div className="shimmerBG media"></div>
            <div className="p-32">
              <div className="shimmerBG title-line"></div>
              <div className="shimmerBG title-line end"></div>
            </div>
          </div>
        </Col>
        <Col md={3} className="mb-3">
          <div className="card">
            <div className="shimmerBG media"></div>
            <div className="p-32">
              <div className="shimmerBG title-line"></div>
              <div className="shimmerBG title-line end"></div>
            </div>
          </div>
        </Col>
        <Col md={3} className="mb-3">
          <div className="card">
            <div className="shimmerBG media"></div>
            <div className="p-32">
              <div className="shimmerBG title-line"></div>
              <div className="shimmerBG title-line end"></div>
            </div>
          </div>
        </Col>
        <Col md={3} className="mb-3">
          <div className="card">
            <div className="shimmerBG media"></div>
            <div className="p-32">
              <div className="shimmerBG title-line"></div>
              <div className="shimmerBG title-line end"></div>
            </div>
          </div>
        </Col>
      </Row>
              </>
            )}
            </div>
          </Container>
        </div>
      {/* ) */}
    </>
  );
};

export default Collection_detail;
