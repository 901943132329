import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { userServices } from "../services/user.Services";
import swal from "sweetalert";
import LoadingSpinerComponent from "./Loader";

const BeachSoccerLeague = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Beach Soccer League";
    return () => {
      document.title = "Athlete & Artist NFT Marketplace";
    };
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmitInterest = (data) => {
    setIsLoading(true);
    userServices
      .registerInterest(data)
      .then((res) => {
        setIsLoading(false);
        swal("Success", res.data.message, "success");
        reset({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          postCode: "",
          city: "",
          teamName: "",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  return (
    <>
      <section className="soccor-banner text-center">
        <LoadingSpinerComponent promiseInProgress={isLoading} />
        <h2>
          Sports<span>finda</span>
        </h2>
        <h4 className="beach-text">BEACH</h4>
        <h5 className="soccor-text">SOCCER LEAGUE</h5>
      </section>

      <section className="beach-box text-center">
        <Container>
          <h4 className="beach-text">BEACH SOCCER</h4>
          <h5 className="soccor-text">is coming to Venice Beach!</h5>
          <Row className="mt-5 justify-content-center">
            <Col xxl={9} lg={12}>
              <Row>
                <Col md={4}>
                  <div className="beach-soccor-one">
                    <img
                      src={require("../assets/images/frame.png").default}
                      alt="frame"
                      className="frame-image"
                    ></img>
                    <img
                      src={
                        require("../assets/images/playing_soccor.png").default
                      }
                      className="input-image"
                      alt="frame"
                    ></img>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="beach-soccor-one">
                    <img
                      src={require("../assets/images/frame.png").default}
                      className="frame-image"
                      alt="frame"
                    ></img>
                    <img
                      src={
                        require("../assets/images/playing_soccor2.png").default
                      }
                      className="input-image"
                      alt="frame"
                    ></img>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="beach-soccor-one">
                    <img
                      src={require("../assets/images/frame.png").default}
                      className="frame-image"
                      alt="frame"
                    ></img>
                    <img
                      src={
                        require("../assets/images/playing_soccor3.png").default
                      }
                      className="input-image"
                      alt="frame"
                    ></img>
                  </div>
                </Col>
              </Row>
              <div className="upcoming-events">
                <h5 className="soccor-text">
                  Upcoming <span>Events</span>
                </h5>
                <div className="event-dates">
                  <h4>Qualifying Tournament</h4>
                  <h3>18 February 2023</h3>
                </div>
                <div className="event-dates event-dates-two">
                  <h4>Qualifying Tournament</h4>
                  <h3>4 March 2023</h3>
                </div>
                <div className="event-dates event-dates-two">
                  <h4>Championship Tournament</h4>
                  <h3>18 March 2023</h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="activities-box">
        <Container fluid>
          <div className="activities-heading">
            <h3>
              ACTIVIT<span>IES!</span>
            </h3>
            <h5>
              8 teams, 2 days
              <br />
              filled with beach <br />
              <span>soccer</span> and <br />
              activities!
            </h5>
          </div>
          <img
            src={require("../assets/images/gallery.png").default}
            alt="gallery"
          ></img>
        </Container>
      </section>

      <section className="prizes-box text-center">
        <img
          src={require("../assets/images/prizes.png").default}
          alt="prize"
        ></img>
        <h4 className="beach-text">Giveaways</h4>
        <h5 className="soccor-text">and prizes for the winning team!</h5>
      </section>

      <section className="soccor-contact-box">
        <Container>
          <div className="contact-form-two">
            <h3 className="mb-4">
              Register Your <span>interest</span>
              <br />
              in participating below!
            </h3>

            <Form onSubmit={handleSubmit(onSubmitInterest)}>
              <Row className="justify-content-center">
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      {...register("firstName", {
                        required: "Firstname is required",
                      })}
                    />
                    {errors.firstName && (
                      <p className="danger">{errors.firstName?.message}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      {...register("lastName", {
                        required: "Lastname is required",
                      })}
                    />
                    {errors.lastName && (
                      <p className="danger">{errors.lastName?.message}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="number"
                      placeholder="Phone Number"
                      {...register("phone", {
                        required: "Phone number is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only numbers are allowed",
                        },
                      })}
                    />
                    {errors.phone && (
                      <p className="danger">{errors.phone?.message}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      {...register("email", { required: "Email is required" })}
                    />
                    {errors.email && (
                      <p className="danger">{errors.email?.message}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="number"
                      placeholder="Post Code"
                      {...register("postCode", {
                        required: "Postal Code is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Only numbers are allowed",
                        },
                      })}
                    />
                    {errors.postCode && (
                      <p className="danger">{errors.postCode?.message}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="text"
                      placeholder="City"
                      {...register("city", { required: "City is required" })}
                    />
                    {errors.city && (
                      <p className="danger">{errors.city?.message}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="text"
                      placeholder="Team Name"
                      {...register("teamName", {
                        required: "Team name is required",
                      })}
                    />
                    {errors.teamName && (
                      <p className="danger">{errors.teamName?.message}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Button
                variant="primary"
                type="submit"
                className="forrm-submit-btn"
              >
                Submit
              </Button>
            </Form>
          </div>
        </Container>
      </section>
    </>
  );
};
export default BeachSoccerLeague;
