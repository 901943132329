import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { formValidation } from "../_services/validation.services";
import { userServices } from "../services/user.Services";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import LoadingSpinerComponent from "./Loader";
import Swal from "sweetalert2";
// import swal from "sweetalert";


const Login = () => {
  const navigate = useNavigate();
  const [showloader, setShowloader] = useState(false);
  const [loginFields, setLoginFields] = useState({
    email: "",
    password: "",
  });
  const token = localStorage.getItem("auth");
  const [errors, setErrors] = useState({});
  const search = useLocation().search;
  const slug = new URLSearchParams(search).get("slug");
  const pathName = new URLSearchParams(search).get("path");

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    let checkValidation = formValidation.loginvalidation(loginFields, {});
    if (checkValidation.formIsValid) {
      setShowloader(true);
      userServices
        .Userlogin({
          email: loginFields.email,
          password: loginFields.password,
        })
        .then((res) => {
          localStorage.setItem("auth", res.data.data.token);
          localStorage.setItem("currentUserId", res.data.data.id);
          localStorage.setItem("user", JSON.stringify(res.data.data));
          let url = localStorage.getItem("requrl");
          // if (res.data.success) {
          //   if (url?.length) {
          //     window.open(url, "_self");
          //     console.log("url?.length",url?.length,url)
          //     localStorage.removeItem("requrl");
          //   } else {
          //     window.open("/profile", "_self");
          //   }
          // }
          if(res?.data.success){
           { (!slug && !pathName) ? window.location.href = "/profile" : window.open(`${pathName}`,'_self')}
          }
          setShowloader(false);
        })
        .catch((error) => {
          if (error?.response?.data?.code == 4009) {
            Swal.fire({
              title: "Error",
              text: "Your email is not verified, please verify your email first.",
              imageUrl: require("../assets/images/thumbs-down.png").default,
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: "Custom image",
              showCancelButton: true,
              confirmButtonText: "Ok",
              cancelButtonText: "Resend link",
            }).then((e) => {
              if (e.isConfirmed) {
                navigate(pathName ? `/login?path=${pathName}`:"/login");
              } else {
                resendVerification();
              }
            });
          } else {
            Swal.fire({
              title: "Error",
              text:  error?.response?.data?.message,
              imageUrl: require("../assets/images/thumbs-down.png").default,
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: "Custom image",
            });
          }
          setShowloader(false);
        });
    } else {
      setErrors(checkValidation);
    }
  };
  const resendVerification = () => {
    setShowloader(true);
    userServices
      .ResendEmailVerificationApi({
        email: loginFields.email,
      })
      .then((res) => {
        if (res.data.success) {
          Swal.fire({
            title: "Success",
            text: "Email sent Successfully.",
            imageUrl: require("../assets/images/thumbs-up.png").default,
            imageWidth: 80,
            imageHeight: 100,
            imageAlt: "Custom image",
          });
        }
        setShowloader(false);
      })
      .catch((error) => {
        setShowloader(false);
        Swal.fire("Error", "Invalid request", "error");
      });
  };
  return (
    <>
      <LoadingSpinerComponent promiseInProgress={showloader} />
      <section className="about-page">
        <Container>
          <Row className="justify-content-center signin-form">
            <Col md={5}>
              <h3 className="text-center">Login</h3>
              <Form
                className="login-form-box"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                noValidate
              >
                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={loginFields.email}
                    onChange={(event) => {
                      setLoginFields((prevObj) => {
                        return {
                          ...prevObj,
                          email: event.target.value.replace(/\s+/g, ""),
                        };
                      });
                      setErrors((obj) => {
                        return { ...obj, email: "" };
                      });
                    }}
                    onKeyDown={(e) =>{
                      if(!e.target.value && e.code === "Space"){
                      e.preventDefault();
                    }
                  }}
                  />
                  <span
                    className="validation-error"
                    style={styles.validationError}
                  >
                    {errors.email ? errors.email : ""}
                  </span>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={loginFields.password}
                    onChange={(event) => {
                      setLoginFields((prevObj) => {
                        return {
                          ...prevObj,
                          password: event.target.value.replace(/\s+/g, ""),
                        };
                      });
                      setErrors((obj) => {
                        return { ...obj, password: "" };
                      });
                    }}
                  />
                  <span
                    className="validation-error"
                    style={styles.validationError}
                  >
                    {errors.password ? errors.password : ""}
                  </span>
                </Form.Group>
                <Link className="forgot" to="/forgetpassword">
                  <i class="fa fa-unlock-alt" aria-hidden="true"></i> Forgot
                  Password?
                </Link>
                <Button variant="unset" type="submit" className="submit-bt">
                  Sign In
                </Button>
                <p className="signup-bt">
                  Don't have an account? <Link to={pathName ? `/signup?path=${pathName}` : "/signup"}> Sign up</Link>
                </p>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Login;

const styles = {
  validationError: {
    fontSize: 12,
    color: "red",
  },
};
