import {
  Container,
  Collapse,
  Row,
  Col,
  Form,
  FormControl,
  ProgressBar,
  Tab,
  Nav,
  Tabs,
  Modal,
  ModalHeader,
  Button,
  InputGroup,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMoralis } from "react-moralis";
import React, { useState, useRef, useEffect } from "react";
import { ethers } from "ethers";
import axios from "axios";
import { nftAbi } from "./Abi";
import { nftService } from "../_services";
import moment from "moment";
import Countdown from "react-countdown";
import {
  winter_single_email,
  winter_single_tokenSymbol,
  winter_single_projectName,
  winter_single_brandImageURL,
  winter_single_contractAddress,
  winter_single_mintFunctionParams,
  winter_single_mintFunction,
  winter_single_sandbox,
  winter_single_maxNumberOfMints,
  winter_single_ercType,
  winter_single_isActive,
  winter_single_requireAddress,
  winter_single_contractPriceFunctionParams,
  winter_single_contractABI,
  withPaperSingleContractId,
} from "../config/config";
import {
  nftaddressETH,
  tokenstandard,
  contracturl,
  contracturlETH,
  contracturlPOLY,
  chainidETH,
  chainidBNB,
  chainidPOLY,
  ipfsurl,
  fbappid,
  httpprovider,
  platform_fee,
  nftaddressPOLY,
  appName,
  appUrl,
  httpproviderETH,
  sportsfindaMetamaskUrl,
  winterCheckoutProduction,
} from "../config/config";
import LoadingSpinerComponent from "./Loader";
import swal from "sweetalert";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from "react-share";

import { CopyToClipboard } from "react-copy-to-clipboard";
import defaultprofile from "../assets/images/main_avatar.png";
import Swal from "sweetalert2";
import Loader from "react-js-loader";
import LazyLoad from "react-lazy-load";
import { Image, Shimmer } from "react-shimmer";
import { userServices } from "../services/user.Services";
import Web3 from "web3";
import { getAccounts, getEstimateGasFee, WEIToETH } from "../Component/common/CommonFunction";

const SingleNftDetail = () => {
  const [putonSale, setPutOnsale] = useState(false);
  const [isEnablingWeb3, setIsEnablingWeb3] = useState(false);
  const [showCrossmintButton, setShowCrossmintButton] = useState(false);
  const search = useLocation().search;
  const slug = new URLSearchParams(search).get("slug");
  const pathName = new URLSearchParams(search).get("path");
  const navigate = useNavigate();
  const { user, Moralis, userError, isAuthenticated } = useMoralis();
  const { userSlug } = useParams();
  const [currentNftAddress, setcurrentNftAddress] = useState();
  const [currentContractUrl, setcurrentContractUrl] = useState();
  const [openProperty, setOpenProprty] = useState(false);
  const [openLevel, setOpenLevel] = useState(false);
  const [openStats, setOpenStats] = useState(false);
  const [nftlevel, setnftLevel] = useState();
  const [nftstat, setnftstat] = useState();
  const [nftProperties, setnftProperties] = useState();
  const [marketItems, setMarketItems] = React.useState();
  const [currentUser, setCurrentUser] = useState();
  const [usdprice, setusdprice] = useState();
  const [bidprice, setbidprice] = useState();
  const [bidpriceErr, setbidpriceErr] = useState("");
  const [priceErr, setpriceErr] = useState("");
  const [minpriceErr, setminpriceErr] = useState("");
  const [messageLoader, setmessageLoader] = React.useState("loading...");
  const [showloader, setshowloader] = useState(true);
  const [showloader1, setshowloader1] = useState(false);
  const [share_url, setshare_url] = useState(null);
  const [copy, setCopy] = useState(false);
  const [showshare, setShowshare] = useState(false);
  const [expired, setexpired] = useState(false);
  const [showBidModal, setShowBidModal] = useState(false);
  const [bidAccepted, setShowBidAccepted] = useState(false);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setmessage] = useState("");
  let title = `Hey! Checkout this Trading Cards detail on ${appName}`;
  const [StartTime, setStartTime] = useState(
    new Date().setMinutes(new Date().getMinutes() + 10)
  );
  const [EndTime, setEndTime] = useState(
    new Date(new Date().setHours(new Date().getHours() + 2)).setMinutes(
      new Date().getMinutes() + 10
    )
  );
  const [opendetails, setOpendetails] = useState(true);
  const [price, setprice] = useState("");
  const [MinBid, setMinbid] = useState("");
  const [auction_check, set_auction_check] = useState(true);
  const [ReservePrice, setReservePrice] = useState(0);
  const ContractRef = useRef();
  const UserRef = useRef();
  const NftChain = useRef();
  const handleCloseshare = () => setShowshare(false);
  const handleClose = () => (window.location.href = "/");
  const [symbolsArr] = useState(["e", "E", "+", "-"]);
  const [profile_image, setprofile_image] = useState(defaultprofile);
  const [nftItem, setnftItem] = useState();
  const [nftId, setNftId] = useState();
  const [showstep, setShowstep] = useState(false);
  const [onSale, setonSale] = useState(false);
  const [approved, setapproved] = useState(false);
  const [showMintModal, setShowMintModal] = useState(false);
  const [auction, setAuction] = useState(false);
  const [showWinter, setShowWinter] = useState(false);
  const [useWinterProjectId, setUseWinterProjectId] = useState("");
  const [isCheckoutSuccess, setIsCheckoutSuccess] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [nftParamsId, setNftParamsId] = useState("");
  const [showMetaModal, setshowMetaModal] = useState(false);
  const [showPaperCheckout, setShowPaperCheckout] = useState(false);
  let location = window.location.pathname;
  let adminWalletAddress = useRef();
  let clientSecret = useRef();
  const locationPath = useLocation();

  useEffect(() => {
    const handlePageLoad = () => {
      if (userSlug) {
        getmynftdetail(userSlug)
      }
    };
    handlePageLoad();
  }, [userSlug, document.getElementById("back-reload"), locationPath])

  useEffect(() => {
    setshare_url(`${appUrl}` + "/trading_cards/" + userSlug);
  }, []);

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(localStorage.getItem("auth") || false);
  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setexpired(true);
      return <span className="expired-nft expired-area">Not available for sale</span>;
    } else {
      return (
        <span>
          {days + "d"} {hours + "h"}:{minutes + "m"}:{seconds + "s"}
        </span>
      );
    }
  };

  useEffect(() => {
    if (copy) {
      const interval = setInterval(() => {
        setCopy(false);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [copy]);



  const getmynftdetail = async (userSlug) => {
    await nftService
      .mynftdetail(userSlug)
      .then(async (response) => {
        if (response.data.data?.length == 0) {

          swal(
            "Warning",
            "This Trading Cards is no longer available.",
            "warning"
          ).then(() => {
            navigate("/profile");
          });
        } else if (response.data.data.nft_enabled == "NO") {
          swal(
            "Warning",
            "This Trading Cards is no longer available.",
            "warning"
          ).then(() => {
            navigate("/profile");
          });
        } else {
          setnftItem(response.data.data);
          setNftId(response.data.data?.id);
        }
        setTimeout(() => {
          setshowloader(false);
        }, 500);
      })
      .catch((err) => { });
  };

  const checkNft = async (nftId) => {
    await nftService
      .mynftdetail(nftId, NftChain.current)
      .then(async (response) => {
        if (response.data.data?.length == 0) {
          swal(
            "Warning",
            "This Trading Cards is no longer available.",
            "warning"
          ).then(() => {
            navigate("/explore");
          });
          return false;
        } else if (response.data.data.nft_enabled == "YES") {
          return true;
        } else {
          swal(
            "Warning",
            "This Digital Trading Cards is is no longer available.",
            "warning"
          ).then(() => {
            navigate("/explore");
          });
          return false;
        }
      });
  };

  // async function ApproveForSale(id, owner) {
  //   try {
  //     setShowstep(true);

  //     const chainIdCurrent = await Moralis.getChainId();

  //     if (blockchain == "BNB" && chainIdCurrent != chainidBNB) {
  //       await Moralis.switchNetwork(chainidBNB);
  //       ApproveForSale(id, owner);
  //       return;
  //     } else if (blockchain == "ETH" && chainIdCurrent != chainidETH) {
  //       await Moralis.switchNetwork(chainidETH);
  //       ApproveForSale(id, owner);
  //       return;
  //     } else if (blockchain == "MATIC" && chainIdCurrent != chainidPOLY) {
  //       try {
  //         await Moralis.switchNetwork(chainidPOLY);
  //         ApproveForSale(id, owner);
  //         return;
  //       } catch (err) {
  //         if (err.code === 4902) {
  //           Swal.fire({
  //             title: "Error",
  //             text: "Please add Polygon to your Metamask network",
  //             imageUrl: require("../../src/assets/images/thumbs-down.png")
  //               .default,
  //             imageWidth: 80,
  //             imageHeight: 100,
  //             imageAlt: "Custom image",
  //             animation: false,
  //           });
  //         } else if (err.code === -32002) {
  //           Swal.fire({
  //             title: "Error",
  //             text: "Switch network request already pending in your Metamask",
  //             imageUrl: require("../../src/assets/images/thumbs-down.png"),
  //             imageWidth: 80,
  //             imageHeight: 100,
  //             imageAlt: "Custom image",
  //             animation: false,
  //           });
  //         } else if (String(err.message).startsWith("Unrecognized chain")) {
  //           Swal.fire({
  //             title: "Error",
  //             text: "Please add Polygon to your Metamask network",
  //             imageUrl: require("../../src/assets/images/thumbs-down.png"),
  //             imageWidth: 80,
  //             imageHeight: 100,
  //             imageAlt: "Custom image",
  //             animation: false,
  //           });
  //         } else {
  //           Swal.fire("Error", err.message, "error");
  //         }
  //         return;
  //       }
  //     } else {

  //       let propsParams = { 
  //         chain: blockchain, 
  //         params: [currentNftAddress, id], 
  //         nftType: "S", 
  //         methodName: "approve", 
  //         sendParams: { from: owner }
  //       }
  //       let gasList = await getEstimateGasFee(propsParams);
  //       console.log("gasList--------------->",gasList);

  //       if (isEnablingWeb3) return;

  //       setIsEnablingWeb3(true);
  //       const web3 = await Moralis.Web3.enableWeb3({
  //           provider: localStorage.getItem("cuurent_provider"),
  //       });
  //       setIsEnablingWeb3(false);

  //       const tokenContractTmp = await new web3.eth.Contract(
  //         nftAbi,
  //         currentNftAddress
  //       );
  //       ContractRef.current = tokenContractTmp;

  //       let apps = await ContractRef.current.methods
  //         .approve(currentNftAddress, id)
  //         .send({ from: owner,gas: gasList[1], gasPrice: gasList[0]  })
  //         .on("transactionHash", function (hash) {})
  //         .on("receipt", function (tx) {
  //           setapproved(true);
  //           getdetail();
  //           setTimeout(() => {
  //             setShowstep(false);
  //             window.location.reload();
  //           }, 2000);
  //         })
  //         .on("confirmation", function (conf) {})
  //         .on("error", async function (error) {
  //           // setshowloader(false);
  //           setShowstep(false);
  //           // swal("Warning", error.message, "warning");
  //           swal("Warning", error?.code =="-32603"? "Gas fee low, try increasing for Metamask acceptance.": error.message, "warning");
  //         });
  //     }
  //   } catch (error) {
  //     setShowstep(false);
  //     // swal("Warning", error.message, "warning");
  //     swal("Warning", error?.code =="-32603"? "Gas fee low, try increasing for Metamask acceptance.": error.message, "warning");
  //   }
  // }



  // const getdetail = async () => {
  //   await nftService
  //     .getNftIdBySlug({
  //       nft_slug: token_id,
  //       type: "SINGLE",
  //       chain: blockchain == "ETH" ? chainidETH : chainidPOLY,
  //     })
  //     .then((res) => {
  //       setNftParamsId(res.data.data.nft_id);
  //       getmynftdetail(res.data.data.nft_id);
  //     });
  // };

  // async function acceptbid(bidId) {
  //   try {
  //     if (bidId < 1) {
  //       alert("something went Wrong");
  //       window.location.reload();
  //       return;
  //     }
  //     const chainIdCurrent = await Moralis.getChainId();
  //     if (blockchain == "BNB" && chainIdCurrent != chainidBNB) {
  //       await Moralis.switchNetwork(chainidBNB);
  //       acceptbid(bidId);
  //       return;
  //     } else if (blockchain == "ETH" && chainIdCurrent != chainidETH) {
  //       await Moralis.switchNetwork(chainidETH);
  //       acceptbid(bidId);
  //       return;
  //     } else if (blockchain == "MATIC" && chainIdCurrent != chainidPOLY) {
  //       try {
  //         await Moralis.switchNetwork(chainidPOLY);
  //         acceptbid(bidId);
  //         return;
  //       } catch (err) {
  //         if (err.code === 4902) {
  //           Swal.fire({
  //             title: "Error",
  //             text: "Please add Polygon to your Metamask network",
  //             imageUrl: require("../../src/assets/images/thumbs-down.png")
  //               .default,
  //             imageWidth: 80,
  //             imageHeight: 100,
  //             imageAlt: "Custom image",
  //             animation: false,
  //           });
  //         } else if (err.code === -32002) {
  //           Swal.fire({
  //             title: "Error",
  //             text: "Switch network request already pending in your Metamask",
  //             imageUrl: require("../../src/assets/images/thumbs-down.png")
  //               .default,
  //             imageWidth: 80,
  //             imageHeight: 100,
  //             imageAlt: "Custom image",
  //             animation: false,
  //           });
  //         } else if (String(err.message).startsWith("Unrecognized chain")) {
  //           Swal.fire({
  //             title: "Error",
  //             text: "Please add Polygon to your Metamask network",
  //             imageUrl: require("../../src/assets/images/thumbs-down.png")
  //               .default,
  //             imageWidth: 80,
  //             imageHeight: 100,
  //             imageAlt: "Custom image",
  //             animation: false,
  //           });
  //         } else {
  //           console.log("err_________",err)
  //           Swal.fire("Error", err.message, "error");
  //         }
  //         return;
  //       }
  //     }
  //     var user = await Moralis.User.current();
  //     const userAddress = user.get("ethAddress");

  //     let propsParams = { 
  //       chain: blockchain, 
  //       params: [currentNftAddress, bidId], 
  //       nftType: "S", 
  //       methodName: "bidAccept", 
  //       sendParams: { from: userAddress }
  //     }
  //     console.log("propsParams___________",propsParams)
  //     let gasList = await getEstimateGasFee(propsParams);
  //     console.log("gasList--------------->",gasList);
  //     if (isEnablingWeb3) return;
  //     setIsEnablingWeb3(true);
  //             const web3 = await Moralis.Web3.enableWeb3({
  //                 provider: localStorage.getItem("cuurent_provider"),
  //             });
  //             setIsEnablingWeb3(false);
  //     // var user = await Moralis.User.current();
  //     // const userAddress = user.get("ethAddress");
  //     ContractRef.current = await new web3.eth.Contract(
  //       nftAbi,
  //       currentNftAddress
  //     );
  //     console.log("Before contract call")
  //     await ContractRef.current.methods
  //       .bidAccept(currentNftAddress, bidId)
  //       .send({ from: userAddress,gas: gasList[1], gasPrice: gasList[0] })
  //       .on("transactionHash", function (hash) {
  //         setmessageLoader("Waiting for receipt.");
  //       })
  //       .on("receipt", function (tx) {
  //         setmessageLoader("waiting for confirmation!!");
  //       })
  //       .on("confirmation", function (conf) {
  //         setTimeout(() => {
  //           setmessageLoader("");
  //           setshowloader(false);
  //           setShowBidAccepted(true);
  //         }, 2000);
  //       })
  //       .on("error", async function (error) {
  //         setshowloader(false);
  //         swal("Warning", error?.code =="-32603"? "Gas fee low, try increasing for Metamask acceptance.": error.message, "warning");
  //       });
  //   } catch (error) {
  //     setshowloader(false);
  //     swal("Warning", error?.code =="-32603"? "Gas fee low, try increasing for Metamask acceptance.": error.message, "warning");
  //   }
  // }
  // async function bidAccept() {
  //   try {
  //     setshowloader(true);
  //     if (isEnablingWeb3) return;
  //     setIsEnablingWeb3(true);
  //             const web3 = await Moralis.Web3.enableWeb3({
  //                 provider: localStorage.getItem("cuurent_provider"),
  //             });
  //             setIsEnablingWeb3(false);

  //     const chainIdCurrent = await Moralis.getChainId();

  //     if (blockchain == "BNB" && chainIdCurrent != chainidBNB) {
  //       await Moralis.switchNetwork(chainidBNB);
  //       bidAccept();
  //       return;
  //     } else if (blockchain == "ETH" && chainIdCurrent != chainidETH) {
  //       await Moralis.switchNetwork(chainidETH);
  //       bidAccept();
  //       return;
  //     } else if (blockchain == "MATIC" && chainIdCurrent != chainidPOLY) {
  //       try {
  //         await Moralis.switchNetwork(chainidPOLY);
  //         bidAccept();
  //         return;
  //       } catch (err) {
  //         if (err.code === 4902) {
  //           Swal.fire({
  //             title: "Error",
  //             text: "Please add Polygon to your Metamask network",
  //             imageUrl: require("../../src/assets/images/thumbs-down.png")
  //               .default,
  //             imageWidth: 80,
  //             imageHeight: 100,
  //             imageAlt: "Custom image",
  //             animation: false,
  //           });
  //         } else if (err.code === -32002) {
  //           Swal.fire({
  //             title: "Error",
  //             text: "Switch network request already pending in your Metamask",
  //             imageUrl: require("../../src/assets/images/thumbs-down.png")
  //               .default,
  //             imageWidth: 80,
  //             imageHeight: 100,
  //             imageAlt: "Custom image",
  //             animation: false,
  //           });
  //         } else if (String(err.message).startsWith("Unrecognized chain")) {
  //           Swal.fire({
  //             title: "Error",
  //             text: "Please add Polygon to your Metamask network",
  //             imageUrl: require("../../src/assets/images/thumbs-down.png")
  //               .default,
  //             imageWidth: 80,
  //             imageHeight: 100,
  //             imageAlt: "Custom image",
  //             animation: false,
  //           });
  //         } else {
  //           Swal.fire("Error", err.message, "error");
  //         }
  //         return;
  //       }
  //     } else {
  //       let getAuctionDetail;
  //       await nftService.getAuctionDetail(nftId).then((res) => {
  //         getAuctionDetail = res.data.data;
  //       });
  //       acceptbid(getAuctionDetail.nft_log_id);
  //     }
  //   } catch (error) {
  //     setshowloader(false);
  //     swal("Warning", error.message, "warning");
  //   }
  // }

  // const bidnow = async () => {
  //   if (nftId) {
  //     setshowloader(true);
  //     nftService
  //       .getNftLatestBid(nftId)
  //       .then(async (res) => {
  //         if (res) {
  //           if (
  //             Number(WEIToETH({ value: nftItem.nft_price })) >
  //               Number(WEIToETH({ value: res.data.data?.price })) &&
  //             res.data.data?.price != undefined
  //           ) {
  //             getmynftdetail(nftParamsId);
  //             setbidpriceErr(
  //               "Please enter pice greater than " +
  //                 WEIToETH({ value: res.data.data?.price })
  //             );
  //             setshowloader(false);
  //             return;
  //           } else {
  //             try {
  //               if (isEnablingWeb3) return;
  //               setIsEnablingWeb3(true);
  //             const web3 = await Moralis.Web3.enableWeb3({
  //                 provider: localStorage.getItem("cuurent_provider"),
  //             });
  //             setIsEnablingWeb3(false);
  //               const chainIdCurrent = await Moralis.getChainId();
  //               const tokenContractTmp = await new web3.eth.Contract(
  //                 nftAbi,
  //                 currentNftAddress
  //               );
  //               ContractRef.current = tokenContractTmp;
  //               console.log("bidprice_____",bidprice, typeof(bidprice),Number(WEIToETH({ value: nftItem.nft_price })))

  //               if (!bidprice) {
  //                 setshowloader(false);
  //                 setbidpriceErr("Please enter bid price");
  //                 return;
  //               }
  //               if (Number(bidprice) < Number(WEIToETH({ value: nftItem.nft_price }))) {
  //                 setshowloader(false);
  //                 setbidpriceErr(
  //                   "Please enter price grater than " +
  //                     WEIToETH({ value: nftItem.nft_price })
  //                 );
  //                 return;
  //               }
  //               setshowloader(true);
  //               var user = await Moralis.User.current();
  //               if (!user) {
  //                 console.log("bidcase try after current user", user)
  //                 setshowloader(false);
  //                 swal("Warning", "Please connect metamask first.", "warning");
  //                 return false;
  //               }
  //               const userAddress = user.get("ethAddress");
  //               setshowloader(true);
  //               if (!userAddress) {
  //                 setshowloader(false);
  //                 setmessage("Please connect metamask first.");
  //                 return false;
  //               }

  //               setmessageLoader("Loading...");
  //               setshowloader(true);
  //               var user = await Moralis.User.current();
  //               const userAddressWallet = user.get("ethAddress");
  //               if (!userAddressWallet) {
  //                 setshowloader(false);
  //                 setmessage("Please connect metamask first.");
  //                 return false;
  //               }
  //               if (bidpriceErr == "" && bidprice) {
  //                 setshowloader(true);
  //                 if (blockchain == "BNB" && chainIdCurrent != chainidBNB) {
  //                   await Moralis.switchNetwork(chainidBNB);
  //                   bidnow();
  //                   return;
  //                 } else if (
  //                   blockchain == "ETH" &&
  //                   chainIdCurrent != chainidETH
  //                 ) {
  //                   await Moralis.switchNetwork(chainidETH);
  //                   bidnow();
  //                   return;
  //                 } else if (
  //                   blockchain == "MATIC" &&
  //                   chainIdCurrent != chainidPOLY
  //                 ) {
  //                   try {
  //                     await Moralis.switchNetwork(chainidPOLY);
  //                     bidnow();
  //                     return;
  //                   } catch (err) {
  //                     if (err.code === 4902) {
  //                       Swal.fire({
  //                         title: "Error",
  //                         text: "Please add Polygon to your Metamask network",
  //                         imageUrl:
  //                           require("../../src/assets/images/thumbs-down.png")
  //                             .default,
  //                         imageWidth: 80,
  //                         imageHeight: 100,
  //                         imageAlt: "Custom image",
  //                         animation: false,
  //                       });
  //                     } else if (err.code === -32002) {
  //                       Swal.fire({
  //                         title: "Error",
  //                         text: "Switch network request already pending in your Metamask",
  //                         imageUrl:
  //                           require("../../src/assets/images/thumbs-down.png")
  //                             .default,
  //                         imageWidth: 80,
  //                         imageHeight: 100,
  //                         imageAlt: "Custom image",
  //                         animation: false,
  //                       });
  //                     } else if (
  //                       String(err.message).startsWith("Unrecognized chain")
  //                     ) {
  //                       Swal.fire({
  //                         title: "Error",
  //                         text: "Please add Polygon to your Metamask network",
  //                         imageUrl:
  //                           require("../../src/assets/images/thumbs-down.png")
  //                             .default,
  //                         imageWidth: 80,
  //                         imageHeight: 100,
  //                         imageAlt: "Custom image",
  //                         animation: false,
  //                       });
  //                     } else {
  //                       Swal.fire("Error", err.message, "error");
  //                     }
  //                     return;
  //                   }
  //                 }
  //                 let price_eth = ethers.utils.parseUnits(bidprice, "ether");
  //                 let propsParams = { 
  //                   chain: blockchain, 
  //                   params: [currentNftAddress, nftParamsId], 
  //                   nftType: "S", 
  //                   methodName: "bid", 
  //                   sendParams: { from: userAddress, value: price_eth }
  //                 }
  //                 let gasList = await getEstimateGasFee(propsParams);
  //                 console.log("gasList--------------->",gasList);



  //                 setshowloader(true);

  //                 await ContractRef.current.methods
  //                   .bid(currentNftAddress, nftParamsId)
  //                   .send({ from: userAddress, value: price_eth,gas: gasList[1], gasPrice: gasList[0] })
  //                   .on("transactionHash", function (hash) {
  //                     console.log("hash_________", hash);
  //                     setmessageLoader("Waiting for receipt.");
  //                   })
  //                   .on("receipt", function (tx) {
  //                     setmessageLoader("waiting for confirmation!!");
  //                     getdetail();
  //                     setTimeout(() => {
  //                       setshowloader(false);
  //                       setShowBidModal(true);
  //                     }, 2000);
  //                   })
  //                   .on("confirmation", function (conf) {})
  //                   .on("error", async function (error) {
  //                     console.log("error__________", error);
  //                     setshowloader(false);
  //                     swal("Warning", error?.code =="-32603"? "Gas fee low, try increasing for Metamask acceptance.": error.message, "warning");
  //                   });
  //               }
  //             } catch (error) {
  //               setshowloader(false);
  //               console.log("error",error)
  //               swal("Warning", error?.code =="-32603"? "Gas fee low, try increasing for Metamask acceptance.": error.message, "warning");
  //             }
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error--------->", error);
  //         setshowloader(false);
  //       });
  //   }
  // };

  const ipfs_to_url = (ipfs_link) => {
    return ipfs_link.replace("https://ipfs.moralis.io:2053", ipfsurl);
  };

  async function checkMetamaskConnected() {
    var user = await Moralis?.User?.current();
    const userAddress = user?.get("ethAddress");
    if (userAddress) {
      return true;
    } else {
      // await loginwithmetamask();
      return false;
    }
  }
















  // const handelchangebid = async (e) => {
  //   // getdetail();
  //   if (Number(WEIToETH({ value: nftItem.nft_price })) < e) {
  //     const chainId = Moralis.getChainId();

  //     setbidprice(e);
  //     setbidpriceErr("");
  //   } else {
  //     setbidpriceErr(
  //       "Bid price should be greater than " +
  //         WEIToETH({ value: nftItem.nft_price }) +
  //         " " +
  //         blockchain
  //     );
  //   }
  // };

  // const putOnAuction = async () => {
  //   try {
  //     if (!MinBid) {
  //       setminpriceErr("Please enter min bid price.");
  //       return;
  //     } else if (MinBid == 0) {
  //       setminpriceErr("Min bid price can't be zero.");
  //       return;
  //     } else {
  //       setminpriceErr("");
  //     }
  //     setAuction(true);
  //     setShowstep(true);
  //     let isConnected = await checkMetamaskConnected();
  //     if (!isConnected) {
  //       return;
  //     }
  //     if (isEnablingWeb3) return;
  //     setIsEnablingWeb3(true);
  //     const web3 = await Moralis.Web3.enableWeb3({
  //         provider: localStorage.getItem("cuurent_provider"),
  //     });
  //     setIsEnablingWeb3(false);
  //     const chainIdCurrent = await Moralis.getChainId();

  //     if (blockchain == "BNB" && chainIdCurrent != chainidBNB) {
  //       await Moralis.switchNetwork(chainidBNB);
  //       putOnAuction();
  //       return;
  //     } else if (blockchain == "ETH" && chainIdCurrent != chainidETH) {
  //       await Moralis.switchNetwork(chainidETH);
  //       putOnAuction();
  //       return;
  //     } else if (blockchain == "MATIC" && chainIdCurrent != chainidPOLY) {
  //       try {
  //         await Moralis.switchNetwork(chainidPOLY);
  //         putOnAuction();
  //         return;
  //       } catch (err) {
  //         if (err.code === 4902) {
  //           Swal.fire({
  //             title: "Error",
  //             text: "Please add Polygon to your Metamask network",
  //             imageUrl: require("../../src/assets/images/thumbs-down.png")
  //               .default,
  //             imageWidth: 80,
  //             imageHeight: 100,
  //             imageAlt: "Custom image",
  //             animation: false,
  //           });
  //         } else if (err.code === -32002) {
  //           Swal.fire({
  //             title: "Error",
  //             text: "Switch network request already pending in your Metamask",
  //             imageUrl: require("../../src/assets/images/thumbs-down.png")
  //               .default,
  //             imageWidth: 80,
  //             imageHeight: 100,
  //             imageAlt: "Custom image",
  //             animation: false,
  //           });
  //         } else if (String(err.message).startsWith("Unrecognized chain")) {
  //           Swal.fire({
  //             title: "Error",
  //             text: "Please add Polygon to your Metamask network",
  //             imageUrl: require("../../src/assets/images/thumbs-down.png")
  //               .default,
  //             imageWidth: 80,
  //             imageHeight: 100,
  //             imageAlt: "Custom image",
  //             animation: false,
  //           });
  //         } else {
  //           Swal.fire("Error", err.message, "error");
  //         }
  //         return;
  //       }
  //     } else {
  //       const tokenContractTmp = await new web3.eth.Contract(
  //         nftAbi,
  //         currentNftAddress
  //       );
  //       ContractRef.current = tokenContractTmp;
  //       let platform_fee_eth = ethers.utils.parseUnits(
  //         String(platform_fee),
  //         "ether"
  //       );
  //       let price = MinBid;
  //       let reservedPrice = ReservePrice;

  //       var user = await Moralis.User.current();
  //       const userAddress = user.get("ethAddress");

  //       let price_eth = ethers.utils.parseUnits(price, "ether");
  //       let reservedPrice_eth = ethers.utils.parseUnits("0.0", "ether");

  //       let start_date_send = StartTime;
  //       let start_date_sendTimestamp = Math.floor(start_date_send / 1000);
  //       let end_date_send = EndTime;
  //       let end_date_sendTimestamp = Math.floor(end_date_send / 1000);



  //       let propsParams = { 
  //         chain: blockchain, 
  //         params: [ currentNftAddress,
  //           nftParamsId,
  //           price_eth,
  //           reservedPrice_eth,
  //           start_date_sendTimestamp,
  //           end_date_sendTimestamp], 
  //         nftType: "S", 
  //         methodName: "putOnAuction", 
  //         sendParams: { from: userAddress }
  //       }
  //       let gasList = await getEstimateGasFee(propsParams);
  //       console.log("gasList--------------->",gasList);



  //       await ContractRef.current.methods
  //         .putOnAuction(
  //           currentNftAddress,
  //           nftParamsId,
  //           price_eth,
  //           reservedPrice_eth,
  //           start_date_sendTimestamp,
  //           end_date_sendTimestamp
  //         )
  //         .send({ from: userAddress,gas: gasList[1], gasPrice: gasList[0]})
  //         .on("transactionHash", function (hash) {
  //           console.log("hash_________", hash);
  //           setmessageLoader("Waiting for receipt.");
  //         })
  //         .on("receipt", function (receipt) {
  //           setonSale(true);
  //           // updateWinterProject(useWinterProjectId);
  //           // ApproveForSale(
  //           //   receipt.events.PutOnAuction.returnValues.tokenId,
  //           //   receipt.events.PutOnAuction.returnValues.seller
  //           // );
  //         })
  //         .on("conmation", function (conf) {})
  //         .on("error", async function (error) {
  //           console.log("error_____", error);
  //           setShowstep(false);
  //           // swal("Warning", error.message, "warning");
  //           swal("Warning", error?.code =="-32603"? "Gas fee low, try increasing for Metamask acceptance.": error.message, "warning");
  //         });
  //     }
  //   } catch (error) {
  //     setShowstep(false);
  //     swal("Warning", error.message, "warning");
  //   }
  // };

  const successCheckout = (event) => {
    setIsCheckoutSuccess(true);
  };

  const onCloseWinterCheckout = () => {
    setShowWinter(false);
    if (isCheckoutSuccess) {
      window.location.reload();
    }
  };



  const handleBuyWithCreditCard = async () => {
    setShowCrossmintButton(true);
  };



  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };



  let createdItems = [];

  if (marketItems) {
    createdItems = marketItems.map(function (item, index) {
      let itemdata = item;
      let tt = itemdata?.attri?.attribute[1]?.value;
      if (itemdata && itemdata.attri) {
        return (
          <Col md={3} lg={2} sm={4} key={index}>
            <div className="tab-pane">
              <div
                className="slide-2 mb-3"
                style={{ cursor: "pointer" }}
              >
                {tt == "video" ? (
                  <video
                    width="100%"
                    height="380"
                    autoPlay
                    muted
                    loop
                    playsInline
                    preload='none'
                    controls={false}
                    id="individual-video-player"
                  >
                    <source src={itemdata.attri.image} type="video/mp4" />
                    <source src={itemdata.attri.image} type="video/ogg" />
                  </video>
                ) : (
                  <img src={itemdata.attri.image} alt="" className="mainimg" />
                )}
                <div className="btm_details">
                  <h5 className="echo-two">{item.attri.name}</h5>
                  <h6 className="bid-text-two">
                    Bid{" "}
                    <span>
                      {"$"}
                      {Number(nftItem.nft_price
                      ).toFixed(2)}{" "}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </Col>
        );
      }
    });
  }

  /* buy nft */
  const [selectedNftOwner, setSelectedNftOwner] = useState(null); // State for selected nft_owner
  const selectedNftOwnerRef = useRef(null);

  async function BuyNft(nftItem) {
    let propsParams = {
      price: nftItem?.nft_price,
      nft_id: nftItem?.id,
      quantity: 1,
      seller: nftItem?.nftowners[0]?.nft_owner
    };
    setshowloader(true);
    nftService.buyNft(propsParams)
      .then((response) => {
        if (response?.status == 200) {
          setshowloader(false);
          window.location.replace(response?.data?.url);
        }
      })
      .catch((err) => {
        setshowloader(false);
      });
  }

  /* put on Sale */
  async function PutOnSale(nftItem) {
    console.log("item sell::", nftItem)

    let propsParams = {
      nftId: nftItem?.id,
      nft_type: nftItem?.nft_type,
      nft_price: price,
      seller: nftItem?.nftowners[0]?.nft_owner

    };
    console.log("put on sale from params:::::", propsParams)
    setshowloader(true);
    nftService.put_on_Sale(propsParams)
      .then((response) => {
        if (response?.status == 200) {
          setshowloader(false);
          setPutOnsale(false)
          console.log("response", response);
          Swal.fire("Success", "Trading card put on sale successfully.", "success")
          getmynftdetail(userSlug)
        }
      })
      .catch((err) => {
        setshowloader(false);
      });
  }

  /* remove from sale */
  async function RemoveFromSale(nftItem) {
    let propsParams = {
      nftId: nftItem?.id,
      nft_type: nftItem?.nft_type,
    };
    console.log("put on sale from params:::::", propsParams)
    setshowloader(true);
    nftService.removeFromSale(propsParams)
      .then((response) => {
        if (response?.status == 200) {
          setshowloader(false);
          console.log("response", response);
          Swal.fire("Success", "Trading cards Remove from sale successfully.", "success")
          getmynftdetail(userSlug)
        }
      })
      .catch((err) => {
        setshowloader(false);
      });
  }

  const handleFollowToggle = async () => {
    try {
      // if (userDetail?.isFollowing) {
      const params = {
        userId: localStorage.getItem("currentUserId"),
        follower_id: nftItem?.nft_creator
      };
      // console.log("params", params);
      await userServices.followUnfollow(params).then((response) => {
        if (response.status === 200) {
          console.log("response", response);
          Swal.fire("Success", response?.data?.message, "success")
          getmynftdetail(userSlug)
        }
      });
      // } 
    } catch (error) {
      console.error("Error toggling follow status:", error);
    }
  };

  return (
    <>
      <section className="bid_auction" id="single-detail-id">
        <LoadingSpinerComponent
          promiseInProgress={showloader || showloader1}
          message={messageLoader}
        />
        {nftItem ? (
          <Container>
            <h5 className="collec-heading">
              <a href="/explore" className="pe-1">
                All Digital Trading Cards
              </a>
              {">"}
              <span className="px-1">{nftItem.nft_category}</span>
              <span className="ps-1">{nftItem.nft_subcategory}</span>
             
              {
                      nftItem.nft_is_on_sale === "0" &&
                        Number(nftItem?.nftowners[0]?.nft_owner) !== Number(localStorage.getItem("currentUserId")) ?
                        (
                          <>
                            {nftItem?.total_avail_for_sale > 0 ?
                              <span className="sold-out-status">Sold Out</span>
                             : ""}
                          </>
                        ) : null}
            </h5>
            <Row className="auction-reverse-box">
              <Col md={12} lg={5} className="text-start mb-3">
                {nftItem &&
                  nftItem.nft_is_on_auction == "1" &&
                  new Date() >= new Date(nftItem.nft_auction_start * 1000) &&
                  nftItem.nft_status == "APPROVED" ? (
                  <>
                    {nftItem?.nft_is_on_auction == "1" ? (
                      <p className="auction-time nft-auction-time">
                        {nftItem.nft_status != "CREATED" &&
                          new Date(nftItem.nft_auction_end * 1000) >= new Date()
                          ? "Auction end in "
                          : " "}
                        <span>
                          {" "}
                          <Countdown
                            date={new Date(nftItem.nft_auction_end * 1000)}
                            renderer={renderer}
                          />
                        </span>
                      </p>
                    ) : null}
                  </>
                ) : (
                  nftItem.nft_is_on_auction == "1" &&
                    nftItem.nft_status == "APPROVED" &&
                    userDetail &&
                    new Date() <= new Date(nftItem.nft_auction_start * 1000) ? (<p className="coming-soon">Coming soon</p>) :
                    nftItem.nft_is_on_auction == "1" &&
                    nftItem.nft_status == "APPROVED" &&
                    !userDetail &&
                    new Date() <= new Date(nftItem.nft_auction_start * 1000) && (<p className="coming-soon">Coming soon</p>)
                )}

                <div className="air-max mb-2">
                  <h2>{nftItem.nft_name}</h2>
                  <div class="share-bt" style={{ cursor: "pointer" }}>
                    <p onClick={() => setShowshare(true)}>
                      <i class="fa fa-share-alt mr-2" aria-hidden="true"></i>{" "}
                      Share
                    </p>
                  </div>
                </div>
                {nftItem.project_name != null ? (
                  <a href={"/projects/" + nftItem.project_slug}>
                    {" "}
                    <div className="projectData ">
                      <i class="fa fa-tasks"></i> {nftItem.project_name}
                    </div>
                  </a>
                ) : null}
                <p className="auction-time">{nftItem.nft_description}</p>



                <div className="profile_tab mb-3 mt-3" id="back-reload">
                  <div className="collection-team">

                    <h6 className="mb-2">Creator:</h6>
                    <div className="follow-boxes">
                      <a
                        href={"/user/" + nftItem?.slug}
                        className="text-dark"
                      >
                        <img
                          src={
                            nftItem && nftItem?.creator_profile
                              ? nftItem.creator_profile
                              : profile_image
                          }
                          alt=""
                        ></img>
                      </a>
                      <div className="follow-div">
                        <p>  {nftItem &&
                          nftItem.creator_firstname &&
                          nftItem.creator_lastname &&
                          `${nftItem.creator_firstname} + ${nftItem.creator_lastname}`.length > 0
                          && nftItem.creator_firstname + " " + nftItem.creator_lastname}
                          {nftItem?.isFollow &&
                            Number(localStorage.getItem("currentUserId")) !== Number(nftItem?.nft_creator) && (
                              <i className="fa fa-check checkmark" aria-hidden="true"></i>
                            )}
                        </p>
                        {!nftItem?.isFollow &&
                          Number(localStorage.getItem("currentUserId")) !== Number(nftItem?.nft_creator) && (
                            <span className="follow-btn-text"
                              onClick={() => {
                                if (isUserLoggedIn) {
                                  handleFollowToggle(nftItem);
                                } else {
                                  window.location.href = `/login?path=${location}`;
                                  localStorage.setItem("requrl", window.location.pathname);
                                }
                              }}
                            >
                              Follow
                            </span>
                          )}

                      </div>
                    </div>
                    <br />

                    <h6 className="mb-2">Owner:</h6>
                    <a
                      href={"/user/" + nftItem.nftowners[0]?.user_slug}
                      className="text-dark"
                    >
                      <img
                        src={
                          nftItem && nftItem.nftowners[0]?.profile
                            ? nftItem.nftowners[0]?.profile
                            : profile_image
                        }
                        alt=""
                      ></img>
                    </a>
                    {nftItem &&
                      nftItem.nftowners[0]?.firstname &&
                      nftItem.nftowners[0]?.lastname &&
                      `${nftItem.nftowners[0]?.firstname} + ${nftItem.nftowners[0]?.lastname}`.length > 0
                      && nftItem.nftowners[0]?.firstname + " " + nftItem.nftowners[0]?.lastname}



                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  {nftItem.nft_price && nftItem.nft_price > 0 ?
                    <p style={{ fontSize: "18px" }} className="mb-1 mt-2">
                      {" "}
                      {nftItem && nftItem.nft_is_on_auction == "1"
                        ? "Latest Bid"
                        : "Price"}
                    </p> : ""}
                  {nftItem.nft_price && nftItem.nft_price > 0 ?
                    <h1>
                      {"$"}
                      {Number(nftItem.nft_price
                      ).toFixed(2)}{" "}

                    </h1> : ""}
                  <div className="buy-place-bt">
                    {
                      nftItem.nft_is_on_sale === "1" &&
                        Number(nftItem?.nftowners[0]?.nft_owner) !== Number(localStorage.getItem("currentUserId")) ? (
                        <>
                          <span
                            onClick={() => {
                              if (isUserLoggedIn) {
                                BuyNft(nftItem);
                              } else {
                                window.location.href = `/login?path=${location}`;
                                localStorage.setItem("requrl", window.location.pathname);
                              }
                            }}
                            className="buy-now-bt"
                            style={{ cursor: "pointer" }}
                          >
                            Buy Now
                          </span>
                        </>
                      ) : null}

                    {
                      nftItem.nft_is_on_sale === "1" &&
                        Number(nftItem?.nftowners[0]?.nft_owner) == Number(localStorage.getItem("currentUserId")) ? (
                        <>
                          <span
                            onClick={() => {
                              if (isUserLoggedIn) {
                                RemoveFromSale(nftItem);
                              } else {
                                window.location.href = `/login?path=${location}`;
                                localStorage.setItem("requrl", window.location.pathname);
                              }
                            }}
                            className="buy-now-bt"
                            style={{ cursor: "pointer" }}
                          >
                            Unlist
                          </span>
                        </>
                      ) : null}



                    {(nftItem.nft_status === "APPROVED" || nftItem?.nft_status === "CREATED") &&
                      nftItem.nft_is_on_sale === "0" &&
                      Number(nftItem?.nftowners[0]?.nft_owner) == Number(localStorage.getItem("currentUserId")) ?
                      (
                        <>
                          <span onClick={() => {
                            if (isUserLoggedIn) {
                              setPutOnsale(!putonSale)
                            } else {
                              window.location.href = `/login?path=${location}`;
                              localStorage.setItem("requrl", window.location.pathname);
                            }
                          }}
                            className="buy-now-bt"
                            style={{ cursor: "pointer" }}
                          > Put on Sale</span>
                        </>
                      ) : null}

                    {
                      nftItem.nft_is_on_sale === "0" &&
                        Number(nftItem?.nftowners[0]?.nft_owner) !== Number(localStorage.getItem("currentUserId")) ?
                        (
                          <>
                            {/* {nftItem?.total_avail_for_sale > 0 ? "Sold out" : "Not available for sale"} */}
                            {nftItem?.total_avail_for_sale > 0 ? "" : "Not available for sale"}
                          </>
                        ) : null}

                  </div>
                  {/* {putonSale &&
                    <Row className="put-sale-boxes">
                      <Col md={7}>
                      <Row className="align-items-center">
                      <Col
                      xs={6}
                        md={7}
                        className={`put-sale-box signle-detail-data-area ${!putonSale ? "put-on-sale-div" : ""}`}
                      >
                        <Form.Control
                          type="text"
                          onWheel={() => document.activeElement.blur()}
                          onKeyDown={(e) =>
                            symbolsArr.includes(e.key) &&
                            e.preventDefault()
                          }
                          maxLength={10}
                          placeholder={
                            "Enter Price"
                          }
                          onChange={(e) => setprice(e.target.value)}
                        />
                        <span className="error">{priceErr}</span>
                      </Col>
                      <Col  xs={6} md={5}>
                        <button
                          type="button"
                          className={`buy-now-bt ${price? "":"disabled-btn"}`}
                          disabled={!price}
                          onClick={() => {
                            if (isUserLoggedIn) {
                             PutOnSale(nftItem);
                            } else {
                              window.location.href = "/login";
                              localStorage.setItem(
                                "requrl",
                                window.location.pathname
                              );
                            }
                          }}
                        >
                          Submit
                        </button>
                      </Col>
                      </Row>
                      </Col>
                    </Row>
                  } */}
                  {putonSale && (
                    <Row className="put-sale-boxes">
                      <Col md={7}>
                        <Row className="align-items-center">
                          <Col
                            xs={6}
                            md={7}
                            className={`put-sale-box signle-detail-data-area ${!putonSale ? "put-on-sale-div" : ""}`}
                          >
                            <InputGroup>
                              <InputGroup.Text>$</InputGroup.Text>
                              <Form.Control
                                type="number" // changed to 'number' for proper validation
                                onWheel={() => document.activeElement.blur()}
                                onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
                                maxLength={10}
                                placeholder={"Enter Price"}
                                value={price}
                                min={1} // Added min attribute to ensure minimum value is 1
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Ensure value is not less than 1
                                  if (/^\d+$/.test(value) && value >= 1) {
                                    setprice(value);
                                  } else if (value === "") {
                                    setprice(""); // handle empty input case
                                  }
                                }}
                              />
                            </InputGroup>
                            <span className="error">{priceErr}</span>
                          </Col>
                          <Col xs={6} md={5}>
                            <button
                              type="button"
                              className={`buy-now-bt ${price ? "" : "disabled-btn"}`}
                              disabled={!price}
                              onClick={() => {
                                if (isUserLoggedIn) {
                                  PutOnSale(nftItem);
                                } else {
                                  window.location.href = "/login";
                                  localStorage.setItem("requrl", window.location.pathname);
                                }
                              }}
                            >
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}



                </div>
              </Col>
              <Col md={12} lg={7}>
                <div className="auction-right-side">
                  {nftItem && nftItem.nft_media_type == "video" ? (
                    <LazyLoad height="100%">
                      <video
                        width="100%"
                        height="380"
                        autoPlay
                        muted
                        loop
                        preload='none'
                        playsInline
                        controls={false}
                      >
                        <source src={nftItem.nft_image} type="video/mp4" />
                        <source src={nftItem.nft_image} type="video/ogg" />
                      </video>
                    </LazyLoad>
                  ) : (
                    <Image
                      src={nftItem.nft_image}
                      alt="nft images"
                      className="img-fluid"
                      fallback={<Shimmer width="100" height="100%" />}
                    />
                  )}
                </div>

                {nftProperties ? (
                  <div>
                    <div className="mt-4">
                      <div
                        className="property_head"
                        onClick={() => setOpenProprty(!openProperty)}
                        aria-controls="Property-box"
                        aria-expanded={openProperty}
                      >
                        <img
                          src={
                            require("../assets/images/properties.svg").default
                          }
                          alt=""
                          width={20}
                        />
                        <div>
                          <h6 className="mb-2">Properties</h6>
                        </div>
                      </div>
                      <Collapse in={openProperty}>
                        <div className="row" id="Property-box">
                          {nftProperties &&
                            nftProperties.map((item, index) => (
                              <div className="col-md-4 mb-2" key={index}>
                                <div className="property-box">
                                  <p className="mb-2">{item.value1}</p>
                                  <h5>{item.name}</h5>
                                </div>
                              </div>
                            ))}
                        </div>
                      </Collapse>
                    </div>
                    <hr />
                  </div>
                ) : null}

                {nftlevel ? (
                  <div>
                    <div className="mt-4 mb-3">
                      <div
                        className="property_head"
                        onClick={() => setOpenLevel(!openLevel)}
                        aria-controls="level-box"
                        aria-expanded={openLevel}
                      >
                        <img
                          src={require("../assets/images/star1.svg").default}
                          alt=""
                          width={20}
                        />
                        <div>
                          <h6 className="mb-2">Levels</h6>
                        </div>
                      </div>
                      <Collapse in={openLevel}>
                        <div id="level-box">
                          {nftlevel &&
                            nftlevel.map((item, index) => (
                              <div className="number_box1">
                                <div className="d-flex justify-content-between">
                                  <p className="mb-2">{item.key}</p>
                                  <p className="mb-2">
                                    {item.value1} of {item.value2}
                                  </p>
                                </div>
                                <div>
                                  <ProgressBar
                                    now={item.value1}
                                    max={item.value2}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </Collapse>
                    </div>
                    <hr />
                  </div>
                ) : null}

                {nftstat ? (
                  <div>
                    <div className="mt-4 mb-4">
                      <div
                        className="property_head"
                        onClick={() => setOpenStats(!openStats)}
                        aria-controls="stats-box"
                        aria-expanded={openStats}
                      >
                        <img
                          src={require("../assets/images/stats1.svg").default}
                          alt=""
                          width={20}
                        />
                        <div>
                          <h6 className="mb-2">Stats</h6>
                        </div>
                      </div>
                      <Collapse in={openStats}>
                        <div id="level-box">
                          {nftstat.map((item, index) => (
                            <div className="number_box1" id="stats-box">
                              <div className="d-flex justify-content-between">
                                <p className="mb-0">{item.name}</p>
                                <p className="mb-0">
                                  {item.value1} of {item.value2}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Collapse>
                    </div>
                  </div>
                ) : null}
              </Col>
            </Row>
          </Container>
        ) : (
          <></>
        )}


        <Modal
          show={show}
          onHide={handleClose}
          animation={false}
          centered
          className="text-center meta-mask-modal"
        >
          <Modal.Body>
            <h3>{appName}</h3>
            {message}
          </Modal.Body>
          <Button type="button" className="ok-bt" onClick={handleClose}>
            Ok
          </Button>
        </Modal>
        <Modal
          show={showshare}
          onHide={handleCloseshare}
          animation={false}
          centered
          className="text-center meta-mask-modal share-modal-new link-copied-box"
        >
          <Modal.Body>
            <h3>{appName}</h3>
            {copy ? (
              <>
                <span style={{ fontSize: "12px", color: "#ff7750" }}>
                  Link Copied
                </span>
                <br></br>
              </>
            ) : (
              <>
                <span style={{ fontSize: "10px" }}></span>
                <br></br>
              </>
            )}
            <input
              type="text"
              value={share_url}
              disabled={true}
              style={{ fontSize: "12px" }}
            />
            <CopyToClipboard text={share_url} onCopy={() => setCopy(true)}>
              <i
                className="fa fa-clipboard Demo__some-network__share-button"
                title="Copy to clipboard"
                aria-hidden="true"
                style={{ fontSize: "19px", cursor: "pointer" }}
              ></i>
            </CopyToClipboard>
            <br></br>
            <br></br>
            <div className="popup-social-icons">
              <WhatsappShareButton
                url={share_url}
                title={title}
                separator=" "
                className="Demo__some-network__share-button"
              >

                <WhatsappIcon size={32} round />
              </WhatsappShareButton>

              <EmailShareButton
                url={share_url}
                subject={title}
                body="Lincode Card: "
                separator=" "
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
              <LinkedinShareButton
                url={share_url}
                className="Demo__some-network__share-button"
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <FacebookShareButton
                url={share_url}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <FacebookMessengerShareButton
                url={share_url}
                appId={`${fbappid}`}
                className="Demo__some-network__share-button"
              >
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>
              <TwitterShareButton
                url={share_url}
                title={title}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="ok-bt"
              onClick={() => setShowshare(false)}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </section>

      {false ? (
        <section className="seller-box">
          <h2>
            More from this
            <br />
            <span> Seller!</span>
          </h2>
          <Container>
            <Row className="justify-content-center">{createdItems}</Row>
          </Container>
        </section>
      ) : null}

      {/* <Modal
        show={showBidModal || showBuyModal || bidAccepted}
        onHide={() => {
          setShowBidModal(false);
          setShowBuyModal(false);
          setShowBidAccepted(false);
        }}
        animation={false}
        centered
        className="contact-us-form"
        backdrop="static"
      >
        <div className="bid-modal-bg">
          <Modal.Body>
            <i
              className="fa fa-times modal-cross-icon-new"
              aria-hidden="true"
              onClick={() => {
                setShowBidModal(false);
                setShowBuyModal(false);
                setShowBidAccepted(false);
                bidAccepted
                  ? window.open("/", "_self")
                  : window.location.reload();
              }}
            ></i>
            <div className="bid-modal">
              <div className="correct-image"></div>
              <div className="text-center">
                <img
                  src={require("../assets/images/correct.png").default}
                  width="200"
                ></img>
                {showBidModal ? (
                  <h5>Bid placed successfully.</h5>
                ) : showBuyModal ? (
                  <h5>Digital Trading Cards purchased successfully.</h5>
                ) : (
                  bidAccepted && <h5>Bid Accepted successfully.</h5>
                )}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal> */}

      {/* <Modal
        show={showstep}
        animation={false}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Follow Steps</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"item"}>
            <div className="spinner-section">
              {!onSale ? (
                <Loader type="spinner-default" bgColor={"#b5adad"} size={67} />
              ) : (
                <div className="right-icon" style={{ color: "green" }}>
                  <i className="fa fa-check" aria-hidden="true"></i>
                </div>
              )}
              <div className="spinner-content">
                {auction ? (
                  <>
                    <h6>On Auction</h6>
                    <p>Put item on auction</p>
                  </>
                ) : (
                  <>
                    <h6>On Sale</h6>
                    <p>Put item on sale</p>
                  </>
                )}
              </div>
            </div>

            <div className="spinner-section">
              {onSale && !approved ? (
                <Loader type="spinner-default" bgColor={"#b5adad"} size={67} />
              ) : (
                <div className="right-icon" style={{ color: "green" }}>
                  <i
                    className={
                      approved ? "fa fa-check" : "fa fa-check grey-check"
                    }
                    aria-hidden="true"
                  ></i>
                </div>
              )}
              <div className="spinner-content">
                <h6>Approve</h6>
                <p>Approving Digital Trading Cards for sale</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal
        show={showMintModal}
        onHide={() => {
          setShowMintModal(false);
        }}
        animation={false}
        centered
        className="contact-us-form"
        backdrop="static"
      >
        <div className="bid-modal-bg">
          <Modal.Body>
            <i
              className="fa fa-times modal-cross-icon"
              aria-hidden="true"
              onClick={() => {
                setShowMintModal(false);
                window.location.reload();
              }}
            ></i>
            <div className="bid-modal">
              <div className="correct-image"></div>
              {auction ? (
                <h5>Item/s put on auction successfully.</h5>
              ) : (
                <h5>Items put on sale successfully.</h5>
              )}
            </div>
          </Modal.Body>
        </div>
      </Modal>
      {/* <WinterCheckout
        email={userDetail?.email}
        projectId={useWinterProjectId}
        production={winterCheckoutProduction}
        showModal={showWinter}
        onSuccess={(e) => {
          successCheckout(e);
        }}
        onClose={() => {
          onCloseWinterCheckout();
        }}
        extraMintParams={{
          contractaddress: nftaddressETH,
          userId: parseInt(localStorage.getItem("currentUserId")),
          nftId: nftParamsId,
          userAddress: "",
        }}
        priceFunctionParams={{ tier: "gold", type: "rare" }}
      /> */}
    </>
  );
};
export default SingleNftDetail;