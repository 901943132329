import { apiUrl } from "../config/config";
import axios from "axios";
import { formDataHeaders, jsonHeaders, jsonHeadersForImage, withPaperHeaders } from "../helper";

var configFormDataHeaders = {
  headers: formDataHeaders(),
};

var configjsonHeaders = {
  headers: jsonHeaders(),
};
var withPaperJsonHeaders = {
  headers: withPaperHeaders(),
};

var configImageUrl ={
  headers: jsonHeadersForImage(),
};

export const userServices = {
  Userlogin,
  saveWalletAddress,
  UserRegister,
  updateWalletAddress,
  getClientSecret,
  getIDwithEdit,
  setOwner,
  forgetpasswordAPI,
  ResetpasswordApi,
  EmailVerifyApi,
  ResendEmailVerificationApi,
  GetInTouchForm,
  registerInterest,
  contactUs,
  getAdminWalletAddress,
  generateImage,
  generate_image_to_image,
  getFrame,
  saveData,
  getImageDetails,
  saveTodraft,
  uploadImage,
  getdraftData,
  deleteDraft,
  draftList,
  getImageFile,
  getImageFromTaskId,
  generateImageUrl,
  createSingleNft,
  createCollection,
  followUnfollow
};

function GetInTouchForm(params) {
  let url = `${apiUrl}/save-contact-feed`;
  return axios.post(url, JSON.stringify(params), {
    headers: {
      Authorization: "Basic xxxxxxxxxxxxxxxxxxx",
      "content-type": "application/json",
    },
  });
}

function Userlogin(params) {
  let url = `${apiUrl}/login`;
  return axios.post(url, params, {
    "content-type": "application/x-www-form-urlencoded;charset=utf-8",
  });
}

function saveWalletAddress(params) {
  let url = `${apiUrl}/user/walletAddress`;
  return axios.post(url, params, configjsonHeaders);
}

function updateWalletAddress(params) {
  let url = `${apiUrl}/ethAddress`;
  return axios.put(url, params, configjsonHeaders);
}

function getClientSecret(params){
  let url = `${apiUrl}/with-paper-secret `;
  return axios.post(url, params, withPaperJsonHeaders);
}

function UserRegister(params) {
  let url = `${apiUrl}/user-signup`;
  return axios.post(url, params, {
    "content-type": "application/x-www-form-urlencoded;charset=utf-8",
  });
}

function getIDwithEdit() {
  let url = `${apiUrl}/user-detail`;
  return axios.get(url, configjsonHeaders);
}

function getAdminWalletAddress() {
  let url = `${apiUrl}/admin-wallet-address`;
  return axios.get(url, configjsonHeaders);
}

function setOwner(tokenId) {
  let url = `${apiUrl}/setOwner/${tokenId}`;
  return axios.put(url, configjsonHeaders);
}

function registerInterest(params) {
  let url = `${apiUrl}/interest-form`;
  return axios.post(url, params, configjsonHeaders);
}

function contactUs(params) {
  let url = `${apiUrl}/contact-feed`;
  return axios.post(url, params);
}

function forgetpasswordAPI(params) {
  let url = `${apiUrl}/forgot-password`;
  return axios.post(url, params, configjsonHeaders);
}
function ResetpasswordApi(params) {
  let url = `${apiUrl}/reset-password`;
  return axios.post(url, params, configjsonHeaders);
}

function EmailVerifyApi(params) {
  let url = `${apiUrl}/email-verification`;
  return axios.post(url, params, {
    "content-type": "application/x-www-form-urlencoded;charset=utf-8",
  });
}

function ResendEmailVerificationApi(params) {
  let url = `${apiUrl}/resend-email`;
  return axios.post(url, params, {
    "content-type": "application/x-www-form-urlencoded;charset=utf-8",
  });
}

function generateImage(params){
  // let url=`${apiUrl}/generateAIImage`; // from openai
  let url=`${apiUrl}/mj/generate-image`; // from midjourney
  return axios.post(url,params,configjsonHeaders)
}

function getImageFromTaskId(taskId){
  let url=`${apiUrl}/mj/task-result/${taskId}`;
  return axios.get(url,configjsonHeaders)
}


function saveTodraft(params){
  let url=`${apiUrl}/save-draft`;
  return axios.post(url, params, configjsonHeaders);
}

function uploadImage(data){
  let url=`${apiUrl}/upload-file`;
  console.log("formDataHeaders___",configjsonHeaders,data)
  return axios.post(url,data,configjsonHeaders)
}

function deleteDraft(id){
  let url=`${apiUrl}/draft/delete/${id}`;
  return axios.delete(url,configjsonHeaders)
}

function getdraftData(id){
  let url=`${apiUrl}/draft/detail/${id}`;
  return axios.get(url,configjsonHeaders)
}
function draftList(page){
  let url=`${apiUrl}/draft/list` + "?page=" + page;
  return axios.get(url,configjsonHeaders)
}

function getImageFile(id){
  let url=`${apiUrl}/draft/image/${id}`;
  return axios.get(url,configjsonHeaders)

}


function generate_image_to_image(data){
  // let url=`${apiUrl}/generate-image-img`; // from openai
   let url=`${apiUrl}/mj/generate-image`; // from midjourney
  return axios.post(url, data,jsonHeaders )
}

function generateImageUrl(data){
  let url= "https://api.midjourneyapi.io/v2/upload";
  return axios.post(url, data, configImageUrl)
}

function getFrame(){
  let url=`${apiUrl}/frames`;
  return axios.get(url)
}
function saveData(params,id){
  let url=`${apiUrl}/frame/update/${id}`;
  return axios.put(url,params,configjsonHeaders)
}

function getImageDetails(id){
  let url=`${apiUrl}/image/detail/${id}`;
  return axios.get(url)
}

function createSingleNft(params) {
  let url = `${apiUrl}/mintingNFT`;
  return axios.post(url, params, configjsonHeaders);
}

function createCollection(params) {
  let url = `${apiUrl}/create-collection`;
  return axios.post(url, params, configjsonHeaders);
}
function followUnfollow(params) {
  let url = `${apiUrl}/user/follow-user`;
  return axios.post(url, params, configjsonHeaders);
}