import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { nftService } from '../../_services';
import { useLocation, useNavigate } from 'react-router-dom';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import Swal from 'sweetalert2';
import { Col, Row } from 'react-bootstrap-v5';

const AddBank = () => {
  const [selectedAddress, setAddress] = useState(null);
  const [accountHolderName, setAccountHolderName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [branch, setBranch] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [bankId, setBankId] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const history = useNavigate();
  const KeyDownPhone = ["`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "=", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "/", ",", "<", ">", "\\", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]

  const [formData, setFormData] = useState({
    accountNo: "",
    accountHolderName: "",
    routingNumber: "",
    accountType: "individual",
    city: "",
    line1: "",
    postalCode: "",
    apartmentNumber: "",
    state: ""
  });
  const [formErrors, setFormErrors] = useState({});
  const [formValid, setFormValid] = useState(false);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const nft = query.get('nft')
  // console.log("Nft Parameter:", nft); // Log the parameter for debugging

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateForm();
  };
  const validateForm = () => {
    const errors = {};

    // Required field validations
    if (!formData.accountNo) errors.accountNo = "Account Number is required.";
    if (!formData.accountHolderName) errors.accountHolderName = "Account Holder Name is required.";
    if (!formData.routingNumber) {
      errors.routingNumber = "Routing Number is required.";
    } else if (formData.routingNumber.length !== 9) {
      errors.routingNumber = "Routing Number must be exactly 9 digits.";
    }
    if (!formData.city) errors.city = "City is required.";
    if (!formData.line1) errors.line1 = "Address is required.";
    if (!formData.state) errors.state = "Province/State is required.";
    if (!formData.postalCode) errors.postalCode = "Postal Code is required.";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  function AddbankData() {
    const params = {
      accountNo: formData.accountNo,
      accountHolderName: formData.accountHolderName,
      routingNumber: formData.routingNumber,
      city: formData.city,
      line1: formData.line1,
      postalCode: formData.postalCode,
      state: formData.state,
      apartmentNumber: formData.apartmentNumber,
      bankId: bankId
    };
    !params.bankId && delete params.bankId
    nftService.addBank(params)
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Bank added successfully.",
            imageUrl: require("../../assets/images/thumbs-up.png"),
            imageWidth: 80,
            imageHeight: 100,
            imageAlt: "Custom image",
            animation: false,
          })
          getBankInfo();
          setTimeout(() => {
            history("/explore")
          }, 1000)
        }
      })
      .catch((error) => {
        console.log("Error in add bank", error);
      });
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      AddbankData();
    }
  };

  useEffect(() => {
    getBankInfo();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll page to top
  }, [])

  useEffect(() => {
    if (selectedAddress) {
      geocodeByPlaceId(selectedAddress?.value?.place_id)
        .then((results) => {
          console.log(results, "result");
          try {
            let place = results[0];
            let city = "";
            let state = "";
            let postalCode = "";

            place.address_components.forEach((component) => {
              const types = component.types;
              if (types.includes("locality")) {
                city = component.long_name;
              } else if (types.includes("administrative_area_level_1")) {
                state = component.long_name;
              } else if (types.includes("postal_code")) {
                postalCode = component.long_name;
              }
            });
            setFormData({
              ...formData,
              line1: place?.formatted_address,
              city: city,
              state: state,
              postalCode: postalCode,
            });
          } catch (error) { }
        })
        .catch((error) => console.error(error));
    } else {
      // Clear the form data when no address is selected
      setFormData({
        ...formData,
        line1: "",
        city: "",
        state: "",
        postalCode: "",
      });
    }
  }, [selectedAddress]);

  function handleSkip() {
    history("/explore");
  }

  function getBankInfo() {
    nftService.getBankInfo().then((response) => {
      if (response.status === 200) {
        console.log("response", response)
        setBankId(response.data.data.id);
        setFormData({
          ...formData,
          accountNo: response.data.data.accountNo,
          accountHolderName: response.data.data.accountHolderName,
          routingNumber: response.data.data.routingNumber,
          city: response.data.data.city,
          line1: response.data.data.line1,
          state: response.data.data.state,
          postalCode: response.data.data.postalCode,
          apartmentNumber: response.data.data.apartmentNumber
        })
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }

  return (
    <section className='add-bank-section'>
      <div className='container'>
        <div className="add-bank-form">
          <div className='row justify-content-center'>
            <div className='col-md-8'>
              <div className='add-bank-box'>
                <h3>Add Bank Account</h3>
                <Form className='login-form-box' onSubmit={handleSubmit}>
                  <Row className='justify-content-center'>
                    <Col md={6} className='m-0'>
                      <Form.Group controlId="accountNo">
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="accountNo"
                          value={formData.accountNo}
                          onChange={(e) => {
                            const value = e.target.value.replace(/\s/g, '').slice(0, 16); // Remove spaces and limit to 16 digits
                            if (/^\d*$/.test(value)) { // Check if the value contains only digits
                              handleInputChange({ target: { name: 'accountNo', value } });
                            }
                          }}
                          onKeyDown={(e) => KeyDownPhone.includes(e.key) && e.preventDefault()}
                        />
                        {formErrors.accountNo && <span className="text-danger">{formErrors.accountNo}</span>}
                      </Form.Group>
                    </Col>
                    <Col md={6} className='m-0'>
                      <Form.Group controlId="accountHolderName">
                        <Form.Label>Account Holder Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="accountHolderName"
                          value={formData.accountHolderName}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Regex to check if the value contains only alphabetic characters
                            const isValid = /^[A-Za-z\s]*$/.test(value);

                            // Check if the input is not empty and does not start with a space
                            if (isValid && (value === "" || value[0] !== " ")) {
                              handleInputChange(e);
                            }
                          }}
                        />
                        {formErrors.accountHolderName && <span className="text-danger">{formErrors.accountHolderName}</span>}
                      </Form.Group>
                    </Col>
                    <Col md={6} className='m-0'>
                      <Form.Group controlId="routingNumber">
                        <Form.Label>Routing Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="routingNumber"
                          value={formData.routingNumber}
                          onChange={(e) => {
                            const value = e.target.value.replace(/\s/g, '').slice(0, 9); // Remove spaces and limit to 9 digits
                            if (/^\d*$/.test(value)) { // Check if the value contains only digits
                              handleInputChange({ target: { name: 'routingNumber', value } });

                              // Validate length
                              if (value.length < 9) {
                                setFormErrors((prevErrors) => ({
                                  ...prevErrors,
                                  routingNumber: 'Please enter a valid routing number.',
                                }));
                              } else {
                                setFormErrors((prevErrors) => ({
                                  ...prevErrors,
                                  routingNumber: '', // Clear error if valid
                                }));
                              }
                            }
                          }}
                        />
                        {formErrors.routingNumber && <span className="text-danger">{formErrors.routingNumber}</span>}
                      </Form.Group>
                    </Col>
                    <Col md={6} className='m-0'>
                      <div className="form-group address-box">
                        <label htmlFor="">Address</label>
                        <GooglePlacesAutocomplete

                          apiKey="AIzaSyA6ORKC7i4lvMbo9cFD6HXa9HRn-lhX3zc"
                          selectProps={{
                            selectedAddress,
                            onChange: setAddress,
                            placeholder: formData.line1,
                            autofocus: true,

                            styles: {
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? 'lightblue' // Color for selected option
                                  : state.isFocused
                                    ? 'lightgray' // Color when hovered
                                    : 'white', // Default color
                                color: state.isSelected ? 'black' : 'black', // Text color
                                padding: 7,
                              }),
                              control: (provided) => ({
                                ...provided,
                                width: "100%",
                                border: "#ffffff8c 1px solid",
                                padding: "0px 4px",
                                borderRadius: "5px",
                                backgroundColor: "transparent",
                                color: "white",
                                height: "58px",
                              }),
                              indicatorsContainer: (provided) => ({
                                ...provided,
                                display: "none !important",
                              }),

                            },
                          }}
                          options={{
                            types: ["(regions)"],
                            componentRestrictions: { country: "CA" }, // Restrict to Canada
                          }}
                        />
                        {formErrors.line1 && <span className="text-danger">{formErrors.line1}</span>}

                      </div>
                    </Col>
                    <Col md={6} className='m-0'>
                      <Form.Group controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          value={formData.city}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (value === "" || !value.startsWith(" ")) {
                              handleInputChange(e);
                            }
                          }}
                        />
                        {formErrors.city && <span className="text-danger">{formErrors.city}</span>}
                      </Form.Group>
                    </Col>
                    <Col md={6} className='m-0'>
                      <Form.Group controlId="state">
                        <Form.Label>Province/State</Form.Label>
                        <Form.Control
                          type="text"
                          name="state"
                          value={formData.state}
                          // onChange={handleInputChange}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (value === "" || !value.startsWith(" ")) {
                              handleInputChange(e);
                            }
                          }}
                        />
                        {formErrors.state && <span className="text-danger">{formErrors.state}</span>}
                      </Form.Group>
                    </Col>
                    <Col md={6} className='m-0'>
                      <Form.Group controlId="postalCode">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          type="text"
                          name="postalCode"
                          value={formData.postalCode}
                          // onChange={handleInputChange}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (value === "" || !value.startsWith(" ")) {
                              handleInputChange(e);
                            }
                          }}
                        />
                        {formErrors.postalCode && <span className="text-danger">{formErrors.postalCode}</span>}
                      </Form.Group>
                    </Col>
                    <Col md={6} className='m-0'>
                      <Form.Group controlId="apartmentNumber">
                        <Form.Label>Apartment/Office Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="apartmentNumber"
                          value={formData.apartmentNumber}
                          // onChange={handleInputChange}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (value === "" || !value.startsWith(" ")) {
                              handleInputChange(e);
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className='m-0'>
                      <div className='action-btns-box d-flex'>
                        <Button className='submit-bt btn btn-unset' variant="primary" type="submit">
                          {bankId ? "Update" : "Submit"}
                        </Button>
                        {nft == "yes" &&
                          <Button className='skip-btn' variant="unset" type="button" onClick={() => history("/explore")}>
                            Skip
                          </Button>}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddBank;
