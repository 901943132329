import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from "react-bootstrap-v5";
import AliceCarousel from "react-alice-carousel";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { chains, categories, chainidETH } from "../config/config";
// import LoadingSpinerComponent from "./Loader";
import Countdown from "react-countdown";
import { nftService } from "../_services";
import { Image, Shimmer } from "react-shimmer";
import { WEIToETH } from "../Component/common/CommonFunction";
import Pagination from "react-js-pagination";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Explore = () => {
  const [searchvalue, setsearchvalue] = useState("");
  const [myitems, setitems] = useState([]);
  const [CollectionList, setCollectionList] = useState([]);
  const [showloader, setshowloader] = useState(true);
  const [showloader1, setshowloader1] = useState(true);
  const [nftpage, setnftpage] = useState(1);
  const [totalpage, settotalpage] = useState();
  const [CollectionTotalPage, setCollectionTotalPage] = useState();
  const [selectedchain, setselectedchain] = useState("");
  const [category, setcategory] = useState("");
  const [keyword, setcategorykeyword] = useState("");
  const [buytype, setbuytype] = useState("");
  const [projectList, setprojectList] = useState([]);
  const [projectId, setprojectId] = useState();
  const [nftType, setnftType] = useState("");
  const [priceSort, setpriceSort] = useState("");
  const [tabType, setTabType] = useState(localStorage.getItem('collectionsTab') == 'collection' ? "M" : "S");
  const [usdprice, setusdprice] = useState();
  let nftdata = useRef("Single");
  const location = useLocation();
  const isCollection = new URLSearchParams(location.search).get("ERC-1155");
  let history = useNavigate()

  useEffect(() => {
    localStorage.removeItem("draftID")
    localStorage.removeItem("draftIDM")
  }, [])

  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Not available for sale</span>;
    } else {
      // Render a countdown
      return (
        <span>
          {" "}
          {days + "d"} {hours + "h"}:{minutes + "m"}:{seconds + "s"}
        </span>
      );
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" })
    marketplaceNft(
      nftpage - 1,
      selectedchain,
      keyword,
      category,
      buytype,
      projectId,
      nftType
    );
    getActiveProjects();
    getprice();
    MyCollection(nftpage - 1, selectedchain, keyword);
  }, []);

  const getprice = async () => {
    await nftService
      .get_price()
      .then((response) => {
        setusdprice(response.data.USD);
      })
      .catch((err) => {
      });
  };

  const getActiveProjects = async () => {
    await nftService
      .getActiveProjects()
      .then((response) => {
        setprojectList(response.data.data);
      })
      .catch((err) => {
      });
  };


  const MyCollection = async (nftpage, selectedchain, keyword) => {
    let params = { chain: selectedchain, keyword: keyword };
    await nftService
      .marketplacceCollection(nftpage, params)
      .then((response) => {
        setCollectionList(response.data.data);
        setCollectionTotalPage(response.data.total_records);

        setshowloader1(false);
      })
      .catch((err) => {
        setshowloader1(false);
      });
  };

  const marketplaceNft = async (
    nftpage,
    selectedchain,
    keyword,
    category,
    buytype,
    projectId,
    nftType,
    sort
  ) => {
    let params = {
      chain: selectedchain,
      keyword: keyword,
      category: category,
      buy_now: buytype,
      project_id: projectId,
      nft_type: nftType,
      price_sort: sort,
    };
    await nftService
      .marketplaceNft(nftpage, params)
      .then((response) => {
        settotalpage(response.data.total_records);

        setitems(response.data.data);
        setshowloader(false);
      })
      .catch((err) => {
        setshowloader(false);
      });
  };

  const handlechangecat = (e) => {
    setcategory(e);
    setnftpage(1);
    marketplaceNft(
      0,
      selectedchain,
      keyword,
      e,
      buytype,
      projectId,
      nftType,
      priceSort
    );
  };

  const handlesorting = (e) => {
    setpriceSort(e);
    setnftpage(1);
    marketplaceNft(
      0,
      selectedchain,
      keyword,
      category,
      buytype,
      projectId,
      nftType,
      e

    );
  };

  const handlechangekeywordval = (e, tabType) => {
    setnftpage(0);
    setsearchvalue(e);

    if (e !== "") {
      if (tabType == "M") {
        setshowloader1(true);
        MyCollection(0, selectedchain, e);
      } else {
        setshowloader(true);
        marketplaceNft(
          0,
          selectedchain,
          e,
          category,
          buytype,
          projectId,
          nftType,
          priceSort
        );
      }
    } else {
      setsearchvalue("");
      if (tabType == "M") {
        setshowloader1(true);
        MyCollection(0, selectedchain, e);
      } else {
        setshowloader(true);
        marketplaceNft(
          0,
          selectedchain,
          "",
          category,
          buytype,
          projectId,
          nftType,
          priceSort
        );
      }
    }
  };

  const handlechangekeyword = () => {
    if (searchvalue.trim() != "") {
      if (nftdata.current == "Collection") {
        MyCollection(0, selectedchain, searchvalue);
      } else {
        marketplaceNft(
          0,
          selectedchain,
          searchvalue,
          category,
          buytype,
          projectId,
          nftType,
          priceSort
        );
      }
    }
  };


  const handlePageClick = (event) => {
    setnftpage(event);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 900);
    if (tabType == "M") {
      MyCollection(event - 1, selectedchain, keyword);
    } else {
      marketplaceNft(
        event - 1,
        selectedchain,
        keyword,
        category,
        buytype,
        projectId,
        nftType,
        priceSort
      );
    }
  };

  function showSingleDetails(userSlug, type) {
    if (type == "MULTIPLE") {
      history("/collection_details/" + userSlug)
    } else {
      history("/trading_cards/" + userSlug)
    }
  }
  function seller_detail(add) {
    history("/profile/" + add)
  }
  function showCollectionDetails(slug, id) {
    localStorage.setItem("collectionsTab", 'collection')
    history("/collection_trading_cards/" + slug + "/" + id)
  }

  useEffect(() => {
    document.title = "Digital Trading Cards Marketplace";
    return () => {
      document.title = "Athlete & Artist Digital Trading Cards Marketplace";
    };
    localStorage.removeItem("imageId")
  }, []);




  return (
    <>
      <section className="market_banner_section h-100">
        <div className="market-bg-box">
          <Container>
            <Row>
              <Col md={12}>
                <div className="market-left">
                  <h1>Marketplace</h1>
                  <div className="search_marketplace">
                    <Form className="searchbar_header">
                      <FormControl
                        type="search"
                        placeholder="Search Products"
                        className="me-2"
                        aria-label="Search"
                        value={searchvalue}
                        onChange={(e) => {
                          setsearchvalue(e.target.value);
                          handlechangekeywordval(e.target.value, tabType);
                        }}
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                      />
                      <Button
                        variant="unset"
                        className="bg-transparent border-0 p-0"
                        onClick={handlechangekeyword}
                      >
                        <img
                          src={
                            require("../assets/images/search_btn.svg").default
                          }
                          alt="search"
                          height="44px"
                        />
                      </Button>
                    </Form>

                    {tabType == "S" && 
                    <Form.Select aria-label="Default select example" className="sort-by-filter"
                      onChange={(e) => {
                        handlesorting(e.target.value)
                      }}
                    >
                      <option>Sort By</option>
                      <option value="ASC">Low to High</option>
                      <option value="DESC">High to Low</option>
                    </Form.Select>}
                    {tabType == "S" && 
                    <Form.Select
                      aria-label="Sports Categories"
                      className=" sort-by-filter ms-2 mobile-filter"
                      onChange={(e) => {
                        const selectedCategory = e.target.value;
                        setsearchvalue("");
                        handlechangecat(selectedCategory);
                      }}
                    >
                      <option value="">All Sports</option>
                      {categories.map((item, i) => (
                        <option key={i} value={item.trim()}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>}
                  </div>
                </div>
              </Col>
              
            </Row>
          </Container>
        </div>
        <Container>
          <Tabs className="collection-nft-tab collection-nft-tab-market" defaultIndex={localStorage.getItem('collectionsTab') == 'collection' ? 1 : 0}>
            <div className="nft-collection-box">
              <TabList>
                <Tab
                  onClick={() => {
                    setTabType("S");
                    handlechangekeywordval("", "S");
                    setnftpage(1);
                    localStorage.removeItem('collectionsTab')
                  }}
                >
                  {" "}
                  Trading Cards{" "}
                </Tab>
                <Tab
                  onClick={() => {
                    setTabType("M");
                    handlechangekeywordval("", "M");
                    setnftpage(1);
                  }}
                >
                  {" "}
                  Collections
                </Tab>
              </TabList>
            </div>
            <TabPanel className="tab-panne-one">
              <section className="new-item-box">
                {/* <div className="flex-row flex-wrap market_categories nav nav-pills mb-2">
                  <Col sm="4" className="sports-tab--new">
                    <div
                      className={
                        category == "" ? "tab_box_home active" : "tab_box_home"
                      }
                      id="All"
                      onClick={() => handlechangecat("")}
                    >
                      <h4>All Sports</h4>
                      <h5></h5>
                    </div>
                  </Col>
                  {categories.map((item, i) => {
                    return (
                      <Col sm="4" className="sports-tab--new">
                        <div
                          className={
                            category == item
                              ? "tab_box_home active"
                              : "tab_box_home"
                          }
                          id={item.trim()}
                          onClick={() => {
                            setsearchvalue("");
                            handlechangecat(item);
                          }}
                        >
                          <h4>{item}</h4>
                          <h5>Sports</h5>
                        </div>
                      </Col>
                    );
                  })}

                </div> */}
                {/* <div className="flex-row flex-wrap market_categories nav nav-pills mb-2">
  <select
    className="sports-dropdown"
    value={category}
    onChange={(e) => {
      const selectedCategory = e.target.value;
      setsearchvalue("");
      handlechangecat(selectedCategory);
    }}
  >
    <option value="">All Sports</option>
    {categories.map((item, i) => (
      <option key={i} value={item.trim()}>
        {item}
      </option>
    ))}
  </select>
</div> */}

                {nftdata.current == "Single" ||
                  nftdata.current == "Multiple" ? (

                  <Row>

                    {
                      myitems.length > 0 ?
                        (
                          myitems.map((item, index) => {
                            return (
                              <Col lg={3} md={6} sm={6} key={index} className="mb-4">
                                <div className="slide-2 mb-3 cursor-pointer">
                                  <div className="d-flex user-timer-box">
                                    {item.nft_is_on_auction == "1" ? (
                                      <div className="timer-box">
                                        {new Date() >=
                                          new Date(item.nft_auction_start * 1000) ? (
                                          <ul>
                                            <Countdown
                                              date={
                                                new Date(item.nft_auction_end * 1000)
                                              }
                                              renderer={renderer}
                                            />
                                          </ul>
                                        ) : (
                                          <p>Coming Soon</p>
                                        )}
                                      </div>
                                    ) : null}
                                    {
                                      item?.nft_availability == "NOT_AVAILABLE"
                                        ?
                                        <div className="timer-box"> <span>Sold out</span> </div> :
                                        null
                                    }
                                  </div>
                                  <div
                                    className="items-image-box-two"
                                    onClick={() =>
                                      showSingleDetails(
                                        item.nft_slug,
                                        item.nft_type
                                      )
                                    }
                                  >
                                    {item?.nft_media_type == "video" ? (
                                      <video
                                        width="100%"
                                        height="100%"
                                        autoPlay
                                        muted
                                        loop
                                        preload='none'
                                        playsinline
                                        controls={false}
                                        poster={item.nft_thumb ? item.nft_thumb : ""}
                                      >
                                        <source
                                          src={item.nft_image}
                                          type="video/mp4"
                                        />
                                        <source
                                          src={item.nft_image}
                                          type="video/ogg"
                                        />
                                        <source
                                          src={item.nft_image}
                                          type="video/webm"
                                        />
                                      </video>
                                    ) : (
                                      <img className="containe_img" src={item.nft_image}></img>
                                    )}
                                  </div>
                                  <div className="items-datails" onClick={() =>
                                    showSingleDetails(
                                      item.nft_slug,
                                      item.nft_type
                                    )
                                  }>
                                    <div className="items-name">
                                      <h5>{item.nft_name}</h5>
                                    </div>
                                    {item.project_name ? (
                                      <h6 className="items-project">
                                        {item.project_name}
                                      </h6>
                                    ) : null}
                                    <h6 className="items-price pb-0">
                                      {item.nft_category}
                                    </h6>
                                    {item.nft_price ?
                                      <h6 className="items-price">
                                        {"$"} {item.nft_price?.toFixed(2)}{" "}

                                      </h6> :
                                      <h6 className="items-price">
                                        {/* {"$"} {item.nft_price?.toFixed(2)}{" "} */}

                                      </h6>}
                                  </div>
                                </div>
                              </Col>
                            )
                          })
                        ) : (
                          <>
                            {!showloader ? (
                              <div className="no-data text-center">
                                <img
                                  src={
                                    require("../assets/images/empty.png")
                                  }
                                  alt="img"
                                  width="250"
                                />
                                <p>No Data Found</p>
                              </div>
                            ) : (
                              showloader && (
                                <>
                                  <div className="loader-home">
                                    <Row>
                                      <Col md={3} className="mb-3">
                                        <div className="card">
                                          <div className="shimmerBG media"></div>
                                          <div className="p-32">
                                            <div className="shimmerBG title-line"></div>
                                            <div className="shimmerBG title-line end"></div>
                                          </div>
                                        </div>
                                      </Col>

                                      <Col md={3} className="mb-3">
                                        <div className="card">
                                          <div className="shimmerBG media"></div>
                                          <div className="p-32">
                                            <div className="shimmerBG title-line"></div>
                                            <div className="shimmerBG title-line end"></div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={3} className="mb-3">
                                        <div className="card">
                                          <div className="shimmerBG media"></div>
                                          <div className="p-32">
                                            <div className="shimmerBG title-line"></div>
                                            <div className="shimmerBG title-line end"></div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={3} className="mb-3">
                                        <div className="card">
                                          <div className="shimmerBG media"></div>
                                          <div className="p-32">
                                            <div className="shimmerBG title-line"></div>
                                            <div className="shimmerBG title-line end"></div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={3} className="mb-3">
                                        <div className="card">
                                          <div className="shimmerBG media"></div>
                                          <div className="p-32">
                                            <div className="shimmerBG title-line"></div>
                                            <div className="shimmerBG title-line end"></div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={3} className="mb-3">
                                        <div className="card">
                                          <div className="shimmerBG media"></div>
                                          <div className="p-32">
                                            <div className="shimmerBG title-line"></div>
                                            <div className="shimmerBG title-line end"></div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={3} className="mb-3">
                                        <div className="card">
                                          <div className="shimmerBG media"></div>
                                          <div className="p-32">
                                            <div className="shimmerBG title-line"></div>
                                            <div className="shimmerBG title-line end"></div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md={3} className="mb-3">
                                        <div className="card">
                                          <div className="shimmerBG media"></div>
                                          <div className="p-32">
                                            <div className="shimmerBG title-line"></div>
                                            <div className="shimmerBG title-line end"></div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </>
                              ))}
                          </>)
                    }
                  </Row>
                ) : (
                  <>
                  </>
                )}
              </section>
            </TabPanel>

            <TabPanel>
              <Row>

                {CollectionList && CollectionList?.length > 0 ?
                  CollectionList.map((item, index) =>
                  (
                    <Col lg={3} md={6} sm={6}>
                      <div className="slide-2 mb-3 cursor-pointer" onClick={() =>
                        showCollectionDetails(
                          item?.collection_slug,
                          item?.id
                        )
                      }>
                        <div
                          className="items-image-box-two"
                          onClick={() =>
                            showCollectionDetails(
                              item?.collection_slug,
                              item?.id
                            )
                          }
                        >
                          <img src={item.collectionImage}></img>
                        </div>
                        <div className="btm_details">
                          <p>{item.collection_name}</p>
                        </div>
                      </div>
                    </Col>
                  ))
                  : !showloader ? (
                    <div className="no-data text-center">
                      <img
                        src={require("../assets/images/empty.png")}
                        alt="img"
                        width="250"
                      />
                      <p>No Data Found</p>
                    </div>
                  )
                    :
                    <>

                      <Row>
                        <Col md={3} className="mb-3">
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3} className="mb-3">
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3} className="mb-3">
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3} className="mb-3">
                          <div className="card">
                            <div className="shimmerBG media"></div>
                            <div className="p-32">
                              <div className="shimmerBG title-line"></div>
                              <div className="shimmerBG title-line end"></div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                }
              </Row>
            </TabPanel>


            {(tabType == "S" && totalpage > 1) ||
              (tabType == "M" && CollectionTotalPage > 1) ? (
              <div className="pagination1">
                <Pagination
                  activePage={nftpage}
                  itemsCountPerPage={12}
                  totalItemsCount={tabType == "S" ? totalpage : tabType == "M" && CollectionTotalPage}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageClick(e)}
                  hideDisabled={true}
                  prevPageText={"<"}
                  nextPageText={">"}
                />
              </div>
            ) : (
              <></>
            )}

          </Tabs>
        </Container>
      </section>
    </>
  );
};
export default Explore;
