import { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Modal, ModalFooter } from "react-bootstrap";
import { nftService } from "../_services";
import { useMoralis } from "react-moralis";
import Loader from "react-js-loader";
import html2canvas from 'html2canvas';
import {
  categories,
  nftaddressETH,
  colectionaddressETH,
  nftaddressPOLY,
  colectionaddressPOLY,
  chainidBNB,
  chainidETH,
  chainidPOLY,
  PINATA_API_KEY,
  PINATA_SECRET_API_KEY,
} from "../config/config";
import {
  winter_multiple_email,
  winter_multiple_tokenSymbol,
  winter_multiple_projectName,
  winter_multiple_mintFunctionParams,
  winter_multiple_mintFunction,
  winter_multiple_sandbox,
  winter_multiple_ercType,
  winter_multiple_isActive,
  winter_multiple_requireAddress,
  winter_multiple_contractABI,
} from "../config/config";
import { CollectionAbi } from "./Abi";
import { ethers } from "ethers";
import swal from "sweetalert";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getEstimateGasFee,
  GetVideoThumbnails,
  handleScrollToTop,
  WEIToETH,
} from "../Component/common/CommonFunction";
import { Base64 } from "js-base64";
import Swal from "sweetalert2";
import { userServices } from "../services/user.Services";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Skeleton from "react-loading-skeleton";

const Multiple_nft = (props) => {
  const navigate = useNavigate();
  const { user, Moralis } = useMoralis();
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [images, set_images] = useState("");
  const [description, setdescription] = useState("");
  const [price, setprice] = useState();
  const [royality_percent, setroyality_percent] = useState(0);
  const [royality_check, set_royality_check] = useState(0);
  const [collectionContractAddress, setcollectionContractAddress] = useState();
  const [collectionName, setcollectionName] = useState();
  const [collectionDesc, setcollectionDesc] = useState();
  const [imageFile, setimageFile] = useState();
  const [imageCollection, setimageCollection] = useState();
  const [imageCollectionShow, setimageCollectionShow] = useState();
  const [coverimageCollection, setcoverimageCollection] = useState();
  const [coverimageCollectionShow, setcoverimageCollectionShow] = useState("");
  const [messageLoader, setmessageLoader] = useState("Loading..");
  const [showloader, setshowloader] = useState(false);
  const [category, setcategory] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setcollectionName();
    setcollectionDesc();
    setcoverimageCollectionShow("");
    setimageCollectionShow();
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [CollectionList, setCollectionList] = useState([]);
  const [collectionErr, setcollectionErr] = useState();
  const [collectionId, setcollectionId] = useState();
  const [projects, setProjects] = useState([]);
  const [projectId, setprojectId] = useState("");
  const [name, setName] = useState("");
  const [showvalue, SetShowvalue] = useState(false);
  const [currentChain, SetcurrentChain] = useState();
  const collectionAddressCurrent = useRef();
  const [unlockable_check, set_unlockable_check] = useState(0);
  const [unlockable_content, set_unlockable_content] = useState("");
  const [addLevel, setAddLevel] = useState([
    { key: "", value1: "", value2: "" },
  ]);
  const [qty, setqty] = useState();
  const [approved, setapproved] = useState(false);
  const [onSale, setonSale] = useState(false);
  const [upload, setUpload] = useState(false);
  const [showstep, setShowstep] = useState(false);
  const [showvaluePro, SetShowvaluePro] = useState(false);
  const [Propertiesvalues, setPropertiesvalues] = useState([
    { name: "", value1: "" },
  ]);
  const [Statevalues, setStatevalues] = useState([
    { name: "", value1: "", value2: "" },
  ]);
  const [showvalueSta, SetShowvalueSta] = useState(false);

  const [addLevelSave, setAddLevelSave] = useState([
    { key: "", value1: "", value2: "" },
  ]);
  const [PropertiesvaluesSave, setPropertiesvaluesSave] = useState([
    { name: "", value1: "" },
  ]);
  const [StatevaluesSave, setStatevaluesSave] = useState([
    { name: "", value1: "", value2: "" },
  ]);
  const { blockchain } = useParams();
  const [frameNumber, setframeNumber] = useState("")
  const [selectFrame, setselectFrame] = useState("")
  let frameImageUrl = useRef();
  const [frameName, setFrameName] = useState("")
  let location = useLocation();
  const AIURL = location.search.split("=")[1] == "yes";
  const id = useLocation()?.search
  const draftID = new URLSearchParams(id).get('id');
  let history =useNavigate();

  useEffect(() => {
    if (localStorage.getItem("auth") != null) {
      MyCollection();
      if (draftID) {
        getDraftDetails(draftID)
      }
    }
    if (draftID) {
      getDraftDetails(draftID)
    }
  }, [user]);

  useEffect(() => {
    let data = localStorage.getItem("imageId")
    if (AIURL) {
      if (data) {
        getDetailsImage(data)
      }
    }
  }, [])

  function getDetailsImage(id) {
    userServices.getImageDetails(id).then((res) => {
      let data = res?.data?.data;
      frameImageUrl.current = data[0]?.image_url;
      console.log("data[0]?.image_name", data)
      setframeNumber(data[0]?.image_number)
      setFrameName(data[0]?.image_name)
      setselectFrame(data[0]?.frame_url)
    })
      .catch((error) => {
        console.log("error", error)
      })
  }

  const takeScreenshot = (isFromSubmit) => {
    const divElement = document.getElementById("center-frame2");
    if (divElement) {
      html2canvas(divElement, { backgroundColor: null, borderColor: null, scrollY: -window.scrollY, letterRendering: 1, }).then((canvas) => {
        const imgData = canvas.toDataURL();
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = imgData;
        const base64String = imgData; // Base64 encoded string
        const fileName = 'image.png'; // Desired file name
        const file = base64ToFile(base64String, fileName, isFromSubmit);
      });
    }
  }

  const [showFrame, setShowFrame] = useState(false);
  function base64ToFile(base64String, fileName, isFromSubmit) {
    const base64 = base64String.replace(/^data:image\/(png|jpg|jpeg);base64,/, '').replace(/\s/g, '');
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: 'image/png' }); // Change the MIME type as needed
    const file = new File([blob], fileName, { type: blob.type });
    setimageFile(file);

    if (isFromSubmit) {
      setTimeout(() => {
        if (validation()) {
          createCollectible(file);
        }
      }, 1000)
    } else {
      uploadImageOnIPFS(file)
    }
  }


  const [mimeType, setMineType] = useState()
  function getDraftDetails(draftID) {
    userServices.getdraftData(draftID).then((res) => {
      let data = res?.data?.data;
      if (data?.nft_type == "M") {
        setName(data?.nft_name)
        setdescription(data?.nft_description)
        setcategory(data?.nft_category);
        setroyality_percent(data?.nft_royality)
        setqty(data?.quantity)
        setMineType(data?.file_mime_type)
        setprice(data?.nft_price)
        setDraftId(data?.id)
        setcollectionId(data?.collection_id)
        set_images(data?.nft_image)
        setimageFile(data?.nft_image)
        set_royality_check(data?.nft_royality > 0 ? true : false)
      }
    })
      .catch((error) => {
        console.log("Error", error)
        Swal.fire({
          title: "",
          text: "You are not authorised to access this page.",
          imageUrl: require("../assets/images/thumbs-down.png").default,
          imageWidth: 80,
          imageHeight: 80,
          imageAlt: "Custom image",
          animation: false,
        }).then(() => window.location.href = "/trading-cards");
      })
  }

  useEffect(() => {
    if (draftID) {
      getDraftDetails(draftID)
    }
  }, [])

  const [draft_id, setDraftId] = useState(localStorage.getItem("draftIDM") || "");
  function SavetoDraft(imgurl) {
    const params = {
      nft_name: name || "",
      nft_type: "M",
      nft_description: description || "",
      nft_image: imgurl ? imgurl : imageFile,
      nft_category: category,
      collection_id: collectionId,
      quantity: qty,
      price: price,
      draft_id: draft_id ? draft_id : "",
      mime_type: imagetypeRef.current ? imagetypeRef.current : "",
    }
    userServices.saveTodraft(params).then((res) => {
      if (res.status === 200) {
        console.log("save To Draft", res);
        let data = res?.data?.data;
        localStorage.setItem("draftIDM", data);
        setDraftId(data)
        toast.success("Saved to draft.", {
          toastId: 'success_draft_1',
        })
      }
    })
      .catch((error) => {
        console.log("error", error)
      })
  }


  const getprojects = async () => {
    var user = await Moralis.User.current();
    const userAddress = user.get("ethAddress");
    await nftService
      .getprojects(userAddress)
      .then((response) => {
        setProjects(response.data.data);
      })
      .catch((err) => {
        console.error("Error signing in.", err.toString());
      });
  };

  const MyCollection = async () => {
    await nftService
      .myCollections()
      .then((response) => {
        setCollectionList(response.data.data);
      })
      .catch((err) => {
      });
  };


  const handleCreateCollection = (e) => {
    e.preventDefault();
    if (validationCollection()) {
      createcollection();
    }
  };

  async function readURL(input) {
    //
    localStorage.removeItem("imageId");
    let type = input.files[0].type.split("/");
    if (input.files && input.files[0]) {
      var filetype = checkfile(input.files[0]);
      if (filetype === true) {
        uploadImageOnIPFS(input.files[0])
        set_images("");
        var reader = new FileReader();
        reader.onload = function (e) {
          set_images(e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
  }

  const [userDetail, setUserDetail] = useState();
  useEffect(() => {
    getUserDetail()
  },[])

  async function getUserDetail() {
    userServices
      .getIDwithEdit()
      .then((res) => {
        console.log("userDetail", res?.data?.data);
        setUserDetail(res?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401)
          Swal.fire({
            title: "",
            text: "Your session has expired. Please login to pick up where you left off.",
            imageUrl: require("../assets/images/thumbs-down.png").default,
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: "Custom image",
            animation: false,
          }).then(() => logout());
      });
  }
  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  let thumbnailImageUrlRef = useRef();
  const imagetypeRef = useRef();
  async function uploadImageOnIPFS(input) {
    const data = input;
    var imagetype = input?.type?.split("/");
    let thumbnailImageUrl;
    if (imagetype[0] === "video") {
      let props = { file: data }; // Remove Moralis reference as it's not needed now
      thumbnailImageUrl = await GetVideoThumbnails(props);
      thumbnailImageUrlRef.current = thumbnailImageUrl;
    }

    // Creating the form data object for Pinata upload
    const formData = new FormData();
    let imagename = Math.floor(Date.now() / 1000) + "." + imagetype[1];
    formData.append("file", data, imagename);

    // Optional: Add metadata or other options (not required for basic file upload)
    const metadata = JSON.stringify({
      name: imagename,
    });

    formData.append("pinataMetadata", metadata);

    // Pinata API call to upload the file
    try {
      const response = await axios.post(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            pinata_api_key: PINATA_API_KEY,
            pinata_secret_api_key: PINATA_SECRET_API_KEY,
          },
        }
      );

      const imgurl = `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;
      imagetypeRef.current = imagetype[0];
      setMineType(imagetypeRef.current);
      setimageFile(imgurl);

      // Save to draft or perform further operations with imgurl and imagetype
      SavetoDraft(imgurl, imagetype);

    } catch (error) {
      console.error("Error uploading to Pinata:", error);
    }
  }

  function validateSize(input) {
    const fileSize = input.size / 1024 / 1024; // in MiB

    if (fileSize > 50) {
      alert("File size exceeds 50 MB");
      return false;
    } else {
      return true;
    }
  }

  //checkfile type
  function checkfile(file_img) {
    var get_ext = file_img.name.split(".");

    var exts = ["png", "jpg", "jpeg", "gif", "mp4", "mov"];
    get_ext = get_ext.reverse();

    var a = exts.indexOf(get_ext[0].toLowerCase());

    if (a > -1) {
      return validateSize(file_img);
      // return true;
    } else {
      alert("Please select a valid image/video file");
      return false;
    }
  }

  function checkfileType(file_img) {
    var get_ext = file_img.name.split(".");

    var exts = ["png", "jpg", "jpeg", "gif"];
    get_ext = get_ext.reverse();

    var a = exts.indexOf(get_ext[0].toLowerCase());

    if (a > -1) {
      return validateSize(file_img);
    } else {
      alert("Please select a valid image file");
      return false;
    }
  }

  function readURLColecttion(input) {
    let type = input.files[0].type.split("/");
    if (input.files && input.files[0]) {
      var filetype = checkfileType(input.files[0]);

      if (filetype === true) {
        setimageCollection(input.files[0]);
        setimageCollectionShow("");
        var reader = new FileReader();
        reader.onload = function (e) {
          setimageCollectionShow(e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
  }

  const handleCreatenft = async (e) => {
    e.preventDefault();
    if (validation()) {
      createCollectible();
    }
    onfocuse()
  };

  const [errors, setErrors] = useState({
    image: "",
    collection: "",
    qty: "",
    name: "",
    royality_percent: "",
    description: "",
    category: "",
    price: "",
    coverimageCollectionShow: "",
    imageCollectionShow: "",
    collectionName: "",
    collectionDesc: "",
  });

  function validationCollection() {
    let status = true;
    let error = { ...errors };
    if (!coverimageCollectionShow) {
      error.coverimageCollectionShow = "Please add cover photo.";
      status = false;
    }
    if (!imageCollectionShow) {
      error.imageCollectionShow = "Please add collection image.";
      status = false;
    }
    if (!collectionName) {
      error.collectionName = "Please enter collection name.";
      status = false;
    }
    if (!collectionDesc) {
      error.collectionDesc = "Please enter description.";
      status = false;
    }
    setErrors({ ...errors, ...error });
    return status;
  }

  function validation() {
    let status = true;
    let error = { ...errors };
    if (!imageFile && localStorage.getItem("imageId") === null) {
      error.image = "Please add media.";
      status = false;
    }
    if (!name) {
      error.name = "Please enter name.";
      status = false;
    }
    if (!description) {
      error.description = "Please enter Description.";
      status = false;
    }
    if (!category) {
      error.category = "Please select category.";
      status = false;
    }
    if (!qty) {
      error.qty = "Please enter number of copies.";
      status = false;
    } else if (qty == 0) {
      error.qty = "Please enter number of copies greater than 0.";
      status = false;
    }
    if (!collectionId) {
      error.collection = "Please select any collection.";
      status = false;
    }

    if (!price) {
      error.price = "Please enter price.";
      status = false;
    } else if (price == 0) {
      error.price = "Price cannot be zero.";
      status = false;
    }

    // if (royality_check && !royality_percent) {
    //   error.royality_percent = "Please enter valid royalty price percentage.";
    //   status = false;
    // } else if (royality_check && royality_percent == 0) {
    //   error.royality_percent = "Please enter royalty price percentage.";
    //   status = false;
    // } else if (royality_check && royality_percent > 20) {
    //   error.royality_percent =
    //     "Please enter royalty percentage less than or equal to 20%.";
    //   status = false;
    // }

    setErrors({ ...errors, ...error });
    return status;
  }


  const pinToPinata = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const pinataApiKey = PINATA_API_KEY; // Replace with your Pinata API Key
    const pinataSecretApiKey = PINATA_SECRET_API_KEY; // Replace with your Pinata Secret API Key

    try {
      const response = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          pinata_api_key: pinataApiKey,
          pinata_secret_api_key: pinataSecretApiKey,
        },
      });
      return `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;
    } catch (error) {
      console.error('Error uploading file to Pinata:', error);
      throw new Error('Failed to upload file to Pinata');
    }
  };

  const pinJsonToPinata = async (jsonData) => {

    const response = await axios.post(
      'https://api.pinata.cloud/pinning/pinJSONToIPFS',
      jsonData,
      {
        headers: {
          pinata_api_key: PINATA_API_KEY,
          pinata_secret_api_key: PINATA_SECRET_API_KEY,
        },
      }
    );

    return `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;
  };



  const createcollection = async () => {
    props.setShowLoader(true);
    let imgurl;
    // Uploading the main image to Pinata
    if (imageCollection) {
      imgurl = await pinToPinata(imageCollection);
    }
    let coverimage;
    // Uploading the cover image to Pinata
    if (coverimageCollection) {
      coverimage = await pinToPinata(coverimageCollection);
    }
    const collectiondata = {
      name: collectionName.replace(/[“”‘’]/g, ""),
      description: collectionDesc.replace(/[“”‘’]/g, ""),
      image: imgurl,
      cover_image: coverimage,
    };
    userServices.createCollection(collectiondata).then((res) => {
      if (res.status === 200) {
        props.setShowLoader(false);
        Swal.fire({
          title: "Success",
          text: "Collection created successfully.",
          imageUrl: require("../../src/assets/images/thumbs-up.png"),
          imageWidth: 80,
          imageHeight: 100,
          imageAlt: "Custom image",
          animation: false,
        })
        MyCollection()
        handleClose()
      }
    }).catch((err) => {
      props.setShowLoader(false);
      Swal.fire({
        title: "Error",
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: "Ok",
      })
    })

  };

  const handleSwitchError = (error) => {
    props.setShowLoader(false);
    if (error.code === 4902) {
      swal("Warning", "Add " + blockchain + " network in your wallet.", "warning");
    } else if (error.code === 4001) {
      createcollection(); // Retry on user rejection
    }
  };

  const handleTransactionError = (error) => {
    props.setShowLoader(false);
    swal(
      "Warning",
      error?.code === "-32603"
        ? "Gas fee low, try increasing for Metamask acceptance."
        : error.message,
      "warning"
    );
  };

  const checkname = async () => {
    const Monster = Moralis.Object.extend("Collections");
    const query = new Moralis.Query(Monster);
    query.equalTo("name", collectionName);
    const results = await query.first();
    if (results) {
      setcollectionErr("Name is already taken");
    } else {
      setcollectionErr();
    }
  };

  function readURLColecttionCover(input) {
    //
    let type = input?.files[0]?.type?.split("/");

    if (input.files && input.files[0]) {
      var filetype = checkfileType(input.files[0]);
      if (filetype === true) {
        setcoverimageCollection(input.files[0]);
        setcoverimageCollectionShow("");
        var reader = new FileReader();
        reader.onload = function (e) {
          setcoverimageCollectionShow(e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
  }

  const handlechangeroyality_check = (e) => {
    set_royality_check(e.target.checked);
    if (!e.target.checked) {
      setroyality_percent(0)
    }
  };

  const addMoreFields = () => {
    var temp = [...addLevel];
    temp.push({ key: "", value1: "", value2: "" });
    setAddLevel(temp);
  };

  const createCollectible = async (file) => {
    const params = {
      name: name.replace(/[“”‘’]/g, ""),
      description: description.replace(/[“”‘’]/g, ""),
      image: imageFile,
      thumbnailImage: thumbnailImageUrlRef.current,
      draft_id: draft_id,
      collectionId: collectionId,
      qty: qty,
      category: category,
      price: price,
      nft_media_type: imagetypeRef.current ? imagetypeRef.current : ""
    };
    props.setShowLoader(true);
    userServices.createSingleNft(params).then((resp) => {
      if (resp.status === 200) {
        Swal.fire({
          title: "Success",
          text: "Trading Card created successfully.",
          imageUrl: require("../../src/assets/images/thumbs-up.png"),
          imageWidth: 80,
          imageHeight: 100,
          imageAlt: "Custom image",
          animation: false,
        }).then(() => {
          if (userDetail?.isNftCreated ==0){
            history("/Add-bank?nft=yes");
             return; 
           }
           else{
             history("/explore", { replace: true })
           }
         })
        props.setShowLoader(false);

      }
    })

  };
  // console.log("nftId",nftId)

  const mintCollectible = async (
    metadataUrl,
    price,
    collectionId,
    royality_percent,
    qty,
    useWinterId
  ) => {
    var user = await Moralis.User.current();
    const userAddress = user.get("ethAddress");

    let propsParams = {
      chain: blockchain,
      params: [
        collectionAddressCurrent.current,
        collectionId,
        qty,
        metadataUrl,
        price,
        royality_percent,
        useWinterId || 0,
      ],
      nftType: "M",
      methodName: "createCollectible",
      sendParams: { from: userAddress },
    };
    let gasList = await getEstimateGasFee(propsParams);
    console.log("gasList--------------->", gasList);

    setmessageLoader("Loading...");
    await collectionContractAddress.methods
      .createCollectible(
        collectionAddressCurrent.current,
        collectionId,
        qty,
        metadataUrl,
        price,
        royality_percent,
        useWinterId || 0
      )
      .send({ from: userAddress, gas: gasList[1], gasPrice: gasList[0] })
      .on("transactionHash", function (hash) {
        setmessageLoader("Initiating");
        setmessageLoader("Waiting for receipt.");
      })
      .on("receipt", function (tx) {
        console.log("tx", tx);
        setonSale(true);
        approveForsale(userAddress);
        setmessageLoader("Got reciept!!");
        localStorage.removeItem("imageId");
        setmessageLoader(
          "Transaction submitted. Item will be available in your profile once we receive a blockchain confirmation."
        );
      })
      .on("confirmation", function (conf) {
        console.log("conf for mutiple", conf);
        setmessageLoader("");
        setmessageLoader("Confirmed!!");
      })
      .on("error", async function (error) {
        if (
          error ==
          "Error: Transaction was not mined within 50 blocks, please make sure your transaction was properly sent. Be aware that it might still be mined!"
        ) {
        } else {
          setShowstep(false);
        }
        setUpload(false);
        // swal("Warning", error.message, "warning");
        swal(
          "Warning",
          error?.code == "-32603"
            ? "Gas fee low, try increasing for Metamask acceptance."
            : error.message,
          "warning"
        );
      });
  };
  const approveForsale = async (nft_owner) => {
    let propsParams = {
      chain: blockchain,
      params: [collectionAddressCurrent.current, true],
      nftType: "M",
      methodName: "setApprovalForAll",
      sendParams: { from: nft_owner },
    };
    let gasList = await getEstimateGasFee(propsParams);
    console.log("gasList--------------->", gasList);

    try {
      await collectionContractAddress.methods
        .setApprovalForAll(collectionAddressCurrent.current, true)
        .send({ from: nft_owner, gas: gasList[1], gasPrice: gasList[0] })
        .on("transactionHash", function (hash) { })
        .on("receipt", function (tx) {
          console.log("tx a f s", tx);
          setapproved(true);
          setTimeout(() => {
            navigate("/profile", {
              state: { fromCreateCollection: true },
            });
            navigate("/profile");
          }, 1000);
        })
        .on("confirmation", async function (conf) { })
        .on("error", async function (error) {
          setShowstep(false);
          swal(
            "Warning",
            error?.code == "-32603"
              ? "Gas fee low, try increasing for Metamask acceptance."
              : error?.message,
            "warning"
          ).then((res) => {
            navigate("/profile");
          });
        });
    } catch (error) { }
  };
  const addMoreProperties = () => {
    var temp = [...Propertiesvalues];
    temp.push({ name: "", value1: "" });
    setPropertiesvalues(temp);
  };
  const deletePro = (i) => {
    var temp = [...Propertiesvalues];
    temp.splice(i, 1);
    setPropertiesvalues(temp);
  };
  const deleteSta = (i) => {
    var temp = [...Statevalues];
    temp.splice(i, 1);
    setPropertiesvalues(temp);
  };

  function handleChangePro(i, event, _key1) {
    const values = [...Propertiesvalues];
    if (_key1 == "name") {
      values[i].name = event;
    }
    if (_key1 == "value1") {
      values[i].value1 = event;
    }

    setPropertiesvalues(values);
  }

  const savePro = () => {
    setPropertiesvaluesSave(Propertiesvalues);
    SetShowvaluePro(false);
    // console.log(Propertiesvalues);
  };

  function handleChangelevel(i, event, _key1) {
    const values = [...addLevel];
    if (_key1 == "key") {
      values[i].key = event;
    }

    if (_key1 == "value1") {
      values[i].value1 = event;
      values[i].value2 = event;
    }

    if (_key1 == "value2") {
      values[i].value2 = event;
    }

    setAddLevel(values);
  }

  const savelavel = () => {
    // console.log(addLevel);
    setAddLevelSave(addLevel);
    SetShowvalue(false);
  };

  const handleClosevalueSta = () => {
    setStatevalues(StatevaluesSave);
    SetShowvalueSta(false);
  };
  function handleChangeSta(i, event, _key1) {
    const values = [...Statevalues];
    if (_key1 == "name") {
      values[i].name = event;
    }
    if (_key1 == "value1") {
      values[i].value1 = event;
    }
    if (_key1 == "value2") {
      values[i].value2 = event;
    }
    setPropertiesvalues(values);
  }

  const addMoreState = () => {
    var temp = [...Statevalues];
    temp.push({ name: "", value1: "", value2: "" });
    // console.log(temp);
    setStatevalues(temp);
  };

  const saveStae = () => {
    // console.log(Statevalues);
    setStatevaluesSave(Statevalues);
    SetShowvalueSta(false);
  };

  const handleClosevaluePro = () => {
    setPropertiesvalues(PropertiesvaluesSave);
    SetShowvaluePro(false);
  };

  const handleClosevalue = () => {
    setAddLevel(addLevelSave);
    SetShowvalue(false);
  };

  useEffect(() => {
    getprice();
  }, []);
  const [usdprice, setusdprice] = useState();

  const getprice = async () => {
    await nftService
      .get_price()
      .then((response) => {
        setusdprice(response.data.USD);
      })
      .catch((err) => {
      });
  };

  /*-------------on focuse fiels functionality--------------*/
  function onfocuse() {
    if (!imageFile && localStorage.getItem("imageId") === null) {
      document.getElementById("file").focus()
    } else if (!name) {
      document.getElementById("name").focus()

    } else if (!description) {
      document.getElementById("description").focus()

    } else if (!category) {
      document.getElementById("category").focus()

    } else if (!qty) {
      document.getElementById("qty").focus()

    } else if (qty == 0) {
      document.getElementById("qty").focus()

    } else if (!collectionId) {
      document.getElementById("collection").focus()

    }
    else if (!price) {
      document.getElementById("price").focus()

    } else if (price == 0) {
      document.getElementById("price").focus()

    } else {

    }
  }

  return (
    <>
      <ToastContainer />
      <Row className="crete-collect-new">
        <Col lg={8}>
          <div className="collectible-box signle-area-collectibla-box">
            <Form.Control
              type="file"
              id="file"
              className="uplage-image-collect"
              onChange={(e) => readURL(e.target)}
            />
            {/* new upload img */}
            <div className="collectible-upload-image text-center">
              <div className="collectible-bg">
                <i className="fa fa-upload" aria-hidden="true"></i>
                <span>PNG, GIF, JPG, mp4 Or JPEG</span>
                <h6 className="mt-2">Browse Files</h6>
              </div>
            </div>
            <div className="preview-area preview-mobile-view">
              <h6>Preview</h6>
              <p className="preview-text single-nft-preview">
                Preview of your new collectible
              </p>
              {images != "" ? (
                <>
                  <div className="preview-image">
                    {imageFile &&
                      mimeType === "video" ? (
                      <video
                        className="video-container video-container-overlay"
                        // autoPlay
                        muted
                        loop
                        controls={false}
                        data-reactid=".0.1.0.0"
                      >
                        <source src={images} />
                      </video>

                    ) : (
                      <img src={images} alt="images"></img>
                    )}
                  </div>

                  <h5 className="mb-1 mt-2">{name}</h5>
                  <p className="mb-1 description-text">{description}</p>
                  <h4 className="priceh4">
                    <span>{price && price !== "null" ? "$" + Number(price)?.toFixed(2) : ""}</span>
                  </h4>
                </>
              ) : (
                ""
              )}
            </div>
            {!imageFile && errors?.image ? (
              <span className="errors">{errors.image}</span>
            ) : (
              <></>
            )}
            {/* new upload img */}
            <div className="name-description-filed single-nft-detail-area">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    maxLength="25"
                    type="text"
                    value={name}
                    id="name"
                    placeholder="Enter Name"
                    onKeyPress={(e) => {
                      if (e.key === " " && e.target.value == 0)
                        e.preventDefault();
                    }}
                    onChange={(e) => setName(e.target.value)}
                    // onBlur={SavetoDraft}
                    onBlur={(e) => {
                      if (e?.target?.value?.length > 0) {
                        SavetoDraft()
                      }
                    }}
                  />
                  {!name && errors?.name ? (
                    <span className="errors">{errors.name}</span>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={description}
                    maxLength="500"
                    id="description"
                    placeholder="Enter Description"
                    onKeyPress={(e) => {
                      if (e.key === " " && e.target.value == 0)
                        e.preventDefault();
                    }}
                    onChange={(e) => setdescription(e.target.value)}
                    onBlur={(e) => {
                      if (e?.target?.value?.length > 0) {
                        SavetoDraft()
                      }
                    }}
                  />
                  {!description && errors?.description ? (
                    <span className="errors">
                      {errors.description}
                      <br />
                    </span>
                  ) : (
                    <></>
                  )}

                  <Form.Text className="text-muted">
                    With preserved line break
                  </Form.Text>
                </Form.Group>
                {projects.length > 0 ? (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                    style={{ position: "relative" }}
                  >
                    <Form.Label>Project</Form.Label>
                    <i
                      className="fa fa-angle-down down-arrow"
                      aria-hidden="true"
                    ></i>

                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => { setprojectId(e.target.value) }}
                    // onBlur={SavetoDraft}
                    >
                      <option value={""}>Please select</option>;
                      {projects.length > 0 ? (
                        projects.map((item, i) => {
                          return (
                            <option value={item.id} key={i}>
                              {item.project_name}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Form.Select>
                  </Form.Group>
                ) : null}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                  style={{ position: "relative" }}
                >
                  <Form.Label>Category</Form.Label>
                  <Form.Select
                    value={category}
                    id="category"
                    aria-label="Default select example"
                    onChange={(e) => setcategory(e.target.value)}
                    onBlur={(e) => {
                      if (e?.target?.value?.length > 0) {
                        SavetoDraft()
                      }
                    }}
                  >
                    <option value={""}>Please select Category</option>
                    {categories.length > 0 ? (
                      categories.map((item) => {
                        return <option value={item}>{item}</option>;
                      })
                    ) : (
                      <></>
                    )}
                  </Form.Select>
                  {!category && errors?.category ? (
                    <span className="errors">{errors.category}</span>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                <div className="create-items">
                  <Form.Label>Collection</Form.Label>
                  <ul>
                    {CollectionList && CollectionList.length < 5 ? (
                      <li onClick={handleShow}>
                        <div className="items-area-box">
                          <div className="plus-add">
                            <svg
                              className=""
                              width="24"
                              height="24"
                              viewBox="0 0 16 16"
                              fill="inherit"
                            >
                              <path d="M8.667 4.667C8.667 4.298 8.368 4 8 4s-.667.298-.667.667v2.667H4.667C4.298 7.333 4 7.632 4 8s.298.667.667.667h2.667v2.667c0 .368.298.667.667.667s.667-.298.667-.667V8.667h2.667c.368 0 .667-.298.667-.667s-.298-.667-.667-.667H8.667V4.667z"></path>
                            </svg>
                          </div>
                          <h6 id="collection">Create</h6>
                          <p>Collection</p>
                        </div>
                      </li>
                    ) : (
                      <></>
                    )}
                    {CollectionList && CollectionList.length > 0 ? (
                      CollectionList.map((item) => {
                        // console.log("item:::",item)
                        return (
                          <li
                            onClick={() => setcollectionId(item.id)}
                            className={
                              collectionId === item.id
                                ? "activedata"
                                : "inactivedata"
                            }
                          >
                            <div className="items-area-box-two">
                              <img
                                src={item.collectionImage}
                                width="52px"
                              ></img>
                              <h6>{item.collection_name}</h6>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </ul>
                  {!collectionId && errors?.collection ? (
                    <span className="errors">{errors.collection}</span>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="auction-toggle area">
                  <div className="instant-sale-prize">
                    <Form.Label>Number of copies</Form.Label>
                    <br />
                    <Form.Control
                      id="qty"
                      controlId="formBasicnumber"
                      maxLength={10}
                      onKeyDown={(e) =>
                        symbolsArr.includes(e.key) && e.preventDefault()
                      }
                      onChange={(e) => {
                        if (/^(\d)*(\.)?(\d)*$/.test(e.target.value)) {
                          setqty(e.target.value);
                        } else {
                          return false;
                        }
                      }}
                      onBlur={(e) => {
                        if (e?.target?.value?.length > 0) {
                          SavetoDraft()
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.key === " " && e.target.value == 0)
                          e.preventDefault();
                      }}
                      value={qty}
                      type="text"
                      className="copies-input"
                      placeholder="Eg. 10"
                    />

                  </div>
                </div>
                {(!qty || qty == 0) && errors?.qty ? (
                  <span className="errors">{errors.qty}</span>
                ) : (
                  <></>
                )}

                <div className="auction-toggle area">
                  <div className="instant-sale-prize">
                    <Form.Label>Instant sale price</Form.Label>
                    <br />
                    <Form.Text className="text-muted">
                      Enter the price for which the item will be instantly sold
                    </Form.Text>
                    <br />
                    <div className="input-group">
                      <span className="input-group-text">$</span>
                      <Form.Control
                        type="number"
                        placeholder="Enter Price"
                        value={price}
                        id="price"
                        min={1} // Minimum value set to 1
                        maxLength={15}
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Ensure value is a valid number and not less than 1
                          if (/^(\d)*(\.)?(\d)*$/.test(value) && value >= 1) {
                            setprice(value);
                          } else if (value === "") {
                            setprice("");
                          }
                        }}
                        onBlur={(e) => {
                          if (e?.target?.value?.length > 0) {
                            SavetoDraft();
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key === " " && e.target.value == 0) e.preventDefault();
                        }}
                      />
                    </div>
                  </div>
                </div>

                {(!price || price == 0) && errors?.price ? (
                  <span className="errors">{errors.price}</span>
                ) : null}


              </Form>
            </div>

            <div className="create-nft-bottom-btns">
              <div className="create-btn-left">
                <button
                  type="submit"
                  className="create-bt"
                  onClick={handleCreatenft}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="preview-area preview-desktop-view">
            <h6>Preview</h6>
            <p className="preview-text single-nft-preview">
              Preview of your new collectible
            </p>
            {images != "" ? (
              <>
                {imageFile ? <div className="preview-image">
                  {imageFile && mimeType == "video" ? (
                    <video
                      className="video-container video-container-overlay"
                      autoPlay
                      muted
                      loop
                      controls={false}
                      data-reactid=".0.1.0.0"
                    >
                      <source src={images} />
                    </video>
                  ) : (
                    <img src={images} alt="images"></img>
                  )}
                </div>
                  :
                  <div className="preview-image">
                    <Skeleton height={200} />
                  </div>
                }

                <h5 className="mb-1 mt-2">{name}</h5>
                <p className="mb-1 description-text">{description}</p>
                <h4 className="priceh4">
                  <span>{price && price !== "null" ? "$" + Number(price)?.toFixed(2) : ""}</span>
                </h4>
              </>
            )
              : null
            }
          </div>
        </Col>
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        className="text-center meta-mask-modal collection-create collection-up-both-img"
      >
        <Modal.Header closeButton>
          <Modal.Title>Collection</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-5">
          <div className="cover-image-box">
            {!coverimageCollectionShow && errors?.coverimageCollectionShow && (
              <span className="errors">
                {errors?.coverimageCollectionShow}
                <br />
              </span>
            )}
            {coverimageCollectionShow ? (
              <img src={coverimageCollectionShow} alt=""></img>
            ) : (
              <img
                src={require("../assets/images/user-profile-new.png").default}
                alt="image"
              ></img>
            )}
            <Form.Group controlId="formFileSm" className="mb-3">
              <Form.Control
                type="file"
                size="lg"
                className=" img-input-mod"
                onChange={(e) => readURLColecttionCover(e.target)}
              />
            </Form.Group>
          </div>

          <div className="modal-profile-img">
            {imageCollectionShow ? (
              <img src={imageCollectionShow} alt="image"></img>
            ) : (
              <img
                src={require("../assets/images/user-profile.png")}
                alt="image"
              ></img>
            )}
            <Form.Group controlId="formFileSm" className="mb-3">
              <Form.Control
                type="file"
                size="lg"
                className="profile-input-mod"
                onChange={(e) => readURLColecttion(e.target)}
              />
            </Form.Group>
          </div>
          {!imageCollectionShow && errors?.imageCollectionShow && (
            <span className="errors">
              {errors?.imageCollectionShow}
              <br />
            </span>
          )}

          <Form className="text-left finda-display">
            <Form.Group className="mb-3 text-left" controlId="formBasicEmail">
              <Form.Label>Display Name (required)</Form.Label>
              <Form.Control
                type="text"
                onBlur={checkname}
                maxLength={25}
                value={collectionName}
                placeholder="Enter collection name"
                onChange={(e) => setcollectionName(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === " " && e.target.value == 0)
                    e.preventDefault();
                }}
              />
              {!collectionName && errors?.collectionName && (
                <span className="errors">
                  {errors?.collectionName}
                  <br />
                </span>
              )}
              <Form.Text className="text-muted ">
                Token Name cannot be changed in future
              </Form.Text>

              <br></br>
              <Form.Label className="mt-3">Description (required)</Form.Label>
              <Form.Control
                maxLength={500}
                as="textarea"
                rows={2}
                value={collectionDesc}
                placeholder="Enter collection Description"
                onChange={(e) => setcollectionDesc(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === " " && e.target.value == 0)
                    e.preventDefault();
                }}
              />
              {!collectionDesc && errors?.collectionDesc && (
                <span className="errors">{errors?.collectionDesc}</span>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <ModalFooter>
          <button
            type="button"
            className="craete-bt submit-bt"
            onClick={handleCreateCollection}
          >
            Create Collection
          </button>
        </ModalFooter>
      </Modal>

    </>
  );
};
export default Multiple_nft;
